import React from 'react'
import ContentAbout from './About/Content/ContentAbout'
import HeaderPrivacyAbout from './Privacy/Header/HeaderPrivacyAbout'
import { Helmet } from 'react-helmet'
import HeaderCC from './PanduanPembayaran/CC/Header/HeaderCC'
import ContentCC from './PanduanPembayaran/CC/Content/ContentCC'
import Footer from '../components/UI/Footer'

const VirtualAccount = () => {
   return (
      <>
         <Helmet>
            <title>E2Pay - Panduan Pembayaran Credit Card</title>
            <meta name="description" content="Panduan Pembayaran Credit Card Page" />
         </Helmet>
         <HeaderCC />
         <ContentCC />
         {/* <Footer/> */}
      </>
   )
}

export default VirtualAccount
