import React from 'react'
import { ContainerEko } from '../../../components/Layout/Ekosistem/Ekosistem.styled'
import ListProduk from '../../../components/Layout/Ekosistem/ListProduk'
import TerimaPembayaran from '../../../components/UI/TerimaPembayaran'

const ContentEko = () => {
   return (
      <ContainerEko>
         <ListProduk />
         <TerimaPembayaran />
      </ContainerEko>
   )
}

export default ContentEko
