import React from 'react'
import VirtualAccount from '../../../../components/Layout/PanduanPembayaran/va/VirtualAccount'
import { Container } from '../../../../components/Layout/PanduanPembayaran/va/VirtualAccount.styled'
import CertificateContent from '../../../../components/Layout/About/CertificateContent'
import NarasiE2payrazer from '../../../../components/Layout/About/NarasiE2payrazer'

const ContentVA = () => {
   return (
      <Container>
         <VirtualAccount />
         {/* <CertificateContent />
         <NarasiE2payrazer /> */}
      </Container>
   )
}

export default ContentVA
