import React from "react";
import {
  Arrow,
  Info,
  Subarrow,
  TahunWrap,
  WrapInfo,
} from "./InfoPerusahaan.styled";
import Card from "./Card"
import { TextDesc } from "../../UI/Text.styled";
import InfoTahun from "./InfoTahun";
import WebLogoInfinet from "../../../assets/images/web_logo_infinetworks.png";
import WebLogoE2Pay from "../../../assets/images/web_logo_E2Pay_S.png";
import WebLogoRazerfintech from "../../../assets/images/web_logo_razerfintech.png";
const InfoPerusahaan = () => {
  return (
    <WrapInfo>
      <Card></Card>
      {/* <InfoTahun
        tahun="2000"
        image={WebLogoInfinet}
        narasi="Sebagai perusahaan yang berpengalaman sejak tahun 2000 dalam implementasi layanan e-banking, Infinetworks melihat geliat tumbuhnya industri e-commerce tanah air yang semakin membutuhkan tersedianya solusi layanan pembayaran yang aman, lengkap dan mudah."
      />
      <InfoTahun
        tahun="2012"
        image={WebLogoE2Pay}
        narasi="PT. E2Pay Global Utama didirikan pada tahun 2012 sebagai bagian dari PT Infinetworks Global dengan berbekal relasi, teknologi dan reputasi terpercaya di perbankan nasional."
      />
      <InfoTahun
        tahun="2022"
        image={WebLogoRazerfintech}
        narasi="Akusisi Razer Fintech Company atas PT. E2Pay Global Utama akan memperluas solusi pembayaran digital organisasi ke Indonesia."
        narasi1="Sinergi antara E2Pay dan Razer Fintech Company memungkinkan kedua organisasi untuk tumbuh lebi luas serta meningkatkan jangkakuan platform di seluruh Asia Tenggara."
      /> */}
      
    </WrapInfo>
  );
};

export default InfoPerusahaan;
