import styled from 'styled-components'

export const ContainerCP = styled.div`
   margin-top: 100px;
   width: 100%;
   max-height: 340px;
   background-color: #edf6ff;
   display: flex;
   align-items: flex;
   margin-bottom: 100px;
 padding-bottom: 70px !important;

`

export const LogoRating = styled.div`
   width: 74px;
   height: 75px;
   border-radius: 19px;
   background-image: linear-gradient(to bottom, #f04c24, #d93574);
`

export const ImageCP = styled.div`
   // border: solid;
   margin-top: -75px;
   position: absolute;
   width: 90%;
   height: 200%;
   background-image: url(${(props) => props.bgImg});
   background-repeat: none;

   @media screen and (max-width: 768px) {
      display: none;
   }

`
