import React from "react";
import { TextTitle } from "../../UI/Text.styled";
import { ContentProduk, FungsiItem } from "./FungsiProduk.styled";
import { ContainerPG } from "./JangkauPenerimaan.styled";
import { IconTB } from "./TujuanBisnis.styled";
import sprite_icon_group from "../../../assets/images/sprite_icon_group.png";
import imgFungsiProduk1 from "../../../assets/images/img-fungsi-produk-1.png";
import imgFungsiProduk2 from "../../../assets/images/img-fungsi-produk-2.png";
import { LogoBg } from "../../UI/Logo.styled";
import click from "../../../assets/images/db/click.svg";
import coffee from "../../../assets/images/db/coffee.svg";
import secure from "../../../assets/images/db/secure.svg";
import people from "../../../assets/images/db/people.svg";
import target from "../../../assets/images/db/target.svg";
import wallet from "../../../assets/images/db/wallet.svg";
import charts from "../../../assets/images/db/charts.svg";
import bank from "../../../assets/images/db/bank.svg";
import bca from "../../../assets/images/payment/bca.png";
import mandiri from "../../../assets/images/payment/mandiri.png";
import cimb from "../../../assets/images/payment/cimb.png";
import bri from "../../../assets/images/payment/bri.png";
import mbayar from "../../../assets/images/payment/m-bayar.png";
import gopay from "../../../assets/images/payment/gopay.png";
import ovo from "../../../assets/images/payment/ovo.png";
import shopee from "../../../assets/images/payment/shopee.png";
import dana from "../../../assets/images/payment/dana.png";

const FungsiProduk = () => {
  // const TitleFungsi = [
  //    {
  //       title: 'Cukup satu klik untuk beragam akun dan bank',
  //       position: '0 -270px',
  //       icon: click

  //    },
  //    {
  //       title: 'Transer online ke banyak penerima sekaligus',
  //       position: '0 -330px',
  //       icon: people

  //    },
  //    {
  //       title: <i>Dual Authorization</i>,
  //       position: '0 -360px',
  //       icon: lock2

  //    },
  //    {
  //       title: 'Transfer ke semua bank di Indonesia',
  //       position: '0 -300px',
  //       image: "add gambar logo bank soon",
  //       // image: imgFungsiProduk2,
  //       icon: bank

  //    },
  //    {
  //       title: 'Mendukung transfer ke dompet digital',
  //       position: '0 -390px',
  //       // image: imgFungsiProduk1,
  //       image: "add gambar logo emoney soon",
  //       icon: wallet

  //    },
  //    {
  //       title: 'Sederhana & mudah digunakan',
  //       position: '0 -42px',
  //       icon: coffee

  //    },
  // ]

  return (
    <ContainerPG>
      <ContentProduk>
        <div className="noPic">
          <div className="parts">
            <img src={click}></img>
            <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
              Cukup satu klik untuk beragam akun dan bank
            </TextTitle>
          </div>
          <div className="parts">
            <img src={people}></img>
            <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
              Transfer online ke banyak penerima sekaligus
            </TextTitle>
          </div>
          <div className="parts">
            <img src={secure}></img>
            <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
              Dual Authorization
            </TextTitle>
          </div>
          <div className="parts">
            <img src={coffee}></img>
            <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
              Sederhana & mudah digunakan
            </TextTitle>
          </div>
        </div>

        <div className="withPic">
          <img src={bank}></img>
          <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
            Transfer ke semua bank di Indonesia
          </TextTitle>
          <div className="pic">
            <div className="box">
              <img src={bca}></img>
            </div>
            <div className="box">
              <img src={mandiri}></img>
            </div>
            <div className="box">
              <img src={cimb}></img>
            </div>
            <div className="box">
              <img src={bri}></img>
            </div>
            <div className="box">
              {" "}
              <span>✔</span> &nbsp; Semua bank lainnya
            </div>
          </div>
        </div>

        <div className="withPic">
          <img src={wallet}></img>
          <TextTitle fw={"bold"} size={"22px"} color={"#1C278B"}>
            Mendukung transfer ke dompet digital
          </TextTitle>
          <div className="pic">
            <div className="box">
              <img src={mbayar}></img>
            </div>
            <div className="box">
              <img src={gopay}></img>
            </div>
            <div className="box">
              <img src={ovo}></img>
            </div>
            <div className="box">
              <img src={shopee}></img>
            </div>
            <div className="box">
              <img src={dana}></img>
            </div>
          </div>
        </div>

        {/* {TitleFungsi.map((item, index) => {
               return (
                  <FungsiItem className="d-flex flex-nowrap gap-2" key={index}>
                     <div>
                        <IconTB
                           bgImg={item.icon}
                           // bgPosition={item.position}
                        />
                     </div>
                     <div>
                        <TextTitle fw={'bold'} size={'22px'} color={"#1C278B"}>
                           {item.title}
                        </TextTitle>
                        {item.image && (
                           <LogoBg
                              width="272px"
                              height="121px"
                              // ImgBg={item.image}
                           > {item.image} </LogoBg>
                        )}
                     </div>
                  </FungsiItem>
               )
            })} */}
      </ContentProduk>
    </ContainerPG>
  );
};

export default FungsiProduk;
