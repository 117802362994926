import React from 'react'
import { ContentLeft, TabTitle } from './Tabs/TabsFaq.styled'
import Nav from 'react-bootstrap/Nav'

const TabLeft = (props) => {
   return (
      <ContentLeft>
         <TabTitle isActive={props.active === 'payment-gateway-desktop'}>
            <Nav.Link className="link" eventKey="payment-gateway-desktop">
               Payment Gateway
            </Nav.Link>
         </TabTitle>
         <TabTitle isActive={props.active === 'disbursement-desktop'}>
            <Nav.Link className="link" eventKey="disbursement-desktop">
               Disbursement
            </Nav.Link>
         </TabTitle>
         <TabTitle isActive={props.active === 'biller-gateway-desktop'}>
            <Nav.Link className="link" eventKey="biller-gateway-desktop">
               Biller Gateway
            </Nav.Link>
         </TabTitle>
         <TabTitle isActive={props.active === 'm-bayar-desktop'}>
            <Nav.Link className="link" eventKey="m-bayar-desktop">
               E-Money 
            </Nav.Link>
         </TabTitle>
         {/* <TabTitle isActive={props.active === 'qris-desktop'}>
            <Nav.Link className="link" eventKey="qris-desktop">
               QRIS
            </Nav.Link>
         </TabTitle> */}
      </ContentLeft>
   )
}

export default TabLeft
