import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
   ContentLeft,
   LogoTagline,
   WrapTxtTagline,
} from './TaglineMbayar.styled'
import { TextDesc } from '../../UI/Text.styled'

import LogoOutlet from '../../../assets/images/web_icon_outlets.svg'
import LogoTartiktunai from '../../../assets/images/web_icon_tariktunai.svg'
import LogoDonasi from '../../../assets/images/web_icon_donasi.svg'
import LogoTransfer from '../../../assets/images/transfer.svg'

const LeftContent = () => {
   return (
      <Col className="d-flex flex-column gap-5" md={4}>
         {/* <ContentLeft>
            <LogoTagline className="mx-3" bgImg={LogoOutlet} />
            <WrapTxtTagline className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="60px">
                  Belanja di Seluruh Merchant QRIS
               </TextDesc>

               <TextDesc size={'14px'} height="60px">
                  Tanpa kartu kredit atau rekening bank pun Anda bisa menikmati
                  belanja online atau offline dengan mudah kapan saja dan di
                  mana saja. [Segera hadir]
               </TextDesc>
            </WrapTxtTagline>
         </ContentLeft> */}
         <ContentLeft>
            <LogoTagline className="mx-3 mt-5" bgImg={LogoTartiktunai} />
            <WrapTxtTagline className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Tarik Tunai
               </TextDesc>
               <TextDesc size={'14px'} height="30px">
                  Tarik tunai di outlet yang bekerjasama dengan M-Bayar. [Segera
                  hadir]
               </TextDesc>
            </WrapTxtTagline>
         </ContentLeft>
         <ContentLeft>
            <LogoTagline className="mx-3" bgImg={LogoTransfer} />
            <WrapTxtTagline className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Transfer
               </TextDesc>
               <TextDesc size={'14px'} height="30px">
                  Transfer uang bisa langsung sampai ke sesama akun pengguna
                  M-Bayar atau ke rekening bank di seluruh Indonesia.
               </TextDesc>
            </WrapTxtTagline>
         </ContentLeft>
         <ContentLeft>
            <LogoTagline className="mx-3" bgImg={LogoDonasi} />
            <WrapTxtTagline className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Donasi
               </TextDesc>
               <TextDesc size={'14px'}>
                  Donasi online terpercara di M-Bayar.
               </TextDesc>
            </WrapTxtTagline>
         </ContentLeft>
      </Col>
   )
}

export default LeftContent
