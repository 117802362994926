import React from 'react'
import ContentDetailNewsPortal from './NewsPortal/ContentDetail/ContentDetailNewsPortal'
import HeaderDetailNewsPortal from './NewsPortal/HeaderDetail/HeaderDetailNewsPortal'
import { Helmet } from 'react-helmet'

const DetailNewsPortal = () => {
   return (
      <>
      <Helmet>
        <title>E2Pay - Detail Article</title>
        <meta name="description" content="E2pay Article Page" />
      </Helmet>
         <HeaderDetailNewsPortal />
         <ContentDetailNewsPortal />
      </>
   )
}

export default DetailNewsPortal
