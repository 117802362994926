import React from 'react'
import {
   ContentJaringan,
   ContentMapJaringan,
   ContentTextJaringan,
   WrapJaringan,
} from '../../UI/Content.styled'
import { LinkCustom } from '../../UI/Link.styled'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import MapJaringan from '../../../assets/images/asean-map.gif'

const InfoJaringan = () => {
   return (
      <ContentJaringan>
         <WrapJaringan>
            <ContentMapJaringan>
               {/* <p>OOO</p> */}
               <LazyLoadImage src={MapJaringan} alt="map jaringan" />
            </ContentMapJaringan>
            <ContentTextJaringan>
               <div style={{ maxWidth: '500px' }}>
                  <TextTitle
                     size="38px"
                     color="#ffffff"
                     fontsm="25px"
                     poppins
                     bold
                  >
                     Jaringan Terlengkap di Asia Tenggara
                  </TextTitle>
               </div>
               <TextDesc color="#ffffff" poppins regular>
                  Kami mengerti keamanan data transaksi adalah yang terpenting.
                  Maka dari itu, kami melakukan upaya terbaik untuk memberikan
                  keamanan pada setiap transaksi Anda.
               </TextDesc>
               <LinkCustom to="https://fiuu.com/" target="_blank">
                  <TextDesc color="#44d62c" poppins bold>
                     Lebih Lanjut <span color="#44d62c"> &#x3e;</span>{' '}
                  </TextDesc>
               </LinkCustom>
            </ContentTextJaringan>
         </WrapJaringan>
      </ContentJaringan>
   )
}

export default InfoJaringan
