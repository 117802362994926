import styled from "styled-components";

export const Jumbotron = styled.header`
  width: 100%;
  height: 100vh;
  /* min-height: 680px; */
  /* min-height: ${(props) => props.minHeight || '680px'}; */

  /* background-image: url(${(props) => props.imageurl}); */
  /* background-color: green; */
  /* background-repeat: no-repeat; */
 
  /* background-size: cover; */
  position: relative;
  padding: 3rem 2rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding: 0;
    /* height: 200px; */
  }
`;

//  background: linear-gradient(240deg, #44d62c, #0b1551, transparent 70%),
//     /* linear-gradient(240deg, #44d62c, #0b1551, transparent 70%), */
//       /* linear-gradient(331deg, #44d62c, #0b1551, transparent 70%), */
//       radial-gradient(
//         at bottom left,
//         #1c278b,
//         #35ad22,
//         #0b1551,
//         transparent 70%
//       ),
//     linear-gradient(90deg, #0b1551, #1c278b, transparent 71%);
//   /* linear-gradient(40deg, #44d62c, #0b1551, transparent 70%), */
//   /* linear-gradient(159deg, #0b1551, #1c278b, transparent 71%); */
//   background-size: 300% 200%;
//   animation: gradient-animation 8s ease infinite;

//   @keyframes gradient-animation {
//     0% {
//       background-position: 0% 50%;
//     }
//     50% {
//       background-position: 100% 50%;
//     }
//     100% {
//       background-position: 0% 50%;
//     }
//   }


export const JumbotronContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 100%;
  position: relative;
  z-index: 1;

  margin: 30px auto 0 auto;
  flex-wrap: wrap;
  gap: 2rem;
  /* padding: 0 4rem; */
  /* padding-left: 50px; */

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding: 0 2rem 5rem 2rem;
  }
`;

export const LeftContent = styled.div`
width: 87%;
padding-left: 0;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-items: ${(props) => props.alignItems || 'center'};

  gap: ${(props) => props.gap};
  margin-left: ${(props) => props.marginLeft};


  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
   @media screen and (min-width: 1600px) {
    margin-top: 150px !important;
  }
`;

export const LaptopImg = styled.div`
  position: absolute;
  bottom: 30px;
  right: 0;
  z-index: 1;

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const SphereImg = styled.div`
  position: absolute;
  bottom: 100px;
  right: 220px;
  z-index: 1;
  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(15deg);
    }
  }
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const RingImg = styled.div`
  position: absolute;
  top: 265px;
  right: 175px;
  z-index: 1;

  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ArcImg = styled.div`
  position: absolute;
  top: 150px;
  left: 300px;
  z-index: 1;

  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const WavelineImg = styled.div`
  position: absolute;
  bottom: 25px;
  left: 400px;
  z-index: 1;

  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const HandphoneImg = styled.div`
  position: absolute;
  top: 150px;
  right: 150px;
  z-index: 1;

  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const HandcardImg = styled.div`
  position: absolute;
  bottom: -10px;
  left: 80px;
  z-index: 1;

  &:hover {
    animation: shake 2s linear infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(3.9px, -2.6px);
    }
    /* 50% {
      transform: rotate(2deg);
    } */
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const JumbotronTitle = styled.div`
  font-size: 2.7rem;
  display: flex;
  flex-direction: column;

`;

export const JumbotronPar = styled.div`
  width: 100%;
`;

export const JumbotronDescription = styled.div`
  max-width: 900px;
  text-align: center;
`;

export const JumbotronFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const JumbotronImage = styled.img`
  width: 100%;
`;

export const JumbotronExp = styled.div`
  max-width: 1226px;
  width: 90%;
  height: 170px;
  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(13, 30, 136, 0.15);
  /* background-color: #fff; */

  display: flex;
  justify-content: space-around;
  align-items: center;

  text-align: center;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -85px;
  z-index: 10;
  backdrop-filter: blur(45px);
  /* opacity: 0.8; */

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 338px;
    height: 120px;
  }
`;
