import React from "react";
import { Narasi } from "../../../components/Layout/Privacy/Informasi.styled";
import ListNewsPortal from "../../../components/Layout/NewsPortal/ListNewsPortal"
import { TextDesc } from "../../../components/UI/Text.styled";
import { TextTitle } from "../../../components/UI/Text.styled";


const ContentNewsPortal = () => {

  return (
    <>
      <Narasi marginTop="-140px" alignItems="center" paddingTop="25px">
        <ListNewsPortal/>
      </Narasi>
      

    </>
  );
};

export default ContentNewsPortal;