import styled from 'styled-components'

export const ButtonCustom = styled.button`
  width: 192px;
  height: 48px;
  line-height: 48px;
  color: ${(props) => props.color || "#44d62c"};

  border-radius: 24px;
  border: solid 1px #44d62c;
  line-height: auto;
  text-transform: uppercase;
  font-weight: 500;

  background-color: ${(props) => props.bg || "transparent"};

  &:hover {
    background-color: transparent;
    color: #fff;
    transition: background-color 300ms linear;
  }

  @media only screen and (max-width: 768px) {
    margin-top: ${(props) => props.mtsm};
    position: ${(props) => props.positionsm};
    top: ${(props) => props.topsm};
    bottom: ${(props) => props.bottomsm};
    right: ${(props) => props.rightsm};
    left: ${(props) => props.leftsm};
  }
`;

export const ButtonArrow = styled(ButtonCustom)`
   position: relative;

   &:after {
      content: url(${(props) => props.content});
      position: absolute;
      right: -20px;
      top: 60%;
      transform: translate(0, -50%);
      transition: transform 0.2s;
   }

   &:hover {
      background-color: #44d62c;
      transition: background-color 300ms linear;
      color: #1c278b;
      &:after {
         transform: translate(30%, -50%);
      }
   }
`
export const ButtonWrap = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 1rem;
`

export const ButtonFooterContainer = styled.div`
   margin-top: 28px;
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
   justify-content: center;
`
export const ButtonFooter = styled.button`
   width: 260px;
   height: 50px;
   border-radius: 24px;
   border: none;
   text-transform: uppercase;

   margin-top: 10px;

   margin-right: ${(props) => props.marright};
   background-color: ${(props) => props.bgCol || '#1C278B'};
   color: ${(props) => props.color || 'black'};

   span:nth-child(2) img {
      transition: transform 0.1s;
   }

   &:hover span:nth-child(2) img {
      transform: translateX(10px);
   }
`

export const ImgFooter = styled.img`
   width: 278px;
   height: 209px;
`

export const ButtonPelajari = styled.button`
   width: 229px;
   height: 48px;
   border-radius: 24px;
   background-color: rgba(45, 98, 237, 0.15);
   border: none;
   color: #2d62ed;
   font-weight: bold;

   &:hover {
      background-color: #89cff0;
   }
`

export const BtnKirim = styled.button`
   width: 200px;
   height: 50px;
   border-radius: 30px;
   border: none;
   color: #fff;
   background-color: #1c278b;
   margin-bottom: ${(props) => props.marginBottom || '0px'};

`
