import React, { useState } from 'react';
import arrow from "../../../../assets/images/payment/arrow.svg";

const Accordions = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-items">
      <div className="accordion-titles" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? <img className='arrow' src={arrow} /> : <img className='arrow' src={arrow}/>}</div>
      </div>
      {isActive && <div className="accordion-contents">{content}</div>}
    </div>
  );
};

export default Accordions;