import styled from 'styled-components'

export const Paralax = styled.div`
   min-height: 25rem;

   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;

   @media screen and (max-width: 768px){
      background-position: 65% 70%;
}
`

export const ParalaxContent = styled.div`
   display: flex;
   flex-direction: column;
   color: #ffffff;
   padding: 150px 20px 0 20px;
   max-width: 1088px;
   margin: 0 auto;


     @media screen and (max-width: 768px){
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
}

`
