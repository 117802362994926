import styled from 'styled-components'

export const ContainerKarir = styled.div`

   width: 90%;
   text-align: left;
   padding: 10px;
   margin-top:75px;
   margin-bottom: 100px;
   @media screen and (max-width: 768px) {
      margin-top: -275px;
   }
  
`

export const HeaderJob = styled.div`
   display: flex;
   flex-wrap: wrap; 
   justify-content: space-between;
   align-items: flex-start;

   gap: 10px;
   @media screen and (max-width: 768px) {
      margin-top: 300px;
   }
`

export const ButtonApply = styled.button`
   width: 200px;
   height: 50px;
   border-radius: 30px;
   border: none;
   line-height: 50px;
   background-color: #edf6ff;
`

export const ContentKarir = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 10px;
`

export const Responsibilities = styled.div`
   flex: 1;
`
export const Requirements = styled(Responsibilities)``
