import styled from 'styled-components'

export const MarqueeItemBG = styled.div`
   width: ${(props) => props.width};
   height: 75px;
   background-image: url(${(props) => props.bgImage});
   background-repeat: no-repeat;
   background-size: auto;
   margin: 0 50px;
   background-position: ${(props) => props.position};

   @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      margin: 0 10px;
   }
`
export const MarqueeItem = styled.div`
  /* width: 230px;
  height: 145px; */
  width: 172px;
  height: 75px;
  border: none;
  /* border-radius: 20px; */
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  max-width: 50%;
  max-height: 50%;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  background-origin: content-box;
  background-clip: content-box; */
  /* padding: 30px; */

  //   horizontal, vertikal
  margin: 0 20px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0 10px;
  }
`;