import styled from "styled-components";

export const InputForm = styled.input`
  width: 100%;
  width: ${(props) => props.checkbox && "20px"};

  height: ${(props) => props.text && "50px"};
  height: ${(props) => props.checkbox && "20px"};
  border-radius: 15px;
  border: solid 1px #bbd9fd;

  background-color: #fafbfc;
  padding: 15px;

  &:focus {
    outline: none;
    border: solid 1px #44d62c;
    background-color: #f7fff5;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 30px;
  }
`;
