import React from 'react'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'
import HeroBG from '../../../assets/images/background header/header-panduan.png'
const HeaderPanduanPembayaran = () => {
   return (
     <HeaderNoHero
       title="Panduan Pembayaran"
       halaman="Bantuan / Panduan Pembayaran"
       background={HeroBG}
     />
   );
}

export default HeaderPanduanPembayaran
