import React from 'react'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import {
   BtnSkema,
   CardSkema,
   ContentSkema,
   HrDashed,
   FooterSkema,
} from './Skemakerjasama.styled'
import Col from 'react-bootstrap/Col'

import iconChecklist from '../../../assets/images/checklist.svg'

const SkemaContent = (props) => {
   return (
      <>
         <Col md={4} id={props.id}>
            <CardSkema>
               <ContentSkema>
                  <div className="mx-auto">
                     <TextTitle
                        color={'#424af5'}
                        fw={'bold'}
                        className="text-center"
                        size={'30px'}
                     >
                        {props.header}
                     </TextTitle>
                     <BtnSkema>{props.txtBtn}</BtnSkema>
                  </div>
                  <div>
                     <div className="d-flex gap-2">
                        <span>
                           <img src={iconChecklist} alt="checklist" />
                        </span>
                        <span>
                           <TextDesc size="14px">{props.layanan1}</TextDesc>
                        </span>
                     </div>
                     <div className="d-flex gap-2 my-3">
                        <span>
                           <img src={iconChecklist} alt="checklist" />
                        </span>
                        <span>
                           <TextDesc size="14px">{props.layanan2}</TextDesc>
                        </span>
                     </div>
                     {props.layanan3 && (
                        <div className="d-flex gap-2">
                           <span>
                              <img src={iconChecklist} alt="checklist" />
                           </span>
                           <span>
                              <TextDesc size="14px">{props.layanan3}</TextDesc>
                           </span>
                        </div>
                     )}
                  </div>
                  <FooterSkema>
                     <HrDashed />
                     <TextDesc
                        size="14px"
                        className="m-0 p-0"
                        style={{ color: '#93969d' }}
                     >
                        Bisnis model:
                     </TextDesc>
                     <TextDesc size="14px" className="m-0 p-0">
                        {props.footer}
                     </TextDesc>
                  </FooterSkema>
               </ContentSkema>
            </CardSkema>
         </Col>
      </>
   )
}

export default SkemaContent
