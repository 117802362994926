import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { List, NavBC, Orderlist } from './BreadCrumbCustom.styled'
import iconBreadcrumb from '../../assets/images/icon-breadcrumb.svg'
import { Link } from 'react-router-dom'

const BreadCrumbCustom = (props) => {
   return (
      <NavBC>
         <Orderlist>
            <List separator className="d-flex align-items-center">
               <Link to="/">
                  <img src={iconBreadcrumb} alt="breacrumb" />
               </Link>
               <span> > </span>
            </List>
            <List style={{ color: props.halaman2 && '#44d62c' }}>
               <a>{props.halaman}</a>
            </List>
            {props.halaman2 && (
               <>
                  <span> > </span>
                  <List>
                     <a>{props.halaman2}</a>
                  </List>
               </>
            )}
         </Orderlist>
      </NavBC>
   )
}

export default BreadCrumbCustom
