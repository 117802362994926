import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";

import { TextDesc, TextTitle } from "../../UI/Text.styled";

const TabPaneBillerGateway = () => {
  return (
    <Tab.Pane eventKey="m-bayar-desktop">
      <Tab.Container id="left-tabs-example" defaultActiveKey="umum">
        <HeadTab>
          <ul>
            <li>
              <Nav.Link eventKey="umum">
                <TextTitle size="16px">umum</TextTitle>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link eventKey="registrasi-akun">
                <TextTitle size="16px">registrasi & akun</TextTitle>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link eventKey="mbayar-id">
                <TextTitle size="16px">M-bayar id</TextTitle>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link eventKey="topup-transaksi">
                <TextTitle size="16px">top-up & transaksi</TextTitle>
              </Nav.Link>
            </li>
          </ul>
        </HeadTab>
        <Tab.Content style={{ maxWidth: "628px" }}>
          {/* Umum */}

          <Tab.Pane eventKey="umum">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Tentang M-Bayar
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">Apakah M-Bayar itu?</TextDesc>
              <TextDesc className="mt-2">
                M-Bayar adalah sebuah layanan uang elektronik yang dapat Anda
                gunakan untuk melakukan pembelian dan pembayaran di aplikasi
                smartphone M-Bayar maupun untuk berbelanja di berbagai merchant
                yang telah bergabung sebagai mitra M-Bayar.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah manfaat dari layanan M-Bayar?</TextDesc>

              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Anda dapat melakukan pembayaran di aplikasi M-Bayar dan
                    merchant online mitra M-Bayar dengan menggunakan akun
                    M-Bayar, meskipun Anda tidak memiliki kartu kredit maupun
                    debit.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Anda dapat melakukan pembelian berbagai produk prepaid dan
                    pembayaran produk postpaid dengan harga yang kompetitif.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Anda memperoleh fleksibilitas dalam mengakses akun M-Bayar
                    milik Anda melalui aplikasi berbasis smartphone (Android dan
                    iOS).
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Anda dapat mengendalikan rencana belanja online Anda dengan
                    mengalokasikan dana Anda di akun M-Bayar.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Anda dapat mengakses rekaman seluruh Transaksi online Anda
                    yang dilakukan menggunakan akun M-Bayar kapan saja melalui
                    aplikasi M-Bayar.
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah layanan M-Bayar aman?</TextDesc>
              <TextDesc className="mt-2">
                Layanan M-Bayar aman, karena:
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Semua komunikasi antara M-Bayar dan merchant M-Bayar serta
                    proses Transaksi yang terjadi di dalamnya diamankan dengan
                    merujuk kepada standar keamanan tinggi yang diterapkan oleh
                    regulator industri terkait.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Setiap Transaksi finansial yang dilakukan dengan akun
                    M-Bayar harus diverifikasi menggunakan PIN (pada aplikasi
                    smartphone).
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa yang terjadi jika nomor seluler yang tercantum sebagai
                M-Bayar ID akun saya diklaim oleh pihak lain?
              </TextDesc>
              <TextDesc className="mt-2">
                Jika pihak lain dapat memberikan bukti kepemilikan nomor seluler
                yang saat ini aktif, maka berikut penyebab yang mungkin terjadi:
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Nomor seluler tersebut telah melalui proses daur-ulang oleh
                    operator seluler yang bersangkutan.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Anda telah berganti nomor seluler dan tidak melakukan
                    perubahan data nomor seluler pada akun M-Bayar milik Anda.
                  </TextDesc>
                </li>
              </ul>
              <TextDesc className="mt-2">
                Apabila hal ini terjadi, Customer Service kami akan menutup akun
                M-Bayar Anda sekaligus mengirimkan notifikasi melalui email ke
                alamat email yang tercantum pada akun M-Bayar Anda.
              </TextDesc>
              <TextDesc className="mt-2">
                Jika akun M-Bayar milik Anda masih memiliki sisa saldo yang bisa
                dicairkan, maka Customer Service kami akan meminta data rekening
                tujuan pencairan sisa saldo akun M-Bayar bersamaan dengan email
                notifikasi penutupan.
              </TextDesc>
            </div>
          </Tab.Pane>
          {/* Registrasi Akun */}

          <Tab.Pane eventKey="registrasi-akun">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Registrasi Akun
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa saja jenis akun di layanan M-Bayar?
              </TextDesc>
              <TextDesc className="mt-2">
                Layanan M-Bayar menawarkan dua jenis akun kepada para Nasabah,
                yaitu Reguler dan Premium. Nasabah dari masing-masing jenis akun
                disebut sebagai Nasabah Reguler dan Nasabah Premium.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa perbedaan antara Nasabah Reguler dan Nasabah Premium?
              </TextDesc>
              <TextDesc className="mt-2">
                Perbedaan utama antara Nasabah Reguler dan Nasabah Premium
                mengikuti peraturan Bank Indonesia tentang uang elektronik
                adalah:
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Nasabah Premium wajib melewati proses verifikasi identitas
                    individu oleh M-Bayar sesuai dengan ketentuan peraturan Bank
                    Indonesia tentang uang elektronik pada saat pendaftaran.
                    Sedangkan Nasabah Reguler dapat mendaftar akun M-Bayar
                    melalui aplikasi M-Bayar tanpa diwajibkan melewati proses
                    verifikasi identitias individu oleh M-Bayar.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Nasabah Premium dapat memiliki batas maksimal saldo di akun
                    M-Bayar hingga sebesar Rp20,000,000 sedangkan Nasabah
                    Reguler hanya dapat memiliki batas maksimal saldo di akun
                    M-Bayar sebesar Rp2,000,000.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Nasabah Premium dapat menggunakan fitur transfer saldo, baik
                    ke akun M-Bayar lain maupun ke rekening bank, sedangkan
                    Nasabah Reguler tidak dapat menggunakan fitur tersebut.
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Berapa biaya pendaftaran akun M-Bayar?
              </TextDesc>
              <TextDesc className="mt-2">
                Tidak ada biaya yang dikenakan oleh M-Bayar untuk setiap
                pendaftaran akun, termasuk Premium.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana cara mendaftar sebagai Nasabah Reguler?
              </TextDesc>
              <TextDesc className="mt-2">
                Anda dapat mendaftar akun M-Bayar jenis Reguler di aplikasi
                berbasis Andorid dan iOS yang dapat akses melalui Google Play
                dan Apple Store dengan mengunduhnya terlebih dahulu. Setelah
                instalasi aplikasi M-Bayar berhasil, masuk ke layar pendaftaran
                dengan mengikuti tombol “Daftar Akun”.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana cara mendaftar sebagai Nasabah Premium?
              </TextDesc>
              <TextDesc className="mt-2">
                Melengkapi kelengkapan data diri Anda pada aplikasi M-Bayar
                disertai dengan mengunggah foto ID (KTP/SIM) Anda dilanjutkan
                dengan mengunggah swafoto (selfie) Anda dengan memegang ID
                (KTP/SIM).
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah M-Bayar PIN itu?</TextDesc>
              <TextDesc className="mt-2">
                M-Bayar PIN merupakan fitur keamanan M-Bayar berupa kombinasi
                angka 6-digit yang ditentukan oleh Nasabah pada saat pendaftaran
                akun M-Bayar. M-Bayar PIN dipergunakan berulang kali untuk
                setiap Transaksi finansial.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apakah M-Bayar OTP (One Time Password) itu?
              </TextDesc>
              <TextDesc className="mt-2">
                M-Bayar OTP merupakan bagian dari fitur keamanan M-Bayar berupa
                kombinasi huruf dan 4-digit angka yang diciptakan secara acak
                dan dikirimkan melalui SMS ke nomor seluler yang tercantum pada
                akun M-Bayar yang bersangkutan. M-Bayar OTP hanya dipergunakan
                satu kali untuk Transaksi tertentu.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa fungsi M-Bayar PIN dan M-Bayar OTP?
              </TextDesc>
              <TextDesc className="mt-2">
                M-Bayar PIN digunakan untuk melakukan beberapa aktivitas
                berikut:
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Verifikasi Nasabah pada saat melakukan Transaksi finansial
                    di aplikasi smartphone M-Bayar.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Verifikasi Nasabah pada beberapa aktivitas lain berkaitan
                    dengan keamanan di aplikasi smartphone M-Bayar.
                  </TextDesc>
                </li>
              </ul>
              <TextDesc>
                Sedangkan M-Bayar OTP berfungsi untuk melengkapi M-Bayar PIN
                dalam melindungi akun M-Bayar melalui aktivitas berikut:
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Verifikasi Nasabah pada beberapa aktivitas lain berkaitan
                    dengan keamanan di aplikasi smartphone M-Bayar.
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana jika saya lupa M-Bayar PIN akun saya?
              </TextDesc>
              <TextDesc className="mt-2">
                Anda dapat melakukan prosedur penyetelan ulang M-Bayar PIN
                melalui menu Lupa PIN yang tersedia di halaman login aplikasi
                M-Bayar.
              </TextDesc>
            </div>
          </Tab.Pane>

          {/* Mbayar ID */}

          <Tab.Pane eventKey="mbayar-id">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Mbayar ID
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">Apakah M-Bayar ID itu?</TextDesc>
              <TextDesc className="mt-2">
                M-Bayar ID adalah sebuah nomor identitas unik yang
                mengidentifikasi setiap akun M-Bayar. M-Bayar ID berbentuk nomor
                seluler yang Anda cantumkan saat pendaftaran layanan M-Bayar.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa yang terjadi dengan M-Bayar ID jika saya mengganti nomor
                seluler?
              </TextDesc>
              <TextDesc className="mt-2">
                Jika Anda tidak lagi menggunakan nomor seluler yang dicantumkan
                saat pendaftaran, segera lakukan perubahan data nomor seluler
                pada akun M-Bayar milik Anda.
              </TextDesc>
              <TextDesc className="mt-2">
                M-Bayar ID akun Anda akan berubah secara otomatis setelah Anda
                melakukan perubahan data nomor seluler pada akun M-Bayar milik
                Anda.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Mengapa M-Bayar ID akun saya harus sama dengan nomor seluler
                yang saya gunakan?
              </TextDesc>
              <TextDesc className="mt-2">
                Layanan M-Bayar melakukan verifikasi pada setiap Transaksi yang
                sensitif dengan mengirimkan M-Bayar OTP (One Time Password)
                melalui SMS ke nomor seluler yang ditunjukkan oleh M-Bayar ID
                akun yang bersangkutan.
              </TextDesc>
              <TextDesc className="mt-2">
                Jika nomor seluler yang ditunjukkan oleh M-Bayar ID bukan
                merupakan nomor seluler milik Anda, proses verifikasi M-Bayar
                OTP akan gagal.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana jika saya ingin mencantumkan nomor seluler milik saya
                yang diketahui masih terdaftar sebagai M-Bayar ID akun lain?
              </TextDesc>
              <TextDesc className="mt-2">
                Operator seluler memiliki wewenang untuk melakukan daur-ulang
                dan menjual kembali nomor seluler yang sudah lama tidak aktif.
                Jika Anda membeli nomor seluler yang sebelumnya pernah
                didaftarkan oleh Nasabah lain pada sebuah akun M-Bayar yang
                belum ditutup, silahkan persiapkan identitas diri dan bukti
                kepemilikan nomor seluler tersebut dan hubungi Customer Service
                kami di:
              </TextDesc>
              <div className="mt-3">
                <TextDesc>021-5292-0139 ext. 333 ( jam kerja )</TextDesc>
                <TextDesc>021-5292-0139 ext. 300 ( selain jam kerja )</TextDesc>
                <TextDesc>support@e2pay.co.id</TextDesc>
              </div>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah M-Bayar Top-Up ID itu?</TextDesc>
              <TextDesc className="mt-2">
                M-Bayar Top-Up ID adalah nomor identitas unik pada setiap akun
                M-Bayar yang dapat digunakan sebagai nomor rekening tujuan
                apabila Nasabah M-Bayar melakukan pengisian saldo (Top-Up).
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Dimana saya dapat mengetahui M-Bayar Top-Up ID akun saya?
              </TextDesc>
              <TextDesc className="mt-2">
                Anda dapat menemukan M-Bayar Top-Up ID pada dashboard aplikasi
                akun M-Bayar. Anda juga dapat menemukan M-Bayar Top-Up ID pada
                halaman petunjuk top-up/penambahan ulang di aplikasi smartphone.
              </TextDesc>
            </div>
          </Tab.Pane>

          {/* Topup Transaksi */}

          <Tab.Pane eventKey="topup-transaksi">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Topup Transaksi
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa saja yang tercakup dalam layanan finansial?
              </TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>Menambah saldo akun M-Bayar.</TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Membeli produk prepaid seperti voucher pulsa seluler.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Membayar tagihan produk postpaid seperti tagihan telepon.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Membayar pembelanjaan e-commerce di Merchant Online Mitra
                    M-Bayar.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Mengirim uang ke sesama Nasabah M-Bayar (hanya untuk Nasabah
                    Premium).
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Menerima uang dari sesama Nasabah M-Bayar (hanya untuk
                    Nasabah Premium).
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Mengirim uang ke rekening bank (hanya untuk Nasabah
                    Premium).
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana cara melakukan top-up saldo pada akun saya?
              </TextDesc>
              <TextDesc className="mt-2">
                Petunjuk cara melakukan top-up saldo akun M-Bayar tersedia pada
                menu di aplikasi smartphone M-Bayar.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Adakah biaya untuk melakukan top-up saldo?
              </TextDesc>
              <TextDesc className="mt-2">
                Apabila Anda melakukan top-up saldo melalui transfer dari
                rekening bank, akan dikenakan biaya transfer oleh jaringan
                bank/penerbit kartu yang sesuai dengan kebijakan yang berlaku.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Adakah batasan nominal top-up saldo yang bisa dilakukan?
              </TextDesc>
              <TextDesc className="mt-2">
                Nasabah Reguler memiliki batas maksimal top-up sebesar
                Rp2,000,000 dikurangi sisa saldo di akun yang bersangkutan,
                serta tidak melebihi batas total transaksi top-up sebesar
                Rp20,000,000 pada bulan yang sedang berjalan.
              </TextDesc>
              <TextDesc className="mt-2">
                Nasabah Premium memiliki batas maksimal top-up sebesar
                Rp20,000,000 dikurangi sisa saldo di akun yang bersangkutan,
                serta tidak melebihi batas total transaksi top-up sebesar
                Rp40,000,000 pada bulan yang sedang berjalan.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana mengetahui batas maksimal nominal top-up saldo yang
                saat ini berlaku?
              </TextDesc>
              <TextDesc className="mt-2">
                Ketika Anda mengakses halaman petunjuk top-up, aplikasi M-Bayar
                akan memperlihatkan batas maksimal top-up saldo yang bisa
                dilakukan. Nominal yang diperlihatkan oleh aplikasi telah
                dihitung terhadap batas maksimal saldo yang diperbolehkan maupun
                terhadap batas maksimal top-up saldo pada bulan yang sedang
                berjalan.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa yang terjadi jika top-up saldo saya melebihi batas maksimal
                yang telah ditentukan?
              </TextDesc>
              <TextDesc className="mt-2">
                Jika Anda melakukan top-up saldo dan nominal top-up tersebut
                mengakibatkan saldo melebihi dari batas maksimum yang
                diperbolehkan, maka proses top-up tersebut akan ditolak.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apakah ada batasan Transaksi dalam akun M-Bayar?
              </TextDesc>
              <TextDesc className="mt-2">Batas minumum:</TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Batas minimum nominal Transaksi untuk transfer antar akun
                    M-Bayar adalah sebesar Rp1. Sedangkan untuk transfer ke
                    rekening bank adalah sebesar Rp10,000.
                  </TextDesc>
                </li>
                <li className="mt-3">
                  <TextDesc>
                    Tidak ada batas minimum nominal Transaksi untuk Transaksi
                    pembayaran belanja e-commerce.
                  </TextDesc>
                </li>
                <li className="mt-3" size="14px">
                  <TextDesc>
                    Tidak ada batas minimum nominal Transaksi untuk pembelian
                    produk prepaid maupun pembayaran produk postpaid.
                  </TextDesc>
                </li>
              </ul>
              <TextDesc className="mt-2">Batas maksimum:</TextDesc>
              <ul className="px-5">
                <li className="mt-3">
                  <TextDesc>
                    Batas maksimum nominal untuk semua jenis Transaksi adalah
                    sebesar batas maksimal saldo dari masing-masing akun
                    berdasarkan jenisnya.
                  </TextDesc>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Adakah biaya untuk melakukan transaksi transfer dana?
              </TextDesc>
              <TextDesc className="mt-2">
                Apabila Anda melakukan transaksi transfer dana ke rekening Bank
                akan dikenakan biaya transfer oleh jaringan bank / penerbit
                kartu sesuai dengan kebijakan yang berlaku. Sedangkan transfer
                dana antar akun nasabah M-Bayar tidak akan dikenakan biaya.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Berapa lama kah proses transaksi transfer dana sampai dana
                diterima?
              </TextDesc>
              <TextDesc className="mt-2">
                Proses pengiriman transfer dana hingga dana nya diterima oleh
                pihak penerima adalah maksimal 3 menit.
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apakah ada batasan masa berlaku dari saldo di akun M-Bayar?
              </TextDesc>
              <TextDesc className="mt-2">
                Akun M-Bayar tidak memiliki batasan masa berlaku, baik untuk
                akun maupun saldo di dalamnya.
              </TextDesc>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  );
};

export default TabPaneBillerGateway;
