import React from 'react'
import ContentAbout from './About/Content/ContentAbout'
import HeaderPrivacyAbout from './Privacy/Header/HeaderPrivacyAbout'
import { Helmet } from 'react-helmet'
import HeaderAbout from './About/Header/HeaderAbout'
import Footer from '../components/UI/Footer'

const About = () => {
   return (
      <>
         <Helmet>
            <title>E2Pay - Kenapa E2Pay</title>
            <meta name="description" content="E2pay About Us Page" />
         </Helmet>
         <HeaderAbout />
         <ContentAbout />
         {/* <Footer/> */}
      </>
   )
}

export default About
