import React from 'react'
import ContentBiaya from './Biaya/Content/ContentBiaya'
import HeaderBiaya from './Biaya/Header/HeaderBiaya'
import { Helmet } from 'react-helmet'

const Biaya = () => {
   return (
      <>
      <Helmet>
        <title>E2Pay - Biaya</title>
        <meta name="description" content="E2pay Ekosistem Page" />
      <meta property="og:url" content="https://e2pay-revamp.vercel.app/"/>
  <meta property="og:image" content="https://i.postimg.cc/XJd11VZ7/logo-title.png"/>

 
      </Helmet>
         <HeaderBiaya />
         <ContentBiaya />
      </>
   )
}

export default Biaya
