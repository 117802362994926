import styled from 'styled-components'

export const ContainerCoBrand = styled.div`
   display: flex;
   width: 90%;
   margin: 0 auto;
   border: solid;
`

export const CoBrandLeft = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   gap : 2rem;
   height: auto;
`
export const CoBrandRight = styled.div`
   /* width: 605px; */
   height: 330px;

   background-image: url(${(props) => props.bgImg});
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center center;
`
