import React from 'react'
import HeaderNoHero from '../../../../components/UI/HeaderNoHero'
import HeroBG from '../../../../assets/images/background header/header-panduan.png'
const HeaderCC = () => {
   return (
     <HeaderNoHero
       title="Credit Card"
       halaman="Bantuan / Panduan Pembayaran / Credit Card"
       background={HeroBG}
     />
   );
}

export default HeaderCC
