import React from "react";
import { ContentContainer } from "../../../components/UI/Content.styled";
import Tagline from "../../../components/Layout/Home/Tagline";
import PartnerPembayaran from "../../../components/Layout/Home/PartnerPembayaran";
import TelahDigunakan from "../../../components/Layout/Home/TelahDigunakan";
import InfoJaringan from "../../../components/Layout/Home/InfoJaringan";
import CoBranding from "../../../components/Layout/Home/CoBranding";
import JaminanKeamanan from "../../../components/Layout/Home/JaminanKeamanan";
import Banner from "../../../components/Layout/Home/Banner";
import HubungiKami from "../../../components/Layout/Home/HubungiKami";
import TerimaPembayaran from "../../../components/UI/TerimaPembayaran";
import BerkembangBersama from "../../../components/Layout/Home/BerkembangBersama";

const Content = () => {
  const textHubKami = {
    titles: [
      {
        title:
          "Ratusan pemilik bisnis seperti Anda telah menjadikan kami partner dalam pengembangan bisnis! ",
      },
      // {
      //   title: "",
      // },
    ],
    message: "Konsultasikan dengan kami sekarang juga:",
  };

  return (
    <>
      <ContentContainer marginTop={"150px"}>
      <Tagline />
        <TelahDigunakan />
        <PartnerPembayaran />
        <InfoJaringan />
        {/* <CoBranding /> */}
        <JaminanKeamanan />
        {/* <BerkembangBersama /> */}
        {/* <Banner /> */}
        <HubungiKami textHubKami={textHubKami} />
        {/* <TerimaPembayaran /> */}
      </ContentContainer>
    </>
  );
};

export default Content;
