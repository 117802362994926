import React from 'react'
import { MarqueeContainer } from '../../UI/PartnerPembayaran.styled'
import { MarqueeItemBG, MarqueeItem } from './MarqueeImageBiller.styled'
import pln from "../../../assets/images/marquee-biller/pln.svg"
import airasia from "../../../assets/images/marquee-biller/airasia.svg"
import citilink from "../../../assets/images/marquee-biller/citilink.svg"
import garena from "../../../assets/images/marquee-biller/garena.png"
import garuda from "../../../assets/images/marquee-biller/garuda.png"
import indosat from "../../../assets/images/marquee-biller/indosat.svg"
import indovision from "../../../assets/images/marquee-biller/indovision.png"
import pdam from "../../../assets/images/marquee-biller/pdam.png"
import smartfren from "../../../assets/images/marquee-biller/smartfren.png"
import telkom from "../../../assets/images/marquee-biller/telkom.svg"
import unipin from "../../../assets/images/marquee-biller/unipin.png"
import xl from "../../../assets/images/marquee-biller/xl.svg"

const MarqueeImageBiller = (props) => {
   return (
      <MarqueeContainer>
         <MarqueeItem>
            <img src={airasia} width="50%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={citilink} width="90%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={garena} width="100%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={garuda} width="90%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={indosat} width="90%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={indovision} width="100%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={pdam} width="70%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={pln} width="95%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={smartfren} width="100%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={telkom} width="100%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={unipin} width="90%" height="auto"></img>
         </MarqueeItem>
         <MarqueeItem>
            <img src={xl} width="70%" height="auto"></img>
         </MarqueeItem>
      </MarqueeContainer>
   )
}

export default MarqueeImageBiller
