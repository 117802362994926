import styled from 'styled-components'

export const WrapHead = styled.div`
   display: flex;
   width: 100%;
   flex-wrap: wrap;
   justify-content: space-around;
   margin: 63px 0;
   padding-top: 0px;
   position: relative;
   z-index: 30;

   .container-text {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      gap: 5px;

      @media only screen and (max-width: 768px) {
         max-width: 245px;
      }
   }
`
