import React from "react";
import {
  ButtonFooter,
  ButtonFooterContainer,
  ImgFooter,
} from "./Button/Button.styled";
import { ContainerTP } from "./TerimaPembayaran.styled";
import { TextTitle } from "./Text.styled";
import arrowGreen from "../../assets/images/icon-arrow-footer.svg";
import imageFooter from "../../assets/images/imgFooter.gif";
import Bubble from "../../assets/images/BG_Bubble.png";

import { Link, useNavigate } from "react-router-dom";

const TerimaPembayaran = () => {
  return (
    <ContainerTP>
      <ImgFooter alt="gambar gif" src={imageFooter} />
      <TextTitle size="28px" aligntext="center" fontsm="20px" poppins bold>
        Segera terima pembayaran melalui E2Pay Razer Fintech
      </TextTitle>
      <ButtonFooterContainer>
        <Link to="../hubungi-sales">
          <ButtonFooter color="#ffffff" bgCol="#1c278b">
            <span style={{ fontFamily: "poppins" }}> Hubungi Kami</span>
          </ButtonFooter>
        </Link>
        {/* <a
          href="https://nmstest.e2pay.co.id/merchant-ss-app/loginpage"
          target="_blank"
        >
          <ButtonFooter color="#2d62ed" bgCol="#e2f9de">
            lihat demo
            <span>
              {" "}
              <img src={arrowGreen} alt={"panah hijau"} />
            </span>
          </ButtonFooter>
        </a> */}
      </ButtonFooterContainer>
    </ContainerTP>
  );
};

export default TerimaPembayaran;
