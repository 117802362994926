import React, { useState, useRef, useEffect } from "react";
import { Container, Card, CardContainer, Paging } from "./NewsPortal.styled";
import calendarIcon from "../../../assets/images/calendar-icon.svg";
import authorIcon from "../../../assets/images/author-icon.svg";
import newsImage from "../../../assets/images/artikel-list.jpg"
import { TextDesc } from "../../UI/Text.styled";
import CardNews from "./CardNews";
import Pagination from "./Pagination";

const ListNewsPortal = () => {
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(1);

  // const getCardData = async () => {
  //   setLoading(true);
  //   const response = await fetch(
  //     "https://667e7652f2cb59c38dc5c272.mockapi.io/article/articles"
  //   );
  //   const cardData = await response.json();
  //   setData(cardData);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   getCardData();
  // }, []);

  const Redirect = () => {
    window.location.href = "/artikel/e2pay-tegaskan-tidak-memfasilitasi-aktivitas-judi-online";
  };

  const data = 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data / itemsPerPage);
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data / itemsPerPage); i++) {
    // for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  function goToNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
  }

  function goToPrevPage() {
    setCurrentPage((prevPage) => prevPage - 1);
  }
  console.log(data);

  return (
    <Container>
      {/* <CardContainer>
        {currentItems.map((datas) => {
          return (
            <Card key={datas.id}>
              <img src={datas.image} />
              <div className="title">{datas.title}</div>
              <div className="desc">{datas.description}</div>
              <div className="calendar-author">
            <div id="calendar">
                <img id="calendarIcon" src={calendarIcon} alt="" /> 
                12 Juni 2023
            </div>
            <div id="author">
              <img id="authorIcon" src={authorIcon} alt="" />
              {datas.author}
            </div>
          </div>
            </Card>
          );
        })}
      </CardContainer> */}
      <CardContainer>
        <Card onClick={Redirect}>
          <img src={newsImage} />
          <div className="title">
          E2Pay Tegaskan Tidak Memfasilitasi Aktivitas Judi <span style={{fontStyle: "italic"}}>Online</span>
          </div>
          <div className="desc">
            Sehubungan dengan Press Release dari Kementerian Komunikasi...{" "}
          </div>
          <div className="calendar-author">
            <div id="calendar">
              <img id="calendarIcon" src={calendarIcon} alt="" />
              15 Agustus 2024
            </div>
            <div id="author">
              <img id="authorIcon" src={authorIcon} alt="" />
              Admin
            </div>
          </div>
        </Card>
      </CardContainer>
      <div className="paging">
        <button id="prev" onClick={goToPrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <ul className="">
          <li className="">
            <button
              id="page"
              className=""
              style={{ backgroundColor: "#1c278b", color: "white" }}
            >
              1
            </button>
          </li>
        </ul>
        <button
          id="next"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {/* <div className="paging">
        <button id="prev" onClick={goToPrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <ul className="">
          {pageNumbers.map((number) => (
            <li key={number} className="">
              <button id="page" onClick={() => paginate(number)} className="" style={currentPage === number ? {backgroundColor: "#44d62c", color: "white"} : {backgroundColor: "white"}}>
                {number}
              </button>
            </li>
          ))}
        </ul>
        <button id="next" onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div> */}

      
    </Container>
  );
};

export default ListNewsPortal;
