import React, {useEffect} from 'react'
import { ContentTelahdigunakan, WrapFlexLogo } from '../../UI/Content.styled'
import { LogoMerchant } from '../../UI/Logo.styled'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import sprite_merchant from '../../../assets/images/sprite_Merchants.png'
import LogoData from './TelahDigunakanAssets'
import { MerchantAktif, ContainerLogo, Item, Grid, Bubble3 } from './TelahDigunakan.styled.js'
import bubble3 from '../../../assets/images/bubblehome3.svg'
import AOS from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from 'react-lazy-load-image-component' // Lazy Load Image

 
const TelahDigunakan = () => {
    useEffect(() => {
      AOS.init();
    }, []);

   return (
     <ContentTelahdigunakan>
       <TextTitle
         size="38px"
         fontsm="25px"
         poppins
         bold
         data-aos-duration="1000"
         data-aos="slide-right"
         plsm={"20px"}
       >
         Telah Digunakan Oleh
       </TextTitle>
       <ContainerLogo>
         <Bubble3>
           <img src={bubble3} alt="bubble3" width="300px" height="300px" />
         </Bubble3>
         <Grid >
           {LogoData.map((item) => {
             return (
               <div className="items" key={item.Name}>
                 <Item bgImg={item.Image} data-aos="flip-up"></Item>
               </div>
             );
           })}
         </Grid>

         <div
           className="d-flex flex-wrap justify-content-center"
           style={{ gap: "2.2rem" }}
         ></div>
       </ContainerLogo>
       <TextDesc aligntext="center">
        Beragam merchant telah mendapatkan keuntungan dari kemudahan layanan kami.
       </TextDesc>
       <TextDesc bold aligntext="center" >Jadilah yang berikutnya!</TextDesc>
     </ContentTelahdigunakan>
   );
}

export default TelahDigunakan

