import React from "react";
import KontenEmoney from "./Emoney/Content/KontenEmoney";
import HeaderEmoney from "./Emoney/Header/HeaderEmoney";
import { Helmet } from "react-helmet";

const Emoney = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>E2Pay - E-Money</title>
        <meta name="description" content="E2pay Ekosistem Page" />
      </Helmet>
      <HeaderEmoney />
      <KontenEmoney />
    </>
  );
};

export default Emoney;
