import React, { useEffect } from 'react'
import Content from './Documentation/Content/Content'
import Header from './Documentation/Header/Header'
import { Helmet } from 'react-helmet'
import styled from "styled-components";

const Container = styled.div`
overflow: hidden;
`;

const Documentation = () => {
   return (
      <Container>
         <Helmet>
            <meta charSet="utf-8" />
            <title>E2Pay - Documentation</title>
            <meta name="description" content="E2pay Documentation Page" />
         </Helmet>
         <Header />
         <Content />
      </Container>
   )
}

export default Documentation
