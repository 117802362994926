import React from 'react'
import PanduanPembayaran from '../../../components/Layout/PanduanPembayaran/PanduanPembayaran'
import { Container } from '../../../components/Layout/PanduanPembayaran/PanduanPembayaran.styled'
import CertificateContent from '../../../components/Layout/About/CertificateContent'
import NarasiE2payrazer from '../../../components/Layout/About/NarasiE2payrazer'

const ContentPanduanPembayaran = () => {
   return (
      <Container>
         <PanduanPembayaran />
         {/* <CertificateContent />
         <NarasiE2payrazer /> */}
      </Container>
   )
}

export default ContentPanduanPembayaran
