import React from 'react'
import { TextTitle } from '../components/UI/Text.styled'

const Page404 = () => {
   return (
      <TextTitle aligntext="center" style={{ paddingTop: '20px' }}>
         Page Not Found
      </TextTitle>
   )
}

export default Page404
