import React, { useState, useRef, useEffect } from "react";
import { Container, DetailArticleContainer } from "./NewsPortal.styled";
import calendarIcon from "../../../assets/images/calendar-icon.svg";
import authorIcon from "../../../assets/images/author-icon.svg";
import { TextDesc } from "../../UI/Text.styled";
import newsImage from "../../../assets/images/artikel.jpg"

const DetailNewsPortal = () => {
  return (
    <Container>
      <DetailArticleContainer>
        <div className="title">
          E2Pay Tegaskan Tidak Memfasilitasi Aktivitas Judi <span style={{fontStyle: "italic"}}>Online</span>
        </div>
        <div className="author-info">
          <div id="date">
            <img src={calendarIcon} alt="" />
            <p>15 Agustus 2024</p>
          </div>
          <div id="author">
            <img src={authorIcon} alt="" />
            <p>oleh Admin</p>
          </div>
          <div id="info">
            <p>Bacaan 10 menit</p>
          </div>
        </div>
        <div className="categories">
          <p>
            Kategori : <a href="">Company Updates</a> -{" "}
            <a href="">Product Updates</a> - <a href="">Insights</a> -{" "}
            <a href="">Life at E2Pay</a>
          </p>
        </div>
        <div className="article-image">
            <img src={newsImage} alt="" />
        </div>
        <div className="paragraph">
        Sehubungan dengan Press Release dari Kementerian Komunikasi dan Informatika Republik Indonesia terkait dengan upaya pemberantasan judi <span style={{fontStyle: "italic"}}>online</span> dimana melibatkan PT E2Pay Global Utama (“E2Pay”) sebagai salah satu Penyelenggara Jasa Pembayaran (PJP) di Indonesia yang diduga memfasilitasi aktivitas perjudian dan menjadi perhatian publik, bersama ini kami sampaikan klarifikasi resmi sebagai berikut:
        </div>
        <div className="paragraph" style={{fontWeight: "bold" }}>
        Kami menegaskan bahwa tidak ada indikasi keterlibatan layanan E2Pay dalam aktivitas perjudian atau aktivitas ilegal lainnya seperti yang disebutkan dalam pemberitaan tersebut. Kami telah melakukan pemeriksaan internal secara menyeluruh untuk memastikan bahwa tidak ada aktivitas atau transaksi yang terkait dengan fasilitas perjudian online melalui platform kami.
        </div>
        <div className="paragraph">
        Pada tanggal 12 Agustus 2024, Kominfo telah mengirimkan surat elektronik kepada E2Pay dengan subjek "Klarifikasi Surat Peringatan Untuk Melakukan Pemeriksaan Internal Terhadap Layanan Sistem Elektronik Terkait Aktivitas Perjudian." Dalam surat tersebut, Kominfo menegaskan bahwa surat elektronik yang dikirim pada tanggal 10 Agustus 2024 perihal "Peringatan Untuk Melakukan Pemeriksaan Internal Terhadap Layanan Sistem Elektronik Terkait Aktivitas Perjudian" bukan merupakan dugaan pelanggaran atau peringatan sanksi, melainkan sebuah himbauan.
        </div>
        <div className="paragraph">
        E2Pay berkomitmen untuk terus menjaga integritas layanan kami dan mematuhi segala regulasi yang berlaku di Indonesia.
        </div>
    
        <div className="paragraph" style={{color: "#0D6EFD",  cursor: "pointer"}}>
        #LayananAmanTanpaJudi #AntiJudiBersamaKami #IndonesiaBebasJudi #JagaIntegritasTanpaJudi #LawanPerjudian
        </div>
  
      </DetailArticleContainer>
    </Container>
  );
};

export default DetailNewsPortal;
