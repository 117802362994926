import styled from 'styled-components'

export const ContainerInformasi = styled.div`
   width: 90%;
   min-height: auto;
   margin: 0 auto;
   background-color: ${(props) => (props.bgCol ? props.bgCol : '#ffffff')};

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 20px;
`

export const InformasiContent = styled.div`
   padding: 20px;
   max-width: 950px;
   min-height: 270px;

   display: flex;
   flex-direction: column;
   gap: 1rem;
`

export const Narasi = styled.div`
   /* max-width: 1226px; */
   width: 80%;
   min-height: 200px;
   border-radius: 15px;
   background-color: #fff;
   font-size: 20px;
   gap: 2rem;
   margin: 0 auto;
   justify-content: center;
   /* align-items: center; */
   /* align-items: ${(props) =>
      props.alignitems ? props.alignitems : 'center'} */
   padding: 0px 69px;
   text-align: center;

   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 20;
   margin-top: ${(props) => (props.marginTop || '-100px')};
   /* margin-top: -100px; */
   /* bottom: 100px; */
   /* margin-top: 100px; */
   //  margin-bottom: 100px;

   @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      padding: 20px;
   }
`
