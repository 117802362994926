import React from 'react'
import Informasi from '../../../components/Layout/Privacy/Informasi'

const ContentPrivacy = () => {
   return (
      <>
         <Informasi />
      </>
   )
}

export default ContentPrivacy
