import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0 0px;
  padding: 0;
  flex-wrap: wrap;
  padding: 0 10%;
  gap: 100px;
  min-height: 500px;

  .paging {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 100px;

    #prev,
    #next {
      width: 120px;
      height: 40px;
      border-radius: 10px;
      border: 1px solid #1c278B;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: white;
    }

    #prev:hover,
    #next:hover {
      background-color: #1c278B;
      color: white;
    }
  }

  ul {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    li {
      list-style: none;
      #page {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #1c278B;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin: 50px 0;
  padding: 0;
  gap: 75px;
  flex-wrap: wrap;

  @media screen and (max-width: 1400px) {
    gap: 50px;
  }
`;

export const DetailArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  /* margin: 50px 0; */
  padding: 0;
  /* gap: 35px; */
  flex-wrap: wrap;

  .title {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: var(--272-d-3-b-text);
    margin: 0;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .author-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    

    #date,
    #author,
    #info {
      min-width: 15%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      margin-right: 10px;

      p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.79;
        letter-spacing: normal;
        text-align: left;
        color: #93969d;
      }

      img {
        padding-bottom: 9%;
      }
    }
  }

  .categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: -15px;

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #93969d;

      a {
        text-decoration: none;
      }
    }
  }

  .article-image {
    width: 100%;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .paragraph-title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--272-d-3-b-text);
    margin-bottom: 30px;
  }

  .paragraph {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #44525b;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1400px) {
    /* gap: 50px; */
  }
`;

export const Paging = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  gap: 75px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 450px;
  height: 600px;
  border-radius: 20px;
  /* border: 2px solid gray; */
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;

  @media screen and (max-width: 1600px) {
    width: 200px;
    height: 275px;
    gap: 20px;
  }

  @media screen and (min-width: 1000px) {
    width: 330px;
    height: 275px;
    gap: 20px;
  }
  img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: auto;
  }

  .title {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  .desc {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
  }

  .calendar-author {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #93969d;

    #calendar,
    #author {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    #author {
      width: 60%;
    }

    img {
      width: 14px;
      height: auto;
      margin-right: 5px;
    }

    img#calendarIcon {
      margin-top: -6px;
    }

    img#authorIcon {
      margin-top: -3px;
    }
  }
`;
