// import React from 'react'
// import ReactDOM from 'react-dom/client'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import './index.css'
// import Home from './pages/Home'
// import Footer from './components/UI/Footer'
// import PG from './pages/PG'
// import BG from './pages/BG'
// import Disbursement from './pages/Disbursement'
// import Ekosistem from './pages/Ekosistem'
// import Emoney from './pages/Emoney'
// import Privacy from './pages/Privacy'
// import About from './pages/About'
// import TentangE2Pay from './pages/TentangE2Pay'
// import Page404 from './pages/Page404'
// import Faq from './pages/Faq'
// import ScrollToTop from './pages/ScrollToTop'
// import Karir from './pages/Karir'
// import HubungiSales from './pages/HubungiSales'
// import MerchantApp from './pages/MerchantApp'
// import Biaya from './pages/Biaya'
// import PendaftaranMerchant from './pages/PendaftaranMerchant'
// import TesBackground from './tesbackground'
// import '././components/UI/i18n';
// // import { useTranslation, Trans } from "react-i18next"; 

// // import SimpleSlider from './slider'

// // const { t, i18n } = useTranslation();

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <BrowserRouter>
//     <ScrollToTop />
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/about-us" element={<TentangE2Pay />} />
//       <Route path="/kenapa-e2pay" element={<About />} />
//       <Route path="/payment-gateway" element={<PG />} />
//       <Route path="/biller-gateway" element={<BG />} />
//       <Route path="/disbursement" element={<Disbursement />} />
//       <Route path="/Ekosistem" element={<Ekosistem />} />
//       <Route path="/E-money" element={<Emoney />} />
//       <Route path="/Privacy" element={<Privacy />} />
//       <Route path="/faq" element={<Faq />} />
//       <Route path=
//       {i18n.lng === "de" ? "/karir" : "/careers"}

//       element={<Karir />} />
//       <Route path="/hubungi-sales" element={<HubungiSales />} />
//       <Route path="/merchant-application" element={<MerchantApp />} />
//       <Route path="/produk/biaya" element={<Biaya />} />
//       <Route path="/tes-background" element={<TesBackground />} />
//       {/* <Route path="/tes-slider" element={<SimpleSlider />} /> */}
//       <Route path="/pendaftaran-merchant" element={<PendaftaranMerchant />} />
//       <Route path="*" element={<Page404 />} />
//     </Routes>
//     <Footer />
//   </BrowserRouter>
// );

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n"
import App from "./app";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import {createStore, combineReducers} from "redux"
// import {routerMiddleware, routerReducer} from "react-router-redux"
import { I18nextProvider } from "react-i18next"
// import * as reducer from "./reducer"
import i18n from "./i18n";
import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

// const store = createStore(
//   combineReducers({
//     app: reducer,
//     routing: routerReducer
//   })
// )

// const BasicExample = () => (
//   <I18nextProvider i18n={i18n}>
//     {/* <Provider store={store}>
//       <routerMiddleware> */}
//         <Routes>
//           <Route path="/:locale" component={App}  Navigate="/id"/> 
//           {/* <Navigate to="/id" /> */}
//         </Routes>
//       {/* </routerMiddleware>
//     </Provider> */}
//   </I18nextProvider>
// )

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<BasicExample />)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
