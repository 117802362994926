import React, { useState } from 'react'
import { Narasi } from '../../Privacy/Informasi.styled'
import { ContainerTab, WrapTab } from './TabsFaq.styled'
import Tab from 'react-bootstrap/Tab'
import TabLeft from '../TabLeft'
import TabRight from '../TabRight'
import SelectMobileFaq from '../SelectMobileFaq'
import { useLocation } from "react-router-dom";
const TabsFaq = () => {

   const [active, setActive] = useState('qris-desktop')
   const [activeMobile, setActiveMobile] = useState('payment-gateway')

   const getActiveMobile = (select) => {
      // setActiveMobile;
      setActiveMobile(select)
   }

   return (
      <Narasi>
         {/* Untuk Mobile */}
         {/* <SelectMobileFaq getActive={getActiveMobile} /> */}
         {/* Untuk Mobile */}
         <ContainerTab>
            <Tab.Container
               id="left-tabs-example"
               defaultActiveKey="payment-gateway"
               activeKey={active}
               onSelect={(k) => setActive(k)}
            >
               <WrapTab>
                  <TabLeft active={active} />
                  <TabRight activeMobile={activeMobile} />
               </WrapTab>
            </Tab.Container>
         </ContainerTab>
      </Narasi>
   )
}

export default TabsFaq
