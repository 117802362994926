import React, { useState, useReducer } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from '@emailjs/browser'

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { InputForm } from '../../UI/InputForm/InputForm.styled'
import { TextDesc } from '../../UI/Text.styled'
import { BtnKirim } from '../../UI/Button/Button.styled'

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { WrapToggleButton } from './PendaftaranForm.styled'
import ModalMessage from './ModalMessage'

const PendaftaranForm = () => {
   const [radioValue, setRadioValue] = useState('pt')
   const radios = [
      { name: 'PT', value: 'PT' },
      { name: 'CV', value: 'CV' },
      { name: 'UD', value: 'UD' },
   ]

   const [namaToko, setNamaToko] = useState('')
   const [namaLengkap, setNamaLengkap] = useState('')
   const [email, setEmail] = useState('')
   const [nomorKontak, setNomorKontak] = useState('')

   const [error, setError] = useState(false)

   const [modalShow, setModalShow] = useState(false)

   const handleClose = () => setModalShow(false)

   const templateID = 'template_uu8e6dw'
   const serviceID = 'service_6kzlgys'
   const publicKey = 'Mbcau9PInSpcTscbY'

   const formHandler = (e) => {
      e.preventDefault()

      setModalShow(true)

      if (
         namaToko.length === 0 ||
         namaLengkap.length === 0 ||
         email.length === 0 ||
         nomorKontak.length === 0
      ) {
         setError(true)
      } else {
         setError(false)

         emailjs.sendForm(serviceID, templateID, e.target, publicKey)

         setNamaToko('')
         setNamaLengkap('')
         setEmail('')
         setNomorKontak('')
      }
   }

   return (
      <Container className="mt-5">
         <Form onSubmit={formHandler}>
            <Row className="d-flex align-items-center">
               <Col md="4">
                  <TextDesc aligntext="left">
                     <Form.Label>
                        Nama bisnis (nama toko / website)
                        <span className="text-danger"> *</span>
                     </Form.Label>
                  </TextDesc>
               </Col>
               <Col md="auto">
                  <ButtonGroup>
                     <WrapToggleButton>
                        {radios.map((radio, index) => (
                           <ToggleButton
                              key={index}
                              id={`radio-${index}`}
                              className="toggle-btn"
                              type="radio"
                              value={radio.value}
                              checked={radioValue === radio.value}
                              onChange={(e) =>
                                 setRadioValue(e.currentTarget.value)
                              }
                              name="user_tipe_perusahaan"
                           >
                              {radio.name}
                           </ToggleButton>
                        ))}
                     </WrapToggleButton>
                  </ButtonGroup>
               </Col>
            </Row>
            <Row className="d-flex align-items-center mt-3">
               <Col md="4"></Col>
               <Col md="5">
                  <InputForm
                     name="user_nama_toko"
                     onChange={(e) => setNamaToko(e.target.value)}
                     value={namaToko}
                  />
               </Col>
            </Row>
            <Row className="d-flex align-items-center mt-3">
               <Col md="4">
                  <TextDesc fontsm="12" aligntext="left">
                     <Form.Label>
                        Nama lengkap pemilik perusahaan
                        <span className="text-danger"> *</span>
                     </Form.Label>
                  </TextDesc>
               </Col>
               <Col md="5">
                  <InputForm
                     type="text"
                     name="user_nama_lengkap"
                     onChange={(e) => setNamaLengkap(e.target.value)}
                     value={namaLengkap}
                  />
               </Col>
            </Row>
            <Row className="d-flex align-items-center mt-3">
               <Col md="4">
                  <TextDesc fontsm="12" aligntext="left">
                     <Form.Label>
                        E-mail <span className="text-danger"> *</span>
                     </Form.Label>
                  </TextDesc>
               </Col>
               <Col md="5">
                  <InputForm
                     type="email"
                     name="user_email"
                     onChange={(e) => setEmail(e.target.value)}
                     value={email}
                  />
               </Col>
            </Row>
            <Row className="d-flex align-items-center mt-3">
               <Col md="4" className="text-left">
                  <TextDesc fontsm="12" aligntext="left">
                     <Form.Label>
                        Nomor kontak <span className="text-danger"> *</span>
                     </Form.Label>
                  </TextDesc>
               </Col>
               <Col md="5">
                  <InputForm
                     name="user_nomorkontak"
                     onChange={(e) => setNomorKontak(e.target.value)}
                     type="number"
                     value={nomorKontak}
                  />
               </Col>
            </Row>
            <Row className="d-flex align-items-center mt-3">
               <Col md="4"></Col>
               <Col md="5">
                  <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" />
               </Col>
            </Row>
            <Row className="mt-5">
               <Col md="4"></Col>
               <Col md="auto">
                  <BtnKirim>Kirim</BtnKirim>
               </Col>
            </Row>
         </Form>

         <ModalMessage show={modalShow} onHide={handleClose} iserror={error} />
      </Container>
   )
}

export default PendaftaranForm
