import React from 'react'
import ContentNewsPortal from './NewsPortal/Content/ContentNewsPortal'
import HeaderNewsPortal from './NewsPortal/Header/HeaderNewsPortal'
import { Helmet } from 'react-helmet'

const NewsPortal = () => {
   return (
      <>
      <Helmet>
        <title>E2Pay - Article</title>
        <meta name="description" content="E2pay Article Page" />
      </Helmet>
         <HeaderNewsPortal />
         <ContentNewsPortal />
      </>
   )
}

export default NewsPortal
