import React from 'react'
import { TextDesc, TextTitle } from '../../../UI/Text.styled'
import {TableBiaya, TableBiaya2} from './TableBiaya'
import { ContainerTitle } from './ContainerTable.styled'
import { Fragment } from 'react'

import Octoclicks from '../../../../assets/images/HalamanBiaya/Biaya-Octoclicks.png'
import BTNMobile from '../../../../assets/images/HalamanBiaya/Biaya-BTNMobile.png'
import QRIS from '../../../../assets/images/HalamanBiaya/qris.png'
import QRISCIMB from '../../../../assets/images/HalamanBiaya/Biaya-QRIS-CIMB.png'
import QRISShopeepay from '../../../../assets/images/HalamanBiaya/Biaya-QRIS-Shopeepay.png'
import QRISLinkAja from '../../../../assets/images/HalamanBiaya/Biaya-QRIS-Linkaja.png'
import Rekpon from '../../../../assets/images/HalamanBiaya/Biaya-Rekpon.png'
import LinkAja from '../../../../assets/images/HalamanBiaya/Biaya-LinkAja.png'
import Nucash from '../../../../assets/images/HalamanBiaya/Biaya-Nucash.png'
import Dana from '../../../../assets/images/HalamanBiaya/Biaya-Dana.png'
import OVO from '../../../../assets/images/HalamanBiaya/Biaya-OVO.png'
import Gopay from '../../../../assets/images/HalamanBiaya/Biaya-Gopay.png'
import CimbNiaga from '../../../../assets/images/HalamanBiaya/Biaya-CIMB.png'
import BCA from '../../../../assets/images/HalamanBiaya/Biaya-BCA.png'
import BNI from '../../../../assets/images/HalamanBiaya/Biaya-bni.png'
import Permata from '../../../../assets/images/HalamanBiaya/Biaya-Permata.png'
import Mandiri from '../../../../assets/images/HalamanBiaya/Biaya-mandiri.png'
import Kredivo from '../../../../assets/images/HalamanBiaya/Biaya-Kredivo.png'
import Indodana from '../../../../assets/images/HalamanBiaya/Biaya-Indodana.png'
import Akulaku from '../../../../assets/images/HalamanBiaya/Biaya-AkuLaku.png'
import Indomaret from '../../../../assets/images/HalamanBiaya/Biaya-Indomaret.png'
import Shopeepay from '../../../../assets/images/HalamanBiaya/Biaya-Shopeepay.png'
import Mbayar from '../../../../assets/images/HalamanBiaya/Biaya-Mbayar.png'
import AlfaLawsonDandan from '../../../../assets/images/HalamanBiaya/Biaya-AlfaLawsonDandan.png'
import CreditCIMB from '../../../../assets/images/HalamanBiaya/Biaya-CreditCIMB.png'
import BRI from '../../../../assets/images/HalamanBiaya/Biaya-BRI.webp'

export const Tables = () => {
   // Data yang digunakan pada tabel
   const semuaBank = [BCA, BRI, BNI]
   const dataTables = [
      {
         title: 'Internet Banking',
         contentTable: [
            {
               image: Octoclicks,
               namaChannel: 'Octo Clicks',
               price: 'Rp4.500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
      {
         title: 'With QR Payment',
         contentTable: [
            {
               image: Octoclicks,
               namaChannel: <div className='newSpan'>Octo Mobile <br/> <p style={{color: "#6c63ff", fontWeight: "normal", fontSize: "12px"}}>(Exclusive Partners QRIS)</p></div>,
               price: 'Rp4.500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            // {
            //    image: BTNMobile,
            //    namaChannel: 'BTN Mobile',
            //    price: 'Rp4.500',
            //    hari: 'T+3',
            //    value: 'Exclude tax 11%',
            //    button: 'LIVE',
            // },
         ],
      },
      {
         title: 'QRIS',
         contentTable: [
            {
               image: QRIS,
               namaChannel: 'QRIS ',
               price: '0,70%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            }
         ],
      },
      {
         title: 'E-Money',
         contentTable: [
            {
               image: Rekpon,
               namaChannel: 'Rekening Ponsel',
               price: 'Rp4,500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: LinkAja,
               namaChannel: 'LinkAja App',
               price: '1,50%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: LinkAja,
               namaChannel: 'LinkAja WCO',
               price: '1,60%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Mbayar,
               namaChannel: 'M-Bayar',
               price: '0.7%',
               hari: 'T+1',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            // {
            //    image: Nucash,
            //    namaChannel: 'Nucash',
            //    price: '1,50%',
            //    hari: 'T+3',
            //    value: 'Exclude tax 11%',
            //    button: 'LIVE',
            // },
            {
               image: Dana,
               namaChannel: 'Dana Balance',
               price: '2,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Dana,
               namaChannel: 'Dana Credit Card',
               price: '2,70%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Dana,
               namaChannel: 'Dana Direct Debit',
               price: '2,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Dana,
               namaChannel: 'Dana Virtual Account',
               price: '2,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Shopeepay,
               namaChannel: 'ShopeePay Non Digital Content',
               price: '2,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Shopeepay,
               namaChannel: 'ShopeePay Digital Content',
               price: '4,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: OVO,
               namaChannel: 'OVO Non Digital Content dalam negeri',
               price: '1,50%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: OVO,
               namaChannel: 'OVO Digital Content dalam negeri',
               price: '2,73%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Gopay,
               namaChannel: 'Gopay Non Digital Content',
               price: '2,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Gopay,
               namaChannel: 'Gopay Digital Content',
               price: '10,00%',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
      {
         title: 'Virtual Account',
         contentTable: [
            {
               image: CimbNiaga,
               namaChannel: 'VA CIMB Niaga',
               price: 'Rp4,500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: BCA,
               namaChannel: 'VA BCA',
               price: 'Rp5,000',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: BNI,
               namaChannel: 'VA BNI',
               price: 'Rp4,500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: BRI,
               namaChannel: 'VA BRI',
               price:
               //  (
               //    <>
               //       MDR Bank: TBA Biaya <br /> E2Pay: Rp1,500
               //    </>
               // ),
               "Rp4,500",
               hari: 'T+1',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Permata,
               namaChannel: 'VA Permata',
               price: 'Rp4,500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Mandiri,
               namaChannel: 'VA Mandiri',
               price: 'Rp4,500',
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
      {
         title: 'Credit Card',
         contentTable: [
            {
               image: CreditCIMB,
               namaChannel: 'Credit Card CIMB Niaga',
               price: (
                  <>
                     MDR Bank: 2,70% <br /> Biaya E2Pay: Rp1,500
                  </>
               ),
               hari: 'T+3',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
      {
         title: 'Financing',
         contentTable: [
            {
               image: Kredivo,
               namaChannel: 'Kredivo',
               price: (
                  <>
                     MDR Bank: 2,30% <br /> Biaya E2Pay: Rp1,500
                  </>
               ),
               hari: 'T+5',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Indodana,
               namaChannel: 'Indodana',
               price: (
                  <>
                     MDR Bank: 1% <br /> Biaya E2Pay: Rp1,500
                  </>
               ),
               hari: 'T+5',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            // {
            //    image: Akulaku,
            //    namaChannel: 'TBA',
            //    price: (
            //       <>
            //          MDR Bank: 1% <br /> Biaya E2Pay: Rp1,500
            //       </>
            //    ),
            //    hari: 'TBA',
            //    value: 'Exclude tax 11%',
            //    button: 'Q4 2022',
            // },
         ],
      },
      {
         title: 'Over The Counter',
         contentTable: [
            {
               image: Indomaret,
               namaChannel: 'Indomaret',
               price: (
                  <>
                     Indomaret Fee : TBA <br />
                     Biaya E2Pay: Rp1,500
                  </>
               ),
               hari: 'TBA',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: AlfaLawsonDandan,
               namaChannel: 'Alfamart, Alfamidi, Lawson, DanDan',
               price: 'Rp5,000',
               hari: 'T+4',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
      
   ]

   
   return (
      <>
         {dataTables.map((data, index) => (
            <Fragment key={index}>
               <ContainerTitle>
                  <TextTitle size="33px" fw="600" aligntext="left">
                     {data.title}
                  </TextTitle>
               </ContainerTitle>

               <TableBiaya data={data} />
            </Fragment>
         ))}


      </>
   )
}



export const Tables2 = () => {
   // Data yang digunakan pada tabel
 
   const images = [BCA, BRI, BNI, Mandiri]
   const [currentImage, setCurrentImage] = React.useState(null);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImage(images[Math.floor(Math.random() * images.length)]);
        }, 1000)
        
        return () => clearInterval(intervalId);
    }, [])
   const dataTables2 = [
      
      {
         title: 'Disbursement',
         contentTable: [
            {
               image: currentImage,
               namaChannel: 'Semua Bank di Indonesia Online Realtime',
               price: 'Rp5,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Mbayar,
               namaChannel: 'M-Bayar Open amount',
               price: 'Rp1,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Dana,
               namaChannel: 'DANA',
               price: 'Rp2,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: OVO,
               namaChannel: 'OVO',
               price: 'Rp2,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Gopay,
               namaChannel: 'Gopay',
               price: 'Rp2,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
            {
               image: Gopay,
               namaChannel: 'Gopay - Driver',
               price: 'Rp2,000',
               // hari: '-',
               value: 'Exclude tax 11%',
               button: 'LIVE',
            },
         ],
      },
   ]

   

   return (
      <>
{dataTables2.map((data, index) => (
            <Fragment key={index}>
               <ContainerTitle>
                  <TextTitle size="33px" fw="600" aligntext="left">
                     {data.title}
                  </TextTitle>
               </ContainerTitle>

               <TableBiaya2 data={data} />

         
            </Fragment>
         ))}
      </>
   )
}

