import styled from 'styled-components'
import { LogoMerchant } from '../../UI/Logo.styled'

export const ContainerPG = styled.div`
   margin-top: ${(props) => props.margintop || '120px'};
`

export const MenuPG = styled.div`
   display: flex;
   gap: 2rem;
   flex-wrap: wrap;
   justify-content: center;
   margin-top: 75px;
`

export const MenuListPG = styled.div`
   max-width: auto;
   min-height: 503px;
   padding: 10px;
   display: flex;
   flex-direction: column;
   gap: 1rem;
`

export const MenuImage = styled.div`
   width: 812px;
   min-height: 503px;
   padding: 20px;
`

export const WrapImage = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 20px;

   justify-content: start;

   @media only screen and (max-width: 820px) {
      /* For mobile phones: */
      justify-content: center;
   }
`

export const MenuList = styled.div`
   width: 250px;
   height: 65px;
   border-radius: 39px;
   border: none;

   cursor: pointer;

   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
   text-align: left;
   padding-right: 20px;

   position: relative;

   &:hover {
      background-color: #edf2fe;
      color: #2d62ed;
   }
`

export const IconChannel = styled(LogoMerchant)`
   width: 40px;
   height: 40px;
`

export const LogoChannel = styled.div`
   width: ${(props) => props.width};
   height: 67px;

   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: auto;
   background-position: ${(props) => props.bgPosition};
`
