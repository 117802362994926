import styled from "styled-components";

export const ContentContainer = styled.div`
    margin-top: ${(props) => (props.marginTop)};

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: ${(props) => (props.marginTop)};
  }
`;

export const Content_2 = styled.div`
  max-width: 1080px;
  min-height: 700px;
  margin: 240px auto 0 auto;

  display: ${(props) => (props.flex ? "flex" : "")};
  flex-wrap: ${(props) => (props.flex ? "wrap" : "")};
  justify-content: ${(props) => (props.flex ? "flex-end" : "")};

  position: relative;
`;

export const ContentTelahdigunakan = styled(Content_2)`
  text-align: left;
  margin-top: 100px;
  margin-bottom: 75px;
`;

// export const ContainTextContent_2 = styled.div`
//    width: 536px;
//    text-align: left;
//    position: absolute;
//    left: 0;
//    top: 0;

//    @media only screen and (max-width: 768px) {
//       /* For mobile phones: */
//       position: relative;
//    }
// `

// export const ContentCard = styled.div`
//    width: 150px;
//    height: 150px;
//    background-image: url(${(props) => props.bgImage});
//    background-size: contain;
//    background-repeat: no-repeat;
// `

// export const CardWrap = styled.div`
//    display: flex;
//    justify-content: space-between;
//    padding-right: 2rem;
//    & img {
//       width: 26px;
//       height: 26px;
//    }
//    & a {
//       align-self: flex-end;
//    }
// `

// export const WrapFlexLogo = styled.div`
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: space-between;
//    gap: 0.5rem;
// `

export const ContentJaringan = styled.div`
  margin-top: 175px;
  background-color: #2d62ed;
  padding: 20px 0;
`;

export const WrapJaringan = styled.div`
  max-width: 1080px;

  margin: 0 auto;
  display: flex;
  width: 100%;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
  }
`;

export const ContentTextJaringan = styled.div`
  width: fit-content;
  padding: 10px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;
export const ContentMapJaringan = styled.div`
  /* flex: 1.5; */
  max-width: 100%;
  p {
    /* width: 20px;
    height: 20px; */
    position: absolute;
    left: 500px;
    top: 50px;
    z-index: 20;
  }

  p:hover {

  }
  img {
    max-width: 100%;
  }
`;
