import React from 'react'
import ContohPengunaan from '../../../components/Layout/PG/ContohPengunaan'
import FungsiProduk from '../../../components/Layout/PG/FungsiProduk'
import TabsJangkauPenerimaan from '../../../components/Layout/PG/TabsJangkauPenerimaan'
import { ContainerPG } from '../../../components/Layout/PG/JangkauPenerimaan.styled'
import LayananBisnis from '../../../components/Layout/PG/LayananBisnis'
import ContainerMarqueeBiller from '../../../components/Layout/PG/ContainerMarqueeBiller'
import ParallaxBE from '../../../components/Layout/PG/ParallaxBE'
import ParallaxDB from '../../../components/Layout/PG/ParallaxDB'
import TujuanBisnis from '../../../components/Layout/PG/TujuanBisnis'
import HeaderDisbursement from '../Header/HeaderDisbursement'
import TerimaPembayaran from '../../../components/UI/TerimaPembayaran'
import BiayaDisbursment from '../../../components/Layout/PG/BiayaDisbursment'

const Disbursement = () => {
   return (
      <div style={{overflow: "hidden"}}>
         <HeaderDisbursement />
         <ContainerPG>
            {/* <TabsJangkauPenerimaan /> */}
            {/* <TujuanBisnis /> */}
            {/* <ParallaxDB /> */}
            <FungsiProduk />
            <ContohPengunaan />
            <BiayaDisbursment />
            {/* <ParallaxBE  /> */}
            {/* <ContainerMarqueeBiller />
            <LayananBisnis /> */}
            <TerimaPembayaran />
         </ContainerPG>
      </div>
   )
}

export default Disbursement
