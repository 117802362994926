import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
   ContentRight,
   LogoTagline,
   WrapTxtTaglineRight,
} from './TaglineMbayar.styled'
import { TextDesc } from '../../UI/Text.styled'

import LogoOutlet from '../../../assets/images/web_icon_outlets.svg'
import LogoTelco from '../../../assets/images/web_icon_telco.svg'
import LogoTagihan from '../../../assets/images/web_icon_tagihan.svg'
import LogoQR from '../../../assets/images/web_icon_QR.svg'

const RightContent = () => {
   return (
      <Col md={4} className="d-flex flex-column gap-5 justify-content-center">
         <ContentRight>
            <LogoTagline className="mx-3" bgImg={LogoTelco} />
            <WrapTxtTaglineRight className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Beli Pulsa dan Paket Data
               </TextDesc>
               <TextDesc size={'14px'} height="30px">
                  Beli pulsa handphone atau paket data dengan mudah dan cepat
               </TextDesc>
            </WrapTxtTaglineRight>
         </ContentRight>
         <ContentRight>
            <LogoTagline className="mx-3" bgImg={LogoTagihan} />
            <WrapTxtTaglineRight className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Bayar Tagihan
               </TextDesc>

               <TextDesc size={'14px'} height="50px">
                  Tagihan pasca-bayar dan tagihan listrik. (Pembayaran beragam
                  tagihan lainnya akan segera hadir)
               </TextDesc>
            </WrapTxtTaglineRight>
         </ContentRight>
         <ContentRight>
            <LogoTagline className="mx-3" bgImg={LogoQR} />
            <WrapTxtTaglineRight className="mt-3">
               <TextDesc size={'18px'} fw={'600'} className="m-0" height="30px">
                  Pembayaran QR
               </TextDesc>

               <TextDesc size={'14px'}>
                  QR Payment dapat digunakan di merchant yang telah terintegrasi
                  dengan M-Bayar sepeti restoran, kafe, dan toko. Cukup scan
                  kode QR dengan aplikasi M-Bayar. [Segera hadir]
               </TextDesc>
            </WrapTxtTaglineRight>
         </ContentRight>
      </Col>
   )
}

export default RightContent
