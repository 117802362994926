import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";
import {Tables} from '../../../components/Layout/Biaya/Tables/ContainerTable'
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { Link } from "react-router-dom";
const TabPanePaymentGateway = () => {
  return (
    <Tab.Pane eventKey="payment-gateway-desktop">
      <Tables/>
    </Tab.Pane>
  );
};

export default TabPanePaymentGateway;
