import React from "react";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  BGDisbursement,
  BgEkoBiller,
  BGMbayar,
  DescPG,
  HrLine,
  ImageBG,
  ImageBiller,
} from "./ListProduk.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { ButtonPelajari } from "../../UI/Button/Button.styled";
import arrowBlue from "../../../assets/images/arrow-blue.svg";
import ImgBiller from "../../../assets/images/ImageBiller.svg";
import ImgMBayar from "../../../assets/images/ImageMbayar.png";
import BgBubbleBG from "../../../assets/images/BG_Bubble1.png";
import BgBubbleSmall from "../../../assets/images/BG_Bubblesmall.png";

const ListProduk = () => {
  return (
    <Container fluid className="p-0 m-0 overflow-hidden" >
      {/* Payment Gateway */}
      <Row className="justify-content-center"
        style={{ maxWidth: '1226px', margin: '0 auto' }}>
        <Col
          style={{ padding: "10px" }}
          className="d-flex justify-content-center"
          md={4}
        >
          <ImageBiller bgImg={ImgBiller} alt={"Logo Biller Gateway"} />
        </Col>
        <Col
          style={{ padding: "10px" }}
          className="d-flex justify-content-center"
          md={4}
        >
          <DescPG>
            <HrLine />
            <div>
              <span>
                <TextTitle size={"38px"} fw={"bold"}>
                  Payment
                </TextTitle>
              </span>
              <span>
                <TextTitle size={"38px"} fw={"bold"}>
                  Gateway
                </TextTitle>
              </span>
            </div>
            <div style={{ maxWidth: "352px" }}>
              <TextDesc>
                Terima pembayaran dari konsumen Anda melalui beragam metode
              </TextDesc>
            </div>
            <ButtonPelajari className="d-flex align-items-center justify-content-center gap-2">
              <span>Pelajari Lebih Lanjut</span>
              <span>
                <img src={arrowBlue} alt={"arrow blue"} />
              </span>
            </ButtonPelajari>
          </DescPG>
        </Col>
      </Row>

      {/* Biller Gateway */}
      <BgEkoBiller bgImg={BgBubbleBG}>
        <Row className="justify-content-center"
          style={{ marginTop: "100px", maxWidth: '1226px', margin: '100px auto 0' }}>
          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <DescPG>
              <HrLine />
              <div>
                <span>
                  <TextTitle size={"38px"} fw={"bold"}>
                    Biller Gateway
                  </TextTitle>
                </span>
              </div>
              <div style={{ maxWidth: "352px" }}>
                <TextDesc>
                  Fasilitasi konsumen Anda untuk membeli dan membayar beragam
                  produk biller
                </TextDesc>
              </div>
              <ButtonPelajari className="d-flex align-items-center justify-content-center gap-2">
                <span>Pelajari Lebih Lanjut</span>
                <span>
                  <img src={arrowBlue} alt={"arrow blue"} />
                </span>
              </ButtonPelajari>
            </DescPG>
          </Col>

          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <ImageBiller bgImg={ImgBiller} alt={"Logo Biller Gateway"} />
          </Col>
        </Row>
      </BgEkoBiller>

      {/* Disbursement */}
      <BGDisbursement bgImg={BgBubbleBG}>
        <Row className="justify-content-center"
          style={{ marginTop: "100px", maxWidth: '1226px', margin: '100px auto 0' }}>
          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <ImageBiller bgImg={ImgBiller} alt={"Logo Biller Gateway"} />
          </Col>
          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <DescPG>
              <HrLine />
              <div>
                <span>
                  <TextTitle size={"38px"} fw={"bold"}>
                    Disbursement
                  </TextTitle>
                </span>
              </div>
              <div style={{ maxWidth: "352px" }}>
                <TextDesc>
                  Terima pembayaran dari konsumen Anda melalui beragam metode
                </TextDesc>
              </div>
              <ButtonPelajari className="d-flex align-items-center justify-content-center gap-2">
                <span>Pelajari Lebih Lanjut</span>
                <span>
                  <img src={arrowBlue} alt={"arrow blue"} />
                </span>
              </ButtonPelajari>
            </DescPG>
          </Col>
        </Row>
      </BGDisbursement>

      {/* Mbayar E-Wallet */}
      <BGMbayar bgImg={BgBubbleSmall}>
        <Row className="justify-content-center"
          style={{ marginTop: "100px", maxWidth: '1226px', margin: '100px auto 0' }}>
          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <DescPG>
              <HrLine />
              <div>
                <span>
                  <TextTitle size={"38px"} fw={"bold"}>
                    M-Bayar Wallet
                  </TextTitle>
                </span>
              </div>
              <div style={{ maxWidth: "352px" }}>
                <TextDesc>
                  Kembangkan akun konsumen dengan deposit guna meluaskan fitur
                  bisnis anda
                </TextDesc>
              </div>
              <ButtonPelajari className="d-flex align-items-center justify-content-center gap-2">
                <span>Pelajari Lebih Lanjut</span>
                <span>
                  <img src={arrowBlue} alt={"arrow blue"} />
                </span>
              </ButtonPelajari>
            </DescPG>
          </Col>

          <Col
            style={{ padding: "10px" }}
            className="d-flex justify-content-center"
            md={4}
          >
            <ImageBiller bgImg={ImgMBayar} alt={"Logo Biller Gateway"} />
          </Col>
        </Row>
      </BGMbayar>
    </Container>
  );
};

export default ListProduk;
