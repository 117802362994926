import React from 'react'
import PendaftaranForm from '../../../components/Layout/PendaftaranMerchant/PendaftaranForm'

import { Narasi } from '../../../components/Layout/Privacy/Informasi.styled'

const ContentPendaftaranMerchant = () => {
   return (
      <Narasi alignitems="flex-start">
         <PendaftaranForm />
      </Narasi>
   )
}

export default ContentPendaftaranMerchant
