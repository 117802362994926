import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TextTitle, TextDesc } from '../../UI//Text.styled'
import ImgHandshake from '../../../assets/images/handshake.png'
import { LogoBg } from '../../UI/Logo.styled'
import SkemaContent from './SkemaContent'
import { BtnSkemaCP } from './Skemakerjasama.styled'
import { Link } from 'react-router-dom'
const Skemakerjasama = () => {
   return (
      <Container fluid style={{ marginTop: '50px' }}>
         <Row style={{ maxWidth: '1088px', margin: '0 auto' }}>
            <Col
               className="d-flex flex-column gap-2 justify-content-center"
               sm={12}
               md={6}
            >
               <div style={{ maxWidth: '440px' }}>
                  <TextTitle fontsm="25px" fw="600">
                     Pilihan Skema Kerjasama
                  </TextTitle>
                  <TextDesc size="14px" style={{ lineHeight: '1.8' }}>
                     Platform uang elektronik M-Bayar dapat digunakan pada
                     aplikasi Anda dengan integrasi API. Tanpa biaya besar dan
                     mengurus perizinan ke Bank Indonesia aplikasi Anda akan
                     semakin sering digunakan oleh para pengguna. Anda tetap
                     mendapatkan keuntungan dari transaksi uang elektronik
                     tersebut.
                  </TextDesc>
               </div>
            </Col>
            <Col sm={12} md={6}>
               <LogoBg width={'100%'} height={'328px'} ImgBg={ImgHandshake} />
            </Col>
         </Row>

         <Row className=" mx-auto justify-content-center" style={{ maxWidth: '1088px' }}>
            {/* <SkemaContent
               header={'B2B API'}
               txtBtn={'sudah memiliki aplikasi'}
               layanan1={'Akses API M-Bayar'}
               layanan2={'Layanan customer service'}
               footer={'Bagi hasil dari transaksi berhasil'}
            /> */}
             <SkemaContent
               header={'Account Linkage'}
               txtBtn={'belum memiliki aplikasi'}
               layanan1={'Pembuatan aplikasi uang elektronik'}
               layanan2={'Akses API M-Bayar'}
               layanan3={'Layanan customer service'}
               footer={'Bagi hasil dari transaksi berhasil'}
            />
            {/* <SkemaContent
               header={'Co-Branding'}
               txtBtn={'belum memiliki aplikasi'}
               layanan1={'Pembuatan aplikasi uang elektronik'}
               layanan2={'Akses API M-Bayar'}
               layanan3={'Layanan customer service'}
               footer={'Bagi hasil dari transaksi berhasil'}
            /> */}
            {/* <SkemaContent
               header={'Disbursment'}
               txtBtn={'kebutuhan financial lainnya'}
               layanan1={
                  'Mengirimkan uang ke berbagai pengguna dalam waktu bersamaan'
               }
               layanan2={'Laporan transaksi'}
               footer={'Fee per transaksi'}
            /> */}
         </Row>
         
         <Row className="justify-content-center mt-5">
            <Link to="/hubungi-sales" className="text-center">
               <BtnSkemaCP>
                  <TextDesc>Hubungi Kami</TextDesc>
               </BtnSkemaCP>
            </Link>
         </Row>
      </Container>
   )
}

export default Skemakerjasama
