import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  padding-top:100px;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;

  .bubble {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 575px;
  }

  .bg1 {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 550px;
  }

  .bg2 {
    position: absolute;
    z-index: 1;
    left: -300px;
    bottom: -150px;
  }
`;



export const Title1 = styled.div`
  position: relative;
  top: -225px;
  background-color: #fff;
  width: 110%;
  height: 175px;
  margin: 0 ;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 15px;
  z-index: 30;

  @media screen and (max-width: 768px) {
    margin-top: 150px;
  }

  p {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--272-d-3-b-text);
  }
`;

export const Title2 = styled.div`
  position: relative;
  width: 110%;
  height: 200px;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--272-d-3-b-text);
  }
`;

export const Row1 = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 25px;
  margin-top: -200px;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Row2 = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  gap: 1rem;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Row3 = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 25px;
  gap: 1rem;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Row4 = styled.div`
  width: 100%;
  height: 250px;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* margin-bottom: 25px; */
  /* gap: 1rem; */
  position: relative;
  z-index: 3;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Row5 = styled.div`
  width: 100%;
  height: 250px;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 25px;
  /* gap: 1rem; */
  position: relative;
  z-index: 3;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    margin-top: 400px;
  }
`; 

export const AboutCard1 = styled.div`
  width: 260px;
  height: 260px;
  margin: 0 16px 0 0;
  padding: 26px 25px 49px;
  border-radius: 20px;
  background-color: #fff;
  /* border: 1px solid black; */
  box-shadow: 0 0 20px 20px #edf6ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-bottom: 5px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #2d62ed;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: center;
    color: #44525b;
  }
`;

export const AboutCard2 = styled.div`
  width: 260px;
  height: 260px;
  /* margin: 0 16px 0 0; */
  padding: 26px 25px 49px;
  /* border-radius: 20px; */
  background-color: #fff;
  /* border: 1px solid black; */
  /* box-shadow: 0 0 20px 20px #edf6ff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;

  img {
    margin-bottom: 5px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #1c278b;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #44525b;
  }
`;
