import styled from "styled-components";

export const ContainerInformasi = styled.div`
  width: 90%;
  min-height: auto;
  margin: 0 auto;
  background-color: ${(props) => (props.bgCol ? props.bgCol : "#ffffff")};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const InformasiContent = styled.div`
  padding: 20px;
  max-width: 950px;
  min-height: 270px;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Narasi = styled.div`
  /* max-width: 1226px; */
  width: 80%;
  min-height: 200px;
  border-radius: 15px;
  background-color: #fff;
  font-size: 20px;
  gap: 2rem;
  margin: 0 auto;

  justify-content: center;
  /* align-items: center; */
  /* align-items: ${(props) =>
    props.alignitems ? props.alignitems : "center"} */

  padding: 0px 10px;
  /* text-align: center; */
  padding-left: ${(props) => props.paddingLeft};
  padding-top: ${(props) => props.paddingTop};

  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || "center"};
  position: relative;
  z-index: 20;
  margin-top: ${(props) => props.marginTop || "-100px"};
  /* margin-top: -100px; */
  /* bottom: 100px; */
  /* margin-top: 100px; */
  //  margin-bottom: 100px;

  .contact {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 400px;
    padding: 0 5%;

    .address {
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 2%;
      gap: 20px;

      .desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .contacts {
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;

      .phone {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .customer-service,
        .business-inquiry {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
        }
      }

      .email {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .email-customer-service,
        .email-business-inquiry {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;

          .desc {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
         
         }

       
      }
    }
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding: 20px;
  }
`;
