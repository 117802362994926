import React from "react";

import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MockupMbayar,
  BubbleBottom,
  PinkSquare,
  BlueSquare,
  OrangeSquare,
} from "./TaglineMbayar.styled";
import ImgMockup from "../../../assets/images/MockupmBayar.png";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import ImgBubble from "../../../assets/images/BG_Bubble1.png";

const TaglineMbayar = () => {
  return (
    <Container fluid className="p-0 m-0 overflow-hidden">
      <Row>
        <LeftContent />
        <Col
          className="d-flex justify-content-center align-items-center"
          md={4}
        >
          <MockupMbayar bgImg={ImgMockup} />
          <BubbleBottom bgImg={ImgBubble} />
          <PinkSquare top={"850px"} left={"300px"} trnsfrm={"rotate(345deg)"} />
          <PinkSquare
            top={"975px"}
            right={"300px"}
            trnsfrm={"rotate(345deg)"}
          />
          <OrangeSquare
            top={"1300px"}
            left={"300px"}
            trnsfrm={"rotate(15deg)"}
          />
          <BlueSquare
            width={"20px"}
            height={"20px"}
            right={"499px"}
            trnsfrm={"rotate(15deg)"}
          />
          {/* <BlueSquare
            bottom={"-905px"}
            width={"33px"}
            height={"33px"}
            left={"630px"}
            trnsfrm={"rotate(25deg)"}
          /> */}
          <BlueSquare
            bottom={"-1025px"}
            width={"24px"}
            height={"24px"}
            right={"400px"}
            trnsfrm={"rotate(25deg)"}
          />
        </Col>
        <RightContent />
      </Row>
    </Container>
  );
};

export default TaglineMbayar;
