import React from 'react'
import {
   BackgroundBD,
   ContainerBD,
   ContentLeft,
   ContentRight,
} from './BiayaDisbursment.styled'
import { NavLink } from 'react-router-dom'

import { TextTitle, TextDesc } from '../../UI/Text.styled'

import ImgContentBD from '../../../assets/images/img_BiayaDisbursment.png'

const BiayaDisbursment = () => {
   return (
      <ContainerBD>
         <ContentLeft>
            <BackgroundBD bgImg={ImgContentBD} />
         </ContentLeft>
         <ContentRight>  
            <TextTitle color="#fff" widthsm="300px">
               Biaya Disbursment Kami Yang Terjangkau
            </TextTitle>
            <TextDesc size="18px" color="#fff" widthsm="300px">
               Kirim dana sekarang dengan flat price tanpa penundaan. Biaya
               hanya akan dikenakan saat transaksi berhasil.
            </TextDesc>

            <NavLink to="/" style={{ textDecoration: 'none' }}>
               <TextDesc color="#44d62c" fw="bold" widthsm="300px" marginBottom="20px" marginTop="-150px" mtsm="0">
                  Pelajari Biaya Lebih Lanjut
               </TextDesc>
            </NavLink>
         </ContentRight>
      </ContainerBD>
   )
}

export default BiayaDisbursment
