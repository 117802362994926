import React from 'react'
import HeaderNoHero from '../../../../components/UI/HeaderNoHero'
import HeroBG from '../../../../assets/images/background header/header-aboutus.png'
const HeaderAbout = () => {
   return (
     <HeaderNoHero
       title="Razer Fintech Company sebagai bagian dari industri
       e-commerce Indonesia"
       halaman="About Us"
       background={HeroBG}
     />
   );
}

export default HeaderAbout
