import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { HeadTab } from './Tabs/TabsFaq.styled'

import { TextDesc, TextTitle } from '../../UI/Text.styled'

const TabPaneBillerGateway = () => {
   return (
      <Tab.Pane eventKey="biller-gateway-desktop">
         <Tab.Container id="left-tabs-example" defaultActiveKey="umum">
            <HeadTab>
               <ul>
                  <li>
                     <Nav.Link eventKey="umum">
                        <TextTitle size="16px">umum</TextTitle>
                     </Nav.Link>
                  </li>
                  <li>
                     <Nav.Link eventKey="status-transaksi">
                        <TextTitle size="16px">status transaksi</TextTitle>
                     </Nav.Link>
                  </li>
                  <li>
                     <Nav.Link eventKey="registrasi">
                        <TextTitle size="16px">registrasi</TextTitle>
                     </Nav.Link>
                  </li>
                  <li>
                     <Nav.Link eventKey="proses-pembayaran">
                        <TextTitle size="16px">proses pembayaran</TextTitle>
                     </Nav.Link>
                  </li>
               </ul>
            </HeadTab>
            <Tab.Content style={{ maxWidth: '628px' }}>
               {/* Umum */}

               <Tab.Pane eventKey="umum">
                  <TextTitle size="22px" color="#27328f" fw="bold">
                     Umum
                  </TextTitle>
                  <div className="mt-3">
                     <TextDesc fw="600">
                        Transaksi apa saja yang bisa dilakukan di E2Pay
                     </TextDesc>
                     <TextDesc className="mt-2">
                        Untuk Biller merchant bisa melakukan pembelian atau
                        pengisian pulsa dan paket data semua operator, token
                        listrik prabayar, pembayaran listrik pascabayar, BPJS,
                        cicilan multifinance, tagihan Telkom/Indihome, PDAM,
                        hingga pembayaran tagihan ponsel pascabayar.
                     </TextDesc>
                  </div>
                  <div className="mt-3">
                     <TextDesc fw="600">
                        Berapa harga pulsa dan paket data yang dijual di E2Pay
                     </TextDesc>
                     <TextDesc className="mt-2">
                        Harga pulsa dan paket data yang tersedia di E2Pay
                        bervariasi dan berbeda-beda untuk setiap operator,
                        silahkan contact sales E2Pay untuk informasi detail
                     </TextDesc>
                  </div>
               </Tab.Pane>
               {/* Status Transaksi */}

               <Tab.Pane eventKey="status-transaksi">
                  <TextTitle size="22px" color="#27328f" fw="bold">
                     Status Transaksi
                  </TextTitle>
                  <div className="mt-3">
                     <TextDesc fw="600">
                        Mengapa produk yang saya beli belum masuk/diterima
                     </TextDesc>
                     <TextDesc className="mt-2">
                        Pastikan Anda sudah melakukan pembayaran sesuai dengan
                        total transaksi (tidak kurang dan tidak lebih) Untuk
                        produk pulsa, paket internet, hingga token listrik akan
                        segera diterima oleh pelanggan dalam waktu 30 detik
                        setelah melakukan pembayaran. Apabila terjadi kendala,
                        harap menunggu maksimal dalam waktu 1 x 24 jam. Lebih
                        dari waktu tersebut, Anda bisa segera menghubungi
                        support kami.
                     </TextDesc>
                  </div>
               </Tab.Pane>

               {/* Registrasi */}

               <Tab.Pane eventKey="registrasi">
                  <TextTitle size="22px" color="#27328f" fw="bold">
                     Registrasi
                  </TextTitle>
                  <div className="mt-3">
                     <TextDesc fw="600">
                        Siapa sajakah yg bisa menjadi Agent Biller
                     </TextDesc>
                     <TextDesc className="mt-2">
                        Untuk menjadi agent biller atau menggunakan layanan
                        biller kami perlu memiliki legalitas usaha atau berbadan
                        hukum minimal CV.
                     </TextDesc>
                  </div>
               </Tab.Pane>

               {/* Proses Pembayaran */}

               <Tab.Pane eventKey="proses-pembayaran">
                  <TextTitle size="22px" color="#27328f" fw="bold">
                     Proses Pembayaran
                  </TextTitle>
                  <div className="mt-3">
                     <TextDesc fw="600">
                        Bagaimanakah proses pembayaran atau pembelian product
                        Biller di E2Pay
                     </TextDesc>
                     <TextDesc className="mt-2">
                        Setelah menjadi Biller Agent perlu melakukan top up ke
                        E2Pay jumlah atau besar nilai silahkan di sesuaikan
                        dengan kebutuhan merchant, setiap ada transaksi
                        pembelian biller akan langsung memotong dari deposit
                        tersebut.
                     </TextDesc>
                  </div>
               </Tab.Pane>
            </Tab.Content>
         </Tab.Container>
      </Tab.Pane>
   )
}

export default TabPaneBillerGateway
