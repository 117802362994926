import styled from "styled-components";

export const Card = styled.div`
  position: ${(props) => props.position || "relative"};
  width: ${(props) => props.width || "320px"};
  height: ${(props) => props.height || "320px"};
  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
  background-color: #fff;
  z-index: 2;
  padding: 30px 0 0 33px;
  padding-top: ${(props) => props.paddingTop || "30px"};

  align-self: ${(props) => props.alignself};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};

  /* transition: transform .8s;*/
  margin-right: ${(props) => props.marright};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};


  &:hover {
    width: 320px;
    height: 220px;
  /* transition: transform .8s; */

    /* background-color: blue; */
    /* transform: scale(1.2) */
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0 auto;
    position: relative;
    top: ${(props) => props.topsm};
    right: ${(props) => props.rightsm};
    bottom: ${(props) => props.bottomsm};
    left: ${(props) => props.leftsm};
    &:hover a {
      transform: translateX(20px);
    }
  }

  /* &:hover a {
    transform: translateX(20px);
  } */
`;

export const ContCard = styled.div`
  position: ${(props) => props.position};

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0 auto;
    position: relative;
  }

  & div {
    margin-top: 10px;
  }
`;
