import React from "react";
import { Narasi } from "../../../components/Layout/Privacy/Informasi.styled";
import DetailNewsPortal from "../../../components/Layout/NewsPortal/DetailNewsPortal"
import { TextDesc } from "../../../components/UI/Text.styled";
import { TextTitle } from "../../../components/UI/Text.styled";


const ContentDetailNewsPortal = () => {

  return (
    <>
      <Narasi marginTop="-240px" alignItems="center" paddingTop="25px">
        <DetailNewsPortal/>
      </Narasi>
      

    </>
  );
};

export default ContentDetailNewsPortal;