import React from "react";
import {
  ContainerImage1,
  ContainerImage2,
  ContainerImage3,
  ContainerImage4,
  ContainerImage5,
  ContainerImage6,
  ContainerImage7,
  ContainerImage8,
  Img,
} from "./CarouselPartnerPembayaran.styled";

import banner1 from "../../../assets/images/banner1.png"
import BgCarousel from "../../../assets/images/bg-carousel.png";
import CarouselImages from "../../../assets/images/carouselimage.png";
import CarouselImagesCounter from "../../../assets/images/carouselimagecounter.png"
import CarouselImagesEmoney from "../../../assets/images/carouselimageemoney.png"
import CarouselImagesVA from "../../../assets/images/carouselimageva.png"
import akulaku from "../../../assets/images/payment/akulaku.png";
import alfamart from "../../../assets/images/payment/alfamart.png";
import alfamidi from "../../../assets/images/payment/alfamidi.png";
import alto from "../../../assets/images/payment/alto.png";
import bca from "../../../assets/images/payment/bca.png";
import bersama from "../../../assets/images/payment/bersama.png";
import bni from "../../../assets/images/payment/bni.png";
import bri from "../../../assets/images/payment/bri.png";
import cimb from "../../../assets/images/payment/cimb.png";
import danamon from "../../../assets/images/payment/danamon.png";
import dana from "../../../assets/images/payment/dana.png";
import dandan from "../../../assets/images/payment/dandan.png";
import finpay from "../../../assets/images/payment/finpay.png";
import gopay from "../../../assets/images/payment/gopay.png";
import jcb from "../../../assets/images/payment/jcb.png";
import indodana from "../../../assets/images/payment/indodana.png";
import indomaret from "../../../assets/images/payment/indomaret.png";
import kredivo from "../../../assets/images/payment/kredivo.png";
import lawson from "../../../assets/images/payment/lawson.png";
import linkaja from "../../../assets/images/payment/linkaja.png";
import linkaja2 from "../../../assets/images/payment/linkaja2.png";
import mandiri from "../../../assets/images/payment/mandiri.png";
import mastercard from "../../../assets/images/payment/mastercard.png";
import muamalat from "../../../assets/images/payment/muamalat.png";
import mbayar from "../../../assets/images/payment/mbayar.png";
import nucash from "../../../assets/images/payment/nucash.png";
import octoclicks from "../../../assets/images/payment/octoclicks.png";
import octomobile from "../../../assets/images/payment/octomobile.png";
import ovo from "../../../assets/images/payment/ovo.png";
import prima from "../../../assets/images/payment/prima.png";
import permata from "../../../assets/images/payment/permata.png";
import qris from "../../../assets/images/payment/qris.png";
import shopee from "../../../assets/images/payment/shopee.png";
import visa from "../../../assets/images/payment/visa.png";

const CarouselImage1 = () => {
  return (
    <>
      <ContainerImage1>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImagesEmoney}></img>
        </Img>
        <div className="box1">
          <img width="42px" height="auto" src={linkaja}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="100px" height="auto" src={gopay}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="50px" height="auto" src={ovo}></img>
        </div>
        <div className="box4">
          {" "}
          <img width="90px" height="auto" src={mbayar}></img>
        </div>
        <div className="box5">
          {" "}
          <img width="150px" height="auto" src={shopee}></img>
        </div>
        <div className="box6">
          {" "}
          <img width="115px" height="auto" src={dana}></img>
        </div>
      </ContainerImage1>
    </>
  );
};

const CarouselImage2 = () => {
  return (
    <>
      <ContainerImage2>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImagesVA} width="369px" height="422px" ></img>
        </Img>
        <div className="box1">
          <img width="82px" height="auto" src={bca}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="82px" height="auto" src={bni}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="140px" height="auto" src={cimb}></img>
        </div>
        <div className="box4">
          {" "}
          <img width="90px" height="auto" src={mandiri}></img>
        </div>
        <div className="box5">
          {" "}
          <img width="120px" height="auto" src={bri}></img>
        </div>
        <div className="box6">
          {" "}
          <img width="115px" height="auto" src={permata}></img>
        </div>
      </ContainerImage2>
    </>
  );
};

const CarouselImage3 = () => {
  return (
    <>
      <ContainerImage3>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImagesCounter}></img>
        </Img>
        <div className="box1">
          <img width="120px" height="auto" src={indomaret}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="120px" height="auto" src={alfamart}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="120px" height="auto" src={alfamidi}></img>
        </div>
        <div className="box4">
          {" "}
          <img width="120px" height="auto" src={lawson}></img>
        </div>
        <div className="box5">
          {" "}
          <img width="110px" height="auto" src={dandan}></img>
        </div>
      </ContainerImage3>
    </>
  );
};

const CarouselImage4 = () => {
  return (
    <>
      <ContainerImage4>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImages} width="467px" height="367px"></img>
        </Img>
        <div className="box1">
          <img width="120px" height="auto" src={indodana}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="110px" height="auto" src={kredivo}></img>
        </div>
      </ContainerImage4>
    </>
  );
};

const CarouselImage5 = () => {
  return (
    <>
      <ContainerImage5>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImages} width="467px" height="367px"></img>
        </Img>
        <div className="box1">
          <img width="84px" height="auto" src={visa}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="100px" height="auto" src={mastercard}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="80px" height="auto" src={jcb}></img>
        </div>
      </ContainerImage5>
    </>
  );
};

const CarouselImage6 = () => {
  return (
    <>
      <ContainerImage6>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImages} width="467px" height="367px"></img>
        </Img>
        <div className="box1">
          <img width="110px" height="auto" src={danamon}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="120px" height="auto" src={octoclicks}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="120px" height="auto" src={muamalat}></img>
        </div>
      </ContainerImage6>
    </>
  );
};

const CarouselImage7 = () => {
  return (
    <>
      <ContainerImage7>
        <Img>
          <img className="bg" src={BgCarousel}></img>
          <img className="icon" src={CarouselImages}></img>
        </Img>
        <div className="box1">
          <img width="120px" height="auto" src={linkaja2}></img>
        </div>
        <div className="box2">
          {" "}
          <img width="1auto" height="auto" src={octomobile}></img>
        </div>
        <div className="box3">
          {" "}
          <img width="150px" height="auto" src={shopee}></img>
        </div>
      </ContainerImage7>
    </>
  );
};

const CarouselImage8 = () => {
  return (
    <>
      <ContainerImage8>
        <img src={banner1}></img>
        <h1>Maksimalkan efesiensi bisnis anda dengan solusi pembayaran kami yang super-lengkap!</h1>
      </ContainerImage8>
    </>
  );
};

export {
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
  CarouselImage5,
  CarouselImage7,
  CarouselImage6,
  CarouselImage8,
};
