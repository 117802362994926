import React from "react";
import HubungiKami from "../../../components/Layout/Home/HubungiKami";
import HubSalesForm from "../../../components/Layout/HubungiSales/HubSalesForm";
import { Narasi } from "../../../components/Layout/Privacy/Informasi.styled";
import { TextDesc } from "../../../components/UI/Text.styled";
import { TextTitle } from "../../../components/UI/Text.styled";
import buildingIcon from "../../../assets/images/building-icon.svg";
import phoneIcon from "../../../assets/images/call-icon.svg";
import emailIcon from "../../../assets/images/email-icon.svg";

const ContentHubSales = () => {
  const textHubKami = {
    titles: [
      {
        title: "Butuh informasi segera?",
      },
    ],
    message: "Silahkan hubungi kami di kanal berikut",
  };
  return (
    <>
      <Narasi marginTop="-140px" alignItems="flex-start" paddingTop="25px">
        <TextTitle
          color="#27328f"
          semibold
          size="24px"
          indenttext="25px"
          padding="3% 5% 0"
        >
          PT. E2Pay Global Utama
        </TextTitle>
        <div className="contact">
          <div className="address">
            <img src={buildingIcon} />
            <div className="desc">
              <TextTitle color="#272d3b" semibold size="18px">
                Graha Aktiva 6th Floor
              </TextTitle>
              <TextDesc size="14px" color="#44525b" marginBottom="0">
                JL. H. R. Rasuna Said Kav. 3 RT. 6 / RW. 4, Kuningan Timur.
                Kecamatan Setiabudi, Jakarta Selatan, DKI Jakarta 12950
              </TextDesc>
            </div>
          </div>
          <div className="contacts">
            <div className="phone">
              <div className="customer-service">
                <img src={phoneIcon} />
                <div className="desc">
                  <TextTitle color="#272d3b" semibold size="18px">
                    Customer Service
                  </TextTitle>
                  <TextDesc size="14px" color="#44525b" marginBottom="0">
                   (021) 5203500 ext. 690, 691 (hanya di jam kerja) <br/>
                   (021) 52920138 ext. 300 (Sabtu, Minggu, hari libur, dan di luar jam kerja)

                  </TextDesc>
                </div>
              </div>
              <div className="business-inquiry">
              <img src={phoneIcon} style={{visibility: "hidden"}}/>

              <div className="desc">
                  <TextTitle color="#272d3b" semibold size="18px">
                    Business Inquiry
                  </TextTitle>
                  <TextDesc size="14px" color="#44525b" marginBottom="0">
                  (021) 5203500 ext. 617 (hanya di jam kerja) <br/>
                  (021) 52920138 ext. 300 (Sabtu, Minggu, hari libur, dan di luar jam kerja)
                  </TextDesc>
                </div>
              </div>
            </div>
            <div className="email">
              <div className="email-customer-service">
                <img src={emailIcon} />
                <div className="desc">
                  <TextTitle color="#272d3b" semibold size="18px">
                   Email Customer Service
                  </TextTitle>
                  <TextDesc textDecoration="underline" size="14px" color="#2d62ed" marginBottom="0">
                    support@e2pay.co.id
                  </TextDesc>
                </div>
              </div>
              <div className="email-business-inquiry">
              <img src={emailIcon} style={{visibility: "hidden"}}/>

              <div className="desc">
                  <TextTitle color="#272d3b" semibold size="18px">
                    Email Business inquiry
                  </TextTitle>
                  <TextDesc  textDecoration="underline" size="14px" color="#2d62ed" marginBottom="0">
                 sales@e2pay.co.id
                  </TextDesc>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Narasi>
      {/* <HubungiKami textHubKami={textHubKami} /> */}
      <HubSalesForm />
    </>
  );
};

export default ContentHubSales;
