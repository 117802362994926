import React from 'react'
import ContentHubSales from './HubungiSales/Content/ContentHubSales'
import HeaderHubungiSales from './HubungiSales/Header/HeaderHubungiSales'
import { Helmet } from 'react-helmet'

const HubungiSales = () => {
   return (
      <>
      <Helmet>
        <title>E2Pay - Hubungi Kami</title>
        <meta name="description" content="E2pay Hubungi Kami Page" />
      </Helmet>
         <HeaderHubungiSales />
         <ContentHubSales />
      </>
   )
}

export default HubungiSales
