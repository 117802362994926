import styled from "styled-components";

export const MerchantAktif = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 150px;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 75px;

    img {
      width: 75px;
    }
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 2rem;
  max-width: 100%;
  margin-top: 50px;
  z-index: 3;

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1px;
  background-color: transparent;
  div {
    background-color: transparent;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 130px;
  background-image: url(${(props) => props.bgImg});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  transition: transform .5s;

  &:hover {
    transform: scale(1.2);
  }
`;

export const Bubble3 = styled.div`
  position: absolute;
  z-index: 1;
  top: 200px;
  right: -250px;
  opacity: 0.5;
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
