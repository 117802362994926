import React from 'react'
import HeaderNoHero from '../../../../components/UI/HeaderNoHero'
import HeroBG from '../../../../assets/images/background header/header-panduan.png'
const HeaderVA = () => {
   return (
     <HeaderNoHero
       title="Virtual Account"
       halaman="Bantuan / Panduan Pembayaran / Virtual Account"
       background={HeroBG}
     />
   );
}

export default HeaderVA
