import styled from 'styled-components'

export const ContentCP = styled.div`
   /* min-height: 220px; */
   width: 80%;
   margin: 0 auto;
   /* padding: 0 20px; */

   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;

   .check {
      display: flex;
      flex-direction: row;

      h3 {
         margin-top: 8px;
         margin-right: 5px;
         color: #44d62c;
      }
   }
   .cp-title {
      width: 340px;
      height: 141px;
   }

   .cp-content-container {
      width: 320px;
      max-height: 220px;

      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      .cp-content {
         height: auto;
      }
   }
`
// export const LogoCP = styled.div`
//    width: 10.5rem;
//    height: 10.5rem;
//    background-size: cover;
//    background-image: url(${(props) => props.bgImg});
// `
