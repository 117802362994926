import styled from 'styled-components'

export const ContainerTabs = styled.div`
   width: 100%;
   height: 100%;
   /* background-color: blue; */
   position: relative;

   .testi {
    position: absolute;
    z-index: 10;
    left: 125px;
    top: 125px;
   }
`
export const TestiTabs = styled.div`
   width: 603px;
   height: 607px;
   border-radius: 10px;
   background-color: white;
   position: relative;
   /* border: 1px solid gray; */
   box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
   top:60px;
   left: 305px;
   z-index: 5;
   padding: 82px 95px 82px 123px;
   text-align: left;

   h1 {
    font-size: 30px;
    color: #272d3b !important;
    padding-left: 0 !important;
   }

   p {
    /* color: #272d3b; */
    margin-bottom: 10px;
  color: ${(props) => props.color || "#272d3b"};

  span {
    color: #44d62c;
  }
   }

   img {
    position: absolute;
    bottom: 60px;
   }
`

export const BackgroundTabs = styled.div`
   width: 145px;
   height: 541px;
   border-radius: 10px;
   background-color: #cfd1d4;
   position: absolute;
   top:90px;
   left: 175px;
   z-index: 3;
`