import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { LinkCustom } from "../../UI/Link.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import {
  JKContainer,
  JKLeft,
  JKLogo,
  JKRight,
  JKWrap,
  AsosiasiLogo
} from "./JaminanKeamanan.styled";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import BI_Logo from "../../../assets/images/BI_Logo.png";
import Qris_Logo from "../../../assets/images/Qris_Logo.png";
import PCI_Logo from "../../../assets/images/PCI_Logo.png";
import Bg_Bubble from "../../../assets/images/BG_Bubble4.svg";
import Fintech_idea_aspi_Logo from "../../../assets/images/Fintech_idea_aspi_Logo.png";
import aspi from "../../../assets/images/about us/aspi.svg";
import idea from "../../../assets/images/about us/idea.svg";
import fintech from "../../../assets/images/about us/fintech.png";
import AOS from "aos";
import "aos/dist/aos.css";

const JaminanKeamanan = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <JKContainer bgImg={Bg_Bubble} bgPosition={"bottom left"}>
      <JKWrap>
        <JKLeft data-aos-duration="1000" data-aos="fade-right">
          <span>
            <TextTitle fw={"700"} size={"2.375rem"} fontsm="25px" poppins bold>
              Jaminan Keamanan
            </TextTitle>
          </span>
          <span className="my-3">
            <TextDesc
              style={{ maxWidth: "536px" }}
              size={"1.125rem"}
              poppins
              regular
            >
              Kami mengerti jaminan keamanan data dan transaksi anda adalah hal
              yang terpenting. Maka dari itu, kami melakukan upaya terbaik untuk
              memberikan keamanan pada setiap transaksi anda, melalui
              persyaratan serta sertifikasi lengkap yang telah kami miliki
            </TextDesc>
          </span>
          <span>
            <LinkCustom
              to="/about-us#certificate-of-membership"
              color="#2d62ed"
              fw="700"
            >
              <TextDesc size="16px" poppins bold>
                Lebih Lanjut
                <span color="#44d62c"> &#x3e;</span>
              </TextDesc>
            </LinkCustom>
          </span>
        </JKLeft>
        <JKRight>
          <Container className="mt-0 pt-0 px-0">
            <Row>
              <Col
                className="d-flex  flex-column align-items-center"
                md={4}
                data-aos="zoom-in-up"
                data-aos-duration="1000"
              >
                <JKLogo bgImg={BI_Logo}></JKLogo>
                <TextDesc
                  poppins
                  regular
                  size="14px"
                  className="d-flex flex-column "
                  height="auto"
                  aligntext="center"
                >
                  <p> Telah terdaftar dan memiliki lisensi PJP level 1 dari Bank Indonesia</p>
                </TextDesc>
              </Col>
              <Col
                className="d-flex  flex-column align-items-center"
                md={4}
                data-aos="zoom-in-down"
                data-aos-duration="1000"
              >
                <JKLogo bgImg={PCI_Logo}></JKLogo>
                <TextDesc
                  poppins
                  regular
                  size="14px"
                  aligntext="center"
                  className="d-flex flex-column "
                  height="auto"
                >
                  <p> Telah tersertifikasi PCI DSS</p>
                  {/* <p>(lebih dari 10jt transaksi/tahun) dan</p>
                   <p>fraud screening system</p> */}
                </TextDesc>
              </Col>

              <Col
                className="d-flex  flex-column align-items-center"
                md={4}
                data-aos="zoom-in-down"
                data-aos-duration="1000"
              >
                <JKLogo bgImg={Qris_Logo}></JKLogo>
                <TextDesc
                  size="14px"
                  className=" "
                  aligntext="center"
                  height="auto"
                >
                  Kemudahan bertransaksi digital <br /> melalui satu standard QR
                  se-Asia Tenggara
                </TextDesc>
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="asosiasi">
               
              <TextTitle
                  color="#27328f"
                  size="24px"
                  fw={"400"}
                  fontsm="24px"
                  poppins
                  bold
                >
                  E2Pay Razer Fintech aktif terlibat dalam berbagai kegiatan
                  yang diselenggarakan oleh idEA & AFTech.
                </TextTitle>
              </div> 
              <Col
                  className="d-flex mt-3 flex-column align-items-center"
                  md={4}
                  data-aos="zoom-in-down"
                  data-aos-duration="1000"
                >
                  <AsosiasiLogo
                    width="272px"
                    height="257px"
                  >
                    <a href="https://www.aspi-indonesia.or.id/files/2022/11/anggota%20biasa%20ASPI%20-%20november%202022.pdf" target="_blank">
                       <img src={aspi}></img>
                    </a>
                   
                  </AsosiasiLogo>
                  {/* <TextDesc
                    poppins
                    regular
                    size="14px"
                    className="d-flex flex-column mt-3"
                    aligntext="center"
                    height="auto"
                  >
                    <p>Telah terdaftar dan menjadi</p>
                    <p>anggota asosiasi</p>
                  </TextDesc> */}
                </Col>
                <Col
                  className="d-flex mt-3 flex-column align-items-center"
                  md={4}
                  data-aos="zoom-in-down"
                  data-aos-duration="1000"
                >
                  <AsosiasiLogo
                    width="272px"
                    height="257px"
                  >
                    <a href="https://www.fintech.id/en/member/detail/382" target="_blank">
                    <img src={fintech} width="155px" height="auto"></img>
                    </a>
                    

                  </AsosiasiLogo>
                  </Col>
                  <Col
                  className="d-flex mt-3 flex-column align-items-center"
                  md={4}
                  data-aos="zoom-in-down"
                  data-aos-duration="1000"
                >
                  <AsosiasiLogo
                    width="272px"
                    height="257px"
                  >
                     <a href="https://idea.or.id/directory-anggota?search=e2pay" target="_blank">
                    <img src={idea} ></img>
                    </a>

                  </AsosiasiLogo>
                </Col>
                <TextDesc
                  poppins
                  regular
                  size="14px"
                  aligntext="left"
                  className="d-flex flex-column "
                  height="auto"
                  marginTop="40px"
                  paddingLeft="35px"
                >
                  <p> Salah satu bentuk peran serta di industri e-commerce, E2Pay Razer Fintech juga tergabung dalam Indonesian E-Commerce Association (idEA) dan Asosiasi FinTech Indonesia (AFTECH).</p>
                  {/* <p>(lebih dari 10jt transaksi/tahun) dan</p>
                   <p>fraud screening system</p> */}
                </TextDesc>
                
            </Row>
          </Container>
        </JKRight>
      </JKWrap>
    </JKContainer>
  );
};

export default JaminanKeamanan;
