import React from 'react'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'
import HeroBG from '../../../assets/images/background header/header-biaya.png'

const HeaderBiaya = () => {
   return <HeaderNoHero background={HeroBG} title="Biaya" halaman="Produk" halaman2="Biaya" desc="Hungungi kami untuk mendapatkan biaya yang kompetitif bagi bisnis anda
   "/>
}

export default HeaderBiaya
