import React from "react";
import ContentDB from "./Disbursement/Content/ContentDB";
import { Helmet } from "react-helmet";

const Disbursement = () => {
  return (
    <div>
      <Helmet>
        <title>E2Pay - Disbursement</title>
        <meta name="description" content="E2pay Disbursement Page" />
      </Helmet>
      <ContentDB />
    </div>
  );
};

export default Disbursement;
