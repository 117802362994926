import React, {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  InnerContainer_2,
  KontakKami,
  SubKontak,
  SubMenu,
  FooterMenu,
  LogoSocmed,
  LogoWrap,
  WrapInnerCont,
  LineFooter,
  ContainerFooter,
  TergabungDalam,
  LogoTergabung,
} from "../UI/Footer.styled";
import { TextTitle, TextDesc } from "../UI/Text.styled";
import LogoE2payRazer from "../../assets/images/Logo-e2pay-razer.png";
import { Logo } from "../UI/Logo.styled";
import { LinkCustom } from "../UI/Link.styled";
import { NavLink } from "react-router-dom";
import LogoFooter from "../../assets/images/e2pay_fiuu.svg"
import LogoIG from "../../assets/images/instagram.svg";
import LogoTwitter from "../../assets/images/twitter.svg";
import LogoMedium from "../../assets/images/LogoMedium.svg";
import LogoTergabungDalam from '../../assets/images/footer-tergabung-dalam.png'
import { useLocation } from "react-router-dom";

const Footer = ({path}) => {
    useEffect(() => {
      AOS.init();
    }, []);

    const {pathname} = useLocation()
    if (pathname === "/emoney/mbayar-apps/download") return null;
    
  return (
    <footer>
      <ContainerFooter>
        <WrapInnerCont>
          <InnerContainer_2
            justifyContent="space-between"
            // data-aos="zoom-in-down"
            // data-aos-duration="2000"
            // data-aos="fade-down"
            // data-aos-duration="3000"
            
          >
            <KontakKami width="400px">
            <SubKontak marginTop="12px">
               <NavLink to="/">
                <Logo src={LogoFooter} />
              </NavLink>
              <p>Part of
                 <a href="https://merchant.razer.com/v3/" target="_blank" >
                 <span> Razer Merchant Service</span>
                </a> </p>


            </SubKontak>
              
             
              <SubKontak>
                <LogoWrap className="mt-3">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/e2pay/"
                  >
                    <LogoSocmed bgImg={LogoIG} />
                  </a>
                  {/* <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/e2pay"
                  >
                    <LogoSocmed bgImg={LogoTwitter} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://medium.com/@E2Pay"
                  >
                    <LogoSocmed bgImg={LogoMedium} />
                  </a> */}
                </LogoWrap>
              </SubKontak>
            </KontakKami>
            <KontakKami width="500px">
              <FooterMenu>
                <SubMenu width="100%">
                  <TextTitle size="16px" poppins="true" bold>
                    PERUSAHAAN
                  </TextTitle>
                  <div>
                    <LinkCustom
                      to="/about-us"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Tentang Kami
                    </LinkCustom>
                  </div>
                  <div>
                    <LinkCustom
                      // to="/privacy"
                      to="/kenapa-e2pay"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Mengapa Kami
                    </LinkCustom>
                  </div>

                  <div>
                    <LinkCustom
                      // to="/privacy"
                      to="/artikel"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Artikel
                    </LinkCustom>
                  </div>
                  {/* <div>
                    <LinkCustom
                      to="/"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Our Happy Clients
                    </LinkCustom>
                  </div> */}
                  {/* <div>
                    <LinkCustom
                      to="/karir"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Karir
                    </LinkCustom>
                  </div> */}
                </SubMenu>
                <SubMenu>
                  <TextTitle size="16px" bold>PRODUK</TextTitle>
                  <div>
                    <LinkCustom
                      to="/payment-gateway"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Terima Pembayaran
                    </LinkCustom>
                  </div>
                  
                  <div>
                    <LinkCustom
                      to="/disbursement"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Transfer Dana
                    </LinkCustom>
                  </div>
                  <div>
                    <LinkCustom
                      to="/biller-gateway"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Tagihan & Voucher
                    </LinkCustom>
                  </div>
                  <div>
                    <LinkCustom
                      to="/emoney/mbayar-app"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Uang Elektronik
                    </LinkCustom>
                  </div>
                </SubMenu>
                <SubMenu>
                  <TextTitle size="16px" bold>BANTUAN</TextTitle>
                  <div>
                    <LinkCustom
                      to="/hubungi-sales"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Hubungi Kami
                    </LinkCustom>
                  </div>
                  <div>
                    <LinkCustom
                      to="/faq"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      FAQ
                    </LinkCustom>
                  </div>
                  <div>
                    <LinkCustom
                      to="/produk/biaya"
                      color="#44d62c"
                      poppins="true"
                      
                    >
                      Biaya
                    </LinkCustom>
                  </div>
                  {/* <div>
                    <LinkCustom
                      to="/documentation"
                      color="#44d62c"
                      poppins="true"
                      
                    >Dokumentasi API
                    </LinkCustom>
                  </div> */}
                </SubMenu>
              </FooterMenu>
            </KontakKami>
          </InnerContainer_2>
          <LineFooter />
          <InnerContainer_2
            justifyContent="space-between"
            // data-aos="fade-up"
            // data-aos-easing="linear"
            // data-aos-duration="1000"
          >
            <TergabungDalam width="100%" justifyContent="space-between" alignSelf="center">
              <TextDesc poppins="true" regular size="14px" className="mt-1">
              © 2023 PT E2Pay Global Utama. All rights reserved.
              </TextDesc>
              {/* <LogoTergabung bgImg={LogoTergabungDalam} /> */}
              <div>
                
              <LinkCustom
                   to="/privacy"
                   color="#44d62c"
                   poppins="true"
              >
                Kebijakan Privasi<span id="dots">&emsp;&emsp; ● &emsp;&emsp;</span> 
              </LinkCustom>
              <LinkCustom
                   to="/terms-condition"
                   color="#44d62c"
                   poppins="true"
              >
                Syarat & Ketentuan
              </LinkCustom>
              
              </div>
            </TergabungDalam>
          </InnerContainer_2>
        </WrapInnerCont>
      </ContainerFooter>
    </footer>
  );
};

export default Footer;

