import React, { useState, useEffect, useRef } from "react";
import { ButtonWrap } from "../../../components/UI/Button/Button.styled";
import ButtonArw from "../../../components/UI/Button/ButtonArw";
import ButtonCstm from "../../../components/UI/Button/ButtonCstm";
import {
  JumbotronDescription,
  JumbotronTitle,
  LaptopImg,
  LeftContent,
  ArcImg,
  WavelineImg,
  HandphoneImg,
  HandcardImg,
  SphereImg,
  RingImg,
} from "../../../components/UI/Jumbotron.styled";
import { TextDesc, TextTitle, SpanTitle } from "../../../components/UI/Text.styled";
import laptopimg from "../../../assets/images/laptopimg.png";
import handcard from "../../../assets/images/hand card@3x.png";
import handphone from "../../../assets/images/hand phone blur@3x.png";
import waveline from "../../../assets/images/waveline.png";
import sphere from "../../../assets/images/sphere.png";
import arc from "../../../assets/images/arc.png";
import ring from "../../../assets/images/ring.png";

const JumbotronContentHome = () => {

  return (
      <LeftContent gap={"2rem"} marginTop={"100px"} alignItems="center">
        <JumbotronTitle alignItems="center">
          <TextTitle
            maxwidth={"700px"}
            size={"80px"}
            fontsm={"35px"}
            poppins
            semibold
            transform={"scale(0.94)"}
            animation={"scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1)"}
          >
            <SpanTitle
              animation={
                "fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              Lebih
            </SpanTitle>
            <SpanTitle
              animation={
                "fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              dari
            </SpanTitle>
            <SpanTitle
              animation={
                "fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              sekedar
            </SpanTitle>
            <SpanTitle
              animation={
                "fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              sistem
            </SpanTitle>
            <br />
            <SpanTitle
              animation={
                "fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              pembayaran
            </SpanTitle>
            <SpanTitle
              animation={
                "fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0)"
              }
            >
              digital
            </SpanTitle>
          </TextTitle>
        </JumbotronTitle>

        <JumbotronDescription>
          <TextDesc
          size={"24px"}
            poppins
            regular
            transform={"scale(1)"}
            animation={
              "fade-in 0.8s 0.9s forwards cubic-bezier(0.95, 0.4, 0.7, 1)"
            }
            filter={"blur(3px);"}
          >
            Kami membantu bisnis Anda berkembang melalui berbagai macam kanal
            pembayaran digital (non-tunai) dan juga mempermudah pengiriman dana
            ke berbagai bank tujuan maupun non bank hingga ke seluruh <br/>Asia
            Tenggara.
          </TextDesc>
        </JumbotronDescription>
        <ButtonWrap>
          {/* <ButtonCstm positionsm={"relative"} /> */}
          {/* <ButtonArw /> */}
        </ButtonWrap>
      </LeftContent>
  );
};

export default JumbotronContentHome;
