import styled from 'styled-components'

export const ContainerVoucher = styled.div`
   max-width: 1000px;
   margin: 0 auto;
   padding: 20px;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   gap: 1rem;
   justify-content: center;
   margin-bottom: 20px;
`