import React from 'react'
import ContentPendaftaranMerchant from './PendaftaranMerchant/Content/ContentPendaftaranMerchant'
import HeaderPendaftaranMerchant from './PendaftaranMerchant/Header/HeaderPendaftaranMerchant'
import { Helmet } from 'react-helmet'

const PendaftaranMerchant = () => {
   return (
      <>
         <Helmet>
            <title>E2Pay - Pendaftaran Merchant</title>
            <meta name="description" content="E2pay Payment Gateway Page" />
         </Helmet>
         <HeaderPendaftaranMerchant />
         <ContentPendaftaranMerchant />
      </>
   )
}

export default PendaftaranMerchant
