import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 100vw;
    overflow-x: hidden;
    perspective: 10px;
    position: relative;
    transform-style: preserve-3d;
    /* z-index: -1; */

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d;
    z-index: -1;
}

.background {
    transform: translateZ(-10px) scale(2);
}

.foreground {
    transform: translateZ(-5px) scale(1.5);
}

.background, .foreground {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}
`