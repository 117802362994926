// import React, { useState, useRef } from "react";
// import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { Container } from "./HubungiSales.styled";
// import Form from "react-bootstrap/Form";
// import { TextDesc } from "../../UI/Text.styled";
// import ModalMessage from "../../Layout/PendaftaranMerchant/ModalMessage";
// import HubungiKami from "../Home/HubungiKami";
// const HubSalesForm = () => {
//   const captchaRef = useRef(null);

//   const [validated, setValidated] = useState(false);
//   const [namaToko, setNamatoko] = useState("");
//   const [namaLengkap, setNamalengkap] = useState("");
//   const [nomorKontak, setNomorkontak] = useState("");
//   const [email, setEmail] = useState("");
//   const [transaksiPerbulan, setTransaksiperbulan] = useState("");
//   const [modalShow, setModalShow] = useState(false);
//   const [error, setError] = useState(true);
//   const [checked, setChecked] = useState(false);
//   const [SuccessMsg, setSuccessMsg] = useState("")
//   const [ErrorMsg, setErrorMsg] = useState("")
//   // const [token, setToken] = useState("")
//   const [valid_token, setValidToken] = useState([]);

//   const handleClose = () => setModalShow(false);
//   const openModal = () => setModalShow(true);

//   const templateID = "template_lpucoks";
//   const serviceID = "service_9o072fo";
//   const publicKey = "Mbcau9PInSpcTscbY";

//   // v3 site key = 6LdFPFUnAAAAAMA5OnJS2i1AFXTdwGbiiT6IQcZg
//   // v3 secret key = 6LdFPFUnAAAAAEcUWBlH84bguGseazN2bVZYKZf2

//   // const formHandler = (e) => {
//   //   e.preventDefault();

//   //   setModalShow(true);

//   //   if (
//   //     namaToko.length === 0 ||
//   //     namaLengkap.length === 0 ||
//   //     email.length === 0 ||
//   //     // nomorKontak.length === 0 ||
//   //     transaksiPerbulan === 0
//   //   ) {
//   //     setError(true);
//   //   } else {
//   //     setError(false);

//   //     emailjs.sendForm(serviceID, templateID, e.target, publicKey);

//   //     setNamatoko("");
//   //     setNamalengkap("");
//   //     setEmail("");
//   //     // setNomorkontak("");
//   //   }
//   // };

//   const validationSchema = Yup.object().shape({
//     namaToko: Yup.string().required("Nama Toko / Website is required"),
//     namaLengkap: Yup.string()
//       .required("Nama Lengkap is required")
//       .min(6, "must be at least 2 characters")
//       .max(20, "must not exceed 100 characters"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//     nomorKontak: Yup.string()
//       .required("Nomor Kontak is required")
//       .min(6, "Nomor Kontak must be at least 6 characters")
//       .max(40, "Nomor Kontak must not exceed 40 characters"),
//     transaksiPerbulan: Yup.string().required("Transaksi Perbulan is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       namaToko: "",
//       namaLengkap: "",
//       email: "",
//       nomorKontak: "",
//       transaksiPerbulan: "",
//       hubungiE2pay: "",
//     },
//     validationSchema,
//     onSubmit: async (values, e) => {
//       // alert(JSON.stringify(values, null, 2));
//       setModalShow(true);
//       console.log(values);
//       setNamatoko(values.namaToko);
//       setNamalengkap(values.namaLengkap);
//       setNomorkontak(values.nomorKontak);
//       setEmail(values.email);
//       setTransaksiperbulan(values.transaksiPerbulan);

//       console.log(formik.values);

//       if (
//         namaToko.length === 0 ||
//         namaLengkap.length === 0 ||
//         email.length === 0 ||
//         nomorKontak.length === 0 ||
//         transaksiPerbulan === 0
//       ) {
//         setError(true);
//       } else {
//         // setError(false);
//         let token = captchaRef.current.getValue();
//         console.log("ini token", token);
//         // let token = "093012hhdnjn1ijnwej1oi2jeeun1ui23uejn1e"
//         if (token) {
//           captchaRef.current.reset()
//           let valid_token = await verifyToken(token);

//           if (valid_token.success) {
//             console.log("verify success")
//             // setError(false);
//             emailjs.send(serviceID, templateID, formik.values, publicKey).then(
//               (result) => {
//                 console.log(result.text);
//               },
//               (error) => {
//                 console.log(error.text);
//               }
//             );

//             formik.resetForm();
//           } else {
//             console.log("verify token gagal")
//             setError(true);
//           }
//         } else {
//           setError(true);
//         }
//       }
//     },
//   });

//   // let headers = new Headers();

//   // headers.append("Content-Type", "application/json");
//   // headers.append("Accept", "application/json");
//   // // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
//   // headers.append("Origin", "*");

//   const verifyToken = async (token) => {
//     try {
//       let response = await axios.post(
//        'http://localhost:4000/',

//         // `http://localhost:4000/verify-token`,
//         {
//           secret: process.env.REACT_APP_SECRET_KEY,
//           token,
//         },
//         // { headers: headers },
//         // { headers: { "Access-Control-Allow-Origin": "*", }},
//         // { withCredentials: true },
//       );
//       return response.data;
//     } catch (error) {
//       console.log("error ", error);
//     }
//   };

//   // const verifyToken = async (token) => {
//   //   let APIResponse = [];

//   //   try {
//   //     let response = await axios.post(
//   //       // 'https://rich-jade-vulture-hose.cyclic.app/verify-token',
//   //       {
//   //         secret: process.env.REACT_APP_SECRET_KEY,
//   //         token,
//   //       }
//   //     );

//   //     APIResponse.push(response['data']);
//   //     return APIResponse;
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   // const verifyToken = async (token) => {
//   //   try {
//   //     let response = await axios.post(
//   //       'https://rich-jade-vulture-hose.cyclic.app/verify-token',
//   //       {
//   //         secret: process.env.REACT_APP_SECRET_KEY,
//   //         token,
//   //       }
//   //     );
//   //     return console.log(response.data);

//   //   } catch (error) {
//   //     console.log("error ", error);
//   //   }
//   // };

//   const onChange = () => {

//   }

//   return (
//     <Container>
//       <form onSubmit={formik.handleSubmit}>
//         <div className="wrapper">
//           <label className="label" htmlFor="namaToko">
//             <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
//               Nama Bisnis (Nama Toko / Website){" "}
//               <span className="text-danger"> *</span>
//             </TextDesc>
//           </label>
//           <div className="form">
//             <input
//               className="input"
//               id="namaToko"
//               name="namaToko"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.namaToko}
//             />
//             <p>
//               {formik.errors.namaToko && formik.touched.namaToko
//                 ? formik.errors.namaToko
//                 : null}
//             </p>
//           </div>
//         </div>

//         <div className="wrapper">
//           <label className="label" htmlFor="namaLengkap">
//             <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
//               Nama Lengkap<span className="text-danger"> *</span>
//             </TextDesc>
//           </label>
//           <div className="form">
//             <input
//               className="input"
//               id="namaLengkap"
//               name="namaLengkap"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.namaLengkap}
//             />
//             <p>
//               {formik.errors.namaLengkap && formik.touched.namaLengkap
//                 ? formik.errors.namaLengkap
//                 : null}
//             </p>
//           </div>
//         </div>

//         <div className="wrapper">
//           <label className="label" htmlFor="nomorKontak">
//             <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
//               Nomor Kontak <span className="text-danger"> *</span>
//             </TextDesc>
//           </label>
//           <div className="form">
//             <input
//               className="input"
//               id="nomorKontak"
//               name="nomorKontak"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.nomorKontak}
//             />
//             <p>
//               {formik.errors.nomorKontak && formik.touched.nomorKontak
//                 ? formik.errors.nomorKontak
//                 : null}
//             </p>
//           </div>
//         </div>

//         <div className="wrapper">
//           <label className="label" htmlFor="email">
//             <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
//               Email<span className="text-danger"> *</span>
//             </TextDesc>
//           </label>
//           <div className="form">
//             <input
//               className="input"
//               id="email"
//               name="email"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.email}
//             />
//             <p>
//               {formik.errors.email && formik.touched.email
//                 ? formik.errors.email
//                 : null}
//             </p>
//           </div>
//         </div>

//         <div className="wrapper">
//           <label className="label" htmlFor="transaksiPerbulan">
//             <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
//               Transaksi Perbulan<span className="text-danger"> *</span>
//             </TextDesc>
//           </label>
//           <div className="form">
//             <input
//               className="input"
//               id="transaksiPerbulan"
//               name="transaksiPerbulan"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.transaksiPerbulan}
//             />
//             <p>
//               {formik.errors.transaksiPerbulan &&
//               formik.touched.transaksiPerbulan
//                 ? formik.errors.transaksiPerbulan
//                 : null}
//             </p>
//           </div>
//         </div>
//         <Form.Check
//           className="check"
//           aria-label="hubungi e2pay"
//           id="hubungi-e2pay"
//           name="hubungiE2pay"
//           value={`ingin E2pay menghubungi saya melalui ${
//             checked ? "telepon" : "email"
//           }`}
//           label="Saya ingin E2Pay menghubungi saya lewat telepon"
//           style={{ textAlign: "left" }}
//           onChange={(e) => setChecked(!checked)}
//         />

//         <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} onChange={onChange} />

//         <button className type="submit">
//           Submit
//         </button>
//       </form>
//       <ModalMessage show={modalShow} onHide={handleClose} iserror={error.toString()} />
//     </Container>
//   );
// };

// export default HubSalesForm;

import React, { useState, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container } from "./HubungiSales.styled";
import Form from "react-bootstrap/Form";
import { TextDesc } from "../../UI/Text.styled";
import ModalMessage from "../../Layout/PendaftaranMerchant/ModalMessage";
import HubungiKami from "../Home/HubungiKami";
const HubSalesForm = () => {
  const captchaRef = useRef(null);

  const [validated, setValidated] = useState(false);
  const [namaToko, setNamatoko] = useState("");
  const [namaLengkap, setNamalengkap] = useState("");
  const [nomorKontak, setNomorkontak] = useState("");
  const [email, setEmail] = useState("");
  const [transaksiPerbulan, setTransaksiperbulan] = useState("");
  const [token, setToken] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleClose = () => setModalShow(false);
  const openModal = () => setModalShow(true);

  const templateID = "template_lpucoks";
  const serviceID = "service_9o072fo";
  const publicKey = "Mbcau9PInSpcTscbY";

  // const formHandler = (e) => {
  //   e.preventDefault();

  //   setModalShow(true);

  //   if (
  //     namaToko.length === 0 ||
  //     namaLengkap.length === 0 ||
  //     email.length === 0 ||
  //     // nomorKontak.length === 0 ||
  //     transaksiPerbulan === 0
  //   ) {
  //     setError(true);
  //   } else {
  //     setError(false);

  //     emailjs.sendForm(serviceID, templateID, e.target, publicKey);

  //     setNamatoko("");
  //     setNamalengkap("");
  //     setEmail("");
  //     // setNomorkontak("");
  //   }
  // };

  const validationSchema = Yup.object().shape({
    namaToko: Yup.string().required("Nama Toko / Website is required"),
    namaLengkap: Yup.string()
      .required("Nama Lengkap is required")
      .min(6, "must be at least 2 characters")
      .max(20, "must not exceed 100 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    nomorKontak: Yup.string()
      .required("Nomor Kontak is required")
      .min(6, "Nomor Kontak must be at least 6 characters")
      .max(40, "Nomor Kontak must not exceed 40 characters"),
    // transaksiPerbulan: Yup.string().required("Transaksi Perbulan is required"),
  });

  const formik = useFormik({
    initialValues: {
      namaToko: "",
      namaLengkap: "",
      email: "",
      nomorKontak: "",
      transaksiPerbulan: "",
      hubungiE2pay: "",
      token: "",
    },
    validationSchema,
    onSubmit: async (values, e) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      setNamatoko(values.namaToko);
      setNamalengkap(values.namaLengkap);
      setNomorkontak(values.nomorKontak);
      setEmail(values.email);
      setTransaksiperbulan(values.transaksiPerbulan);

      // console.log(formik.values);
      let token = captchaRef.current.getValue();
      // console.log("ini tokennnn", token);
      // console.log("panjang token", token.length);
      // let token = "093012hhdnjn1ijnwej1oi2jeeun1ui23uejn1e"
      if (token.length > 1) {
        const verifyToken = async () => {
          try {
            let response = await axios.post(
              `https://rich-jade-vulture-hose.cyclic.app/verify-token`,
              {
                secret: process.env.REACT_APP_SECRET_KEY,
                token,
              },
              { headers: headers },
              // console.log(token)
            );
           
            // return response.data;
          } catch (error) {
            // console.log("error ", error);
          }
        };

        verifyToken(token);
        const params = {
          ...formik.values,
          "g-recaptcha-response": token,
        };

      //  const validated = await verifyToken() 
       if (token.length > 1) {
        setError(true)
        setTimeout(() => {
          setModalShow(true)
        }, 1000);
      ;
      formik.resetForm();

          emailjs.send(serviceID, templateID, params, publicKey).then(
            (result) => {
              // console.log(result.text);
              setError(true);
            },
            (error) => {
              // console.log(error.text);
              setError(false);
            }
          );
        }
        // } else {
        // setError(true);
      } else {
      setModalShow(true);
      // formik.resetForm();

        // console.log("token tidak ada");
        setError(false);
        
      
      }
      // } else {
      //   setError(false);
      // }
    },
  });

  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
  headers.append("Origin", "*");

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <div className="wrapper">
          <label className="label" htmlFor="namaToko">
            <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
              Nama Bisnis (Nama Toko / Website){" "}
              <span className="text-danger"> *</span>
            </TextDesc>
          </label>
          <div className="form">
            <input
              className="input"
              id="namaToko"
              name="namaToko"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.namaToko}
            />
            <p>
              {formik.errors.namaToko && formik.touched.namaToko
                ? formik.errors.namaToko
                : null}
            </p>
          </div>
        </div>

        <div className="wrapper">
          <label className="label" htmlFor="namaLengkap">
            <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
              Nama Lengkap<span className="text-danger"> *</span>
            </TextDesc>
          </label>
          <div className="form">
            <input
              className="input"
              id="namaLengkap"
              name="namaLengkap"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.namaLengkap}
            />
            <p>
              {formik.errors.namaLengkap && formik.touched.namaLengkap
                ? formik.errors.namaLengkap
                : null}
            </p>
          </div>
        </div>

        <div className="wrapper">
          <label className="label" htmlFor="nomorKontak">
            <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
              Nomor Kontak <span className="text-danger"> *</span>
            </TextDesc>
          </label>
          <div className="form">
            <input
              className="input"
              id="nomorKontak"
              name="nomorKontak"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nomorKontak}
            />
            <p>
              {formik.errors.nomorKontak && formik.touched.nomorKontak
                ? formik.errors.nomorKontak
                : null}
            </p>
          </div>
        </div>

        <div className="wrapper">
          <label className="label" htmlFor="email">
            <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
              Email<span className="text-danger"> *</span>
            </TextDesc>
          </label>
          <div className="form">
            <input
              className="input"
              id="email"
              name="email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <p>
              {formik.errors.email && formik.touched.email
                ? formik.errors.email
                : null}
            </p>
          </div>
        </div>

        <div className="wrapper">
          <label className="label" htmlFor="transaksiPerbulan">
            <TextDesc fontsm="12px" aligntext="left" marginBottom="0">
              Transaksi Perbulan
            </TextDesc>
          </label>
          <div className="form">
            <input
              className="input"
              id="transaksiPerbulan"
              name="transaksiPerbulan"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.transaksiPerbulan}
            />
            {/* <p>
              {formik.errors.transaksiPerbulan &&
              formik.touched.transaksiPerbulan
                ? formik.errors.transaksiPerbulan
                : null}
            </p> */}
          </div>
        </div>
        <Form.Check
          className="check"
          aria-label="hubungi e2pay"
          id="hubungi-e2pay"
          name="hubungiE2pay"
          value={`ingin E2pay menghubungi saya melalui ${
            checked ? "telepon" : "email"
          }`}
          label="Saya ingin E2Pay menghubungi saya lewat telepon"
          style={{ textAlign: "left" }}
          onChange={(e) => setChecked(!checked)}
        />

        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />

        <button className type="submit">
          Submit
        </button>
      </form>
      <ModalMessage
        show={modalShow}
        onHide={handleClose}
        iserror={error}
      />
    </Container>
  );
};

export default HubSalesForm;
