import React, { useState, useEffect, useRef } from "react";
import { TextTitle } from "../../UI/Text.styled";
import {
  IconChannel,
  LogoChannel,
  MenuImage,
  MenuList,
  MenuListPG,
  MenuPG,
  WrapImage,
} from "./JangkauPenerimaan.styled";
import "./JangkauPenerimaan.css";
import SpriteLogoChannel from "../../../assets/images/sprite_pg-channel.png";
import SpriteIconPGBlue from "../../../assets/images/Sprite_IconTabs_Blue.svg";
import SpriteIconPGGrey from "../../../assets/images/Sprite_IconTabs_Grey.svg";
import cc from "../../../assets/images/PGIcons/cc.svg";
import ccblue from "../../../assets/images/PGIcons/ccblue.svg";
import ibanking from "../../../assets/images/PGIcons/ibanking.svg";
import ibankingblue from "../../../assets/images/PGIcons/ibankingblue.svg";
import VA from "../../../assets/images/PGIcons/VA.png";
import VAblue from "../../../assets/images/PGIcons/VAblue.png";
import financing from "../../../assets/images/PGIcons/financing.svg";
import financingblue from "../../../assets/images/PGIcons/financingblue.svg";
import counter from "../../../assets/images/PGIcons/counter.svg";
import counterblue from "../../../assets/images/PGIcons/counterblue.svg";
import octoclicks from "../../../assets/images/payment/octoclicks.png";
import muamalat from "../../../assets/images/payment/muamalat.png";
import danamon from "../../../assets/images/payment/danamon.png";
import shopee from "../../../assets/images/payment/shopee.png";
import octomobile from "../../../assets/images/payment/octomobile.png";
import ovo from "../../../assets/images/payment/ovo.png";
import gopay from "../../../assets/images/payment/gopay.svg";
import linkaja from "../../../assets/images/payment/linkaja2.png";
import mbayar from "../../../assets/images/payment/m-bayar.png";
import dana from "../../../assets/images/payment/dana.png";
import visa from "../../../assets/images/payment/visa.png";
import jcb from "../../../assets/images/payment/jcb.png"
import mastercard from "../../../assets/images/payment/mastercard.png";
import bca from "../../../assets/images/payment/bca.png";
import bni from "../../../assets/images/payment/bni.png";
import bri from "../../../assets/images/payment/bri.png";
import cimb from "../../../assets/images/payment/cimb.png";
import mandiri from "../../../assets/images/payment/mandiri.png";
import permata from "../../../assets/images/payment/permata.png";
import indomaret from "../../../assets/images/payment/indomaret.png";
import alfamart from "../../../assets/images/payment/alfamart.png";
import lawson from "../../../assets/images/payment/lawson.png";
import dandan from "../../../assets/images/payment/dandan.png";
import alfamidi from "../../../assets/images/payment/alfamidi.png";
import indodana from "../../../assets/images/payment/indodana.png";
import kredivo from "../../../assets/images/payment/kredivo.png";

const TabsJangkauPenerimaan = () => {
  const [ToggleState, setToggleState] = useState(2);
  const refAnchor = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#jangkau-penerimaan") {
      refAnchor.current.scrollIntoView();
    }
  }

  let activeStyle = {
    backgroundColor: "#edf2fe",
    color: "#2d62ed",
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (i) => {
    return ToggleState === i;
  };

  let displayLogo = "";

  switch (ToggleState) {
    case 2:
      displayLogo = (
        <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          gap: "30px",
        }}>
          {/* <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -608px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -665px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -721px"
                  width="169px"
                  height="70px"
               /> */}
          <img src={octoclicks} width="auto" height="40px"></img> 
          <img src={octomobile} width="auto" height="32px"></img> 
          <img src={danamon} width="auto" height="40px"></img> 
          <img src={shopee} width="auto" height="40px"></img>
          <img src={muamalat} width="auto" height="40px"></img> 
        </div>
      );
      break;
    // case 2:
    //    displayLogo = (
    //       <>
    //          <LogoChannel
    //             bgImg={SpriteLogoChannel}
    //             bgPosition="0 -785px"
    //             width="169px"
    //             height="70px"
    //          />
    //          <LogoChannel
    //             bgImg={SpriteLogoChannel}
    //             bgPosition="0 -837px"
    //             width="169px"
    //             height="70px"
    //          />
    //          <LogoChannel
    //             bgImg={SpriteLogoChannel}
    //             bgPosition="0 -896px"
    //             width="169px"
    //             height="70px"
    //          />
    //       </>
    //    )
    //    break
    case 3:
      displayLogo = (
        <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          gap: "30px",
        }}>
          {/* <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -217px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -274px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -325px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -385px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -445px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1350px"
                  width="169px"
                  height="70px"
               /> */}
          <img src={dana} width="auto" height="48px"></img> 
          <img src={linkaja} width="auto" height="40px"></img> 
          <img src={mbayar} width="auto" height="45px"></img> 
          <img src={ovo} width="auto" height="45px"></img> 
          <img src={gopay} width="auto" height="36px"></img> 
        </div>
      );
      break;
    case 5:
      displayLogo = (
        <>
          {/* <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -951.5px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -785px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -835px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -896px"
                  width="169px"
                  height="70px"
               /> */}
          <img src={visa} width="auto" height="48px"></img> &nbsp;
          <img src={jcb} width="auto" height="48px"></img> &nbsp;
          <img src={mastercard} width="auto" height="55px"></img> &nbsp;
        </>
      );
      break;
    // case 5:
    //    displayLogo = (
    //       <>
    //          <LogoChannel
    //             bgImg={SpriteLogoChannel}
    //             bgPosition="0 -160px"
    //             width="169px"
    //             height="70px"
    //          />
    //          <LogoChannel
    //             bgImg={SpriteLogoChannel}
    //             bgPosition="0 -104px"
    //             width="169px"
    //             height="70px"
    //          />
    //       </>
    //    )
    //    break
    case 6:
      displayLogo = (
        <div
          style={{

            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          {/* <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1235px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1007px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1065px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1295px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1127px"
                  width="169px"
                  height="70px"
               />
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -1185px"
                  width="169px"
                  height="70px"
               /> */}
          <img src={bca} width="auto" height="45px"></img> &nbsp;
          <img src={bni} width="auto" height="40px"></img> &nbsp;
          <img src={cimb} width="auto" height="40px"></img> &nbsp;
          <img src={bri} width="auto" height="45px"></img> &nbsp;
          <img src={mandiri} width="auto" height="45px"></img> &nbsp;
          <img src={permata} width="auto" height="45px"></img> &nbsp;
        </div>
      );
      break;
    case 7:
      displayLogo = (
        
        <>
          {/* <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 -45px"
                  width="169px"
                  height="70px"
               />n
               <LogoChannel
                  bgImg={SpriteLogoChannel}
                  bgPosition="0 10px"
                  width="169px"
                  height="70px"
               /> */}
          <img src={indomaret} width="auto" height="50px"></img> &nbsp;
          <img src={lawson} width="auto" height="35px"></img> &nbsp;
          <img src={alfamidi} width="auto" height="50px"></img> &nbsp;
          <img src={alfamart} width="auto" height="50px"></img> &nbsp;

          <img src={dandan} width="auto" height="40px"></img> &nbsp;
        </>
      );
      break;
    case 8:
      displayLogo = (
        <>
          {/* <LogoChannel
            bgImg={SpriteLogoChannel}
            bgPosition="0 -505px"
            width="169px"
            height="70px"
          />
          <LogoChannel
            bgImg={SpriteLogoChannel}
            marginRight="10px"
            bgPosition="0 -480px"
            width="169px"
            height="70px"
          /> */}
          <img src={indodana} width="auto" height="38px"></img> &nbsp;
          <img src={kredivo} width="auto" height="50px"></img> &nbsp;

          
        </>
      );
      break;
  }

  useEffect(() => scrollToComponent(), []);

  return (
    <>
      <div
        ref={refAnchor}
        style={{ maxWidth: "1080px" }}
        className="mx-auto px-5 px-sm-0"
      >
        <TextTitle aligntext={"center"} fontsm="25px" fw="600">
          Jangkau penerimaan pembayaran melalui 6 metode dengan lebih dari 20
          kanal.
        </TextTitle>
      </div>
      <MenuPG>
        <MenuListPG>
          {/* <MenuList
                  className={getActiveClass(1) && 'menu-active'}
                  onClick={() => toggleTab(1)}
               >
                  <IconChannel
                     bgPosition="0px -40px"

                     bgImage={
                        getActiveClass(1) ? SpriteIconPGBlue : SpriteIconPGGrey
                     }
                  />
                  <span style={{ width: '135px' }}>Internet Banking</span>
               </MenuList> */}
          <MenuList
            className={getActiveClass(2) && "menu-active"}
            onClick={() => toggleTab(2)}
          >
            <IconChannel
              bgPosition="0px -80px"
              bgImage={getActiveClass(2) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span style={{ width: "135px" }}>Internet Banking</span>
          </MenuList>
          <MenuList
            className={getActiveClass(3) && "menu-active"}
            onClick={() => toggleTab(3)}
          >
            <IconChannel
              bgPosition="0px -120px"
              bgImage={getActiveClass(3) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span style={{ width: "135px" }}>E-Wallet</span>
          </MenuList>
          {/* <MenuList
                  className={getActiveClass(4) && 'menu-active'}
                  onClick={() => toggleTab(4)}
               >
                  <IconChannel
                     bgPosition="0px -120px"
                     bgImage={
                        getActiveClass(4) ? SpriteIconPGBlue : SpriteIconPGGrey
                     }
                  />
                  <span style={{ width: '135px' }}>QR - Payment</span>
               </MenuList> */}
          <MenuList
            className={getActiveClass(5) && "menu-active"}
            onClick={() => toggleTab(5)}
          >
            <IconChannel
              // bgPosition="0px -160px"
              bgImage={getActiveClass(5) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span style={{ width: "135px" }}> Credit Card</span>
          </MenuList>
          <MenuList
            className={getActiveClass(6) && "menu-active"}
            onClick={() => toggleTab(6)}
          >
            <IconChannel
              bgPosition="0px -160px"
              bgImage={getActiveClass(6) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span style={{ width: "135px" }}> Virtual Account</span>
          </MenuList>
          <MenuList
            className={getActiveClass(7) && "menu-active"}
            onClick={() => toggleTab(7)}
          >
            <IconChannel
              bgPosition="0px -200px"
              bgImage={getActiveClass(7) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span
              style={{
                width: "157px",
                textIndent: "20px",
                marginLeft: "-20px",
              }}
            >
              Over the Counter
            </span>
          </MenuList>
          <MenuList
            className={getActiveClass(8) && "menu-active"}
            onClick={() => toggleTab(8)}
          >
            <IconChannel
              bgPosition="0px -240px"
              bgImage={getActiveClass(8) ? SpriteIconPGBlue : SpriteIconPGGrey}
            />
            <span style={{ width: "135px" }}> Financing</span>
          </MenuList>
        </MenuListPG>
        <MenuImage id="MenuImage">
          <WrapImage>{displayLogo}</WrapImage>
        </MenuImage>
      </MenuPG>
    </>
  );
};

export default TabsJangkauPenerimaan;
