import React from 'react'
import NavbarBootstrap from '../../components/UI/NavbarBootstrap'
import BackgroundNavbar from '../../assets/images/backgroundNavbar.png'
import { ContainerHeaderPriv, Hero, Narasi } from './HeaderNoHero.styled'
// import HeroBG from '../../assets/images/backgroundHero.png'
import HeroBG from '../../assets/images/background header/header-biaya.png'
import { TextTitle, TextDesc } from '../../components/UI/Text.styled'
import { Container } from 'react-bootstrap'
import BreadCrumbCustom from './BreadCrumbCustom'
import NewNavbar from './NewNavbar'

const HeaderNoHero = (props) => {
   return (
      <>
         {/* <ContainerHeaderPriv bgImg={BackgroundNavbar}> */}
         {/* <NavbarBootstrap backgroundColor="#1C278B" /> */}
         <NewNavbar backgroundColor="#1C278B" />
         {/* </ContainerHeaderPriv> */}
         <Hero bgImg={props.background}>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
               <BreadCrumbCustom
                  halaman={props.halaman}
                  halaman2={props.halaman2}
               />
               <TextTitle
                  size="38px"
                  color="#ffffff"
                  aligntext="center"
                  style={{ maxWidth: '1100px' }}
                  fw={'bold'}
                  fontsm="25px"
                  className="px-2"
               >
                  {props.title}
               </TextTitle>
               <TextDesc
                color="#ffffff"
                aligntext="center"
                size="18px"
               >
                  {props.desc}
               </TextDesc>
            </div>
         </Hero>
      </>
   )
}

export default HeaderNoHero
