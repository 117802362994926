import React from "react";
import KontenB2B from "./Emoney/ContentB2B/KontenB2B";
import HeaderB2B from "./Emoney/HeaderB2B/HeaderB2B";
import { Helmet } from "react-helmet";

const B2B = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>E2Pay - E-Money</title>
        <meta name="description" content="E2pay Ekosistem Page" />
      </Helmet>
      <HeaderB2B />
      <KontenB2B />
    </>
  );
};

export default B2B;
