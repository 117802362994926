import React from 'react'
import ContentAbout from './About/Content/ContentAbout'
import HeaderPrivacyAbout from './Privacy/Header/HeaderPrivacyAbout'
import { Helmet } from 'react-helmet'
import HeaderPanduanPembayaran from './PanduanPembayaran/Header/HeaderPanduanPembayaran'
import ContentPanduanPembayaran from './PanduanPembayaran/Content/ContentPanduanPembayaran'
import Footer from '../components/UI/Footer'

const PanduanPembayaran = () => {
   return (
      <>
         <Helmet>
            <title>E2Pay - Panduan Pembayaran</title>
            <meta name="description" content="Panduan Pembayaran Page" />
         </Helmet>
         <HeaderPanduanPembayaran />
         <ContentPanduanPembayaran />
         {/* <Footer/> */}
      </>
   )
}

export default PanduanPembayaran
