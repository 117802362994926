import styled from 'styled-components'

export const ContainerSolusi = styled.section`
   margin-top: 200px;
   margin-bottom: 200px;
   background-image: url(${(props) => props.bgImg[0]}),
      url(${(props) => props.bgImg[1]}), url(${(props) => props.bgImg[2]});
   background-repeat: no-repeat;
   background-position: left 60%, bottom left, 110% 0%;

   @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      background-image: none;
   }
`

export const SectionText = styled.section`
   max-width: 1088px;
   height: 100%;

   margin: 0 auto;

   position: relative;

   .text {
      position: absolute;

      @media only screen and (max-width: 768px) {
         position: relative;
         text-align: center;
         font-size: 25px;
         margin: 0 auto;
      }
   }
`

export const SectionContent = styled.section`
   max-width: 1088px;
   /* height: 1000px; */
   margin: 0 auto;

   display: flex;
   flex-wrap: wrap;
   justify-content: center;

   gap: 2rem;
`

export const CardWrap = styled.section`
   display: flex;
   flex-direction: column;
   margin-top: 80px;

   gap: 2rem;
`

export const CardContent = styled.section`
   width: 355px;
   height: auto;
   padding: 60px 0 42px 0;
   /* padding: 50px 20px 20px 20px; */

   gap: 1rem;

   border-radius: 15px;
   box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
   background-color: #fff;
`
