import React from "react";
import {
  WrapInfo,
} from "./CreditCard.styled";
import Card from "./Card"
// import InfoTahun from "./InfoTahun";
const CreditCard = () => {
  return (
    <WrapInfo>
      <Card></Card>
    </WrapInfo>
  );
};

export default CreditCard;
