import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";
import {Tables2} from '../../../components/Layout/Biaya/Tables/ContainerTable'
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { Link } from "react-router-dom";
const TabDisbursement = () => {
  return (
    <Tab.Pane eventKey="disbursement-desktop">
      <Tables2/>
    </Tab.Pane>
  );
};

export default TabDisbursement;
