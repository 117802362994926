import React from 'react'
import { ContainerPG } from './JangkauPenerimaan.styled'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import { ContainerVoucher } from './Voucher.styled'
import iconChart2 from '../../../assets/images/web_icon_chart2.svg'
import iconDart from '../../../assets/images/web_icon_dart.svg'
import iconArrow from '../../../assets/images/web_icon_arrow.svg'
import flexi from "../../../assets/images/PGIcons/flexi.svg"
import chart2 from "../../../assets/images/PGIcons/chart2.svg"
import target from "../../../assets/images/PGIcons/target.svg"
const Voucher = () => {
   return (
      <ContainerPG id="voucher">
         <ContainerVoucher>
         <TextTitle size={"32px"} color={'#272d3b'} bold>Top Up Telekomunikasi</TextTitle>
         <TextDesc size={"18px"}>Untuk prabayar, pelanggan dapat membeli voucher elektronik dengan kode PIN di outlet ritel terdekat. Pelanggan dapat mengisi ulang dan menikmati pulsa seluler dengan cara memasukkan kode PIN ke perangkat seluler mereka.</TextDesc>
         </ContainerVoucher>
         <ContainerVoucher>
         <TextTitle size={"32px"} color={'#272d3b'} bold>Pengisian Ulang Permainan dan Hiburan</TextTitle>
         <TextDesc size={"18px"}>Tidak banyak perbedaan, pelanggan dapat membeli voucher elektronik dengan kode PIN di outlet ritel terdekat untuk menikmati konten permainan dan hiburan favorit. Setelah pembelian voucher, masukkan kode PIN voucher elektronik langsung pada situs merchant untuk pembelian atau pengisian ulang konten digital seperti Google Play, online games, game credit, dan software.</TextDesc>
         </ContainerVoucher>
      </ContainerPG>
   )
}

export default Voucher
