import React from 'react'
import {
   JumbotronDescription,
   JumbotronTitle,
   LeftContent,
} from '../../../components/UI/Jumbotron.styled'
import {
   ButtonCustom,
   ButtonWrap,
} from '../../../components/UI/Button/Button.styled'

import { TextTitle, TextDesc } from '../../../components/UI/Text.styled'

import ModelImage from '../../../assets/images/model_pg.png'
import { ModelImg } from '../../../components/Layout/PG/HeroPG.styled'

const JumbotronContentEko = () => {
   return (
      <>
         <LeftContent marginTop={'100px'}>
            <JumbotronTitle>
               <TextTitle fw="600" maxwidth={'628px'} size={'24px'}>
                  Ekosistem yang mendukung pembayaran digital anda, dan
                  melampauinya!
               </TextTitle>
            </JumbotronTitle>
            <JumbotronDescription className='mt-1'>
               <TextDesc>Bangun ekosistem bisnis anda sekarang juga</TextDesc>
            </JumbotronDescription>
            <ButtonWrap>
               <ButtonCustom marright="16px" className='mt-5' bg="#44d62c" color="#1c278b">
                  Hubungi Sales
               </ButtonCustom>
            </ButtonWrap>
         </LeftContent>
         <ModelImg bgImg={ModelImage} />
      </>
   )
}

export default JumbotronContentEko
