import styled from 'styled-components'

export const WrapNarasi = styled.div`
   width: 90%;
   min-height: 328px;
   margin: 50px auto;
   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   /* border: solid; */

   display: flex;
   justify-content: center;
   align-items: center;
`
