import React, { useEffect } from 'react'
import Content from './Home/Content/Content'
import Header from './Home/Header/Header'
import { Helmet } from 'react-helmet'
import styled from "styled-components";

const Container = styled.div`
overflow: hidden;
`;

const Home = () => {
   return (
      <Container>
         <Helmet>
            <meta charSet="utf-8" />
            <title>PT E2Pay Global Utama</title>
            <meta name="description" content="E2pay Home Page" />
         </Helmet>
         <Header />
         <Content />
      </Container>
   )
}

export default Home
