import styled from "styled-components";


export const Container = styled.div`
  // border: solid red;
`;

export const WrapInfo = styled.div`
     width: url(${(props) => props.width || "90%"});

  min-height: 200px;
  margin: 0 auto;
  // border: solid red;
  border-radius: 15px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Info = styled.div`
  width: 345px;
  padding: 43px 0;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const TahunWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;

export const Arrow = styled.div`
  width: 293px;
  height: 70px;
  background-image: linear-gradient(to right, #bbd9fd 24%, #fff);
  position: relative;
`;

export const ImageCompany = styled.figure`
  width: 300px;
  height: 50px;
`;
export const Narasi = styled.div`
  display: flex;
  min-height: 210px;
  flex-direction: column;
`;
