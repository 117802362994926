import styled from 'styled-components'

export const ContainerTB = styled.div`
   max-width: 1088px;
   margin: 0 auto;
   padding: 20px;
   display: flex;
   flex-wrap: wrap;
   gap: 3rem;
   justify-content: center;
`
export const ContainerText = styled.div`
   max-width: 1088px;
   margin: 0 auto;
   padding: 55px;
   display: flex;
   flex-wrap: wrap;
   gap: 3rem;
   justify-content: center;
   text-align: justify;
`


export const ItemTB = styled.div`
   max-width: 460px;

   display: flex;
   flex-direction: column;
   gap: 0.5rem;

   .sub {
      display: flex;
      flex-direction: row;
      gap: 10px
   }
`

export const IconTB = styled.div`
   width: 30px;
   height: 30px;
   /* margin-bottom: 8px; */
   background-position: ${(props) => props.bgPosition};
   background-repeat: no-repeat;
   background-image: url(${(props) => props.bgImg});
   /* background-size: cover; */
   background-size: ${(props) => props.bgSize || 'cover'};

`
