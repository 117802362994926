import React from 'react'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'

const HeaderPendaftaranMerchant = () => {
   return (
      <HeaderNoHero
         title="Merchant Application"
         halaman="Pendaftaran Merchant"
      />
   )
}

export default HeaderPendaftaranMerchant
