import React from "react";
import ContentTnC from "./TnC/Content/ContentTnC";
import HeaderTnCAbout from "./TnC/Header/HeaderTnCAbout";
import { Helmet } from "react-helmet";

const TnC = () => {
  return (
    <>
      <Helmet>
        <title>E2Pay - Terms and Conditions</title>
        <meta name="description" content="E2pay Terms and Conditions Page" />
      </Helmet>
      <HeaderTnCAbout
        title="Syarat & Ketentuan"
        halaman="Syarat & Ketentuan"
      />
      <ContentTnC />
    </>
  );
};

export default TnC;
