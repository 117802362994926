import React from 'react'
import CreditCard from '../../../../components/Layout/PanduanPembayaran/cc/CreditCard'
import { Container } from '../../../../components/Layout/PanduanPembayaran/cc/CreditCard.styled'

const ContentCC = () => {
   return (
      <Container>
         <CreditCard />
         {/* <CertificateContent />
         <NarasiE2payrazer /> */}
      </Container>
   )
}

export default ContentCC
