import styled from 'styled-components'

export const ModelImg = styled.div`
   width: 450px;
   height: 450px;
   z-index: 20;

   position: absolute;
   right: 0px;
   top: 30%;


   @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      background-image: none;
   }
`
