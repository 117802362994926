import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { LinkCustom } from "../../UI/Link.styled";
import { ContainerPP, ContainerTitle, TextContainer, Arrow } from "../../UI/PartnerPembayaran.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import CarouselPartnerPembayaran from "./CarouselPartnerPembayaran"
import { MarqueeHomeLeft, MarqueeHomeRight } from "./MarqueeHome";
import Arc from '../../../assets/images/arcPP.png'

const PartnerPembayaran = () => {
  return (
  

    <ContainerPP>
      <img src={Arc} className='arcbg'></img>
      <ContainerTitle>
        <TextTitle poppins bold size="38px" aligntext="left" fontsm="20px;">
          Partner Pembayaran
        </TextTitle>
        <TextDesc lineHeight="30px">
        Lengkapi Payment Channel kami di website atau layanan anda untuk mempermudah pengguna anda bertransaksi.
        </TextDesc>
        <TextContainer>
          {/* <React.Fragment> */}
          <Link
            to="../payment-gateway#jangkau-penerimaan"
            style={{ textDecoration: "none" }}
          >
            <Arrow>
              <TextDesc poppins bold aligntext="left" fontsm={"14px"}>
              Lihat Semua Partner Pembayaran
                <span color="#44d62c"> &#x3e;</span>
              </TextDesc>
            </Arrow>
          </Link>
          <Link
            to="/produk/biaya"
            style={{ textDecoration: "none" }}
          >
            <Arrow>
              <TextDesc poppins bold aligntext="left" fontsm={"14px"}>
              Lihat Biaya
                <span color="#44d62c"> &#x3e;</span>
              </TextDesc>
            </Arrow>
          </Link>
          {/* </React.Fragment> */}
        </TextContainer>
      </ContainerTitle>
      <CarouselPartnerPembayaran />
    </ContainerPP>
  );
};

export default PartnerPembayaran;
