import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin: 50px 0;
  padding: 0;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 50px;
  }

  button {
    width: 200px;
    height: 50px;
    border-radius: 30px;
    background-color: #1c278b;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.31;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: none;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    background-color: #fff;

    .label {
      width: 25%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      @media screen and (min-width: 1600px) {
        width: 18%;
      }
    }
    .form {
      width: 40%;

      p {
        font-size: 14px;
        color: red;
        margin-top: 10px;
        text-indent: 10px;
      }
    }

    .input {
      width: 100%;
      height: 50px;
      border-radius: 15px;
      border: solid 1px #bbd9fd;
      background-color: #fafbfc;
      text-indent: 20px;
    }
  }

  .formCaptcha {

      p {
        font-size: 14px;
        color: red;
        margin-top: 10px;
        text-indent: 10px;
      }
    }

  .check {
    padding-left: 100px;
  }

  /* .captcha {
    padding-left: 150px;
  } */

  /* background-color: red; */
`;
