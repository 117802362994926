import React from 'react'
import { TextDesc } from '../../UI/Text.styled'
import { WrapHead } from './HeadBiaya.styled'
import iconChecklist from '../../../assets/images/checklist.svg'

const HeadBiaya = () => {
   return (
      <WrapHead>
         <div className="container-text">
            <TextDesc size="18px" fw="600">
              ✔ &nbsp;Bebas biaya set-up fee & biaya bulanan
            </TextDesc>
            <span>
               {/* <img src={iconChecklist} /> */}
            </span>
         </div>

         <div className="container-text">
            <TextDesc size="18px" fw="600">
               ✔ &nbsp; Biaya hanya dikenakan untuk transaksi berhasil
            </TextDesc>
            <span>
               {/* <img src={iconChecklist} /> */}
            </span>
         </div>
      </WrapHead>
   )
}

export default HeadBiaya
