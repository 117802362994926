import React from "react";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { ContainerPG } from "./JangkauPenerimaan.styled";
import { ContainerTB, IconTB, ItemTB } from "./TujuanBisnis.styled";
import api from "../../../assets/images/PGIcons/api.svg";
import bank from "../../../assets/images/PGIcons/bank.svg";
import calendar from "../../../assets/images/PGIcons/calendar.svg";
import clock from "../../../assets/images/PGIcons/clock.svg";
import card from "../../../assets/images/PGIcons/card.svg";
import chart from "../../../assets/images/PGIcons/chart.svg";
import coin from "../../../assets/images/PGIcons/coin.svg";
import device from "../../../assets/images/PGIcons/device.svg";
import lock from "../../../assets/images/PGIcons/lock.svg";
import flexi from "../../../assets/images/PGIcons/flexi.svg";
import chart2 from "../../../assets/images/PGIcons/chart2.svg";
import click from "../../../assets/images/PGIcons/click.svg";
import coffee from "../../../assets/images/PGIcons/coffee.svg";
import lock2 from "../../../assets/images/PGIcons/lock2.svg";
import people from "../../../assets/images/PGIcons/people.svg";
import target from "../../../assets/images/PGIcons/target.svg";
import wallet from "../../../assets/images/PGIcons/wallet.svg";

const TujuanBisnis = () => {
  return (
    <ContainerPG>
      <ContainerTB>
        <ItemTB>
          <IconTB>
            <img src={lock}></img>
          </IconTB>
          <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
            Transaksi aman adalah kepercayaan konsumen
          </TextTitle>
          <TextDesc>
            Tidak sekedar memenuhi standar PCI-DSS, E2Pay mengamankan transaksi
            pembayaran dari konsumen dengan otentikasi 3-D Secure, penyaringan
            transaksi, pencegahan fraud, dan beragam bentuk pengamanan lainnya.
          </TextDesc>
        </ItemTB>
        <ItemTB>
          <IconTB>
            <img src={chart}></img>
          </IconTB>
          <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
            Meningkatkan efesiensi bagi operasional bisnis
          </TextTitle>
          <div className="sub">
            <img src={clock} width="20px" height="20px"></img>

            <TextDesc display="flex" flexDirection="row">
              Laporan transaksi tersedia secara real-time Akses kapanpun ke
              laporan transaksi terkini dan statistik pembayaran konsumen
              melalui portal layanan
            </TextDesc>
          </div>

          <div className="sub">
            <img src={calendar} width="20px" height="20px"></img>
            <TextDesc display="flex" flexDirection="column">
              Settlement transaksi cepat secara teratur Hasil settlement
              terkirim otomatis dalam rentang waktu H+5 setelah transaksi
              terjadi. Settlement hingga H+1 dimungkinkan untuk merchant dengan
              volume transaksi besar.
            </TextDesc>
          </div>
        </ItemTB>

        <ItemTB>
          <IconTB>
            <img src={coin}></img>
          </IconTB>
          <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
            Kemudahan integrasi memangkas biaya pengembangan
          </TextTitle>
          <TextDesc>
            Kemudahan dalam mengintegrasikan platform bisnis anda tersedia dalam
            layanan kami.
          </TextDesc>

          <div className="sub">
            <img src={api} width="20px" height="20px"></img>
            <TextDesc display="flex" flexDirection="column">
              <b>Spesifikasi API tunggal untuk semua kanal pembayaran</b>
              Sesuai untuk: Karakteristik platform bisnis yang unik atau
              kompleks
              <br />
            </TextDesc>
          </div>

          <div className="sub">
            <img src={device} width="20px" height="20px"></img>
            <TextDesc display="flex" flexDirection="column">
              <b>
                {" "}
                Perangkat pendukung platform web instan dan aplikasi mobile
              </b>
              Sesuai untuk: Karakteristik platform web instan atau berbasis
              mobile
              <br />
            </TextDesc>
          </div>

          <div className="sub">
            <img src={card} width="20px" height="20px"></img>
            <TextDesc display="flex" flexDirection="column">
              <b>Tombol instan ke semua kanal pembayaran</b>
              Sesuai untuk: Karakteristik pengembangan minimum
            </TextDesc>
          </div>
        </ItemTB>

        <ItemTB>
          <IconTB>
            <img src={flexi}></img>
          </IconTB>
          <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
            Fleksibel untuk beragam jenis usaha
          </TextTitle>
          <TextDesc>
            Model bisnis anda begitu unik hingga membutuhkan spesifikasi khusus?
            Diskusikan dengan kami.
          </TextDesc>
        </ItemTB>
      </ContainerTB>
    </ContainerPG>
  );
};

export default TujuanBisnis;
