import styled from 'styled-components'

export const WrapToggleButton = styled.div`
   .toggle-btn {
      width: 76px;
      line-height: 50px;
      border-radius: 15px;
      background-color: #fff;
      margin-right: 15px;
      color: #93969d;
      border: solid 1px #bbd9fd;
   }
   .btn-check:active + .btn-primary,
   .btn-check:checked + .btn-primary,
   .btn-primary.active,
   .btn-primary:active,
   .show > .btn-primary.dropdown-toggle {
      background-color: #bbd9fd;
      color: #2d62ed;
      border: none;
   }
`
