import React, { useState, useEffect, useRef } from "react";
import FOGS from "vanta/dist/vanta.fog.min";
import {
  Jumbotron,
  JumbotronContainer,
} from "../../../components/UI/Jumbotron.styled";

import BackgroundHeader from "../../../assets/images/background-header.png";
import JumbotronExperience from "../../../components/UI/Jumbotron/JumbotronExperience";
import NavbarBootstrap from "../../../components/UI/NavbarBootstrap";

import JumbotronContentHome from "../Content/JumbotronContentHome";
import NewNavbarHome from "../../../components/UI/NewNavbarHome.js";

const Header = (props) => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOGS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: "#0b1551",
          midtoneColor: "#44d62c",
          lowlightColor: "#35ad22",
          baseColor: "#1c278b",
          blurFactor: 0.74,
          speed: 3.3,
          zoom: 0.9,
        })
      );
    } 
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    }; 
  }, [vantaEffect]);
  return ( 
    <Jumbotron imageurl={BackgroundHeader} ref={myRef}>
      {/* <NavbarBootstrap /> */}
      <NewNavbarHome /> 
      <JumbotronContainer>
        <JumbotronContentHome />
      </JumbotronContainer>
      {/* <JumbotronExperience /> */}
    </Jumbotron>
  );
};

export default Header;
