import React from 'react'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'
import HeroBG from '../../../assets/images/background header/header-whyus.png'
const HeaderAbout = () => {
   return (
     <HeaderNoHero
       title="Apa yang membedakan E2Pay dengan layanan lain yang sejenis?"
       halaman="About / Kenapa E2Pay"
       background={HeroBG}
     />
   );
}

export default HeaderAbout
