import styled from "styled-components";

export const MockupMbayar = styled.div`
  width: 332px;
  height: 646px;

  background-image: url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ContentLeft = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    align-items: center;
  }
`;

export const ContentRight = styled(ContentLeft)`
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    align-items: center;
  }
`;

export const WrapTxtTagline = styled.div`
  text-align: right;
  max-width: 292px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    text-align: center;
  }
`;

export const WrapTxtTaglineRight = styled(WrapTxtTagline)`
  text-align: left;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    text-align: center;
  }
`;

export const LogoTagline = styled.div`
  width: 34.4px;
  height: 34.4px;
  background-image: url(${(props) => props.bgImg});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const BubbleBottom = styled.div`
  width: 400px;
  position: absolute;
  bottom: -1425px;
  left: -100px;
  height: 656px;
  z-index: -1;
  background-image: url(${(props) => props.bgImg});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const PinkSquare = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  width: 24px;
  height: 24px;
  background-color: #fe6ba0;
  opacity: 0.54;
  transform: ${(props) => props.trnsfrm};
`;

export const OrangeSquare = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  width: 24px;
  height: 24px;
  background-image: linear-gradient(to bottom, #fbe34d, #f90);
  opacity: 0.54;
  transform: ${(props) => props.trnsfrm};
`;

export const BlueSquare = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "24px"};
  background-image: linear-gradient(
    to bottom,
    #25dcf1 -1%,
    #1ebfe9 7%,
    #1595df 21%,
    #0d71d6 35%,
    #0756cf 49%,
    #0343ca 64%,
    #0037c7 78%,
    #0034c7 94%
  );
  opacity: 0.54;
  transform: ${(props) => props.trnsfrm};
`;
