import styled from 'styled-components'
import Bubble from '../../assets/images/BG_Bubble.png'

export const ContainerTP = styled.div`
   width: 100%;
   min-height: 500px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   /* Background */
   background-image: url(${Bubble});
   background-repeat: no-repeat;
   background-position: left center;
`
