import styled from "styled-components";

export const DescPG = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 352px;

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
`;

export const HrLine = styled.hr`
  width: 43px;
  height: 4px;
  color: #2d62ed;
  opacity: 1;
  border: 2px solid;
`;

export const ImageBiller = styled.div`
  width: 352px;
  height: 339.2px;

  background-image: url(${(props) => props.bgImg});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const BgEkoBiller = styled.div`
  background: url(${(props) => props.bgImg}) -10% 90% no-repeat;
`;

export const BGDisbursement = styled(BgEkoBiller)`
  background: url(${(props) => props.bgImg}) 110% 10% no-repeat;
`;

export const BGMbayar = styled(BgEkoBiller)`
  background: url(${(props) => props.bgImg}) 0% 130% no-repeat;
`;
