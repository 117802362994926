import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Title1,
  Title2,
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  Row6,
  Row7
} from "./InfoTentangE2Pay.styled";
import {
  Arrow,
  Info,
  Subarrow,
  TahunWrap,
  WrapInfo,
} from "./InfoPerusahaan.styled";
import bubble from "../../../assets/images/about us/bgBubble2.png"
import bgCurve from "../../../assets/images/about us/bgCurve.png"
import bgQuote from "../../../assets/images/about us/bgQuote.svg"
import groupphoto from "../../../assets/images/about us/group-photo.png";
import arrow2012 from "../../../assets/images/about us/arrow2012.png";
import asean from "../../../assets/images/about us/asean.png";
import SEA from "../../../assets/images/about us/SEA.png";
import aspi from "../../../assets/images/about us/aspi.svg";
import idea from "../../../assets/images/about us/idea.svg";
import fintech from "../../../assets/images/about us/fintech.png";
import certificate from "../../../assets/images/about us/certificate.png";
import arrow2022 from "../../../assets/images/about us/arrow2022.png";
// import jumbotron from "../../../assets/images/about us/jumbotron.png";
// import jumbotron2 from "../../../assets/images/about us/jumbotron2.png";
import man from "../../../assets/images/about us/man.png";
import woman2 from "../../../assets/images/about us/woman2.png";
import woman1 from "../../../assets/images/about us/woman1.png";
import bulb from "../../../assets/images/about us/bulb.png";
import bulb2 from "../../../assets/images/about us/bulb2.png";
import megaphone from "../../../assets/images/about us/megaphone.png";
import mug from "../../../assets/images/about us/mug.png";
import thumb from "../../../assets/images/about us/thumb.png";
import medal from "../../../assets/images/about us/medal.png";
import clipboard from "../../../assets/images/about us/cllipboard.png";
import cashless from "../../../assets/images/about us/cashless.png";
import confetti from "../../../assets/images/about us/confetti.png";
import phone from "../../../assets/images/about us/phone.png";
import creditcard from "../../../assets/images/about us/creditcard.png";
import razerfintech from "../../../assets/images/about us/razerfintech.png";
import hand1 from "../../../assets/images/about us/hand1.png";
import hand2 from "../../../assets/images/about us/hand2.png";
import coin from "../../../assets/images/about us/coin.png";
import scan from "../../../assets/images/about us/scan.png";
import InfoTahun from "./InfoTahun";
import WebLogoInfinet from "../../../assets/images/web_logo_infinetworks.png";
import WebLogoE2Pay from "../../../assets/images/about us/e2pay.svg";
import WebLogoRazerfintech from "../../../assets/images/about us/razer.png";
import bgBergabung from "../../../assets/images/about us/bgBergabung.png"

const InfoTentangE2Pay = () => {
  // const images = [jumbotron, jumbotron2]
  const colors = ["#fdb91d", "#00ccf2", "#39d5cf"];
  const text = ["Bangun Ekosistem Bisnis Anda ", "Produk Terbaik & Aman ","Kemudahan & Kenyamanan"]
  // const colors = ["#92c952", "#771796", "#24f355", "#d32776", "#f66b97", "#771796", "#24f355", "#d32776", "#f66b97"];

  const [currentImage, setCurrentImage] = React.useState(null);
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((v) => {
        return v === 2 ? 0 : v + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);


// React.useEffect(() => {
//   AOS.init();
// }, []);
document.addEventListener("mousemove", parallax);
      function parallax(event) {
        this.querySelectorAll(".mouse").forEach((shift) => {
          const position = shift.getAttribute("value")*2;
          const x = (window.innerWidth - event.pageX * position) / 90;
          const y = (window.innerHeight - event.pageY * position) / 90;
  
          shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
      }
  return (
    <WrapInfo width="100%">
      <Container>
      <Title1>
          <p>2012</p>
          <img src={arrow2012}></img>
          <p>2022</p>
          <img src={arrow2022}></img>
        </Title1>
        <Row1>
          <div className="e2pay">
            <img src={WebLogoE2Pay}></img>
            <p>
              PT. E2Pay Global Utama didirikan pada tahun 2012 dengan berbekal relasi, teknologi dan
              reputasi terpercaya di perbankan nasional.
            </p>
          </div>
          <div className="razer">
            <img src={WebLogoRazerfintech}></img>
            <p>
              Razer Fintech telah mengakuisisi PT E2Pay Global Utama untuk
              memperluas jaringan bisnis mereka di pasar Indonesia, sebagai
              salah satu negara dengan pertumbuhan ekonomi digital tercepat di
              Asia Tenggara.
            </p>
          </div>
        </Row1>
      <Row2 bgColor={colors[value]}>
          <h1 style={{color: colors[value] }}>{text[value]}</h1>
          <div 
          // data-aos="zoom-in-down"
          //   data-aos-duration="2000"
          >
            <div className="jumbotron1">
                <img  className="woman1" src={woman1} top="500px"></img>
                <img value="-0.5" className="bulb2 mouse"  src={bulb2}></img>
                <img value="-0.5" className="cashless mouse"  src={cashless}></img>
                <img value="-0.5" className="phone mouse"  src={phone}></img>
            </div>

            <div className="jumbotron2">
                <img className="woman2" src={woman2}></img>
                <img value="-0.5" className="thumb mouse"  src={thumb}></img>
                <img value="-0.5" className="medal mouse"  src={medal}></img>
                <img value="-0.5" className="megaphone mouse"  src={megaphone}></img>
                <img value="-0.5" className="confetti mouse"  src={confetti}></img>
            </div>

            <div className="jumbotron3">
                <img className="man" src={man}></img>
                <img value="-0.5" className="mug mouse"  src={mug}></img>
                <img value="-0.5" className="clipboard mouse"  src={clipboard}></img>
                <img value="-0.5" className="bulb mouse"  src={bulb}></img>
                <img value="-0.5" className="creditcard mouse"  src={creditcard}></img>
            </div>
          {/* <img className="imgbot" src={jumbotron2}></img>
          <img className="imgtop" src={jumbotron}></img> */}

          </div>
        </Row2>
       
        <Row3>
          <div className="wrapper">
            <div className="left">
              <div>
                <p>
                  Lebih dikenal sebagai perusahaan high-performing Software Provider, khususnya untuk gaming, Razer merilis
                  entitas terpisah yaitu Razer Fintech untuk melayani segmen
                  finansial.
                </p>
              </div>
              <img src={razerfintech}></img>
            </div>
            <div className="right">
              <img src={scan}></img>

              <div>
                <h1>
                  Razer Fintech telah mengakuisisi <br />
                  PT E2Pay Global Utama
                </h1>
                <p>
                  Untuk memperluas jaringan bisnis mereka di pasar Indonesia,
                  sebagai salah satu negara dengan pertumbuhan ekonomi digital
                  tercepat di Asia Tenggara.
                </p>
              </div>
            </div>
          </div>

          <div className="transaksi">
            <h1>Lebih dari 1 miliar transaksi telah berhasil diproses</h1>
            <p>
            Razer Fintech, yang merupakan salah satu digital payment network O2O (Offline to Online) terkemuka di Asia Tenggara, telah memproses lebih dari 1 miliar transaksi dengan total nilai pembayaran miliaran dolar.
            </p>
            <img className="hand1" src={hand1}></img>
            <img className="hand2" src={hand2}></img>
            <img className="coin" src={coin}></img>
          </div>
        </Row3>
        {/* <Row4>
          <div className="phototop">
            <img src={groupphoto}></img>
          </div>
          <div className="content">
            <img src={SEA}></img>
            <div className="content-text">
              <p>
                Sinergi antara E2Pay dan Razer Fintech memiliki misi untuk memfasilitasi ekosistem yang mampu memberikan kemudahan-kemudahan, keamanan, kecepatan, efisiensi serta kenyamanan berbagai ragam kebutuhan transaksi keuangan secara elektronik untuk siapapun, dengan platform yang menjangkau hingga ke seluruh Asia Tenggara.
              </p>
            </div>
          </div>
          <div className="photobot">
            <img src={groupphoto}></img>
          </div>
        </Row4> */}
        {/* <Row5>
            <img src={bubble} className="bubble"></img>
            <img className="certificate" src={certificate}></img>
            <div className="content">
              <div className="logo">
                <img src={idea} width="104px" smallwidth="70px" height="51px"></img>
                <img src={fintech} width="164px" height="97px" ></img>
                <img src={aspi} width="158px" height="51px"></img>
              </div>
              <div>
                <h1>E2pay Razer Fintech aktif terlibat dalam berbagai kegiatan yang diselenggarakan oleh idEA & AFTech.</h1>
                <p>Salah satu bentuk peran serta di industri e-commerce, E2pay Razer Fintech juga tergabung dalam Indonesian E-Commerce Association (idEA) dan Asosiasi FinTech Indonesia (AFTECH).</p>
              </div>
            </div>
        </Row5> */}
        <Row6>
          <img className="bgCurve" src={bgCurve}></img>
          <div className="quote">
  <p>E2Pay terus berkomitmen menyediakan solusi pembayaran elektronik yang semakin efektif dan efisien guna memenuhi kebutuhan dari konsumen e-commerce Indonesia yang besar dan memiliki keunikan tersendiri.</p>
  <img src={bgQuote}></img>
          </div>
        </Row6>
        {/* <Row7 bgImg={bgBergabung}>
        <div className="content">
          <h1>Bergabung bersama kami</h1>
          <p>Kami sedang membangun working-culture untuk para pekerja luar biasa (seperti kamu!) untuk dapat melakukan yang terbaik. Jika kamu siap mengembangkan karir dan membantu jutaan bisnis tumbuh lebih baik, kamu ada di tempat yang tepat!</p>
          <a href="/karir"><button>Cek Lowongan Yang Tersedia</button></a>
        </div>
        </Row7> */}
      </Container>
    </WrapInfo>
  );
};

export default InfoTentangE2Pay;
