import React from "react";
import {
  CardContent,
  CardWrap,
  ContainerSolusi,
  SectionContent,
  SectionText,
} from "./Solusi.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";

import IkonKontak from "../../../assets/images/web_icon_kontak.svg";
import IkonFleksible from "../../../assets/images/web_icon_fleksible.svg";
import IkonCart from "../../../assets/images/web_icon_shoppingcart.svg";
import MockupMbayar from "../../../assets/images/mbayar_mockup.png";
import SmallBubble from "../../../assets/images/BG_Bubblesmall.png";
import BigBubble from "../../../assets/images/BG_Bubble3.png";
import IkonLockBlack from "../../../assets/images/web_icon_lock_black.svg";

import ContentSolusi from "./ContentSolusi";
const Solusi = () => {
  return (
    <ContainerSolusi bgImg={[MockupMbayar, SmallBubble, BigBubble]}>
      <SectionText>
        <TextTitle
          className="text"
          size={"32px"}
          color={"#272d3b"}
          fw="600"
          style={{ maxWidth: "418px" }}
        >
          Transaksi jadi lebih cepat, mudah, aman dan praktis dengan menggunakan
          M-Bayar
        </TextTitle>
      </SectionText>
      <SectionContent className="justify-content-md-end justify-content-sm-center">
        <CardWrap className="align-self-end">
          <ContentSolusi
            title="Tanda Identitas Akun Adalah Nomor Seluler Nasabah"
            desc="Uang elektronik M-Bayar mengedepankan kenyamanan 
                    kepada Nasabah dalam mengingat tanda identitas akun 
                    miliknya berupa nomor telepon seluler. Kenyamanan ini pun 
                    ditambah dengan kemudahan untuk memperbarui tanda 
                    identitas akun ketika Nasabah yang bersangkutan mengganti 
                    nomor selulernya."
            ikon={IkonKontak}
          />
          <ContentSolusi
            title="Fleksibilitas Akses Dari Berbagai Platform"
            desc="Nasabah dimudahkan dengan aksesibilitas yang fleksibel terhadap akun miliknya baik melalui aplikasi smartphone, Android dan iOS."
            ikon={IkonFleksible}
          />
        </CardWrap>
        <CardWrap className="align-self-center">
          <ContentSolusi
            title="Keamanan Akun Yang Ketat Namun Bersahabat"
            desc="Uang elektronik M-Bayar mengedepankan kenyamanan 
                    kepada Nasabah dalam mengingat tanda identitas akun 
                    miliknya berupa nomor telepon seluler. Kenyamanan ini pun 
                    ditambah dengan kemudahan untuk memperbarui tanda 
                    identitas akun ketika Nasabah yang bersangkutan mengganti 
                    nomor selulernya."
            ikon={IkonLockBlack}
          />
          <ContentSolusi
            title="Integrasi dengan produk
                    E-Commerce dan Biller"
            desc="Nikmati kemudahan untuk melakukan pembayaran produk e-commerce dan produk biller baik prabayar maupun pascabayar. 
                  M-Bayar berkomitmen untuk terus meluaskan 
                  kerjasama dengan lebih banyak mitra merchant dan agensi biller guna semakin memenuhi kebutuhan Nasabah.
"
            ikon={IkonCart}
          />
        </CardWrap>
      </SectionContent>
    </ContainerSolusi>
  );
};

export default Solusi;
