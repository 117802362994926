import React from "react";
import ContentBG from "./BG/Content/ContentBG";
import { Helmet } from "react-helmet";

const BG = () => {
  return (
    <div>
      <Helmet>
        <title>E2Pay - Biller Gateway</title>
        <meta name="description" content="E2pay Biller Gateway Page" />
      </Helmet>
      <ContentBG />
    </div>
  );
};

export default BG;
