import styled from 'styled-components'

export const CardSkema = styled.div`
padding: 30px 0px 0px 0px;
   max-width: 352px;
   height: 400px;
   border-radius: 15px;
   box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
   background-color: #fff;
   margin-top: 20px;
`

export const ContentSkema = styled.div`
   display: flex;
   flex-direction: column;
   /* align-items: center; */

   height: 100%;

   position: relative;

   max-width: 278px;
   margin: 0 auto;
   padding: 20px 0;

   gap: 1rem;
`

export const BtnSkema = styled.button`
   width: 250px;
   min-height: 38px;
   border-radius: 19px;
   background-image: linear-gradient(89deg, #717cf8 0%, #4149f5 97%);
   font-size: 14px;
   text-transform: uppercase;
   color: #ffffff;
   border: none;
`
export const BtnSkemaCP = styled.button`
   width: 100%;
   max-width: 352px;
   height: 60px;
   line-height: 60px;
   padding: 0 20px;
   background-color: #44d62c;
   border-radius: 30px;
   border: none;
   color: #ffffff;
   font-size: 16px;
   font-weight: bold;
   &:hover {
      background-color: green;
   }
`

export const HrDashed = styled.hr`
   border-top: 1px dashed #cfd1d4;
`

export const FooterSkema = styled.div`
   position: absolute;
   bottom: 20px;
   width: 100%;
`
