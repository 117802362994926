import React from 'react'
import InfoTentangE2Pay from '../../../../components/Layout/About/InfoTentangE2Pay'
import { Container } from '../../../../components/Layout/About/About.styled'
import CertificateContent from '../../../../components/Layout/About/CertificateContent'
import NarasiE2payrazer from '../../../../components/Layout/About/NarasiE2payrazer'

const ContentAbout = () => {
   return (
      <Container>
         <InfoTentangE2Pay />
         {/* <CertificateContent />
         <NarasiE2payrazer /> */}
      </Container>
   )
}

export default ContentAbout
