import React from 'react'
import {
    Container,
    Title1,
    Title2,
    Row1,
    Row2,
    Row3,
    Row4,
    Row5,
    AboutCard1,
    AboutCard2
} from "./Card.styled"

import bgBubble from '../../../assets/images/about us/bgBubble.svg'
import bg1 from '../../../assets/images/about us/bg1.svg'
import bg2 from '../../../assets/images/about us/bg2.svg'
import mobilexdk from "../../../assets/images/about us/mobilexdk.svg"
import vt from "../../../assets/images/about us/vt.png"
import secure from "../../../assets/images/about us/secure.svg"
import gateway from "../../../assets/images/about us/gateway-solution.svg"
import disbursement from "../../../assets/images/about us/disbursement.svg"
import integrasi from "../../../assets/images/about us/integrasi.svg"
import escrow from "../../../assets/images/about us/escrow.svg"
import pembayaran from "../../../assets/images/about us/pembayaran-berulang.svg"
import skema from "../../../assets/images/about us/skema.svg"
import siap from "../../../assets/images/about us/siap.svg"
import hemat from "../../../assets/images/about us/hemat.svg"
import aman from "../../../assets/images/about us/aman.svg"
import metode from "../../../assets/images/about us/metode.svg"
import praktis from "../../../assets/images/about us/praktis.svg"
import pengembangan from "../../../assets/images/about us/pengembangan.svg"
import mudah from "../../../assets/images/about us/mudah.svg"

const Card = () => {
  return (
    <Container>
      <img src={bgBubble} className="bubble"></img>
      <img src={bg1} className="bg1"></img>
      <img src={bg2} className="bg2"></img>
      <Title1>
        <p>Beragam Inovasi Fitur Yang Memudahkan</p>
      </Title1>
      <Row1>
        <AboutCard1>
          <img src={mobilexdk} width="27px" height="40px"></img>
          <h1>Mobile XDK</h1>
          <p>
            Instrumen pembayaran lintas platform untuk pengalaman mobile
            terbaik; native & hybrid.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={vt} width="56px" height="60px"></img>
          <h1>Terminal Virtual</h1>
          <p>
            Solusi pembayaran seluler untuk pembayaran kartu kredit & kode QR.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={secure} width="29px" height="40px"></img>
          <h1>Secure 1-Click</h1>
          <p>Website dan aplikasi seluler yang dilengkapi dengan token.</p>
        </AboutCard1>
      </Row1>
      <Row2>
        <AboutCard1>
        <img src={gateway} width="30px" height="40px"></img>
          <h1>Gateway Solution</h1>
          <p>
            Solusi yang menghubungkan bisnis online ke akun merchant lewat
            akusisi bank.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={disbursement} width="40px" height="40px"></img>
          <h1>Disbursement</h1>
          <p>
            Layanan manajemen kas yang memudahkan penjual untuk pencairan dan
            pengembalian uang.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={integrasi} width="34px" height="38px"></img>
          <h1>Integrasi Mudah</h1>
          <p>
            Fitur yang mengarahkan pembeli langsung ke halaman pembayaran tanpa
            redirect.
          </p>
        </AboutCard1>
      </Row2>
      <Row3>
        <AboutCard1>
        <img src={escrow} width="29px" height="35px"></img>
          <h1>Layanan Escrow</h1>
          <p>
            Solusi yang menghubungkan bisnis online ke akun merchant lewat
            akusisi bank.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={mudah} width="38px" height="35px"></img>
          <h1>Pembayaran Berulang</h1>
          <p>
            Fitur penagihan otomatis atau pembayaran berlangganan untuk
            menghindari keterlambatan pembayaran.
          </p>
        </AboutCard1>
        <AboutCard1>
        <img src={skema} width="31px" height="35px"></img>
          <h1>Skema Pembayaran Mudah</h1>
          <p>
            Layanan bagi pelanggan online untuk merubah transaksi kartu kredit
            menjad cicilan.
          </p>
        </AboutCard1>
      </Row3>
      <Title2>
        <p>Ekosistem Yang Praktis</p>
      </Title2>
      <Row4>
        <AboutCard2>
        <img src={siap} width="31px" height="35px"></img>
          <h1>Siap Digunakan</h1>
          <p>
          Hanya dalam beberapa langkah mudah, anda dapat mulai menerima pembayaran.
          </p>
        </AboutCard2>
        <AboutCard2>
        <img src={hemat} width="31px" height="35px"></img>
          <h1>Hemat Biaya</h1>
          <p>
          Biaya seminimal mungkin untuk sub-bisnis dan cabang.
          </p>
        </AboutCard2>
        <AboutCard2>
        <img src={aman} width="31px" height="35px"></img>
          <h1>Aman</h1>
          <p>
          Miliki kontrol sepenuhnya dengan fitur sub-account.
          </p>
        </AboutCard2>
        <AboutCard2>
        <img src={metode} width="31px" height="35px"></img>
          <h1>Metode Pembayaran</h1>
          <p>
          Tawarkan metode pembayaran yang beragam untuk pelanggan baru dan pelanggan setia anda.
          </p>
        </AboutCard2>
      </Row4>
      <Row5>
      <AboutCard2>
       
        </AboutCard2>
        <AboutCard2>
        <img src={praktis} width="31px" height="35px"></img>
          <h1>Praktis</h1>
          <p>
          Ingin pindai e-wallet? Kartu kredit? Atau kirim tautan pembayaran? Semua bisa dilakukan dengan cepat & mudah.
          </p>
        </AboutCard2>
        <AboutCard2>
        <img src={pengembangan} width="31px" height="35px"></img>
          <h1>Pengembangan Bisnis</h1>
          <p>
          Anda dapat mengembangkan bisnis dan memiliki hingga 1,000 sub-account dengan biaya yang minimal.
          </p>
        </AboutCard2>
        <AboutCard2>
        <img src={mudah} width="31px" height="35px"></img>
          <h1>Mudah</h1>
          <p>
          Terima pembayaran kapanpun dan dimanapun hanya dengan membawa ponsel anda.
          </p>
        </AboutCard2>
      </Row5>
    </Container>
  );
}

export default Card