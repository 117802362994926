import React from 'react'
import MarqueeImagePaymentChannel from '../Home/MarqueeImagePaymentChannel'
import Marquee from 'react-fast-marquee'

import Dana from '../../../assets/images/DANA.png'
import OVO from '../../../assets/images/OVO.jpg'
import MBAYAR from '../../../assets/images/MBAYAR.jpg'
import Nucash from '../../../assets/images/Nucash.png'
import REKPON from '../../../assets/images/REKPON.png'
import LINKAJA from '../../../assets/images/LINKAJA.png'
import { ContainerPG } from './JangkauPenerimaan.styled'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import MarqueeImageBiller from './MarqueeImageBiller'
import MarqueeImageBG from '../../../assets/images/marquee-biller.png'
const ContainerMarqueeImageBiller = () => {
   let ArrImage = [Dana, OVO, MBAYAR, Nucash, REKPON, LINKAJA]

   return (
      <ContainerPG>
         <TextTitle
            size={'18px'}
            fw={'bold'}
            style={{ marginBottom: '3rem', textAlign: 'center' }}
         >
            Beragam produk biller yang terus berkembang
         </TextTitle>
         <Marquee pauseOnHover="true" speed="60">
            <MarqueeImageBiller logo={MarqueeImageBG} />
         </Marquee>

         <TextDesc style={{ marginTop: '50px' }} aligntext={'center'}>
            dan masih banyak yang lainnya
         </TextDesc>
      </ContainerPG>
   )
}

export default ContainerMarqueeImageBiller
