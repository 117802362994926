import React from "react";
import { Narasi } from "./Informasi.styled";
import SubInformasi from "./SubInformasi";

const Informasi = () => {
  const sub1 = {
    narasi1: <div><b>E2Pay</b> adalah sebuah badan hukum yang terdaftar dengan nama PT E2Pay Global Utama dan menerbitkan layanan uang elektronik dalam memberikan solusi pembayaran non-tunai kepada masyarakat. E2Pay berdomisili di Graha Aktiva Lt. 6, Jalan HR Rasuna Said Blok X-1 Kav. 3, Jakarta Selatan 12950.</div>,
    narasi2: <div><b>M-Bayar</b> adalah layanan uang elektronik yang diterbitkan oleh E2Pay.</div>,
    narasi3: <div><b>Akun</b> mengacu kepada sebuah wadah virtual di dalam Sistem M-Bayar yang menyimpan uang elektronik Nasabah, dimana setiap Akun diasosiasikan dengan satu Nasabah.</div>,
    narasi4: <div><b>Akun Regular</b> adalah jenis Akun yang memiliki Batas Maksimum Saldo sebesar Rp2,000,000.</div>,
    narasi5: <div><b>Akun Premium</b> adalah jenis Akun yang memiliki Batas Maksimum Saldo sebesar Rp20,000,000, serta memiliki fasilitas lebih lengkap dibandingkan Akun Regular.</div>,
    narasi6: <div><b>Calon Nasabah</b> adalah individu yang akan mendaftarkan diri untuk memperoleh akun.</div>,
    narasi7: <div><b>Nasabah</b> adalah individu yang memiliki Akun di sistem M-Bayar.</div>,
    narasi8: <div><b>Aplikasi M-Bayar</b>adalah perangkat lunak yang digunakan oleh Nasabah untuk mengakses Akun miliknya dalam rangka memanfaatkan fitur layanan M-Bayar.</div>,
    narasi9: <div><b>Sistem M-Bayar</b> adalah perangkat lunak yang berfungsi untuk mengoperasikan layanan M-Bayar, termasuk seluruh Akun dan berbagai fitur yang ada di dalamnya.</div>,
    narasi10: <div><b>M-Bayar ID</b> adalah nomor identitas yang mengidentifikasikan Akun Nasabah pada layanan M-Bayar.</div>,
    narasi11: <div><b>Nomor VA (Virtual Account) Reguler</b> adalah nomor unik pada setiap Akun, sebagai nomor rekening virtual tujuan transfer dana dari rekening bank lain dalam kaitannya untuk melakukan pengisian saldo Akun.</div>,
    narasi12: <div><b>PIN (Personal Identification Number)</b> adalah fitur keamanan Akun berupa kombinasi 6-angka yang dibuat oleh Nasabah pada saat pendaftaran Akun.</div>,
    narasi13: <div><b>OTP (One Time Password)</b> adalah fitur pelengkap keamanan Akun berupa kombinasi acak 6-angka yang dihasilkan oleh M-Bayar dan dikirimkan melalui SMS ke nomor seluler milik Nasabah.</div>,
    narasi14: <div><b>Mitra M-Bayar</b> adalah lembaga maupun entitas komersil yang melakukan kerjasama dengan M-Bayar dan menyediakan fasilitas bagi kebutuhan operasional M-Bayar.</div>,
    narasi15: <div><b>Bank Mitra M-Bayar</b> adalah Mitra M-Bayar yang secara spesifik berupa bank dan/atau lembaga keuangan yang berada di bawah kewewenangan Bank Indonesia/Otoritas Jasa Keuangan dan menyediakan fasilitas bagi kebutuhan operasional M-Bayar.</div>,
    narasi16: <div><b>Merchant Mitra M-Bayar</b> adalah entitas komersil yang menawarkan produk dan jasa kepada konsumen, bekerjasama dengan M-Bayar serta menerima pembayaran dari konsumen menggunakan M-Bayar sebagai alat pembayaran.</div>,
    narasi17: <div><b>Biller Mitra M-Bayar</b> adalah entitas komersil yang menawarkan layanan jalur pembelian produk prabayar dan pembayaran produk pascabayar dari berbagai penyelenggara layanan.</div>,
    narasi18: <div><b>Transaksi</b> adalah seluruh kegiatan finansial yang dilakukan dan berkaitan dengan Akun, dimana kegiatan tersebut memiliki dampak perubahan pada saldo Akun Nasabah terkait.</div>,
    narasi19: <div><b>Batas Maksimum Saldo</b> adalah batas maksimum dari nominal saldo yang diperbolehkan pada setiap Akun sesuai Peraturan Bank Indonesia tentang Penyelenggaraan Layanan Uang Elektronik.</div>,
    narasi20: <div><b>Batas Maksimum Transaksi</b> Bulanan adalah batas maksimum dari keseluruhan Transaksi pengisian saldo ke dalam Akun, dihitung pada bulan yang sedang berjalan, yang diperbolehkan sesuai Peraturan Bank Indonesia tentang Penyelenggaraan Layanan Uang Elektronik.</div>,
    narasi21: <div><b>Biaya Transaksi</b> adalah biaya yang dikenakan oleh M-Bayar maupun oleh Mitra E2Pay kepada Nasabah pemilik Akun terkait sehubungan dengan aktivitas Transaksi yang dilakukan oleh Nasabah menggunakan saldo yang tersedia pada Akun tersebut.</div>,
    narasi22: <div><b>Biaya Transfer Bank</b> disingkat Biaya Transfer adalah Biaya Transaksi yang mungkin timbul pada setiap transaksi transfer dana dari suatu rekening bank ke Akun M-Bayar.</div>,
    narasi23: <div><b>Biaya Transaksi E-Commerce</b> adalah Biaya Transaksi yang mungkin timbul untuk setiap transaksi pembayaran belanja e-commerce.</div>,
    narasi24: <div><b>Biaya Transaksi Biller</b> adalah Biaya Transaksi yang mungkin timbul untuk setiap transaksi pembelian produk prabayar maupun pembayaran produk pascabayar.</div>,
    narasi25: <div><b>Usaha Terlarang</b> adalah segala bentuk kegiatan dan/atau dukungan yang dilakukan sebagai bagian atau seluruh layanan dan/atau bentuk usaha yang termasuk dalam kategori berikut: <br/>  1. Pornografi atau konten dewasa <br/> 2. Senjata api, amunisi, senapan berkekuatan tinggi, senapan angin <br/> 3. Petasan/kembang api atau pembuatan petasan/kembang api <br/> 4. Obat-obatan terlarang <br/> 5. Perjudian <br/> 6. Replikasi dan produk tiruan dari merk ternama <br/> 7. Tindak pencucian uang <br/> 8. Penjualan dengan skema piramida <br/> 9. Sumbangan dan pendanaan terorisme.</div>,
    narasi26: <div><b>Keluhan</b> adalah ungkapan ketikdakpuasan Pengguna baik yang disebabkan oleh adanya potensi kerugian financial/non financial pada Pengguna maupun yang tidak, yang diduga karena kesalahan atau kelalaian E2Pay.</div>,
    narasi27: <div><b>Payment Gateway</b> atau <b>PG</b> adalah produk layanan berbasis pada jaringan internet (online) yang diselenggarakan oleh suatu perusahaan untuk memfasilitasi entitias komersil dalam menerima pembayaran secara elektronik dari konsumen melalui aplikasi miliknya. </div>,
    narasi28: <div><b>E2Pay Payment Gateway</b> atau <b>E2Pay PG</b> adalah produk layanan Payment Gateway yang diselenggarakan oleh E2Pay. E2Pay PG dalam hal ini berperan sebagai penghubung antara Merchant Gateway dan Mitra Lembaga Keuangan dalam hal kegiatan pembayaran oleh konsumen Merchant Gateway.</div>,
    narasi29: <div><b>Merchant Gateway</b> atau <b>Merchant</b> adalah produk layanan Payment Gateway yang diselenggarakan oleh E2Pay. E2Pay PG dalam hal ini berperan sebagai penghubung antara Merchant Gateway dan Mitra Lembaga Keuangan dalam hal kegiatan pembayaran oleh konsumen Merchant Gateway. </div>,
    narasi30: <div><b>Mitra Lembaga Keuangan</b> adalah entitias yang diberikan kewenangan oleh regulator keuangan, baik berbentuk bank maupun non-bank, untuk memberikan layanan jasa keuangan kepada masyarakat yang menjadi konsumennya (nasabah), kemudian bekerja-sama dengan E2Pay PG untuk menyelenggarakan layanan pembayaran berbasis jaringan internet.</div>,
    narasi31: <div><b>Mitra E2Pay</b> adalah baik Merchant Gateway maupun Mitra Lembaga Keuangan yang bekerja-sama dengan layanan E2Pay PG.</div>,
    narasi32: <div><b>Konsumen</b> adalah individu yang memiliki akses kepada maupun keterikatan terhadap jasa layanan keuangan yang diselenggarakan oleh Mitra Lembaga Keuangan, dan melakukan pembelian produk dan/atau menggunakan jasa yang ditawarkan oleh Merchant Gateway.</div>,
    narasi33: <div><b>Metode Pembayaran</b> adalah pilihan bentuk pada pembayaran berbasis teknologi pada jaringan internet yang memiliki perbedaan dalam hal konsep dasar, prinsip kerja maupun pada bentuk izin kewenangan yang diberikan oleh regulator, Terdapat beberapa metode pembayaran yang umumnya dikenal, antara lain: <br/> 1. Metode Internet Banking, yaitu metode pembayaran yang diselenggarakan oleh lembaga perbankan melalui sistem e-banking dan memiliki dukungan fungsi pembayaran untuk e-commerce pada layanannya tersebut. <br/> 2. Metode E-Money, yaitu metode pembayaran menggunakan layanan uang elektronik (e-money) milik Mitra Lembaga Keuangan (Bank dan Non-Bank) yang diberikan izin penyelenggaraan layanan uang elektronik oleh regulator keuangan. <br/> 3. Metode Kartu Kredit, yaitu metode pembayaran menggunakan layanan kartu kredit yang diselenggarakan oleh perbankan yang menerbitkan kartu kredit serta jaringan pemrosesan kartu kredit Visa, MasterCart, dan lainnya. <br/> 4. Metode Virtual Account, yaitu metode pembayaran menggunakan layanan virtual account milik Mitra Lembaga Keuangan Perbankan. <br/> 5. Metode Cash-In Outlet, yaitu metode pembayaran yang disediakan oleh Mitra Lembaga Keuangan yang memiliki jaringan outlet fisik, terhubung secara elektronik ke jaringan internet dan menerima pembayaran tunai dari konsumen melalui outlet tersebut</div>,
    narasi34: <div><b>Kanal Pembayaran</b> adalah pilihan tata cara pembayaran dalam suatu kelompok metode pembayaran yang sama, dan umumnya diselenggarakan oleh Mitra Lembaga Keuangan yang berbeda-beda melalui suatu merek tersendiri.</div>,
    narasi35: <div><b>Akuisisi Merchant</b> adalah kegiatan dalam penyelenggaraan layanan E2Pay PG yang berupaya menarik minat calon Merchant Gateway dan membuat kerjasama pembayaran menggunakan layanan E2Pay PG. </div>,
    narasi36: <div><b>Penyelesaian Transaksi</b> atau <b>Settlement</b> adalah proses penyelesaian transaksi yang dikumpulkan dalam kurun waktu tertentu dan dilakukan secara berulang yang meliputi perhitungan dan perbandingan data transaksi, pencairan dana transaksi kepada Merchant serta pembayaran biaya admin layanan E2Pay PG.</div>,
    narasi37: <div><b>Perselisihan Transaksi</b> atau <b>Dispute</b> adalah perbedaan yang terjadi pada data transaksi antara dua pihak atau lebih, baik pada proses penyelesaian transaksi maupun ketika terjadi permasalahan pada layanan E2Pay PG, dan memerlukan penyelesaian yang disepakati oleh pihak-pihak yang terlibat.</div>,
    narasi38: <div><b>Aplikasi</b> adalah software yang digunakan untuk menyimpan data maupun mengoperasikan layanan E2Pay Wallet untuk melayani nasabah</div>,
  }
  return (
    <div>
      <Narasi>
        <div>
        Transaksi jadi lebih cepat, mudah, aman dan praktis dengan menggunakan Layanan E2Pay, nikmati berbagai fitur yang kami berikan untuk bisnis anda.
        </div>
      </Narasi>
      <br />
      <br />

      <SubInformasi
        title={"I. Definisi"}
        bgCol={"#edf6ff"}
        // narasi1={
        //   "<b>E2Pay<b/> adalah sebuah badan hukum yang terdaftar dengan nama PT E2Pay Global Utama dan menerbitkan layanan uang elektronik dalam memberikan solusi pembayaran non-tunai kepada masyarakat. E2Pay berdomisili di Graha Aktiva Lt. 6, Jalan HR Rasuna Said Blok X-1 Kav. 3, Jakarta Selatan 12950."
        // }
        narasi1={sub1.narasi1}
        narasi2={
          sub1.narasi2
        }
        narasi3={
          sub1.narasi3
        }
        narasi4={
          sub1.narasi4
        }
        narasi5={
         sub1.narasi5
        }
        narasi6={
          sub1.narasi6
        }
        narasi7={
          sub1.narasi7
        }
        narasi8={
          sub1.narasi8
        }
        narasi9={
          sub1.narasi9
        }
        narasi10={
          sub1.narasi10
        }
        narasi11={
          sub1.narasi11
        }
        narasi12={
          sub1.narasi12
        }
        narasi13={
          sub1.narasi13
        }
        narasi14={
          sub1.narasi14
        }
        narasi15={
          sub1.narasi15
        }
        narasi16={
          sub1.narasi16
        }
        narasi17={
          sub1.narasi17
        }
        narasi18={
          sub1.narasi18
        }
        narasi19={
          sub1.narasi19
        }
        narasi20={
          sub1.narasi20
        }
        narasi21={
          sub1.narasi21
        }
        narasi22={
          sub1.narasi22
        }
        narasi23={
          sub1.narasi23
        }
        narasi24={
          sub1.narasi24
        }
        narasi25={
          sub1.narasi25
        }
       narasi26={
        sub1.narasi26
      }
      narasi27={
        sub1.narasi27
      }
      narasi28={
        sub1.narasi28
      }
      narasi29={
        sub1.narasi29
      }
      narasi30={
        sub1.narasi30
      }
      narasi31={
        sub1.narasi31
      }
      narasi32={
        sub1.narasi32
      }
      narasi33={
        sub1.narasi33
      }
      narasi34={
        sub1.narasi34
      }
      narasi35={
        sub1.narasi35
      }
      narasi36={
        sub1.narasi36
      } 
      narasi37={
        sub1.narasi37
      }
      narasi38={
        sub1.narasi38
      }
       
      />

      <SubInformasi
        title={"II. Pendaftaran Akun"}
        narasi1={
          "Setiap individu berhak untuk melakukan pendaftaran Akun dengan memenuhi syarat-syarat berikut: \n 1. Memiliki Nomor Seluler dan Alamat Email yang Aktif. \n 2. Untuk mendaftar Akun Reguler, diharuskan melengkapi formulir pendaftaran di aplikasi M-Bayar dengan mengisi data pribadi seperti Nama, Tanggal Lahir, Jenis Kelamin, Nomor Seluler dan Alamat Email. \n 3. Untuk mendaftar Akun Premium, wajib melakukan verifikasi dengan mengunggah tanda pengenal diri yang sah melalui aplikasi M-Bayar. \n 4. Tidak terlibat dalam sebagian atau seluruh Usaha Terlarang."
        }
      />

      <SubInformasi
        title={"III. Transaksi Akun"}
        bgCol={"#edf6ff"}
        narasi1={
          "Nasabah wajib untuk memastikan data nomor seluler dan alamat email yang tercantum pada Akun miliknya telah berhasil diverifikasi sebelum melakukan permintaan Transaksi."
        }
        narasi2={
          "Nasabah wajib untuk memastikan kelengkapan, kebenaran dan ketepatan isian data Transaksi maupun informasi yang diterima sehubungan Transaksi yang dilakukan olehnya. E2Pay tidak bertanggung jawab terhadap segala dampak apapun yang mungkin timbul akibat kelalaian, ketidakjelasan, dan/atau ketidak-tepatan permintaan Transaksi dari Nasabah."
        }
        narasi3={
          "Nasabah memiliki kesempatan untuk memeriksa kembali dan/atau membatalkan data yang telah diisi sebelum pemasukan dan verifikasi PIN atau OTP."
        }
        narasi4={
          "Setiap Transaksi yang diminta oleh Nasabah untuk dilakukan terhadap Akun miliknya serta disetujui oleh Nasabah yang bersangkutan dengan memasukan PIN merupakan Transaksi yang sah dan tidak dapat dibatalkan."
        }
        narasi5={
          "Sebagai bukti bahwa Transaksi yang diminta oleh Nasabah untuk dilakukan telah berhasil diproses, M-Bayar akan mengirimkan informasi terkait Transaksi tersebut melalui email ke alamat email yang tercantum pada Akun dan melalui menu riwayat transaksi pada Aplikasi M-Bayar."
        }
        narasi6={
          "Nasabah menyetujui bahwa dengan dilaksanakannya Transaksi menggunakan Akun miliknya, maka semua permintaan Nasabah berkaitan dengan Transaksi tersebut akan dijadikan alat bukti yang sah meskipun tidak dibuat dokumen tertulis dan/atau dikeluarkannya dokumen yang ditandatangani."
        }
        narasi7={
          "Bukti atas permintaan Nasabah untuk melakukan Transaksi menggunakan Akun miliknya disimpan di pusat data elektronik M-Bayar atau dalam bentuk penyimpanan informasi dan data lainnya di M-Bayar, baik berupa dokumen tertulis, catatan, hasil cetak komputer dan/atau salinan, diakui sebagai alat bukti yang tidak akan dibantah keabsahan, kebenaran dan keasliannya."
        }
        narasi8={
          "M-Bayar berhak untuk menolak permintaan Nasabah untuk melakukan Transaksi apabila: \n 1. Alamat email yang tercantum pada Akun terkait milik Nasabah yang bersangkutan belum berhasil diverifikasi. \n 2. Transaksi yang telah atau akan dilakukan diduga oleh E2Pay merupakan sebuah penipuan dan/atau aksi kejahatan. "
        }
        narasi9={
          "Nasabah memahami dan menyetujui untuk membayar seluruh Biaya Transaksi yang mungkin timbul atas permintaan Transaksi yang diproses menggunakan Akun miliknya."
        }
        narasi10={
          "E2Pay ataupun Mitra E2Pay berhak untuk tidak mengembalikan Biaya Transaksi yang telah dikenakan atas transaksi menggunakan Akun oleh Nasabah yang berhasil diproses, apabila terjadi permintaan pembatalan transaksi atas permintaan Nasabah yang bersangkutan. Seluruh Biaya Transaksi yang dimaksud diperhitungkan sebagai biaya pemakaian sumber daya sistem yang sudah digunakan untuk memproses transaksi tersebut."
        }
        narasi11={
          "E2Pay berhak sewaktu-waktu melakukan perubahan atas Biaya Transaksi dalam layanan M-Bayar dengan mengirimkan pemberitahuan kepada Nasabah melalui email atau menampilkannya melalui notifikasi layanan."
        }
      />

      <SubInformasi
        title={"IV. Otentikasi Layanan"}
        narasi1={
          "Setelah berhasil melakukan pendaftaran dan aktivasi, Nasabah berhak melakukan akses ke Akun miliknya melalui Aplikasi M-Bayar dengan melakukan otentikasi."
        }
        narasi2={
          "Nasabah memahami bahwa M-Bayar ID merupakan parameter utama yang mengidentifikasikan setiap Akun terkait. Baik Calon Nasabah maupun Nasabah wajib memastikan bahwa nomor seluler yang dicantumkan pada Akun miliknya merupakan nomor seluler miliknya sendiri yang dapat dipertanggungjawabkan dan tidak dipakai oleh orang lain."
        }
        narasi3={
          "Nasabah memahami bahwa PIN dan OTP merupakan parameter pengaman pada layanan M-Bayar yang bersifat rahasia dan kewenangan penggunaannya ada pada Nasabah."
        }
        narasi4={
          "Nasabah wajib untuk melindungi PIN dan OTP dari Akun miliknya dalam penggunaan dan penyimpanannya agar tidak diketahui oleh pihak lain. Penggunaan yang tidak berwenang terhadap parameter pengaman tersebut secara tidak berwenang menjadi tanggung jawab Nasabah."
        }
        narasi5={
          "E2Pay menghimbau seluruh Nasabah untuk tidak menyimpan PIN dari Akun miliknya dalam bentuk catatan cetak maupun digital dengan pertimbangan resiko kebocoran data tersebut kepada pihak lain."
        }
      />

      <SubInformasi
        title={"V. Pengisian Saldo"}
        bgCol={"#edf6ff"}
        narasi1={
          "Nasabah memahami tanggung jawabnya untuk memastikan nomor tujuan transfer sesuai dengan Nomor VA yang tercantum untuk Akun terkait yang diinformasikan melalui Aplikasi M-Bayar. Kesalahan transfer dana dari rekening bank yang disebabkan oleh kesalahan masukan nomor tujuan transfer menjadi tanggung jawab Nasabah sepenuhnya."
        }
        narasi2={
          "E2Pay berhak untuk menolak pengisian saldo ke dalam Akun apabila: \n 1. Pengisian saldo akan menyebabkan Akun terkait melampaui Batas Maksimum Saldo. \n 2. Pengisian saldo akan menyebabkan akun terkait melampaui Batas Maksimum Transaksi Bulanan."
        }
      />

      <SubInformasi
        title={"VI. Pembelian dan Pembayaran"}
        narasi1={
          "M-Bayar berhak untuk tidak memenuhi permintaan Nasabah untuk melakukan Transaksi apabila saldo pada Akun milik Nasabah yang bersangkutan tidak mencukupi."
        }
        narasi2={
          "Nasabah memahami dan menyetujui bahwa E2Pay tidak bertanggung jawab, atau menjamin, bahwa semua informasi mengenai produk e-commerce yang diberikan oleh Merchant Mitra E2Pay adalah akurat, lengkap, benar atau terbaru."
        }
        narasi3={
          "Nasabah memahami dan menyetujui bahwa E2Pay tidak bertanggung jawab pada penyediaan dan pengiriman produk e-commerce yang dibayarkan oleh Nasabah menggunakan Akun. Seluruh penyediaan dan pengiriman tersebut sepenuhnya menjadi tanggung jawab Merchant Mitra E2Pay."
        }
         />

      <SubInformasi
        title={"VII. Pemblokiran"}
        bgCol={"#edf6ff"}
        narasi1={
          "M-Bayar berhak untuk melakukan pemblokiran terhadap suatu Akun apabila: \n 1. Terdapat permintaan dari Nasabah pemilik Akun terkait yang tercatat melalui komunikasi menggunakan email dan/atau telepon ke Customer Service E2Pay. \n 2. E2Pay mengetahui dan memiliki alasan bahwa Nasabah yang bersangkutan terindikasi dalam salah satu atau lebih Usaha Terlarang. \n 3. E2Pay memperoleh informasi dan/atau perintah dari pihak berwenang yang ditunjukkan melalui keterangan tertulis untuk melakukan pemblokiran. "
        }
        narasi2={
          "Nasabah dapat melakukan permintaan pembukaan pemblokiran terhadap Akun miliknya ke Customer Service E2Pay, dengan syarat: \n 1. Nasabah yang bersangkutan dapat membuktikan keabsahan dirinya sebagai pemilik Akun terkait dengan data yang dapat diverifikasi. \n 2. Apabila pemblokiran dilakukan karena Nasabah yang bersangkutan terindikasi di dalam Usaha Terlarang, maka Nasabah yang bersangkutan wajib membawa bukti dan/atau surat keterangan yang diakui keabasahannya oleh pihak berwenang dan menginformasikan bahwa Nasabah tersebut tidak terbukti terlibat dalam Usaha Terlarang yang disangkakan. "
        }
       
      />

      <SubInformasi
        title={"VIII. Penutupan Akun"}
        narasi1={
          "Nasabah berhak sewaktu-waktu melakukan penutupan Akun miliknya melalui Aplikasi M-Bayar apabila sudah tidak memiliki saldo di dalamnya."
        }
        narasi2={
          "Akun yang telah ditutup tidak dapat diaktifkan kembali. Nasabah yang ingin menggunakan kembali layanan M-Bayar setuju untuk mendaftar ulang Akun melalui Aplikasi M-Bayar, sekalipun menggunakan Nomor Selular yang sama dengan sebelumnya."
        }
       
      />

      <SubInformasi
        title={"IX. Penghentian Layanan"}
        bgCol={"#edf6ff"}
        narasi1={
          "E2Pay berhak menghentikan Layanan M-Bayar untuk sementara waktu maupun untuk jangka waktu tertentu yang ditentukan oleh E2Pay untuk keperluan pembaruan Aplikasi M-Bayar dan/atau Sistem M-Bayar, pemeliharaan Aplikasi M-Bayar dan/atau Sistem M-Bayar, penyelesaian transaksi harian maupun bulanan, atau untuk tujuan lain dengan alasan apapun yang dianggap perlu oleh E2Pay."
        }
        narasi2={
          "Segala hak kekayaan intelektual yang melekat pada Aplikasi M-Bayar seperti logo, foto dan/atau gambar, nama, kata, huruf, angka, susunan warna dan kombinasi dari unsur-unsur tersebut merupakan milik E2Pay sepenuhnya. Nasabah dilarang untuk meniru, memperbanyak, atau menggunakannya untuk kepentingan dan dengan cara apapun tanpa persetujuan tertulis sebelumnya dari E2Pay."
        }
       
      />

      <SubInformasi
        title={"X. Rangkuman Daftar Biaya"}
        narasi1={
          "Berikut adalah daftar biaya untuk menggunakan Aplikasi M-Bayar: \n \n Biaya Pendaftaran: Gratis \n \n Biaya Top Up Saldo: Tidak dikenakan biaya oleh M-Bayar, namun bank/outlet Mitra E2Pay yang memfasilitasi top-up dapat mengenakan biaya sesuai dengan kebijakan bank/outlet tersebut \n \n Biaya Transaksi E-Commerce: Gratis \n \n Biaya Transaksi Biller: Gratis \n \n Biaya SMS: Nasabah akan dikenakan biaya SMS oleh operator seluler setiap kali melakukan pendaftaran awal, dan setiap kali menerima SMs yang berisi OTP."
        }
       
      />
        <SubInformasi
        title={"XI. Syarat dan Ketentuan E-KYC M-Bayar"}
        bgCol={"#edf6ff"}
        narasi1={
          "Sehubungan dengan verifikasi identitas untuk tujuan E-KYC, data pribadi Nasabah berupa data demografi dan/atau biometrik akan diperiksa kesesuaiannya, oleh PT Indonesia Digital Identity (VIDA) sebagai mitra M-Bayar, dengan data yang tercatat pada sistem DISDUKCAPIL (instansi pemerintahan yang berhak mengeluarkan identitas tersebut). Apabila data pribadi Nasabah terverifikasi kesesuaiannya, maka VIDA sebagai Penyelenggara Sertifikasi Elektronik tersertifikasi oleh Kementerian Telekomunikasi dan Informasi, akan menerbitkan sertifikat elektronik sebagai bukti bahwa data pribadi Nasabah telah diverifikasi dan sesuai dengan data yang tercatat pada sistem instansi yang berhak mengeluarkan identitas tersebut."
        }
        narasi2={
          "Dengan menggunakan Aplikasi M-Bayar, Nasabah tunduk pada seluruh ketentuan dan peraturan yang berlaku yang berkaitan dengan kegiatan E-KYC."
        }
        narasi3={
          "Oleh karenanya, Nasabah menjamin keakuratan data pribadi yang Nasabah sediakan dan setuju atas pemrosesan data pribadi Nasabah tersebut untuk tujuan penerbitan sertifikat elektronik serta layanan lain yang melekat pada sertifikat elektronik yang dilakukan oleh VIDA."
        }
        narasi4={
          "Segala ketidaksesuaian yang berkaitan dengan data yang tercatat di DISDUKCAPIL sepenuhnya akan menjadi tanggung jawab antara Nasabah dengan DISDUKCAPIL, baik M-Bayar maupun VIDA tidak memiliki kewenangan melakukan perbaikan data Nasabah yang tercatat di DUKCAPIL."
        }
             
      />

      <SubInformasi
        title={"XII. Lain-Lain"}
        narasi1={
          "E2Pay berhak untuk melakukan perubahan terhadap sebagian atau seluruh syarat dan ketentuan ini setiap saat. Nasabah dihimbau untuk melihat kembali syarat dan ketentuan penggunaan M-Bayar secara berkala."
        }
        narasi2={
          "Dengan menggunakan Aplikasi M-Bayar, Nasabah tunduk dan patuh pada seluruh ketentuan dan peraturan yang berlaku pada M-Bayar termasuk setiap perubahannya."
        }
        narasi3={
          "Apabila timbul perselisihan sehubungan dengan penafsiran dan pelaksanaan dari syarat dan ketentuan ini, E2Pay dan Nasabah sepakat untuk menyelesaikan perselisihan yang dimaksud secara musyawarah untuk mencapai mufakat."
        }
        narasi4={
          "Apabila perselisihan tidak dapat diselesaikan secara musyawarah, E2Pay dan Nasabah dengan ini sepakat untuk menyelesaikan perselisihan yang dimaksud melalui Pengadilan Negeri Jakarta Selatan, dengan tidak mengurangi hak E2Pay untuk mengajukan gugatan atau tuntutan melalui Pengadilan Negeri lainnya dalam wilayah Republik Indonesia."
        }
      />
      <br />
      <br />
    </div>
  );
};

export default Informasi;
