import React  from "react";
import {
  Container,
  Title1,
  Title2,
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  AboutCard1,
  AboutCard2,
} from "./Card.styled";

import bgBubble from "../../../assets/images/about us/bgBubble.svg";
import bg1 from "../../../assets/images/about us/bg1.svg";
import bg2 from "../../../assets/images/about us/bg2.svg";
import cc from "../../../assets/images/Panduan Pembayaran/cc.svg";
import ccblue from "../../../assets/images/Panduan Pembayaran/ccblue.svg";
import counter from "../../../assets/images/Panduan Pembayaran/counter.svg";
import counterblue from "../../../assets/images/Panduan Pembayaran/counterblue.svg";
import financing from "../../../assets/images/Panduan Pembayaran/financing.svg";
import financingblue from "../../../assets/images/Panduan Pembayaran/financingblue.svg";
import va from "../../../assets/images/Panduan Pembayaran/va.png";
import vablue from "../../../assets/images/Panduan Pembayaran/vablue.png";
import ibankingblue from "../../../assets/images/Panduan Pembayaran/ibankingblue.svg";
import ibanking from "../../../assets/images/Panduan Pembayaran/ibanking.svg";
import { Link } from "react-router-dom";

const Card = () => {
  return (
    <Container>
      <Title1>
        <p>Pilih Metode Pembayaran Anda:</p>
      </Title1>
      <Row1>
        <AboutCard1>
          <img src={cc} width="36px" height="auto"></img>
          <img src={ccblue} width="36px" height="auto"></img>&ensp; Credit Card
        </AboutCard1>
        <AboutCard1>
          <img src={cc} width="36px" height="auto"></img>
          <img src={ccblue} width="36px" height="auto"></img>&ensp; E-Money
        </AboutCard1>
        <AboutCard1>
          <img src={counter} width="36px" height="auto"></img>
          <img src={counterblue} width="36px" height="auto"></img>&ensp; Over
          The Counter
        </AboutCard1>
      </Row1>
      <Row2>
        <AboutCard1>
          <img src={ibanking} width="36px" height="auto"></img>
          <img src={ibankingblue} width="36px" height="auto"></img>&ensp;
          i-Banking
        </AboutCard1>
        <Link style={{ textDecoration: "none" }} to="/panduan-pembayaran/virtual-account">
          <AboutCard1>
            <img src={va} width="36px" height="auto"></img>
            <img src={vablue} width="36px" height="auto"></img>&ensp; Virtual
            Account
          </AboutCard1>
        </Link>
        <AboutCard1>
          <img src={financing} width="36px" height="auto"></img>
          <img src={financingblue} width="36px" height="auto"></img>&ensp;
          Financing
        </AboutCard1>
      </Row2>
s    </Container>
  );
};

export default Card;
