import React, { Component } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./carousel.css";
import { Container, ContainerSlider } from "./Banner.styled";
import "./banner.css";
// import { ContainerSlider } from "./CarouselPartnerPembayaran.styled";
import {
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
  CarouselImage5,
  CarouselImage7,
  CarouselImage6,
  CarouselImage8,
} from "./CarouselImage";

export default class CarouselPartnerPembayaran extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 1000,
      dots: true,
      autoplay: true,
      arrows: false,
      //           prevArrow: '<button type="button" class="slick-custom-arrow slick-prev"> < </button>',
      // nextArrow: '<button type="button" class="slick-custom-arrow slick-next"> > </button>'
    };

    return (
      <Container>
        <ContainerSlider>
          <Slider {...settings}>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
            <div>
              <CarouselImage8 title="Credit Card"></CarouselImage8>
            </div>
          </Slider>
        </ContainerSlider>
      </Container>
    );
  }
}
