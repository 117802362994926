import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Accordion from "react-bootstrap/Accordion";

import { TextDesc, TextTitle } from "../../UI/Text.styled";
import TabPanePaymentGateway from "./TabPanePaymentGateway";
import TabPaneBillerGateway from "./TabPaneBillerGateway";
import TabPaneMBayar from "./TabPaneMBayar";
import TabQris from "./TabQris";
import TabDisbursement from "./TabDisbursement";


const TabsContent = (props) => {
  return (
    <>
      {/* START Tab Content Desktop */}
      <Tab.Content className="tabs-desktop">
        <TabPanePaymentGateway />
        <TabDisbursement/>
        <TabPaneBillerGateway />
        <TabPaneMBayar />
        {/* <TabQris /> */}
      </Tab.Content>
      {/* END Tab Content Desktop */}

      {/* START Tab Content Mobile */}

      {/* <Tab.Content className="tabs-mobile"> */}
        {/* {props.activeMobile === 'payment-gateway' && (
               <Accordion className="tabs-mobile">
                  <Accordion.Item eventKey="0">
                     <Accordion.Header>Registrasi</Accordion.Header>
                     <Accordion.Body>
                        <TextTitle
                           fontsm="1rem"
                           size="22px"
                           color="#27328f"
                           fw="bold"
                        >
                           Registrasi
                        </TextTitle>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Siapa saja yang bisa bergabung menjadi merchant ?
                           </TextDesc>
                           <TextDesc fontsm="0.8rem" className="my-3">
                              Semua badan usaha dalam bentuk UD, CV dan PT bisa
                              menjadi merchant
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Dokumen apa saja yang dibutuhkan untuk proses
                              integrasi
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              KTP, NPWP, SIUP, Dokumen{' '}
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Apa saja solusi yang dimiliki E2Pay Razer Fintech
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Satu ekosistem yang terdiri dari Payment Gateway,
                              Biller Gateway, DIsbursment, dan Digital Wallet
                              M-Bayar
                           </TextDesc>
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                     <Accordion.Header>Integrasi</Accordion.Header>
                     <Accordion.Body>
                        <TextTitle
                           fontsm="1rem"
                           size="22px"
                           color="#27328f"
                           fw="bold"
                        >
                           Integrasi
                        </TextTitle>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Bagaimana proses integrasinya
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Kami memiliki beragam plugin, API dan ISO yg dapat
                              di sesuaikan dengan kebutuhan merchant
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Apakah tersedia plugin
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Magento, WooCommerce, Prestashop dan Opencart
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Apakah tersedia Sandbox untuk bisa integrasi
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Kami memiliki environment yg dapat di coba untuk
                              di integrasikan, silahkan untuk menghubungi sales
                              kami untuk diskusi lebih lanjut
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Bagaimana tingkat keamanan transaksi di E2pay
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              E2pay sebagai perusahaan Payment gateway sudah
                              tersertifikasi PCI DSS level 1 dan fraud screening
                              system untuk menjaga transaksi online
                           </TextDesc>
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                     <Accordion.Header>Biaya Layanan</Accordion.Header>
                     <Accordion.Body>
                        <TextTitle
                           fontsm="1rem"
                           size="22px"
                           color="#27328f"
                           fw="bold"
                        >
                           Biaya Layanan
                        </TextTitle>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Channel pembayaran apa saja yg saat ini tersedia
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Kartu kredit, Internet Banking, Mobile Banking,
                              Virtual account, Emoney, Convenience store
                           </TextDesc>
                        </div>

                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Bagaimana proses perhitungannya Fee
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Fee di hitung dari total transaksi sukses dikali
                              dengan fee per transaksi sesuai dengan channel
                              payment yg di gunakan
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Apakah ada biaya set up dan monthly
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Tidak ada biaya set up dan montly hanya per
                              transaksi
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Berapa lama proses pencairan dana
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Untuk proses pencairan dana memakan waktu kurang
                              lebih 3 sampai dengan 7 hari kerja
                           </TextDesc>
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                     <Accordion.Header>Support</Accordion.Header>
                     <Accordion.Body>
                        <TextTitle
                           fontsm="1rem"
                           size="22px"
                           color="#27328f"
                           fw="bold"
                        >
                           Support
                        </TextTitle>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Bagaimana kalau ada kendala di dalam layanan
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              Kami memiliki layanan support 24 jam yg akan
                              membantu merchant
                           </TextDesc>
                        </div>
                        <div>
                           <TextDesc fontsm="0.8rem" fw="600">
                              Contact Us
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              021-5292-0139 ext. 333 ( jam kerja )
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              021-5292-0139 ext. 300 ( selain jam kerja )
                           </TextDesc>
                           <TextDesc fontsm="0.8rem">
                              sales@e2pay.co.id
                           </TextDesc>
                        </div>
                     </Accordion.Body>
                  </Accordion.Item>
               </Accordion>
            )}

            {props.activeMobile === 'biller-gateway' && (
               <div>Biller Gateway</div>
            )}

            {props.activeMobile === 'm-bayar' && <div>Mbayar</div>} */}
        {/* <Tab.Content className="tabs-desktop">
          <TabPanePaymentGateway />

          <TabPaneBillerGateway />
          <TabPaneMBayar />
          <TabQris />
        </Tab.Content> */}
      {/* </Tab.Content> */}
      {/* END Tab Content Desktop */}
    </>
  );
};

export default TabsContent;
