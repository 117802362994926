import React from 'react'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'
import HeroBG from '../../../assets/images/background header/header-faq.png'

const HeaderFaq = () => {
   return (
      <HeaderNoHero
         title="Kami ada untuk membantu Anda"
         halaman="FAQ / Bantuan"
         background={HeroBG}
      />
   )
}

export default HeaderFaq
