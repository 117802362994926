import styled from 'styled-components'

export const NavBC = styled.nav`
   position: absolute;
   left: 0px;
   top: 50px;
`

export const Orderlist = styled.ol`
   color: #fff;
   display: flex;
   list-style: none;
   align-items: center;
   gap: 1rem;
   padding-left: 100px;
`

export const List = styled.li`
   display: list-item;
`
