import React from 'react'
import ContactPerson from '../../../components/Layout/Emoney/ContactPerson'
import Layanan from '../../../components/Layout/Emoney/Layanan'
import ParallaxEmoney from '../../../components/Layout/Emoney/ParallaxEmoney'
import Skemakerjasama from '../../../components/Layout/Emoney/Skemakerjasama'
import Solusi from '../../../components/Layout/Emoney/Solusi'
import OurHappyClients from '../../../components/Layout/Emoney/OurHappyClients'
import TaglineMbayar from '../../../components/Layout/Emoney/TaglineMbayar'
import Terdaftar from '../../../components/Layout/Emoney/Terdaftar'

const KontenEmoney = () => {
   return (
      <div style={{ marginBottom: '80px', overflow: "hidden" }}>
        
         <Skemakerjasama />
     
      </div>
   )
}

export default KontenEmoney
