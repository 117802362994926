import React from 'react'
import HeadBiaya from '../../../components/Layout/Biaya/HeadBiaya'
import TablesBiaya from '../../../components/Layout/Biaya/Tables/ContainerTable'
import Content from '../../../components/Layout/Biaya/Content'
import { Narasi } from '../../../components/Layout/Privacy/Informasi.styled'
import { TextDesc } from '../../../components/UI/Text.styled'
import shield from "../../../assets/images/about us/shield.svg"

const ContentBiaya = () => {
   return (
      <>
      <Narasi aligntext="left" style={{ gap: '1rem' }}>
         <HeadBiaya />
         {/* <TablesBiaya /> */}
      </Narasi>
      <Content/>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <img style={{paddingLeft: "10%", paddingBottom: "50px"}} src={shield}></img>
                <TextDesc marginBottom="40px"  paddingRight="150px" paddingLeft="10px" poppins regular size="14px">
            Waspada upaya penipuan mengatasnamakan PT E2Pay Global Utama. E2Pay bukan perusahaan peer to peer lending dan tidak memiliki produk pinjaman pribadi. Jika anda menemukan indikasi penipuan yang mengatasnamakan E2Pay segera hubungi kami di <b>support@e2pay.co.id</b>
            </TextDesc>
            </div>
           
            

      </>
   )
}

export default ContentBiaya
