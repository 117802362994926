import React from "react";
import ContentEko from "./Ekosistem/Content/ContentEko";
import HeaderEko from "./Ekosistem/Header/HeaderEko";
import { Helmet } from "react-helmet";

const Ekosistem = () => {
  return (
    <>
      <Helmet>
        <title>E2Pay - Ekosistem</title>
        <meta name="description" content="E2pay Ekosistem Page" />
      </Helmet>
      <HeaderEko />
      <ContentEko />
    </>
  );
};

export default Ekosistem;
