import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkCustom = styled(Link)`
   text-decoration: none;
   color: ${(props) => props.color};
   font-weight: ${(props) => props.fw};
   font-family: ${(props) => props.poppins && 'poppins, sans-serif'};
   font-size: ${(props) => props.small || '14px'};

   @media screen and (max-width: 768px) {
   font-size: ${(props) => props.small || '12px'}
   }
`
