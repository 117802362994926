import React from 'react'
import HeroBG from '../../../assets/images/background header/header-privacy.png'
import HeaderNoHero from '../../../components/UI/HeaderNoHero'

const HeaderTnCAbout = (props) => {
   return (
      <>
         <HeaderNoHero
            title="Syarat & Ketentuan"
            halaman="Syarat & Ketentuan"
            background={HeroBG}
         />
      </>
   )
}

export default HeaderTnCAbout
