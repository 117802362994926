import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import {
  ButtonHubKami,
  ContainerHubKami,
  ContentLeft,
  ContentRight,
  InnerContainer,
  WrapIconCall,
  ButtonWrap
} from "./HubungiKami.styled";
import HubKamiBG from "../../../assets/images/hubkami.png"
import IconCall from "../../../assets/images/web_icon_call.png";
import IconMessage from "../../../assets/images/web_icon_message.png";
import Logo_Hubkami from "../../../assets/images/Logo_Hubkami.png";
import shield from "../../../assets/images/about us/shield.svg"
import { Link } from "react-router-dom";

const HubungiKami = (props) => {
   useEffect(() => {
     AOS.init();
   }, []);
  return (
    <ContainerHubKami>
      <InnerContainer
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
<div className="content">
        <ContentLeft>
          <div>
            {props.textHubKami.titles.map((text, idx) => (
              <React.Fragment key={idx}>
                <TextTitle
                  color="#27328f"
                  size="24px"
                  fw={"400"}
                  fontsm="24px"
                  poppins
                  bold
                >
                  {text.title}
                </TextTitle>
              </React.Fragment>
            ))}
          </div>
          <div style={{ width: "90%" }}>
            <TextDesc poppins regular>
              {props.textHubKami.message}
            </TextDesc>
          </div>
          <Link to="../hubungi-sales">
            <ButtonHubKami ><p className="hover-underline-animation">Hubungi Kami</p></ButtonHubKami>
          </Link>
        </ContentLeft>
        <ContentRight ><img src={HubKamiBG}></img></ContentRight>

        </div>
        <div className="shield">
              <img src={shield}></img>
            <TextDesc poppins regular>
            Waspada upaya penipuan mengatasnamakan PT E2Pay Global Utama. E2Pay bukan perusahaan peer to peer lending dan tidak memiliki produk pinjaman pribadi. Jika anda menemukan indikasi penipuan yang mengatasnamakan E2Pay segera hubungi kami di <span>support@e2pay.co.id</span>
            </TextDesc>
            </div>
      </InnerContainer>
            
    </ContainerHubKami>
  );
};

export default HubungiKami;
