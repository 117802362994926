import styled from 'styled-components'

export const JKContainer = styled.div`
  background-image: url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition};
  background-size: auto;
  margin: ${(props) => props.margin};
  perspective: ${(props) => props.perspective};
  transform-style: ${(props) => props.transformStyle};
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  /* padding-left: 100px; */

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: ${(props) => props.fontsm};
    display: flex; 
    flex-direction: column;
    align-items: center;
  }
`;
export const JKWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  /* flex-wrap: wrap; */
  width: 90%;
  margin: 260px auto 0 auto;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: ${(props) => props.fontsm};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  // padding: 0 3rem;
`;

export const JKLeft = styled.div`
   display: flex;
width: 40%;
   flex-direction: column;

   @media screen and (max-width: 768px) {
      width: 80%;
   }
`
export const JKRight = styled.div`
   display: flex;
   justify-content: center;
   align-items: flex-start;
width: 60%;
   .asosiasi {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   }

   gap: 2rem;
`

export const JKRightWrap = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 3rem;
`

export const JKLogo = styled.div`
   width: ${(props) => props.width || '10.5rem'};
   height: ${(props) => props.height || '10.5rem'};
   background-image: url(${(props) => props.bgImg});
   background-size: cover;
   /* background-size: 125px auto ; */
   background-repeat: no-repeat;
   margin-bottom: 20px;

   @media screen and (max-width: 768px) {
      width: ${(props) => props.width || '9rem'};
   height: ${(props) => props.height || '9rem'};  
   }
`
export const AsosiasiLogo = styled.div`
   width: 185px;
   height: 125px;
   border-radius: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
  box-shadow: 0 0 20px 20px #edf6ff;
  transition: transform .5s;


&:hover {
   transform: scale(1.2);
}
`