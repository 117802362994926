import React, {useEffect} from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";
import { Container } from "./TabQris.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { Link } from "react-router-dom";
import table from "../../../assets/images/about us/table.png"
const TabQris = () => {

  return (
    <Tab.Content style={{ maxWidth: '700px' }}>
    <Tab.Pane eventKey="qris-desktop" id="qris">
      <Tab.Container id="left-tabs-example" defaultActiveKey="registrasi">
        <Container>
          <TextDesc fw="600" marginBottom="30px">
            Apakah ada ketentuan khusus untuk transaksi QRIS yang berlaku bagi
            user M-Bayar?
          </TextDesc>
          <ol>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Minimal nilai transaksi QRIS Rp. 1
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                User tidak dikenakan biaya untuk transaksi pembayaran pada
                layanan Qris
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Maksimal transaksi Qris sesuai ketentuan limit transaksi E-Money
                harian yaitu Rp. 2.000.000 (dua juta rupiah) untuk user regular
                dan Rp. 20.000.000 (dua puluh juta rupiah) untuk user premium
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Jenis QRIS sesuai standar dari regulator ada 2, yaitu:
              </TextDesc>
              <ul>
                <li>
                  <TextDesc paddingLeft="20px" marginBottom="20px">
                    Static QRIS : merupakan jenis kode QRIS yang bersifat statis
                    / tetap (tidak terjadi perubahan kode QR dalam bertransaksi)
                    umumnya QRIS ini dicetak oleh merchant / toko dan user cukup
                    melakukan scan lalu input nilai transaksinya secara manual.
                  </TextDesc>
                </li>
                <li>
                  <TextDesc paddingLeft="20px" marginBottom="20px">
                    Dynamic QRIS : merupakan jenis QRIS yang bersifat dinamis,
                    umumnya setiap transaksi menghasilkan kode QRIS berbeda yang
                    akan disediakan oleh merchant / toko dan user cukup
                    melakukan scan lalu nilai transaksinya akan tampil.
                  </TextDesc>
                </li>
              </ul>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Untuk beberapa QRIS terdapat kolom tips, yaitu tambahan biaya
                yang dibebankan oleh merchant/toko kepada pembelinya yang secara
                otomatis akan menjadi keuntungan pihak merchant/toko tersebut.
                adapun 3 tipe tips:
              </TextDesc>
              <ul>
                <li>
                  <TextDesc paddingLeft="20px" marginBottom="20px">
                    Open amount tips, yaitu tipe tips tanpa ditentukan nilainya
                    oleh merchant/toko yang bisa diinput user mulai dari Rp. 1.
                    Tips ini bersifat sukarela / non mandatory, user bisa
                    mengisinya atau tidak perlu diisi dan tinggal melanjutkan
                    proses transaksinya
                  </TextDesc>
                </li>
                <li>
                  <TextDesc paddingLeft="20px" marginBottom="20px">
                    Fixed Tips, yaitu tipe tips dengan nilai yang sudah
                    ditentukan oleh merchant /toko
                  </TextDesc>
                </li>
                <li>
                  <TextDesc paddingLeft="20px" marginBottom="20px">
                    Percentage tips, yaitu tipe tips dengan nilai yang sudah
                    ditentukan oleh merchant /toko berdasarkan persentasi dari
                    nilai transaksinya.
                  </TextDesc>
                </li>
              </ul>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="30px">
                Bila user mendapatkan respon transaksi dengan status pending,
                dan tampil tombol “Refresh” maka user dapat menekan tombol
                tersebut untuk dimintakan status terbaru dari switching,
                kemudian akan tampil hasil akhirnya apakah sukses / gagal /
                pending. User dapat melakukan pengecekan ini maksimal 3x dengan
                menekan tombol “Refresh” tersebut. Bila setelah 3x dan
                mendapatkan status akhirnya gagal / pending maka transaksi
                tersebut akan dilakukan rekonsiliasi terlebih dahulu dan user
                dapat melakukan pengecekan status akhir transaksi tersebut pada
                menu Riwayat transaksi pada hari kerja berikutnya.
              </TextDesc>
            </li>
          </ol>

          <TextDesc fw="600" marginBottom="30px">
            Apakah ada ketentuan khusus untuk transaksi QRIS yang berlaku bagi
            Layanan Merchant QRIS?
          </TextDesc>
          <ol>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Melakukan registrasi pada form pendaftaran merchant Qris M-Bayar
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                User melengkapi dokumen pendukung yang diperlukan dalam proses
                pendaftaran Merchant
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="20px">
                Setelah pendaftaran berhasil maka Merchant akan disediakan
                berupa Qris untuk dicetak oleh Merchant
              </TextDesc>
            </li>
            <li>
              <TextDesc paddingLeft="20px" marginBottom="30px">
                Skema bisnis Merchant Discount Rate (MDR) layanan Qris M-Bayar
                bagi merchant sbb :
              </TextDesc>
            </li>
            <TextDesc fw="600" marginBottom="30px" aligntext="center">
              Skema dan Biaya Pemrosesan Transaksi <br/>(Merchant Discount Rate) QRIS
              <br/>Merchant Presented Mode dan Consumer Presented Mode
            </TextDesc>
            <img src={table}></img>
          </ol>
        </Container>
      </Tab.Container>
    </Tab.Pane>
    </Tab.Content>
  );
};

export default TabQris;
