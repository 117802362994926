import React from 'react'
import { ContentRight } from './Tabs/TabsFaq.styled'
import TabsContent from './TabsContent.js'

const TabRight = (props) => {
   return (
      <ContentRight>
         <TabsContent activeMobile={props.activeMobile} />
      </ContentRight>
   )
}

export default TabRight
