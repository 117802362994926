import React, { useEffect } from "react";
import {
  Content2,
  ContainTextContent2,
  ContentCard,
  CardWrap,
  Bubble1,
  Bubble2,
  Bg1,
  Bg2,
  ParallaxWrapper,
  RegularWrapper,
  TaglineCard,
} from "./Tagline.styled.js";

import { TextDesc } from "../../UI/Text.styled";
import cashless from "../../../assets/images/cashless@3x.png";
import finance from "../../../assets/images/finance_10@3x.png";
import world from "../../../assets/images/world@3x.png";
import arrow_green from "../../../assets/images/icon-arrow-green.png";
import { Card, ContCard } from "../../UI/Card.styled";
import bubble1 from "../../../assets/images/bubblehome1.svg";
import bubble2 from "../../../assets/images/bubblehome2.svg";
import bgTagline from "../../../assets/images/bg-tagline.png";
import bgTelahDigunakan from "../../../assets/images/bg-telah-digunakan.png";
import { JKContainer } from "./JaminanKeamanan.styled";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Tagline = () => {
  let navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <JKContainer perspective={"1px"} transformStyle={"preserve-3d"}>
      <Content2 flex>
        {/* <ParallaxWrapper> */}
        <Bubble1>
          <img src={bubble1} alt="bubble1" width="300px" height="300px" />
        </Bubble1>
        <Bubble2>
          <img src={bubble2} alt="bubble2" width="300px" height="300px" />
        </Bubble2>
        <Bg1>
          <img src={bgTagline}></img>
        </Bg1>
        <Bg2>
          <img src={bgTelahDigunakan}></img>
        </Bg2>
        {/* </ParallaxWrapper> */}

        {/* <RegularWrapper> */}
        <ContainTextContent2
          className="mb-4"
          data-aos="fade-right"
          data-aos-duration="3000"
        >
          <TextDesc
            poppins
            bold
            lineHeight={"50px"}
            size={"34px"}
            marginBottom={"10px"}
            fontsm={"28px"}
          >
            Fokuskan diri anda dalam pengembangan bisnis,{" "}
          </TextDesc>
          <TextDesc
            poppins
            regular
            mbsm={"75px"}
            displaysm={"none"}
            display={"flex"}
          >
            kami siap mendukung dengan fasilitas kemudahan,
            <br /> keamanan kenyamanan serta efisiensi dari beragam <br />{" "}
            kebutuhan transaksi keuangan secara digital (non-tunai).
          </TextDesc>
          <TextDesc
            poppins
            regular
            mbsm={"75px"}
            display={"none"}
            displaysm={"flex"}
          >
            kami siap mendukung dengan fasilitas kemudahan,keamanan kenyamanan
            serta efisiensi dari beragam kebutuhan transaksi keuangan secara
            digital (non-tunai).
          </TextDesc>
        </ContainTextContent2>

        {/* <Card
          position={"absolute"}
          alignself="center"
          width={"300px"}
          height={"200px"}
          paddingTop={"60px"}
          marright={"50px"}
          marginBottom={"-190px"}
          data-aos-duration="1000"
          data-aos="fade-left"
          right={"300px"}
          bottom={"345px"}
          rightsm={"0px"}
          bottomsm={"0px"}
        ><div>
          <a href="/#" className="text-decoration-none text-black">
            <CardWrap className="mt-3">
              <ContentCard
                bgImg={finance}
                width={"94px"}
                height={"133px"}
                top={"-75px"}
                left={"50px"}
              ></ContentCard>
              <TextDesc
                removePd
                removeMg
                className="d-flex flex-column"
                size="24px"
                fw="700"
                poppins
                bold
                fontsm="20px"
              >
                <span>Kemudahan &</span>
                <span>Kenyamanan</span>
                <span>Bertransaksi</span>
              </TextDesc>
              <div>
                <img src={arrow_green} alt="arrow green" />
              </div>
            </CardWrap>
          </a>
          </div>
        </Card>
        <ContCard className="mt-4" position={"absolute"}>
          <Card
            width={"300px"}
            height={"200px"}
            paddingTop={"90px"}
            marginBottom={"100px"}
            data-aos-duration="3000"
            data-aos="fade-left"
          >
            <a href="/#" className="text-decoration-none text-black">
              <CardWrap className="mt-3">
                <ContentCard
                  bgImg={cashless}
                  width={"114px"}
                  height={"143px"}
                  top={"-60px"}
                ></ContentCard>
                <TextDesc
                  removePd
                  removeMg
                  className="d-flex flex-column"
                  size="24px"
                  fw="700"
                  poppins
                  bold
                  fontsm="20px"
                >
                  <span>Cashless Adalah </span>
                  <span>Masa Depan</span>
                </TextDesc>
                <div href="">
                  <img src={arrow_green} alt="arrow green" />
                </div>
              </CardWrap>
            </a>
          </Card>

          <Card
            className="mt-4"
            width={"300px"}
            height={"200px"}
            paddingTop={"50px"}
            data-aos-duration="2000"
            data-aos="fade-left"
          >
            <a href="/#" className="text-decoration-none text-black">
              <CardWrap className="mt-3">
                <ContentCard
                  bgImg={world}
                  width={"130px"}
                  height={"130px"}
                  top={"-85px"}
                  topsm={"-60px"}
                ></ContentCard>
                <TextDesc
                  removePd
                  removeMg
                  className="d-flex flex-column"
                  size="24px"
                  fw="700"
                  poppins
                  bold
                  fontsm="20px"
                  ptsm={"40px"}
                >
                  <span>Bangun Ekosistem</span>
                  <span>Bisnis Anda</span>
                </TextDesc>
                <div href="/#">
                  <img src={arrow_green} alt="arrow green" />
                </div>
              </CardWrap>
            </a>
          </Card>
        </ContCard> */}
        {/* </RegularWrapper> */}
        <TaglineCard left="600px" >
            <p>
              <br />
              Cashless adalah masa depan
            </p>
            <a href="/kenapa-e2pay">
              <img src={arrow_green} id="arrow"></img>
            </a>
            <img
              src={cashless}
              id="cashless"
              width="114px"
              height="143px"
              imgtop="-300px"
              imgleft="0px"
            ></img>
        </TaglineCard>
        <TaglineCard top="300px" left="300px"  >
            <p>
              <br />
              Bangun Ekosistem Bisnis Anda
            </p>
            <a href="/kenapa-e2pay">
              <img src={arrow_green} id="arrow"></img>
            </a>
            <img src={world} id="world" width="131px" height="129px"></img>
        </TaglineCard>
        <TaglineCard top="300px" right="375px" >
            <p>Kemudahan & Kenyamanan Bertransaksi</p>
            <a href="/kenapa-e2pay">
              <img src={arrow_green} id="arrow"></img>
            </a>
            <img src={finance} id="finance" width="94px" height="113px"></img>
        </TaglineCard>
      </Content2>
    </JKContainer>
  );
};

export default Tagline;
