import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { ContainerSlider, Container } from "./CarouselPartnerPembayaran.styled";
import {
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
  CarouselImage5,
  CarouselImage7,
  CarouselImage6,
} from "./CarouselImage";

export default class CarouselPartnerPembayaran extends Component {
  render() {
    const titles = [
      "Credit Card",
      "i-Banking",
      "E-Money",
      "Virtual Account",
      "Over The Counter",
      "Financing",
    ];

    const title = titles.map((i) => {
      return i;
    });

    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      dotsClass: "slick-dots slick-thumb",
      autoplay: true,
      autoplaySpeed: 4000,
      customPaging: function (title) {
        return <a>{pagination[title]}</a>;
      },
      responsive: [
        {
          breakpoint: 768,
          settings: {
            customPaging: function (title) {
              // return <a>&diams;</a>;
              // return <a>&#9677;</a>;
              // return <a>&#9677;</a>;
              // return <a>&#9677;</a>;
              return <a>&#9673;</a>;
              // return <a>●</a>;
            },
          },
        },
      ],
    };

    const pagination = [];

    if (Array.isArray(titles)) {
      titles.map((title) => {
        pagination.push(title);
      });
    }

    return (
      <Container>
        <ContainerSlider>
          <Slider {...settings}>
            <div>
              <CarouselImage5 title="Credit Card"></CarouselImage5>
            </div>
            <div>
              <CarouselImage6 title="Internet Banking"></CarouselImage6>
            </div>

            <div>
              <CarouselImage1 title="E-Money"></CarouselImage1>
            </div>
            <div>
              <CarouselImage2 title="Virtual Account"></CarouselImage2>
            </div>
            <div>
              <CarouselImage3 title="Over The Counter"></CarouselImage3>
            </div>
            <div>
              <CarouselImage4 title="Financing"></CarouselImage4>
            </div>
          </Slider>
        </ContainerSlider>
      </Container>
    );
  }
}
