import styled from 'styled-components'

export const ContainerBD = styled.div`
   margin-top: 120px;
   height: 355px;
   background-color: #2d62ed;
   display: flex;
   gap: 1rem;
   flex-wrap: wrap;
   justify-content: center;
   padding: 0 20px;
   @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      height: 100%;
      flex-direction: column;
      gap: 0px;
   }
`

export const ContentLeft = styled.div`
   width: 746px;
`

export const ContentRight = styled(ContentLeft)`
   margin-top: 52px;
   width: 460px;
   display: flex;
   flex-direction: column;
   gap: 24px;
`

export const BackgroundBD = styled.div`
   margin-top: 52px;
   width: 100%;
   height: 396px;

   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: cover;

     @media only screen and (max-width: 768px) {
    background-position: left;
    background-size: 320px 200px;
   }
`
