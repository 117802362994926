import React from 'react'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import { ContainerTB, IconTB, ItemTB } from '../PG/TujuanBisnis.styled'
import { ContainerLayanan, LayananItem } from './Layanan.styled'
import iconStamp from '../../../assets/images/web_icon_stamp.svg'
import iconFlexi from '../../../assets/images/web_icon_flexi.svg'
import iconChat from '../../../assets/images/web_icon_chat.svg'
import iconLock from '../../../assets/images/web_icon_lock.svg'
import iconoffline from '../../../assets/images/web_icon_onoffline.svg'
import iconQR from '../../../assets/images/web_icon_QR2.svg'
import stamp from "../../../assets/images/Emoney/stamp.svg"
import message from "../../../assets/images/Emoney/message.svg"
import lock from "../../../assets/images/Emoney/lock.svg"
import flexi from "../../../assets/images/Emoney/flexi.svg"

//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.79;
//   letter-spacing: normal;
//   text-align: left;
const Layanan = () => {
   return (
     <ContainerLayanan>
       <ContainerTB>
         <ItemTB>
           {/* <IconTB bgImg={iconStamp} /> */}
           <img src={stamp} width="30px" height="30px"></img>

           <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
             Layanan resmi dan aman untuk bisnis anda
           </TextTitle>
           <TextDesc
             color={"#44525b"}
             size={"14px"}
             fontWeight={"normal"}
             lineHeight={"1.79"}
             letterSpacing={"normal"}
           >
             Platform layanan uang elektronik M-Bayar terdaftar dan diawasi oleh
             Bank Indonesia.
           </TextDesc>
         </ItemTB>
         <ItemTB>
           {/* <IconTB bgImg={iconChat} /> */}
           <img src={message} width="30px" height="30px"></img>

           <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
             Terbuka bagi komunitas dan penyedia layanan
           </TextTitle>
           <TextDesc
             color={"#44525b"}
             size={"14px"}
             fontWeight={"normal"}
             lineHeight={"1.79"}
             letterSpacing={"normal"}
           >
             M-Bayar mendukung pengembangan komunitas lokal secara positif
             termasuk beragam kebutuhan yang spesifik bagi komunitas terkait.
           </TextDesc>
         </ItemTB>
         <ItemTB>
           {/* <IconTB bgImg={iconFlexi} /> */}
           <img src={flexi} width="30px" height="30px"></img>

           <TextTitle color={"#27328f"} fw={"bold"} size={"22px"}>
             Fleksibilitas metode pembayaran untuk keunikan bisnis anda
           </TextTitle>
           <div className="d-flex gap-3">
             <div>
               <img src={iconoffline} />
             </div>
             <TextDesc
               color={"#44525b"}
               size={"14px"}
               fontWeight={"normal"}
               lineHeight={"1.79"}
               letterSpacing={"normal"}
             >
               Mendukung pembayaran pada merchant berbasis online maupun offline
               (toko fisik)
             </TextDesc>
           </div>
           <div className="d-flex gap-3">
             <div>
               <img src={iconQR} />
             </div>
             <TextDesc
               color={"#44525b"}
               size={"14px"}
               fontWeight={"normal"}
               lineHeight={"1.79"}
               letterSpacing={"normal"}
             >
               Mendukung pembayaran menggunakan model otentikasi web maupun
               pemindaian kode QR
             </TextDesc>
           </div>
         </ItemTB>
         <ItemTB>
           {/* <IconTB bgImg={iconLock} /> */}
           <img src={lock} width="30px" height="30px"></img>

           <TextTitle color={"#27328f"} size={"22px"} fw={"bold"}>
             Aman dan terdaftar secara resmi di Bank Indonesia
           </TextTitle>
           <div>
             <TextDesc
               color={"#44525b"}
               className="d-flex flex-column"
               size={"14px"}
               fontWeight={"normal"}
               lineHeight={"1.79"}
               letterSpacing={"normal"}
             >
               {/* <span className="fw-bold" style={{ color: "#707070" }}>
                 E-Money License from BI
               </span>
               <span style={{ color: "#707070" }}>
                 20/208/DKSP/Srt/B, 22 May 2018
               </span> */}
               Telah terdaftar di Bank Indonesia sebagai PJP 1
             </TextDesc>
           </div>
           <div>
             {/* <TextDesc
               color={"#44525b"}
               className="d-flex flex-column"
               size={"14px"}
               fontWeight={"normal"}
               lineHeight={"1.79"}
               letterSpacing={"normal"}
             >
               <span className="fw-bold" style={{ color: "#707070" }}>
                 Remittance License from BI
               </span>
               <span style={{ color: "#707070" }}>
                 21/255/DKSP/94, 2 Oct 2019
               </span>
             </TextDesc> */}
           </div>
         </ItemTB>
       </ContainerTB>
     </ContainerLayanan>
   );
}

export default Layanan
