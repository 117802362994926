import React from "react";
import {
  Arrow,
  Info,
  Subarrow,
  TahunWrap,
  WrapInfo,
} from "./PanduanPembayaran.styled";
import Card from "./Card"
import { TextDesc } from "../../UI/Text.styled";
// import InfoTahun from "./InfoTahun";
import WebLogoInfinet from "../../../assets/images/web_logo_infinetworks.png";
import WebLogoE2Pay from "../../../assets/images/web_logo_E2Pay_S.png";
import WebLogoRazerfintech from "../../../assets/images/web_logo_razerfintech.png";
const PanduanPembayaran = () => {
  return (
    <WrapInfo>
      <Card></Card>
    </WrapInfo>
  );
};

export default PanduanPembayaran;
