import React from 'react'
import MeshGradient from 'mesh-gradient.js';
import { useEffect } from 'react';
import './tesbackground.css'

// const COLORS =   [#1c278b,
// #44d62c,
// #0b1551,
// #35ad22
// ]

const Tesbackground = () => {
    
    // create instance of Gradient Class
//   const gradient = new MeshGradient();
//   const canvasId = "my-canvas"

//   useEffect(() => {
//     // initialize new gradient
//     // @Params
//     // 1. id of canvas elememt
//     // 2. array of colors in hexcode
//     gradient.initGradient("#" + canvasId, COLORS) 
//     // Mesh Id 
//     // Any positive numeric value which acts as a seed for mesh pattern
//     gradient?.changePosition(780); 
//   }, [])

//   const regenerate = () => {
//     const value = Math.floor(Math.random() * 1000)
//     // change pattern by changing mesh Id
//     gradient?.changePosition(value);
//   }

// useEffect(() => {
//     const canvasElement = document.getElementById("gradient-canvas");
//     const gradient = new Gradient();
//     if (canvasElement) {
//         gradient.initGradient("#gradient-canvas");
//     } else {
//         gradient.pause();
//     }
// }, []);

// // Create your instance
// const gradient = new Gradient()

// // Call `initGradient` with the selector to your canvas
// gradient.initGradient('#gradient-canvas')

// const gradient = new normalizeColor()
// gradient.initGradient('#gradient-canvas');

return (
    <div className="outer">
        <div className="gradient-background" id="gradient-canvas"></div>
        {/* <button onClick={() => regenerate()}> Regenerate </button> */}
    </div>
  )
}

export default Tesbackground





// function App() {

// // create instance of Gradient Class
//   const gradient = new MeshGradient();
//   const canvasId = "my-canvas"

//   useEffect(() => {
//     // initialize new gradient
//     // @Params
//     // 1. id of canvas elememt
//     // 2. array of colors in hexcode
//     gradient.initGradient("#" + canvasId, COLORS) 
//     // Mesh Id 
//     // Any positive numeric value which acts as a seed for mesh pattern
//     gradient?.changePosition(780); 
//   }, [])

//   const regenerate = () => {
//     const value = Math.floor(Math.random() * 1000)
//     // change pattern by changing mesh Id
//     gradient?.changePosition(value);
//   }

//   return (
//     <div className="App">
//         <canvas id={canvasId} width="800" height="600"/>
//         <button onClick={() => regenerate()}> Regenerate </button>
//     </div>
//   );
// }

// export default App;