import React from "react";
import {
  Container,
  Menu,
  MenuButton,
  Title1,
  Title2,
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  AboutCard1,
  AboutCard2,
} from "./Card.styled";
import permataa from "../../../../assets/images/payment/permataa.svg";
import cimb from "../../../../assets/images/payment/cimb.png";
import bni from "../../../../assets/images/payment/bni.png";
import mandiri from "../../../../assets/images/payment/mandiri.png";
import bri from "../../../../assets/images/payment/bri.png";
import bca from "../../../../assets/images/payment/bca.png";
import bsi from "../../../../assets/images/payment/bsi.svg";
import Accordion from "react-bootstrap/Accordion";
import { getBrightness } from "vanta/src/helpers";
import { bezier } from "animejs";
import { TextDesc } from "../../../UI/Text.styled";
import "./Card.css";
import Accordions from "./Accordion";

const Card = () => {
  const accordionDataPermata = [
    {
      title: "ATM Permata / ATM ALTO",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Other Transaction".</li>
          <li>Pilih "Payment Transaction".</li>
          <li>Pilih "Other".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukkan 16 digit Virtual Account / kode pembayaran yang telah anda
            terima.{" "}
          </li>
          <li>
            Nomor virtual account dan jumlah yang harus dibayarkan akan muncul
            pada halaman konfirmasi pembayaran. Pilih “Correct”.
          </li>
          <li>Pilih rekening pembayaran anda, kemudian pilih “Correct”.</li>
        </ol>
      ),
    },
    {
      title: "ATM Bersama",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Antar Bank Online".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukan nomor 013 diikuti 16 digit kode pembayaran/nomor Virtual
            Account yang Anda terima. Contoh: 0131234567812345678.{" "}
          </li>
          <li>
            Masukan total yang harus dibayarkan. Jumlah yang tidak tepat akan
            membatalkan transaksi.
          </li>
          <li>Kosongkan nomor referensi dan klik "Benar".</li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM PRIMA",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Rek Bank Lain".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukan kode 013 untuk Bank Permata dan pilih "Lanjut" / "Benar".
          </li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Lanjut". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan 16 digit virtual account/kode pembayaran lalu klik "Lanjut"
            / "Benar".
          </li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
  ];

  const accordionDataBca = [
    {
      title: "ATM BCA / ATM ALTO",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Other Transaction".</li>
          <li>Pilih "Payment Transaction".</li>
          <li>Pilih "Other".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukkan 16 digit Virtual Account / kode pembayaran yang telah anda
            terima.{" "}
          </li>
          <li>
            Nomor virtual account dan jumlah yang harus dibayarkan akan muncul
            pada halaman konfirmasi pembayaran. Pilih “Correct”.
          </li>
          <li>Pilih rekening pembayaran anda, kemudian pilih “Correct”.</li>
        </ol>
      ),
    },
    {
      title: "ATM Bersama",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Antar Bank Online".</li>
          <li>
            Masukan nomor diikuti 16 digit kode pembayaran/nomor Virtual Account
            yang Anda terima. Contoh: 0141234567812345678.
          </li>
          <li>
            Masukan total yang harus dibayarkan. Jumlah yang tidak tepat akan
            membatalkan transaksi.
          </li>
          <li>Kosongkan nomor referensi dan klik "Benar".</li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM PRIMA",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Rek Bank Lain".</li>
          <li>Masukan kode 014 untuk Bank BCA dan pilih "Lanjut" / "Benar".</li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Lanjut". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan 16 digit virtual account/kode pembayaran lalu klik "Lanjut"
            / "Benar".
          </li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
  ];

  const accordionDataBni = [
    {
      title: "ATM BNI / ATM ALTO",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Other Transaction".</li>
          <li>Pilih "Transfer".</li>
          <li>Pilih rekening pembayaran Anda, kemudian pilih "Correct".</li>
          <li>Pilih "Virtual Account Billing".</li>
          <li>
            Masukan 16 digit Virtual Account/kode pembayaran yang telah Anda
            terima.{" "}
          </li>
          <li>
            Nomor virtual account dan jumlah yang harus dibayarkan akan muncul
            pada halaman konfirmasi pembayaran. Pilih "Correct".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM Bersama",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Antar Bank Online".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukan nomor 009 diikuti 16 digit kode pembayaran/nomor Virtual
            Account yang Anda terima. Contoh: 009+[no VA].
          </li>
          <li>
            Masukan total yang harus dibayarkan. Jumlah yang tidak tepat akan
            membatalkan transaksi.
          </li>
          <li>Kosongkan nomor referensi dan klik "Benar".</li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM PRIMA",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Rek Bank Lain".</li>
          <li>Masukan kode 009 untuk Bank BNI dan pilih "Lanjut" / "Benar".</li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Lanjut". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan 16 digit virtual account/kode pembayaran lalu klik "Lanjut"
            / "Benar".
          </li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM ALTO",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Ke Rekening Bank Lain".</li>
          <li>Masukan kode 009 untuk BNI dan pilih "Benar".</li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Benar". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan 16 digit virtual account/kode pembayaran lalu klik "Benar".
          </li>
          <li>Pilih rekening yang akan didebit dan klik "Benar".</li>
        </ol>
      ),
    },
    {
      title: "ATM LINK",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Ke Rekening Bank Lain".</li>
          <li>Masukan kode 009 untuk BNI dan pilih "Benar".</li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Benar". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan 16 digit virtual account/kode pembayaran lalu klik "Benar".
          </li>
          <li>Pilih rekening yang akan didebit dan klik "Benar".</li>
        </ol>
      ),
    },
    {
      title: "iBnk Personal BNI",
      content: (
        <ol>
          <li>Ketik alamat https://ibank.bni.co.id kemudian klik "Enter".</li>
          <li>Masukkan User ID dan Password.</li>
          <li>Pilih menu "Transfer".</li>
          <li>Pilih "Virtual Account Billing".</li>
          <li>
            Masukan nomor Virtual Account [No VA], lalu pilih rekening debet
            yang akan digunakan. Kemudian tekan "Lanjut".
          </li>
          <li>
            Kemudian tagihan yang harus dibayarkan akan muncul pada layar
            Konfirmasi.
          </li>
          <li>Masukkan Kode Otentikasi Token lalu klik "Proses".</li>
          <li>Pembayaran Anda telah berhasil.</li>
        </ol>
      ),
    },

    {
      title: "Mobile Banking BNI",
      content: (
        <ol>
          <li>
            Akses BNI Mobile Banking dari handphone kemudian masukkan User ID
            dan Password.
          </li>
          <li>Pilih menu "Transfer".</li>
          <li>
            Pilih menu "Virtual Account Billing" kemudian pilih rekening debet.
          </li>
          <li>
            Masukkan nomor Virtual Account [No VA] pada menu "input baru".
          </li>
          <li>
            Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.
          </li>
          <li>
            Kemudian tagihan yang harus dibayarkan akan muncul pada layar
            Konfirmasi.
          </li>
          <li>Konfirmasi transaksi dan masukkan Password Transaksi.</li>
          <li>Pembayaran Anda telah berhasil.</li>
        </ol>
      ),
    },
  ];

  const accordionDataBri = [
    {
      title: "ATM BRI / ATM PRIMA",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer".</li>
          <li>Pilih "Rek Bank Lain".</li>
          <li>Masukan kode 002 untuk Bank BRI dan pilih "Lanjut" / "Benar".</li>
          <li>
            Masukan jumlah yang harus dibayarkan dan klik "Lanjut". Jumlah yang
            tidak tepat akan membatalkan transaksi.
          </li>
          <li>
            Masukan virtual account/kode pembayaran lalu klik "Lanjut" / "Benar"
          </li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM Bersama",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Transaksi Lainnya".</li>
          <li>Pilih "Transfer.</li>
          <li>Pilih "Antar Bank Online".</li>
          <li>
            Masukan nomor diikuti kode pembayaran/nomor Virtual Account yang
            Anda terima. Contoh: 1161900000003376.
          </li>
          <li>
            Masukan total yang harus dibayarkan. Jumlah yang tidak tepat akan
            membatalkan transaksi.{" "}
          </li>
          <li>Kosongkan nomor referensi dan klik "Benar".</li>
          <li>
            Jumlah yang harus dibayar dan nomor rekening akan muncul pada
            halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih
            "Benar".
          </li>
        </ol>
      ),
    },
    {
      title: "ATM ALTO",
      content: (
        <ol>
          <li>Pada menu utama, pilih "Other Transaction".</li>
          <li>Pilih "Payment Transaction.</li>
          <li>Pilih "Other".</li>
          <li>Pilih pembayaran Virtual Account.</li>
          <li>
            Masukan Virtual Account/kode pembayaran yang telah Anda terima.
          </li>
          <li>
            Nomor virtual account dan jumlah yang harus dibayarkan akan muncul
            pada halaman konfirmasi pembayaran. Pilih "Correct".
          </li>
          <li>Pilih rekening pembayaran anda, kemudian pilih "Correct".</li>
        </ol>
      ),
    },
  ];

  const accordionDataMandiri = [
    {
      title: "Mobile Banking Bank Mandiri (Livin' By Mandiri)",
      content: (
        <ol>
          <li>Login ke aplikasi Livin' By Mandiri.</li>
          <li>Pilih menu bayar.</li>
          <li>Pilih menu e-Commerce.</li>
          <li>Ketik Kode Perusahaan 70007 pada kolom pencarian.</li>
          <li>Masukkan Nomor Virtual Account Transaksi Anda.</li>
          <li>Ikuti instruksi selanjutnya sampai transaksi selesai.</li>
        </ol>
      ),
    },
    {
      title: "ATM Mandiri",
      content: (
        <ol>
          <li>
            Di ATM, masukkan kartu ATM Mandiri dan ketik PIN Mandiri Anda.
          </li>
          <li>Pilih menu Bayar / Beli.</li>
          <li>Pilih menu lainnya, hingga menemukan menu Multipayment.</li>
          <li>Masukkan Kode Perusahaan 70007.</li>
          <li>Masukkan Nomor Virtual Account transaksi Anda.</li>
          <li>Masukkan nominal transaksi Anda.</li>
          <li>
            Tekan nomor "1" untuk memilih tagihan kemudian klik tombol Ya.
          </li>
          <li>Konfirmasi pembayaran, jika sesuai pilih Ya.</li>
          <li>Transaksi selesai.</li>
        </ol>
      ),
    },
  ];

  const accordionDataCimb = [
    {
      title: "ATM CIMB Niaga",
      content: (
        <ol>
          <li>Masukkan kartu ATM dan PIN CIMB Anda</li>
          <li>Pilih menu Pembayaran &gt; Lanjut &gt; Virtual Account</li>
          <li>Masukkan nomor virtual account Anda 8629000000188307</li>
          <li>Muncul nama dan nominal billing dilayar konfirmasi</li>
          <li>Pilih "OK" untuk payment </li>
        </ol>
      ),
    },
    {
      title: "ATM Bersama / PRIMA / Bank Lain",
      content: (
        <ol>
          <li>Masukan kartu ATM dan PIN Anda</li>
          <li>Pilih menu TRANSFER / TRANSFER ONLINE</li>
          <li>Masukkan kode bank CIMB Niaga: 022</li>
          <li>Masukkan nomor Virtual Account Anda 8629000000188307</li>
          <li>Masukkan jumlah pembayaran sesuai tagihan</li>
          <li>Ikuti instruksi untuk menyelesaikan transaksi</li>
        </ol>
      ),
    },
    {
      title: "Octo Clicks",
      content: (
        <ol>
          <li>Login ke OCTO Clicks (https://www.octoclicks.co.id).</li>
          <li>Pilih menu Bayar Tagihan / Pay Bills.</li>
          <li>Pilih "Virtual Account" pada field jenis pembayaran.</li>
          <li>Masukkan nomor Virtual Account Anda 8629000000188307.</li>
          <li>Muncul nama dan nominal billing di layar konfirmasi.</li>
          <li>Masukkan 6 digit mPIN dan tekan tombol Submit.</li>
          <li>Konfirmasi pembayaran ditampilkan pada layar.</li>
        </ol>
      ),
    },
    {
      title: "Internet Banking Bank Lain",
      content: (
        <ol>
          <li>Login ke Internet Banking.</li>
          <li>Pilih "Transfer".</li>
          <li>Pilih menu Transfer ke Bank Lain Online.</li>
          <li>Pilih bank tujuan Bank CIMB Niaga (kode bank: 022).</li>
          <li>Masukkan nomor Virtual Account Anda 8629000000188307.</li>
          <li>Masukan jumlah amount sesuai tagihan.</li>
          <li>Ikuti instruksi untuk menyelesaikan transaksi.</li>
        </ol>
      ),
    },
    {
      title: "Go Mobile CIMB Niaga",
      content: (
        <ol>
          <li>Login ke GO Mobile.</li>
          <li>
            Pilih menu TRANSFER &gt; Transfer to Other CIMB Niaga Account.
          </li>
          <li>Pilih rekening sumber Anda: CASA atau Rekening Ponsel.</li>
          <li>
            Masukkan nomor Virtual Account Anda 8629000000188307 pada kolom
            "TRANSFER TO".
          </li>
          <li>Masukan jumlah amount sesuai tagihan.</li>
          <li>Masukkan 6 digit mPIN dan tekan tombol Submit.</li>
          <li>Ikuti instruksi untuk menyelesaikan transaksi.</li>
        </ol>
      ),
    },
  ];

  const accordionDataBsi = [
    {
      title: "ATM BSI",
      content: (
        <ol>
          <li>Pilih menu "Pembayaran/Pembelian".</li>
          <li>Pilih menu "Institusi".</li>
          <li>Masukkan nomor Virtual Account BSI. Contoh: 9448000000412246.</li>
          <li>Detail yang ditampilkan: NIM, Nama, & Total Tagihan.</li>
          <li>Konfirmasi detail transaksi Anda dan proses.</li>
        </ol>
      ),
    },
    {
      title: "Internet Banking BSI",
      content: (
        <ol>
          <li>Pilih menu "Pembayaran".</li>
          <li>Pilih nomor rekening BSI Anda.</li>
          <li>Pilih menu "Institusi".</li>
          <li>Masukkan kode Institusi 9448 (E2Pay)".</li>
          <li>Masukkan nomor Virtual Account BSI. Contoh: 000000412246.</li>
          <li>Masukkan PIN Anda.</li>
          <li>Konfirmasi detail transaksi Anda dan proses.</li>
        </ol>
      ),
    },
    {
      title: "Mobile Banking BSI",
      content: (
        <ol>
          <li>Pilih menu "Pembayaran".</li>
          <li>Pilih nomor rekening BSI Anda.</li>
          <li>Pilih menu "Institusi".</li>
          <li>Masukkan kode Institusi 9448 (kode Institusi E2Pay)".</li>
          <li>Masukkan nomor Virtual Account BSI. Contoh: 000000412246.</li>
          <li>Masukkan PIN Anda.</li>
          <li>Konfirmasi detail transaksi Anda dan proses.</li>
        </ol>
      ),
    },
    {
      title: "Transfer Antar Bank",
      content: (
        <ol>
          <li>Pilih menu "Transfer".</li>
          <li>Pilih menu "Antar Bank".</li>
          <li>Pilih Bank BSI (kode bank 451).</li>
          <li>
            Masukkan BSI VA Code "900" + nomor Virtual Account BSI. Contoh:
            9009448000000412246.
          </li>
          <li>Masukkan nominal yang ingin dibayarkan.</li>
          <li>Konfirmasi detail transaksi Anda.</li>
        </ol>
      ),
    },
  ];

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
 
  return (
    <Container>
      <Title1>
        <Menu>
          <a href="/panduan-pembayaran/virtual-account#permata">
            <MenuButton>
              <img src={permataa} height="30px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#bca">
            <MenuButton>
              <img src={bca} height="20px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#bni">
            <MenuButton>
              <img src={bni} height="20px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#bri">
            <MenuButton>
              <img src={bri} height="20px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#mandiri">
            <MenuButton>
              <img src={mandiri} height="20px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#cimb">
            <MenuButton>
              <img src={cimb} height="18px" width="auto" />
            </MenuButton>
          </a>

          <a href="/panduan-pembayaran/virtual-account#bsi">
            <MenuButton>
              <img src={bsi} height="18px" width="auto" />
            </MenuButton>
          </a>
        </Menu>
      </Title1>
      <Row1 id="permata">
        <div className="accordions">
          <div className="bank">
            <p>Permata</p>
            <img src={permataa} width="auto" height="40px"></img>
          </div>
          {accordionDataPermata.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row1>

      <Row2 id="bca">
        <div className="accordions">
          <div className="bank">
            <p>BCA</p>
            <img src={bca} width="auto" height="40px"></img>
          </div>
          {accordionDataBca.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>

      <Row2 id="bni">
        <div className="accordions">
          <div className="bank">
            <p>BNI</p>
            <img src={bni} width="auto" height="40px"></img>
          </div>
          {accordionDataBni.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>

      <Row2 id="bri">
        <div className="accordions">
          <div className="bank">
            <p>BRI</p>
            <img src={bri} width="auto" height="40px"></img>
          </div>
          {accordionDataBri.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>

      <Row2 id="mandiri">
        <div className="accordions">
          <div className="bank">
            <p>Mandiri</p>
            <img src={mandiri} width="auto" height="40px"></img>
          </div>
          {accordionDataMandiri.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>

      <Row2 id="cimb">
        <div className="accordions">
          <div className="bank">
            <p>CIMB Niaga</p>
            <img src={cimb} width="auto" height="40px"></img>
          </div>
          {accordionDataCimb.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>

      <Row2 id="bsi">
        <div className="accordions">
          <div className="bank">
            <p>BSI</p>
            <img src={bsi} width="auto" height="40px"></img>
          </div>
          {accordionDataBsi.map(({ title, content }) => (
            <Accordions title={title} content={content} />
          ))}
        </div>
      </Row2>
    </Container>
  );
};

export default Card;
