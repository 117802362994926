import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  padding-top:100px;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;

  .bubble {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 575px;
  }

  .bg1 {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 550px;
  }

  .bg2 {
    position: absolute;
    z-index: 1;
    left: -300px;
    bottom: -150px;
  }
`;

export const Menu = styled.div`
  width: 90% ;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
`;

export const MenuButton = styled.button`
  width: 140px ;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: solid 1px #cfd1d4;
  background-color: #fff;
  box-sizing: border-box;
  
  :hover {
    border: 2px solid #44d62c;
  }
`;



export const Title1 = styled.div`
  position: relative;
  top: -225px;
  background-color: #fff;
  width: 110%;
  height: 175px;
  margin: 0 ;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 15px;
  z-index: 20;

  @media screen and (max-width: 768px) {
    margin-top: 150px;
  }

  p {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--272-d-3-b-text);
  }
`;

export const Title2 = styled.div`
  position: relative;
  width: 70%;
  height: 200px;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  p {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--272-d-3-b-text);
  }

  a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: #44d62c;
  }
`;

export const Row1 = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-top: -175px;
  margin-bottom: 30px;
  position: relative;
  z-index: 30; 
  padding: 0 100px;
  img {
    height: 30px;
    width: auto;
  }


  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Row2 = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 30px;
  margin-top: 0;
  position: relative;
  z-index: 3;
  padding: 0 100px;

  img {
    height: 25px;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;


export const AboutCard1 = styled.button`
  width: 260px;
  height: 65px;
  border: none;
background-color: white;
border-radius: 100px;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 5px 20px;
font-size: 18px;

  :hover {
  background-color: #edf2fe;
  color: #2d62ed;
  }

  img:last-child {
  display: none;  
}
:hover img:last-child {
  display: block;  
}
:hover img:first-child {
  display: none;  
}
`;
