import styled from 'styled-components'

export const WrapCertificate = styled.div`
   width: 90%;
   padding: 20px;
   margin: 0 auto;
`

export const FlexImage = styled.div`
   display: flex;
   /* flex-wrap: wrap; */
   align-items: center;
   /* justify-content: center; */
   gap: 2rem;
   div {
      max-width: 164.5px;
      max-height: 97.4px;
   }
`

export const Narasi = styled.div`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`
