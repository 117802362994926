// "homepage":"http://192.168.0.201/webs/",
// "homepage":"https://services.farizramadhan.com/e2pay-web/",
// "homepage":"https://services.farizramadhan.com/e2pay-web/",
// "homepage":"http://e2payweb.farizramadhan.com/",
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Home from "./pages/Home";
import Documentation from "./pages/Documentation";
import Footer from "./components/UI/Footer";
import PG from "./pages/PG";
import BG from "./pages/BG";
import Voucher from './pages/Voucher'
import Disbursement from "./pages/Disbursement";
import Ekosistem from "./pages/Ekosistem";
import Emoney from "./pages/Emoney";
import B2B from "./pages/B2B";
import EmoneyDownload from "./pages/EmoneyDownload";
import Privacy from "./pages/Privacy";
import TnC from "./pages/TnC";
import About from "./pages/About";
import TentangE2Pay from "./pages/TentangE2Pay";
import Page404 from "./pages/Page404";
import Faq from "./pages/Faq";
import ScrollToTop from "./pages/ScrollToTop";
import Karir from "./pages/Karir";
import HubungiSales from "./pages/HubungiSales";
import MerchantApp from "./pages/MerchantApp";
import Biaya from "./pages/Biaya";
import PanduanPembayaran from "./pages/PanduanPembayaran";
import NewsPortal from "./pages/NewsPortal"
import DetailNewsPortal from "./pages/DetailNewsPortal";
import PendaftaranMerchant from "./pages/PendaftaranMerchant";
import TesBackground from "./tesbackground";
import "./i18n";
import { useTranslation, Trans } from "react-i18next";
// import { createBrowserHistory } from "history";
import PathToRegexp, { compile, parse } from "path-to-regexp";
import { connect, Provider } from "react-redux"

// import SimpleSlider from './slider'

import i18n from "./i18n";
import VirtualAccount from "./pages/VirtualAccount";
import CreditCard from "./pages/CreditCard";
import Artikel from "./pages/Artikel";

const lang = i18n.language;
// const history = createBrowserHistory();

const generateLanguage = (locale, location) => {
  const ROUTE = "/:locale/:path*";
  const definePath = compile(ROUTE);
  const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);

  console.log(routeComponents)

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split("/");
  }

  return definePath({
    locale,
    path: subPaths,
  });
};

const baseUrl = "/"

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng)
}

// const changeLanguage = (lng) => {
//   i18n.changeLanguage(lng);
// };

let App = ({ match, location }) => {
  console.log("location", location);
  console.log("match", match);

  const { t, i18n } = useTranslation();
  console.log(i18n.language);
  console.log(i18n.lng);

  // if
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes >
        <Route path="/" element={<Home />}  />
        {/* <Route path="/documentation" element={<Documentation />}  /> */}
        <Route path="/about-us" element={<TentangE2Pay />} />
        <Route path="/kenapa-e2pay" element={<About />} />
        <Route path="/artikel" element={<NewsPortal />} />
        <Route path="/payment-gateway" element={<PG />} />
        <Route path="/biller-gateway" element={<BG />} />
        {/* <Route path="/biller-gateway/voucher-purchase" element={<Voucher />} /> */}
        <Route path="/disbursement" element={<Disbursement />} />
        <Route path="/Ekosistem" element={<Ekosistem />} />
        <Route path="/emoney/mbayar-app" element={<Emoney />} />
        <Route path="/emoney/b2b-account-linkage" element={<B2B />} />
        <Route path="/emoney/mbayar-apps/download" element={<EmoneyDownload />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/terms-condition" element={<TnC />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/panduan-pembayaran" element={<PanduanPembayaran />} />
        <Route path="/panduan-pembayaran/virtual-account" element={<VirtualAccount />} />
        <Route path="/panduan-pembayaran/credit-card" element={<CreditCard />} />
        {/* {i18n.language === "de" ?  <Route
        path="/karir"
        element={<Karir />}
      /> : i18n.language === "en" ?  <Route
      path="/careers"
      element={<Karir />} 
    /> :  
    <Route path="/faq" element={<Faq />} />
    
    } */}
        {/* <Route path="/karir" element={<Karir />} /> */}
        {/* <Route path="/careers" element={<Karir />} /> */}

        <Route path="/hubungi-sales" element={<HubungiSales />} />
        <Route path="/merchant-application" element={<MerchantApp />} />
        <Route path="/produk/biaya" element={<Biaya />} />
        <Route path="/article" element={<NewsPortal />} />
        <Route path="artikel/e2pay-tegaskan-tidak-memfasilitasi-aktivitas-judi-online" element={<DetailNewsPortal />} />
        <Route path="/tes-background" element={<TesBackground />} />
        {/* <Route path="/tes-slider" element={<SimpleSlider />} /> */}
        <Route path="/pendaftaran-merchant" element={<PendaftaranMerchant />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

// App = connect(
//   state => ({
//     location: state.routing.location
//   }), null
// )(App)

export default App;
