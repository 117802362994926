import React from "react";
import ContentPG from "./PG/Content/ContentPG";
import { Helmet } from "react-helmet";

const PG = () => {
  return (
    <div>
      <Helmet>
        <title>E2Pay - Payment Gateway</title>
        <meta name="description" content="E2pay Payment Gateway Page" />
      </Helmet>
      <ContentPG />
    </div>
  );
};

export default PG;
