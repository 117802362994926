import React from 'react'
import HeroBG from "../../../assets/images/background header/header-privacy.png"
import HeaderNoHero from '../../../components/UI/HeaderNoHero'

const HeaderPrivacyAbout = (props) => {
   return (
      <>
         <HeaderNoHero
            title="Privasi dalam pembayaran elektronik adalah prioritas kami"
            halaman="Privacy"
            background={HeroBG}
         />
      </>
   )
}

export default HeaderPrivacyAbout
