import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";
import { Container } from "./TabQris.styled";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { Link } from "react-router-dom";
import table1 from "../../../assets/images/cutoff1.png"
import table2 from "../../../assets/images/cutoff2.png"
import table3 from "../../../assets/images/cutoff3.png"
const TabQris = () => {
  return (
    <Tab.Content style={{ maxWidth: '700px' }}>
    <Tab.Pane eventKey="disbursement-desktop">
      <Tab.Container id="left-tabs-example" defaultActiveKey="registrasi">
        <Container>
          <TextTitle fw="600" size="22px">
          Dimana credential untuk pengujian UAT bisa didapatkan ?
          </TextTitle>
          <TextDesc fw="400" marginBottom="50px">
          Anda bisa mendapatkannya lewat email ke <span >emoney.prd@e2pay.co.id</span> 
          </TextDesc>

          <TextTitle fw="600" size="22px">
          Kapan periode cut off pada sistem bank?
          </TextTitle>
          <TextDesc fw="400" marginBottom="20px">
          Berikut adalah informasi mengenai period cut off pada sistem bank, baik End Of Day (EOD), maupun End Of Month (EOM)
          </TextDesc>
          <img src={table1}></img>
          <img src={table2}></img>
          <img src={table3}></img>

          <TextTitle fw="600" size="22px" marginTop="50px">
          Kapan periode cut off pada sistem E2pay setiap harinya?
          </TextTitle>
          <TextDesc fw="400" marginBottom="50px">
          Pukul 00.00 WIB sampai dengan pukul 00.30 WIB.
Untuk menghindari hal-hal yang tidak diharapkan, kami menyarankan Anda untuk tidak melakukan transaksi dari pukul 23:00 sampai 01:00
          </TextDesc>
        </Container>
      </Tab.Container>
    </Tab.Pane>
    </Tab.Content>
  );
};

export default TabQris;
