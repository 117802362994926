import React from 'react'
import Informasi from '../../../components/Layout/TnC/Informasi'

const ContentTnC = () => {
   return (
      <>
         <Informasi />
      </>
   )
}

export default ContentTnC
