import styled from 'styled-components'

export const ContainerHeaderPriv = styled.header`
   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: cover;
   padding: 20px;
`

export const Hero = styled.div`
   width: 100%;
   height: 400px;
   background-image: url(${(props) => props.bgImg});
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   background-position: 70% 0;
   
   z-index: 2;

   div {
      padding-top: 175px;
   }
   /* transform: translateZ(-10px) scale(2);
   top: -30%; */
`
