import styled from "styled-components";

export const WrapTable = styled.div`
  Table {
    /* Table Head */
    thead {
      tr {
        font-size: 18px;
        color: rgba(28, 39, 139, 0.48);
        th {
          background-color: #edf6ff;
          border-top: solid #edf6ff;
          border-bottom: solid #edf6ff;
          height: 120px;
        }
        th:nth-child(1) {
          width: 406px;
        }
        th:nth-child(2) {
          max-width: 196px;
        }
        th:nth-child(3) {
          max-width: 164px;
        }

        th:nth-child(4) {
          max-width: 322px;
        }
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4) {
          min-width: 150px;
        }

        /* Desktop */
        @media only screen and (min-width: 768px) {
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(4) {
            padding-left: 20px;
            padding-bottom: 28px;
          }
          th:nth-child(1) {
            padding-left: 50px;
            padding-bottom: 28px;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: #fff;
        td {
          height: 110px;
        }

        td:nth-child(1) {
          padding-left: 17px;
          padding-top: 15px;
        }

        /* Mobile */
        @media only screen and (min-width: 768px) {
          td:nth-child(1) {
            padding-left: 50px;
          }
        }
        /* Mobile */
        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4) {
          padding-left: 17px;
          padding-top: 15px;
        }
      }
    }
  }
`;

export const WrapNamaChannel = styled.div`
  max-width: 300px;

  display: flex;
  /* flex-wrap: wrap; */
  gap: 2rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const WrapVAT = styled.div`
  max-width: 250px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BtnTableBiaya = styled.button`
  width: 91px;
  height: 35px;
  border-radius: 18px;
  border: solid 1px #cfd1d4;
  background-color: #fff;
  line-height: 35px;
`;
