import styled from "styled-components";

export const ContainerHubKami = styled.div`
  /* background-image: linear-gradient(to bottom, #edf6ff, rgba(255, 255, 255, 0)); */
  width: 100%;
  background-color: #edf6ff;
  padding-top: 30px;
  /* background-color: red; */
  height: 100%;
  margin: 0;
  min-height: 25rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-bottom: 100px; */
  @media only screen and (max-width: 768px) {
    margin: 0;
    min-height: 17rem;
    padding-top: 0px;
  }
`;

export const InnerContainer = styled.div`
  /* max-width: 1200px; */
  width: 100%;
  margin: 0;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  /* padding-left: 50px; */

  .content {
    width: 90%;
  margin: 0;
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  padding-left: 75px;
  }

  .shield {
    width: 90%;
  margin: 0;
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 50px;
  gap: 20px;
  color: #2d62ed;
  margin-top: 20px;
  margin-bottom: 50px;

  span {
    color:#44d62c ;
    font-weight: 600;
  }
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    justify-content: center;
  }
  /* gap: 2rem; */
`;

export const ContentLeft = styled.div`
  display: flex;
  width: 55%;
  height: 100%;
  padding-top: 3rem;
  flex-direction: column;
  gap: 1rem;
  /* background-color: green; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ContentRight = styled.div`
  display: flex;
  width: 45%;
  height: 370px;
  /* background-color: yellow; */
  background: url(${(props) => props.bgImg}) no-repeat center;

  background-size: 550px 400px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 15rem;
    height: 15rem;
    display: none;
  }
`;

export const ButtonHubKami = styled.button`
  width: 600px;
  height: 119px;
  background-color: transparent;
  position: relative;
  border: none;
  color: #1c278b;
  font-size: 60px;
  font-weight: 600;
  border: none;
  font-family: poppins;
  padding: 5px 0 0 0px;
  text-decoration: underline;
  text-decoration-color: #44d62c;
  text-decoration-thickness: 5px;
  text-align: left;

  &:hover {
    color: #2a0afa;
    text-decoration-color: #26fa0a;
    /* transition: color, text-decoration-color 100ms linear; */
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #1c278b;
  }

  .hover-underline-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 7px;
    bottom: 2px;
    left: 0;
    background-color: #26fa0a;
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 300px;
    height: 100px;
    font-size: 32px;
  }
`;

export const WrapIconCall = styled.div`
  position: absolute;

  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  img {
    height: 44px;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    img {
      height: 25px;
      width: 25px;
    }
    left: 10px;
    top: 5px;
    transform: translate(0, 0);
  }
`;

export const WrapIconMessage = styled(WrapIconCall);

export const ButtonWrap = styled.div`
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;
