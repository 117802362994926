import React from "react";
import { ModelImg } from "../../../components/Layout/PG/HeroPG.styled.js";
import {
  ButtonCustom,
  ButtonWrap,
} from "../../../components/UI/Button/Button.styled";
import {
  JumbotronDescription,
  JumbotronTitle,
  LeftContent,
} from "../../../components/UI/Jumbotron.styled";
import { TextDesc, TextTitle } from "../../../components/UI/Text.styled";

import ModelImage from "../../../assets/images/Emoney/pic-header.png";
import { Link } from "react-router-dom";
const JumbotronContentDb = () => {
  return (
    <>
      <LeftContent marginTop={"100px"} >
        <JumbotronTitle>
          <TextTitle maxwidth={"628px"} size={"28px"} fw="600" aligntext="left" >
          DISBURSEMENT & REMITTANCE
          </TextTitle>
        </JumbotronTitle>
        <JumbotronDescription className="my-3">
          <TextDesc aligntext="center" size="24px">
          Distribusikan dana anda dalam jumlah dan penerima yang beragam dalam satu waktu sekaligus. Dana yang ditransfer dapat dicairkan secara real-time dari mana saja
          </TextDesc>
        </JumbotronDescription>
        <ButtonWrap>
          <Link to="../hubungi-sales">
            {/* <ButtonCustom
              marright="16px"
              bg="#44d62c"
              color="#1c278b"
              style={{ textDecoration: "none" }}
            >
              <TextDesc fw="600">Hubungi Kami</TextDesc>
            </ButtonCustom> */}
          </Link>
        </ButtonWrap>
      </LeftContent>
      {/* <ModelImg >
            <img src={ModelImage}></img>
         </ModelImg> */}
    </>
  );
};

export default JumbotronContentDb;
