import React from 'react'
import {
   Jumbotron,
   JumbotronContainer,
} from '../../../components/UI/Jumbotron.styled'
import BackgroundPG from '../../../assets/images/background_pg.png'
import NavbarBootstrap from '../../../components/UI/NavbarBootstrap'
import JumbotronContentEko from '../Content/JumbotronContentEko'
import NewNavbar from '../../../components/UI/NewNavbar'

const HeaderEko = () => {
   return (
      <Jumbotron imageurl={BackgroundPG}>
         <NewNavbar />
         <JumbotronContainer>
            <JumbotronContentEko />
         </JumbotronContainer>
      </Jumbotron>
   )
}

export default HeaderEko
