import React from 'react'
import TabsFaq from '../../../components/Layout/Faq/Tabs/TabsFaq'

const ContentFaq = () => {
   return (
      <>
         <TabsFaq />
      </>
   )
}

export default ContentFaq
