import styled from 'styled-components'

export const ContainerTab = styled.div`
   width: 100%;
   height: auto;
   text-align: left;
   margin-top: 50px;
   padding: 20px;
`

export const TabTitle = styled.button`
   width: 260px;
   height: 65px;
   border-radius: 40px;
   border: none;
   background-color: #fff;
   transition:  ease-out 1s;
   /* if aktif */
   background-color: ${(props) => (props.isActive ? '#edf2fe' : '#fff')};

   &:hover {
      /* background-color: #edf2fe; */
      box-shadow: inset 400px 0 0 0 #e2f2fe;
      a {
         color: #2d62ed;
      }
   }
   .link {
      color: ${(props) => (props.isActive ? '#2d62ed' : '#93969d')};
   }
`

export const ContentLeft = styled.div`
   display: flex;
   flex-direction: column;
   gap: 1rem;

   @media only screen and (max-width: 768px) {
      /* display: none; */
      /* align-items: center; */
      /* justify-content: center; */
   /* padding-left: 5%; */
   margin: 0 auto;

   }
`

export const WrapTab = styled.div`
   display: flex;
   /* flex-wrap: wrap; */
   gap: 110px;
   margin-bottom: 25px;

   @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-top: 10px;
      gap: 30px
   }
`

export const ContentRight = styled.div`
   .tabs-mobile {
      display: none;
   }

   @media only screen and (max-width: 768px) {
      width: 100%;
      .tabs-mobile {
         display: block;
      }

      .tabs-desktop {

      }
   }
`

// START  Nested Tab (Payment Gateway)

export const HeadTab = styled.div`
   ul {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      // justify-content: flex-start;
      list-style-type: none;
      text-transform: uppercase;
      
      li {
         margin-right: 20px;
      }

      a {
         color: #cfd1d4;
      }

      a:hover {
         color: #44d62c;
      }

      @media only screen and (max-width: 768px) {
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         /* padding: 0px; */
         margin-left: -15px;
      }
   }
`

// END Nested Tab (Payment Gateway)
