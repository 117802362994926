import React from 'react'
import {
   ButtonCustom,
   ButtonWrap,
} from '../../../components/UI/Button/Button.styled'
import {
   JumbotronDescription,
   JumbotronTitle,
   LeftContent,
} from '../../../components/UI/Jumbotron.styled'
import { TextDesc, TextTitle } from '../../../components/UI/Text.styled'
import { ModelImg } from '../../../components/Layout/PG/HeroPG.styled'
import { LogoBg } from '../../../components/UI/Logo.styled'
import { Link } from 'react-router-dom'

import ModelImage from '../../../assets/images/Emoney/pic-header.png'
import LogoMbayar from '../../../assets/images/LogoMbayar.png'
import LogoPlaystore from '../../../assets/images/LogoGooglePlay.png'
import LogoAppstore from '../../../assets/images/LogoAppStore.png'

const JumbotronContentEmoney = () => {
   return (
         <LeftContent marginTop={'100px'}  >
            <JumbotronTitle>
                 {/* <LogoBg
                  width="100%"
                  height="45px"
                  display={"flex"}
                  justifyContent={"flex-start"}
               >
               <img src={LogoMbayar} ></img>
               </LogoBg>  */}
               
               <TextTitle
                  // maxwidth={'80%'}
                  size={'28px'}
                  style={{ lineHeight: '4rem' }}
                  className="my-4"
                  aligntext="center"
                  bold
               >
                  Account Linkage Uang Elektronik
               </TextTitle>
            </JumbotronTitle>
            <TextDesc
            size={'24px'} aligntext="center">
            Aplikasi apapun yang anda miliki, bisa mempunyai fitur uang elektronik
            </TextDesc>
            {/* <ButtonWrap>
               <Link to="../hubungi-sales">
                  <ButtonCustom
                     className="fw-bold"
                     marright="16px"
                     bg="#44d62c"
                     color="#1c278b"
                  >
                     Hubungi Kami 
                  </ButtonCustom>
               </Link>
            </ButtonWrap> */}
            {/* <div className="d-flex gap-3 mt-4">
               <a
                  href="https://play.google.com/store/search?q=mbayar&c=apps"
                  target="_blank"
               >
                  <LogoBg ImgBg={LogoPlaystore} width="134px" height="40px" />
               </a>
               <a
                  href="https://apps.apple.com/us/app/m-bayar/id1436288939"
                  target="_blank"
               >
                  <LogoBg ImgBg={LogoAppstore} width="134px" height="40px" />
               </a>
            </div> */}

         {/* <ModelImg >
            <img src={ModelImage}></img>
         </ModelImg> */}
         </LeftContent>

   )
}

export default JumbotronContentEmoney
