import React, { useState, useEffect, Suspense } from "react";
import "./NewNavbarHome.css";

import Logoe2payrazer from "../../assets/images/newlogo-e2pay.png";
import iconPlus from "../../assets/images/Icon feather-plus-circle.svg";
import IconActiveNavbar from "../../assets/images/icon-active-navbar.svg";
import IconActiveDropdown from "../../assets/images/icon-active-dropdown-navbar.svg";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { Link, NavLink } from "react-router-dom";
import arrowdown from "../../assets/images/arrowdown.svg";
import { useTranslation, Trans } from "react-i18next";
import newLogo from "../../assets/images/e2pay_fiuu.svg";

const NewNavbarHome = (props) => {
  // const locale = navigator.language
  // console.log("ini locale", locale)
  const [toggle, setToggle] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const toggleHandler = (e) => {
    setToggle(e.target.checked);
  };
  //  const urlCodeDisbursement = /[^/]*$/.exec(window.location.href)[0];
  let urlCode = window.location.pathname;
  console.log("path sekarang", urlCode);
  //  let urlDB = urlCodeDisbursement === "payment-gateway#disbursement" ? "payment-gateway#disbursement" : "";
  //  let urlBiller = urlCodeBiller==="payment-gateway#biller-gateway" ? "payment-gateway#biller-gateway" : "";

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let navbar = document.querySelector("nav");
      navbar.classList.toggle("navbarOnScroll", window.scrollY > 730);
    });
  }, []);

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }
  //  useEffect(() => {
  //    const interval = setInterval(() => {
  //      urlCode = /[^/]*$/.exec(window.location.href)[0];
  //    }, 1000);
  //    console.log("ini url biller", urlCode);
  //  }, [])

  const handleClick = () => {
    window.location.replace("https://merchant.razer.com/v3/channels/");
  };

  const { t, i18n } = useTranslation();

  const buttonlanguage = i18n.language;

  // const generateLanguage = (locale, location) => {
  //   const ROUTE = "/:locale/:path*";
  //   const definePath = compile(ROUTE);
  //   const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);

  //   console.log(routeComponents)

  //   let subPaths = null;
  //   if (routeComponents && routeComponents[2]) {
  //     subPaths = routeComponents[2].split("/");
  //   }

  //   return definePath({
  //     locale,
  //     path: subPaths,
  //   });
  // };

  const baseUrl = "/";

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Suspense fallback="loading">
        {/* <div className="topnav" id="myTopnav">
        home
      </div> */}
        <nav  
          style={{ backgroundColor: props.backgroundColor }}
          className="mainnav"
        >
          <div className="container-nav">
            <div className="logo">
              <Link to="/">
                <img className="new-logo" src={newLogo} />
                <img className="old-logo" src={newLogo} />
              </Link>
              <ul>
                {/* <li>
                  <NavHashLink to="/hubungi-sales">
                    <button className="btn-tanya">{t('contact')}</button>
                    <h2>{t("Welcome to React")}</h2>
                  </NavHashLink>
                </li> */}
                <li>
                  <button
                    style={{
                      backgroundColor:
                        buttonlanguage === "de" ? "transparent" : "transparent",
                    }}
                    className="btn-language"
                    onClick={() => changeLanguage("de")}
                  >
                    ID
                  </button>
                </li>
                {/* <li>
                  <button
                   style={{backgroundColor: buttonlanguage === "en" ? '#44d62c' : 'transparent', textAlign: "center", padding: "8px 10px 5px 8px"}}
                    className="btn-language"
                    onClick={() => changeLanguage("en")}
                  >
                  EN
                  </button>
                </li> */}
              </ul>
            </div>

            <ul className={`${toggle && "slide"} wrap navigation`}>
              {/* <ul> */}
              <li className="tentang">
                <HashLink to="" className="d-flex gap-1">
                  <span>{t("company")}&nbsp;</span>
                  <span>
                    <img src={arrowdown} />
                  </span>
                </HashLink>

                <div className="hover-dropdown">
                  <NavHashLink to="/about-us">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("about")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/kenapa-e2pay">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("why")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/artikel">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          Artikel
                        </span>
                      </span>
                    )}
                  </NavHashLink>
                  {/* <NavHashLink to="/panduan-pembayaran">
                      {({ isActive }) => (
                        <span className="d-flex gap-2">
                          <span
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            <img src={IconActiveDropdown} />
                          </span>
                          <span style={{ color: isActive ? "#44d62c" : "" }}>
                            Panduan Pembayaran
                          </span>
                        </span>
                      )}
                    </NavHashLink> */}

                  {/* <NavHashLink to="/karir">
                      {({ isActive }) => (
                        <span className="d-flex gap-2">
                          <span
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            <img src={IconActiveDropdown} />
                          </span>
                          <span style={{ color: isActive ? "#44d62c" : "" }}>
                            {t("careers")}
                          </span>
                        </span>
                      )}
                    </NavHashLink>                  */}
                </div>
              </li>

              {/* <li className="tentang-responsive">
          <button className="accordion">Tentang Kami</button>
          <div className="panel">
          <NavHashLink to="/about-us">
                {({ isActive }) => (
                  <span className="d-flex gap-2">
                    <span style={{ display: isActive ? "block" : "none" }}>
                      <img src={IconActiveDropdown} />
                    </span>
                    <span style={{ color: isActive ? "#44d62c" : "" }}>
                      Tentang E2Pay
                    </span>
                  </span>
                )}
              </NavHashLink>

              <NavHashLink to="/kenapa-e2pay">
                {({ isActive }) => (
                  <span className="d-flex gap-2">
                    <span style={{ display: isActive ? "block" : "none" }}>
                      <img src={IconActiveDropdown} />
                    </span>
                    <span style={{ color: isActive ? "#44d62c" : "" }}>
                      Kenapa E2Pay
                    </span>
                  </span>
                )}
              </NavHashLink>
            <NavHashLink to="/karir">
              {({ isActive }) => (
                <span className="d-flex gap-2">
                  <span style={{ display: isActive ? "block" : "none" }}>
                    <img src={IconActiveDropdown} />
                  </span>
                  <span style={{ color: isActive ? "#44d62c" : "" }}>
                    Karir
                  </span>
                </span>
              )}
            </NavHashLink>
          </div>
          </li> */}

              <div
                className="tentang-responsive accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Perusahaan
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <NavHashLink to="/about-us">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Tentang Kami
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <NavHashLink to="/kenapa-e2pay">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Mengapa Kami
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                      
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Terima Pembayaran
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <NavHashLink to="/payment-gateway">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              className="icon-dot"
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Payment Channels (Indonesia)
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <a
                        href="https://merchant.razer.com/v3/channels/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t("pgasean")}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Transfer Dana
                    </button>
                  </h2>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Tagihan & Voucher
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <NavHashLink to="//biller-gateway#bills">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Bayar Tagihan
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <NavHashLink to="/biller-gateway#voucher">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Beli Voucher
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Uang Elektronik
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                  
                    <NavHashLink to="/emoney/b2b-account-linkage">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              className="icon-dot"
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              B2B Account Linkage
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                      <NavHashLink to="/emoney/mbayar-app">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              className="icon-dot"
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              M-Bayar App
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                      {/* <NavHashLink to="/emoney/mbayar-app">
                      {({ isActive }) => (
                        <span className="d-flex gap-2">
                          <span
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            <img src={IconActiveDropdown} />
                          </span>
                          <span style={{ color: isActive ? "#44d62c" : "" }}>
                            M-Bayar App
                          </span>
                        </span>
                      )}
                    </NavHashLink> */}

                      <NavHashLink to="/emoney/mbayar-apps/download" target="_blank">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              className="icon-dot"

                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Download M-Bayar
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Bantuan
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <NavHashLink to="/hubungi-sales">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Hubungi Kami
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <NavHashLink to="/faq">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              FAQ
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <NavHashLink to="/produk/biaya">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Biaya
                            </span>
                          </span>
                        )}
                      </NavHashLink>

                      <NavHashLink to="/documentation">
                        {({ isActive }) => (
                          <span className="d-flex gap-2">
                            <span
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <img src={IconActiveDropdown} />
                            </span>
                            <span style={{ color: isActive ? "#44d62c" : "" }}>
                              Dokumentasi & API
                            </span>
                          </span>
                        )}
                      </NavHashLink>
                    </div>
                  </div>
                </div>
              </div>

              <li className="tentang">
                <HashLink to="" className="d-flex gap-1">
                  <span>{t("pg")}&nbsp;</span>
                  <span>
                    <img src={arrowdown} />
                  </span>
                </HashLink>

                <div className="hover-dropdown">
                  <NavHashLink to="/payment-gateway">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("pgid")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <a
                    href="https://merchant.razer.com/v3/channels/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{t("pgasean")}</span>
                  </a>
                  {/* <NavHashLink to="" onClick={handleClick} rel="noopener" target="_blank">
                  {({ isActive }) => (
                    <span className="d-flex gap-2">
                      <span style={{ display: isActive ? "block" : "none" }}>
                        <img src={IconActiveDropdown} />
                      </span>
                      <span style={{ color: isActive ? "#44d62c" : "" }}>
                        Payment Channels (ASEAN)
                      </span>
                    </span>
                  )}
                </NavHashLink> */}
                </div>
              </li>
              <li className="tentang">
                <HashLink to="/disbursement" className="d-flex gap-1">
                  <span>{t("db")}&nbsp;</span>
                  {/* <span>
                    <img src={arrowdown} />
                  </span> */}
                </HashLink>

                {/* <div className="hover-dropdown">
                  <NavHashLink to="/disbursement">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("localPayout")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "none" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "" : "" }}>
                          {t("remittance")}
                        </span>
                      </span>
                    )}
                  </NavHashLink> */}

                {/* <NavHashLink to="/karir">
                  {({ isActive }) => (
                    <span className="d-flex gap-2">
                      <span style={{ display: isActive ? "block" : "none" }}>
                        <img src={IconActiveDropdown} />
                      </span>
                      <span style={{ color: isActive ? "#44d62c" : "" }}>
                        Karir
                      </span>
                    </span>
                  )}
                </NavHashLink> */}
                {/* </div> */}
              </li>
              <li className="tentang">
                <HashLink to="" className="d-flex gap-1">
                  <span>{t("bg")}&nbsp;</span>
                  <span>
                    <img src={arrowdown} />
                  </span>
                </HashLink>

                <div className="hover-dropdown">
                  <NavHashLink to="/biller-gateway#bills">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("billPayments")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/biller-gateway#voucher">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "none" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "" : "" }}>
                          {t("voucherPurchase")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  {/* <NavHashLink to="/karir">
                  {({ isActive }) => (
                    <span className="d-flex gap-2">
                      <span style={{ display: isActive ? "block" : "none" }}>
                        <img src={IconActiveDropdown} />
                      </span>
                      <span style={{ color: isActive ? "#44d62c" : "" }}>
                        Karir
                      </span>
                    </span>
                  )}
                </NavHashLink> */}
                </div>
              </li>
              <li className="tentang">
                <HashLink to="" className="d-flex gap-1">
                  <span>{t("emoney")}&nbsp;</span>
                  <span>
                    <img src={arrowdown} />
                  </span>
                </HashLink>

                <div className="hover-dropdown">
                  <NavHashLink to="/emoney/b2b-account-linkage">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("b2b")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/emoney/mbayar-app">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("mbayarapp")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/emoney/mbayar-apps/download" target="_blank">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          Download M-Bayar
                        </span>
                      </span>
                    )}
                  </NavHashLink>
                </div>
              </li>
              <li className="tentang">
                <HashLink to="" className="d-flex gap-1">
                  <span>{t("supports")}&nbsp;</span>
                  <span>
                    <img src={arrowdown} />
                  </span>
                </HashLink>

                <div className="hover-dropdown">
                  <NavHashLink to="/hubungi-sales">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("contact")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/faq">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          FAQ
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  <NavHashLink to="/produk/biaya">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          {t("listPrice")}
                        </span>
                      </span>
                    )}
                  </NavHashLink>

                  {/* <NavHashLink to="/documentation">
                    {({ isActive }) => (
                      <span className="d-flex gap-2">
                        <span style={{ display: isActive ? "block" : "none" }}>
                          <img src={IconActiveDropdown} />
                        </span>
                        <span style={{ color: isActive ? "#44d62c" : "" }}>
                          Dokumentasi API
                        </span>
                      </span>
                    )}
                  </NavHashLink> */}
                </div>
              </li>
              {/* </ul> */}
            </ul>

            <div className="menu-toggle">
              <input type="checkbox" onClick={toggleHandler} />
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </nav>
      </Suspense>
    </>
  );
};

export default NewNavbarHome;
