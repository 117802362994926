import React from "react";
import { ModelImg } from "../../../components/Layout/PG/HeroPG.styled.js";
import {
  ButtonCustom,
  ButtonWrap,
} from "../../../components/UI/Button/Button.styled";
import {
  JumbotronDescription,
  JumbotronTitle,
  LeftContent,
} from "../../../components/UI/Jumbotron.styled";
import { TextDesc, TextTitle } from "../../../components/UI/Text.styled";

import ModelImage from "../../../assets/images/Emoney/pic-header.png";
import { Link } from "react-router-dom";
const JumbotronContentPg = () => {
  return (
    <>
      <LeftContent marginTop={"100px"} >
        <JumbotronTitle>
          <TextTitle maxwidth={"808px"} size={"28px"} fw="600" aligntext="center" >
            Terima pembayaran dari ratusan juta konsumen <br /> Di Asia Tenggara
          </TextTitle>
        </JumbotronTitle>
        <JumbotronDescription className="my-3">
          <TextDesc aligntext="center" size="24px">
            Bidik potensi pembayaran atas layanan bisnis anda dari ratusan juta
            nasabah perbankan, pelanggan telekomunikasi selular, dan segment
            masyarakat lain di tanah air dan seluruh negara ASEAN.
          </TextDesc>
        </JumbotronDescription>
        {/* <ButtonWrap>
          <Link to="../hubungi-sales">
            <ButtonCustom
              marright="16px"
              bg="#44d62c"
              color="#1c278b"
              style={{ textDecoration: "none" }}
            >
              <TextDesc fw="600">Hubungi Kami</TextDesc>
            </ButtonCustom>
          </Link>
        </ButtonWrap> */}
      </LeftContent>
      {/* <ModelImg >
            <img src={ModelImage}></img>
         </ModelImg> */}
    </>
  );
};

export default JumbotronContentPg;
