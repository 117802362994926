import styled from 'styled-components'

export const ContainerFooter = styled.div`
   height: 400px;
   background-color: #1c278b;
   padding-top: 75px;
   /* margin-top: 100px; */
   /* margin-bottom: 50px; */
   position: relative;
   z-index: 20;


   #dots {
         color: white;
   }
   @media screen and (max-width: 768px) {
      height: 600px;
      padding-top: 50px;
    
   }

   
`

export const WrapInnerCont = styled.div`
   width: 90%;
   /* min-height: 300px; */
   /* margin: 0 auto; */
   margin-left: 75px;

   @media screen and (max-width: 768px) {
      margin-left: 10px;
   }
`

export const InnerContainer_2 = styled.div`
   display: flex;
   justify-content: ${(props) => props.justifyContent};
   flex-wrap: wrap;
  
`

export const KontakKami = styled.div`
  width: ${(props) => props.width};
  color: ${(props) => props.color || '#ffffff'};
  align-self: ${(props) => props.alignself};
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-bottom: 15px;
  }
`;

export const TergabungDalam = styled.div`
  width: ${(props) => props.width};
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-self: ${(props) => props.alignself};
  justify-content: ${(props) => props.justifyContent}; 
  @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
   }
`;

export const SubKontak = styled.div`
  margin-top: ${(props) => props.marginTop || "63px"}; 

  p {
   padding-top: 10px;

   a {
      text-decoration: none;
   }

   span {
      color: #44d62c;
      font-weight: 600;
   }
  }


   /* margin-top: 63px; */
`

export const FooterMenu = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 10px
`

export const SubMenu = styled.div`
   display: flex;
   flex-direction: column;
   gap: 5px;
`

export const LogoWrap = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    justify-content: flex-start;
  }
`;

export const LogoSocmed = styled.div`
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-image: url(${(props) => props.bgImg});
   background-color: #fff;
   background-repeat: no-repeat;
   background-position: center;

   &:hover {
      background-color: grey;
   }
`

export const LogoTergabung = styled.div`
  width: 383px;
  height: 128px;
  background-size: contain;
  background-image: url(${(props) => props.bgImg});
  background-color: none;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    background-color: none;
  }
`;

export const LineFooter = styled.hr`
   border: 2px solid #2d62ed;
` 