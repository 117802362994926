import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab } from "./Tabs/TabsFaq.styled";

import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { Link } from "react-router-dom";
const TabPanePaymentGateway = () => {
  return (
    <Tab.Pane eventKey="payment-gateway-desktop">
      <Tab.Container id="left-tabs-example" defaultActiveKey="registrasi">
        <HeadTab>
          <ul>
            <li>
              <Nav.Link eventKey="registrasi">
                <TextTitle size="16px">registrasi</TextTitle>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link eventKey="integrasi">
                <TextTitle size="16px">integrasi</TextTitle>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link eventKey="biaya-layanan">
                <TextTitle size="16px">biaya layanan</TextTitle>
              </Nav.Link>
            </li>
            {/* <li>
              <Nav.Link eventKey="support">
                <TextTitle size="16px">support</TextTitle>
              </Nav.Link>
            </li> */}
          </ul>
        </HeadTab>
        <Tab.Content style={{ maxWidth: "628px" }}>
          {/* Registrasi */}

          <Tab.Pane eventKey="registrasi">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Registrasi
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">
                Siapa saja yang bisa bergabung menjadi merchant ?
              </TextDesc>
              <TextDesc className="mt-2">
              Semua badan usaha dalam bentuk UD, CV, PT maupun perorangan bisa menjadi merchant
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Dokumen apa saja yang dibutuhkan untuk proses integrasi
              </TextDesc>
              <TextDesc className="mt-2">KTP dan NPWP</TextDesc>
              {/* <TextDesc className="mt-2">KTP, NPWP, SIUP, Dokumen </TextDesc> */}
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apa saja solusi yang dimiliki E2Pay Razer Fintech
              </TextDesc>
              <TextDesc className="mt-2">
                Satu ekosistem yang terdiri dari Payment Gateway, Biller
                Gateway, DIsbursment, dan Digital Wallet M-Bayar
              </TextDesc>
            </div>
          </Tab.Pane>
          {/* Integrasi */}

          <Tab.Pane eventKey="integrasi">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Integrasi
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">Bagaimana proses integrasinya</TextDesc>
              <TextDesc className="mt-2">
                Kami memiliki beragam plugin, API dan ISO yg dapat di sesuaikan
                dengan kebutuhan merchant
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah tersedia plugin</TextDesc>
              <TextDesc className="mt-2">
                Magento, WooCommerce, Prestashop dan Opencart
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Apakah tersedia Sandbox untuk bisa integrasi
              </TextDesc>
              <TextDesc className="mt-2">
                Kami memiliki environment yg dapat di coba untuk di
                integrasikan, silahkan untuk menghubungi sales kami untuk
                diskusi lebih lanjut
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana tingkat keamanan transaksi di E2pay
              </TextDesc>
              <TextDesc className="mt-2">
                E2pay sebagai perusahaan Payment gateway sudah tersertifikasi
                PCI DSS dan fraud screening system untuk menjaga
                transaksi online
              </TextDesc>
            </div>
          </Tab.Pane>

          {/* Biaya Layanan */}

          <Tab.Pane eventKey="biaya-layanan">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Biaya Layanan
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">
                Channel pembayaran apa saja yg saat ini tersedia
              </TextDesc>
              <TextDesc className="mt-2">
                Kartu kredit, Internet Banking, Mobile Banking, Virtual account,
                Emoney, Convenience store
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">
                Berapa biaya yg di kenakan didalam menggunakan layanan
              </TextDesc>
              <TextDesc className="mt-2">
                Untuk melihat detail biaya, klik{" "}
                <Link to="/produk/biaya">halaman biaya</Link>
              </TextDesc>
            </div>

            <div className="mt-3">
              <TextDesc fw="600">Bagaimana proses perhitungannya Fee</TextDesc>
              <TextDesc className="mt-2">
                Fee di hitung dari total transaksi sukses dikali dengan fee per
                transaksi sesuai dengan channel payment yg di gunakan
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Apakah ada biaya set up dan monthly</TextDesc>
              <TextDesc className="mt-2">
                Tidak ada biaya set up dan montly hanya per transaksi
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Berapa lama proses pencairan dana</TextDesc>
              <TextDesc className="mt-2">
                Untuk proses pencairan dana memakan waktu kurang lebih 3 sampai
                dengan 7 hari kerja
              </TextDesc>
            </div>
          </Tab.Pane>

          {/* Support */}

          <Tab.Pane eventKey="support">
            <TextTitle size="22px" color="#27328f" fw="bold">
              Support
            </TextTitle>
            <div className="mt-3">
              <TextDesc fw="600">
                Bagaimana kalau ada kendala di dalam layanan
              </TextDesc>
              <TextDesc className="mt-2">
                Kami memiliki layanan support 24 jam yg akan membantu merchant
              </TextDesc>
            </div>
            <div className="mt-3">
              <TextDesc fw="600">Contact Us</TextDesc>
              <TextDesc className="mt-2">
                021-5292-0139 ext. 333 ( jam kerja )
              </TextDesc>
              <TextDesc className="mt-2">
                021-5292-0139 ext. 300 ( selain jam kerja )
              </TextDesc>
              <TextDesc className="mt-2">sales@e2pay.co.id</TextDesc>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  );
};

export default TabPanePaymentGateway;
