import styled from "styled-components";

export const Content2 = styled.div`
  max-width: 1080px;
  min-height: 700px;
  margin: 0px auto 0 auto;
  padding: 10px;
  display: ${(props) => (props.flex ? "flex" : "")};
  flex-wrap: ${(props) => (props.flex ? "wrap" : "")};
  justify-content: ${(props) => (props.flex ? "flex-end" : "")};
  position: relative;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0;
  }
`;

export const ContainTextContent2 = styled.div`
  width: 536px;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
export const ContentCard = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: 2;
  background-image: url(${(props) => props.bgImg});
  background-size: cover;
  @media only screen and (max-width: 768px) {
    bottom: ${(props) => props.bottomsm};
    top: ${(props) => props.topsm};
  }
`;

export const CardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
  & img {
    width: 26px;
    height: 26px;
  }
  & div {
    align-self: flex-end;
    transition: transform 0.2s;
  }
`; 

export const Bubble1 = styled.div`
  position: absolute;
  top: -100px;
  right: -275px;
  z-index: 3;
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Bubble2 = styled.div`
  position: absolute;
  bottom: -100px;
  left: -150px;
  z-index: 3;

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Bg1 = styled.div`
  position: absolute;
 top: 50px;
left: -200px;
z-index: -1;

img {
  width: 125%;
  height: 125%;
}
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Bg2 = styled.div`
  position: absolute;
  top: 600px;
  left: -150px;
  z-index: 1;

  img {
    width: 125%;
    height: 125%;
  }
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ParallaxWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  transform-style: preserve-3d;

  &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transform: translateZ(-1px) scale(3) ;
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;



export const RegularWrapper = styled.div`
  z-index: 2;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const TaglineCard = styled.div`
 width:300px ;
 height: 200px;
 display: flex;
 border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
  background-color: #fff;
  position: relative;
  z-index: 2;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  padding: 82px 25.8px 15px 31px;
  transition: transform .5s;
  
  &:hover {
  transform: scale(1.1);
  }

  div {
    width: 100%;
    height: 100%  ;
  }

  p {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: var(--272-d-3-b-text);
  
  }

  img#arrow {
    width: 25px;
  height: 25px;
  position: absolute;
  right: 30px;
  bottom: 30px;

  &:hover {
    cursor: pointer;
  }
  }

  img#cashless{
    position: absolute;
    width: ${(props) => props.width} ;
    height: ${(props) => props.height};
    top: -60px;
  }

  img#world{
    position: absolute;
    width: ${(props) => props.width} ;
    height: ${(props) => props.height};
    top: -60px;
  }

  img#finance{
    position: absolute;
    width: ${(props) => props.width} ;
    height: ${(props) => props.height};
    top: -60px;
  }

  @media only screen and (max-width: 768px) {
    position: relative;
    margin-bottom: 30px;
    margin-right: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  `;