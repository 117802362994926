import styled from 'styled-components'

export const Container = styled.div`
ul {
    padding-left: 35px;
}

img {
    width: 100%;
}

`