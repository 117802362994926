import LogoTokped from "../../../assets/images/PaymentChannelLogo/LogoTokopedia.png";
import LogoTiket from "../../../assets/images/PaymentChannelLogo/LogoTiket.png";
import LogoBlibli from "../../../assets/images/PaymentChannelLogo/LogoBlibli.svg";
import LogoBukalapak from "../../../assets/images/PaymentChannelLogo/LogoBukalapak.png";
import LogoJakmall from "../../../assets/images/PaymentChannelLogo/LogoJakmall.png";
import LogoAirpaz from "../../../assets/images/PaymentChannelLogo/LogoAirpaz.png";
import LogoGramedia from "../../../assets/images/PaymentChannelLogo/LogoGramedia.png";
import LogoHokben from "../../../assets/images/PaymentChannelLogo/LogoHokben.png";
import LogoTraveloka from "../../../assets/images/PaymentChannelLogo/LogoTraveloka.png";
import LogoGarudaIndonesia from "../../../assets/images/PaymentChannelLogo/LogoGarudaIndonesia.png";
import LogoAirasia from "../../../assets/images/PaymentChannelLogo/LogoAirasia.svg";
import LogoCitilink from "../../../assets/images/PaymentChannelLogo/LogoCitilink.png";
import LogoVenteny from "../../../assets/images/PaymentChannelLogo/LogoVenteny.png";
import LogoFinansialku from "../../../assets/images/PaymentChannelLogo/LogoFinansialku.png";
import LogoUnipin from "../../../assets/images/PaymentChannelLogo/LogoUnipin.svg";
import LogoLinxchat from "../../../assets/images/PaymentChannelLogo/LogoLinxchat.png";
import LogoGreatday from "../../../assets/images/PaymentChannelLogo/LogoGreatday.png";
import LogoSmartfren from "../../../assets/images/PaymentChannelLogo/LogoSmartfren.png";
import LogoDanai from "../../../assets/images/PaymentChannelLogo/LogoDanai.png";
import LogoTanamera from "../../../assets/images/PaymentChannelLogo/LogoTanamera.png";
import LogoDompetDhuafa from "../../../assets/images/PaymentChannelLogo/LogoDompetDhuafa.png";
import LogoRumahyatim from "../../../assets/images/PaymentChannelLogo/LogoRumahyatim.png";
import LogoGemala from "../../../assets/images/PaymentChannelLogo/LogoGemala.png";
import LogoMasihBanyak from "../../../assets/images/PaymentChannelLogo/LogoMasihBanyak.png";

const LogoData = [
  {
    Name: "Tokopedia",
    Image: LogoTokped,
  },
  {
    Name: "Tiket",
    Image: LogoTiket,
  },
  {
    Name: "Blibli",
    Image: LogoBlibli,
  },
  {
    Name: "Bukalapak",
    Image: LogoBukalapak,
  },
  {
    Name: "Jakmall",
    Image: LogoJakmall,
  },
  {
    Name: "Airpaz",
    Image: LogoAirpaz,
  },
  {
    Name: "Gramedia",
    Image: LogoGramedia,
  },
  {
    Name: "Hokben",
    Image: LogoHokben,
  },
  {
    Name: "Traveloka",
    Image: LogoTraveloka,
  },
  {
    Name: "GarudaIndonesia",
    Image: LogoGarudaIndonesia,
  },
  {
    Name: "Airasia",
    Image: LogoAirasia,
  },
  {
    Name: "Citilink",
    Image: LogoCitilink,
  },
  {
    Name: "Venteny",
    Image: LogoVenteny,
  },
  {
    Name: "Finansialku",
    Image: LogoFinansialku,
  },
  {
    Name: "Unipin",
    Image: LogoUnipin,
  },
  {
    Name: "Linxchat",
    Image: LogoLinxchat,
  },
  {
    Name: "Greatday",
    Image: LogoGreatday,
  },
  {
    Name: "Smartfren",
    Image: LogoSmartfren,
  },
  {
    Name: "Danai",
    Image: LogoDanai,
  },
  {
    Name: "Tanamera",
    Image: LogoTanamera,
  },
  {
    Name: "DompetDhuafa",
    Image: LogoDompetDhuafa,
  },
  {
    Name: "Rumahyatim",
    Image: LogoRumahyatim,
  },
  {
    Name: "Gemala",
    Image: LogoGemala,
  },
  {
    Name: "MasihBanyakLagi",
    Image: LogoMasihBanyak,
  },
  
];

export default LogoData

// <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoTokped}
//                      alt="Tokopedia"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage effect="blur" src={LogoTiket} alt="Tiketcom" />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage effect="blur" src={LogoBlibli} alt="Blibli" />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoBukalapak}
//                      alt="Bukalapak"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoJakmall}
//                      alt="Jakmall"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage effect="blur" src={LogoAirpaz} alt="Airpaz" />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoGramedia}
//                      alt="Gramedia"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoHokben}
//                      alt="Hokahoka Bento"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoTraveloka}
//                      alt="Traveloka"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoGarudaIndonesia}
//                      alt="Garuda Indonesia"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoAirasia}
//                      alt="Air Asia"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoCitilink}
//                      alt="Citilink"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoVenteny}
//                      alt="Venteny"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoFinansialku}
//                      alt="Finansialku"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage effect="blur" src={LogoUnipin} alt="Unipin" />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoLinxchat}
//                      alt="Linxchat"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoGreatday}
//                      alt="Greatday"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoSmartfren}
//                      alt="Smartfren"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage effect="blur" src={LogoDanai} alt="Danai" />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoTanamera}
//                      alt="Tanamera"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoDompetDhuafa}
//                      alt="Dompet Dhuafa"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoGemala}
//                      alt="Sekolah Dasar Gemala Ananda"
//                   />
//                </MerchantAktif>
//                <MerchantAktif>
//                   <LazyLoadImage
//                      effect="blur"
//                      src={LogoRumahyatim}
//                      alt="Rumah Yatim"
//                   />
//                </MerchantAktif>