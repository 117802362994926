import React from "react";
import {
  Arrow,
  Info,
  Subarrow,
  TahunWrap,
  WrapInfo,
} from "./VirtualAccount.styled";
import Card from "./Card"
import { TextDesc } from "../../../UI/Text.styled";
// import InfoTahun from "./InfoTahun";
const VirtualAccount = () => {
  return (
    <WrapInfo>
      <Card></Card>
    </WrapInfo>
  );
};

export default VirtualAccount;
