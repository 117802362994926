import styled from "styled-components";

export const TextTitle = styled.h1`
a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
  font-size: ${(props) => props.size || "32px"};
  color: ${(props) => props.color};
  text-align: ${(props) => props.aligntext};
  text-indent: ${(props) => props.indenttext};

  font-weight: ${(props) => props.fw};
  margin-top: ${(props) => props.marginTop};
  letter-spacing: ${(props) => props.letterspacing};
  line-height: ${(props) => props.lineheight || "40px"};


  max-width: ${(props) => props.maxwidth};
  font-family: poppins, sans-serif;
  font-weight: ${(props) => props.semibold && "600"};
  font-weight: ${(props) => props.bold && "700"};

  padding: ${(props) => (props.removePd ? 0 : "")};
  padding: ${(props) => (props.padding)};
  margin: ${(props) => (props.removeMg ? 0 : "")};
  transform: ${(props) => props.transform};
  animation: ${(props) => props.animation};
  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: ${(props) => props.fontsm};
    padding-left: ${(props) => props.plsm};
    width: ${(props) => props.widthsm};
  }
`;


export const SpanTitle = styled.span`
  font-size: ${(props) => props.size || "40px"};
  color: ${(props) => props.color};
  text-align: ${(props) => props.aligntext};
  font-weight: ${(props) => props.fw};
  margin-right: ${(props) => props.marginRight || "10px"};

  max-width: ${(props) => props.maxwidth};

  font-family: poppins, sans-serif;
  font-weight: ${(props) => props.semibold && "600"};
  font-weight: ${(props) => props.bold && "700"};

  padding: ${(props) => (props.removePd ? 0 : "")};
  margin: ${(props) => (props.removeMg ? 0 : "")};
  transform: ${(props) => props.transform};
  animation: ${(props) => props.animation};
  display: inline-block;
  opacity: 0;
  filter: blur(1px);
  /* @keyframes scale {
    100% {
      transform: scale(1);
    }
  } */
  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: ${(props) => props.fontsm};
  }
`;


// @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
// }

export const TextDesc = styled.p`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};


  width: ${(props) => props.width};
  gap: ${(props) => props.gap};
  height: ${(props) => props.height || "100%"};
  font-size: ${(props) => props.size || "16px"};
  max-width: ${(props) => props.maxwidth};
  color: ${(props) => props.color};
  text-align: ${(props) => props.aligntext};
  text-indent: ${(props) => props.indentText};
  font-weight: ${(props) => props.fw};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lineSpacing};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding: ${(props) => props.padding};
  text-decoration: ${(props) => props.textDecoration} ;


  p {
    font-size: ${(props) => props.size || "16px"};
    color: ${(props) => props.color};
  margin-bottom: ${(props) => props.marginBottom};

  }
  ul li {
  padding-left: ${(props) => props.paddingLeft};
  }

  ul {
  padding-left: ${(props) => props.paddingLeft};

  }


  span {
    color: ${(props) => props.color || "#44d62c"};
  }

  .newSpan p {
    color: ${(props) => props.color} !important;
    font-weight: ${(props) => props.weight} !important;
    font-size: ${(props) => props.size} !important;
  }

  &::after {
    content: "${(props) => props.ikon && "\f054"}";
  }

  /* padding: ${(props) => (props.removePd ? 0 : "")};
  margin: ${(props) => (props.removeMg ? 0 : "")}; */

  font-family: poppins, sans-serif;
  font-weight: ${(props) => props.semibold && "600"};
  font-weight: ${(props) => props.bold && "700"};
  font-weight: ${(props) => props.regular && "400"};
  animation: ${(props) => props.animation};
  opacity: 1;
  filter: ${(props) => props.filter};

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0px);
    }
  }
  transform: ${(props) => props.transform};
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 768px) {
    display: ${(props) => props.displaysm};
    /* For mobile phones: */
    width: ${(props) => props.widthsm};

    height: ${(props) => props.height || "auto"};
    font-size: ${(props) => props.fontsm};
    margin-top: ${(props) => props.mtsm};
    margin-bottom: ${(props) => props.mbsm};
    padding-top: ${(props) => props.ptsm};
    text-align: ${(props) => props.aligntextsm};
  margin-left: ${(props) => props.marginLeft};

  }
`;
