import React from 'react'
import { TextDesc } from '../../UI/Text.styled'
import { CardContent } from './Solusi.styled'

const ContentSolusi = (props) => {
   return (
      <>
         <CardContent>
            <section
               style={{ maxWidth: '266px' }}
               className="d-flex flex-column gap-2 mx-auto"
            >
               <span>
                  <img src={props.ikon} />
               </span>
               <span>
                  <TextDesc size={'16px'} color={'#272d3b'} fw={'bold'}>
                     {props.title}
                  </TextDesc>
               </span>
               <span>
                  <TextDesc
                     size={'14px'}
                     color={'#272d3b'}
                     style={{ lineHeight: '1.8' }}
                  >
                     {props.desc}
                  </TextDesc>
               </span>
            </section>
         </CardContent>
      </>
   )
}

export default ContentSolusi
