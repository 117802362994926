import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  padding-top: 100px;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;

  .bubble {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 575px;
  }

  .bg1 {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 550px;
  }

  .bg2 {
    position: absolute;
    z-index: 1;
    left: -300px;
    bottom: -150px;
  }
`;

export const Title1 = styled.div`
  position: relative;
  top: -225px;
  background-color: #fff;
  width: 110%;
  height: 175px;
  margin: 0;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 15px;
  z-index: 30;

  @media screen and (max-width: 768px) {
    margin-top: 150px;
  }

  p {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--272-d-3-b-text);
  }
`;

export const Title2 = styled.div`
  position: relative;
  width: 110%;
  height: 200px;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--272-d-3-b-text);
  }
`;

export const Row1 = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-bottom: 25px;
  margin-top: -200px;
  position: relative;
  z-index: 3;
  flex-wrap: wrap !important;
  padding: 0 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const AboutCard1 = styled.div`
  width: 260px;
  height: 260px;
  margin: 0 16px 0 0;
  padding: 26px 25px 49px;
  border-radius: 20px;
  background-color: #fff;
  /* border: 1px solid black; */
  box-shadow: 0 0 20px 20px #edf6ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 5px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #2d62ed;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: center;
    color: #44525b;
  }
`;

export const CardContent = styled.div`
  width: 320px;
  height: 375px;
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  /* margin-bottom: 25px; */
  /* border: 2px solid #ededed; */
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background-color: #f5f3f0;
  }

  p {
    padding: 0 8px;
    margin: 0;
  }

  #title {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #272d3b;
  }

  #desc {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #44525b;
  }

  .name {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #93969d;
    font-size: 14px;
  }

  #thumbnail {
    padding: 5px;
width: 98%;
height: auto;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;


export const CardLists = styled.div`
  width: 100%;
  // border: solid red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  /* margin-bottom: 25px; */
  cursor: pointer;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Paginations = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;

 button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: #93969d;
    border: 3px solid white;
}

button.active {
    font-weight: 900;
    border: 3px solid white;
    background: #1c278b;
    color: white;
}
`