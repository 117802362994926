import React from 'react'
import InfoPerusahaan from '../../../components/Layout/About/InfoPerusahaan'
import { Container } from '../../../components/Layout/About/About.styled'
import CertificateContent from '../../../components/Layout/About/CertificateContent'
import NarasiE2payrazer from '../../../components/Layout/About/NarasiE2payrazer'

const ContentAbout = () => {
   return (
      <Container>
         <InfoPerusahaan />
         {/* <CertificateContent />
         <NarasiE2payrazer /> */}
      </Container>
   )
}

export default ContentAbout
