import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    height: 420px;
  }
  /* background-color: red; */
`;

export const ContainerSlider = styled.div`
  /* display: flex;
  justify-content: center; */
  width: 80%;
  height: 100%;
  background-color: #bbd9fd;
  border-radius: 20px;
  overflow: visible;
  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }
`;

export const ContainerImage1 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  /* background-color: transparent; */
  border-radius: 20px;
  position: relative;

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '0px'} !important;
   right: ${(props) => props.rights || '-250px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '0px'} !important;
   right: ${(props) => props.rights || '100px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5, .box6 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

  .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage2 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '30px'} !important;
   right: ${(props) => props.rights || '150px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5, .box6 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage3 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '-20px'} !important;
   right: ${(props) => props.rights || '100px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage4 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '50px'} !important;
   right: ${(props) => props.rights || '60px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage5 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;

  /* background-color: transparent;  background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '50px'} !important;
   right: ${(props) => props.rights || '60px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage6 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '50px'} !important;
   right: ${(props) => props.rights || '60px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage7 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: #bbd9fd; */
  border-radius: 20px;
  position: relative;

  .bg,
  .icon {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon {
   position: absolute;

   /* width: ${(props) => props.width || '407px'};
   height: ${(props) => props.height || '367px'}; */
   bottom: ${(props) => props.bots || '0px'} !important;
   right: ${(props) => props.rights || '-150px'} !important;
   top: ${(props) => props.tops };
   left: ${(props) => props.lefts };

  }

  
  .box1,
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 180px;
    height: 118px;
    background-color: white;
    border-radius: 20px;
    border: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 90px;
    }
  }

    .box1 {
    left: -40px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 12%;
    }
  }
  .box2 {
    left: 160px;
    top: 20px;

    @media screen and (max-width: 768px) {
      top: 60px;
      left: 52%;
    }
  }
  .box3 {
    left: 360px;
    top: 20px;

    @media screen and (max-width: 768px) {
      left: 12%;
      top: 170px;
    }
  }
  .box4 {
    left: 70px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 170px;
      left: 52%;
    }
  }
  .box5 {
    left: 270px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 12%;
    }
  }

  .box6 {
    left: 470px;
    top: 160px;
    @media screen and (max-width: 768px) {
      top: 280px;
      left: 52%;
    }
  }
`;

export const ContainerImage8 = styled.div`
  width: 100%;
  height: 300px;
  /* margin: 0 32px; */
  padding: 26px 38px 25px 57px;
  background-color: #42d155;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width : 768px) {
    padding: 20px;

    }

  h1 {
    width: 297px;
    height: 130px;
    margin: 59px 0 60px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    @media screen and (max-width : 768px) {
      text-align: center;
    }
  }

  img {
    @media screen and (max-width : 768px) {
      display: none;
    }
  }
`;

export const Img = styled.div`
.bg {
    width: 496px;
    height: 360px;
  position: relative;   
    z-index: 0;
    bottom: 40px;
    right: -250px;
  }
`;
