import styled from 'styled-components'

export const ContainerPP = styled.div`
/* margin-bottom: 50px; */
  background-color: #edf6ff;
  margin-top: 250px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;

  .arcbg {
  position: absolute;
  width: 110%;
  left: -70px;
  top: -130px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  height: 800px;

    padding: 0;
    .arcbg {
      top: -40px;
      left: -40px;
    }
  }
`;

export const ContainerTitle = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  position: relative;
  top: -50px;
  padding: 0 100px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  height: 200px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  margin: 0px;
  text-align: left;
  gap: 50px;
  display: flex;
  flex-direction:row;
  Link {
    @media only screen and (max-width: 768px) {
      display: flex;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    text-align: left;
  }
`;

export const MarqueeItem = styled.div`
  width: 230px;
  height: 145px;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  max-width: 50%;
  max-height: 50%;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  background-origin: content-box;
  background-clip: content-box; */
  padding: 30px;

  //   horizontal, vertikal
  margin: 0 70px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 0 10px;
  }
`;

export const MarqueeContainer = styled.div`
/* gap: 30px; */
   display: flex;
   margin-top: 10px;
   margin-bottom: 20px;
`
export const Arrow = styled.div`
  &:hover {
    span {
      animation-name: example;
      animation-duration: 1.5s;
      position: relative;
      animation-iteration-count: infinite;
    }
  }

  @keyframes example {
    0% {
      left: 0px;
    }
    /* 25% {
      left: 10px;
    }
    50% {
      left: 30px;
    }
    75% {
      left: 40px;
    } */
    100% {
      opacity: 0.1;
      left: 50px;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(5.48889px, -1.661111px);
    }
    25% {
      transform: translate(6.8px, 7.07778px);
    }
    50% {
      transform: translate(2.8px, 3.07778px);
    }
    75% {
      transform: translate(4.8px, 9.07778px);
    }
    100% {
      transform: translate(1.8px, 5.07778px);
    }
  }

`;