import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  height: auto;
  margin: 100px auto;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  border-radius: 10px;
  /* margin-top: -300px; */
  `;

  export const Button = styled.button`
    width: 250px;
    height: 60px;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 20px;
    background-color: gray;

    :hover {
        background-color: #27328f;
    }
  `
