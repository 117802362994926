import styled from "styled-components";

export const ContentProduk = styled.div`
  /* background-color: lightgray; */
  max-width: 80%;
  margin: 0 auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;

  .noPic {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:10px;
    

    .parts {
      width: 25%;
      text-align: left;
      padding: 20px;

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
      }
    }
  }
 
  .withPic {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
    }

    .pic {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .box {
        min-width: 145px;
        height: 50px;
        border-radius: 15px;
        background-color: #fff;
        display: flex;
  box-shadow: 0 0 5px 5px #edf6ff;

        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;

        img {
          height: 20px;
          margin-top: 15px;
          width: auto;
        }
      }
    }
  }
`;

export const FungsiItem = styled.div`
  width: 260px;
  height: 97px;
  color: #1c278b;
  margin-bottom: 20px;
`;
