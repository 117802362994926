import styled from "styled-components";

export const Logo = styled.img`
  width: 50%;
  //  height: 65.9px;
`;

export const LogoBg = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: url(${(props) => props.ImgBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
`;

export const LogoMerchant = styled.div`
  width: 194px;
  height: 84px;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition};
  grid-area: ${(props) => props.gridarea};
  box-sizing: border-box;
`;
