import React, { useEffect } from "react";
import {
  ButtonCustom,
  ButtonWrap,
} from "../../../components/UI/Button/Button.styled";
import {
  JumbotronDescription,
  JumbotronTitle,
  LeftContent,
} from "../../../components/UI/Jumbotron.styled";
import { TextDesc, TextTitle } from "../../../components/UI/Text.styled";
import { ModelImg } from "../../../components/Layout/PG/HeroPG.styled";
import { LogoBg } from "../../../components/UI/Logo.styled";
import { Link } from "react-router-dom";

import ModelImage from "../../../assets/images/Emoney/pic-header.png";
import LogoMbayar from "../../../assets/images/LogoMbayar.png";
import LogoPlaystore from "../../../assets/images/LogoGooglePlay.png";
import LogoAppstore from "../../../assets/images/LogoAppStore.png";

const JumbotronContentEmoney = () => {
  function detectOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }
  

  return (
    <LeftContent marginTop={"100px"}>
      <JumbotronTitle>
        <LogoBg
          width="100%"
          height="45px"
          display={"flex"}
          justifyContent={"center"}
        >
          <img src={LogoMbayar}></img>
        </LogoBg>

        <TextTitle
          size={"24px"}
          style={{ lineHeight: "2rem" }}
          className="py-5 px-5"
          aligntext="center"
        >
          Menyematkan fitur deposit pada akun konsumen mampu meluaskan
          pengembangan platform bisnis anda
        </TextTitle>
      </JumbotronTitle>
      {/* <ButtonWrap>
               <Link to="../hubungi-sales">
                  <ButtonCustom
                     className="fw-bold"
                     marright="16px"
                     bg="#44d62c"
                     color="#1c278b"
                  >
                     Hubungi Sales
                  </ButtonCustom>
               </Link>
            </ButtonWrap> */}
      <div className="d-flex gap-3 mt-4">
        <a
          href="https://play.google.com/store/search?q=mbayar&c=apps"
          target="_blank"
        >
          <LogoBg ImgBg={LogoPlaystore} width="134px" height="40px" />
        </a>
        <a
          href="https://apps.apple.com/id/app/m-bayar/id1436288939"
          target="_blank"
        >
          <LogoBg ImgBg={LogoAppstore} width="134px" height="40px" />
        </a>
      </div>

      {/* <ModelImg >
            <img src={ModelImage}></img>
         </ModelImg> */}
    </LeftContent>
  );
};

export default JumbotronContentEmoney;
