import React, { useState, useEffect, useRef } from "react";
import FOGS from "vanta/dist/vanta.fog.min";
import {
  Jumbotron,
  JumbotronContainer,
} from "../../../components/UI/Jumbotron.styled";
import BackgroundPG from "../../../assets/images/background_pg.png";
import NavbarBootstrap from "../../../components/UI/NavbarBootstrap";
import JumbotronContentDb from "../Content/JumbotronContentDb";
import NewNavbarHome from "../../../components/UI/NewNavbarHome";

const HeaderDisbursement = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOGS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: "#0b1551",
          midtoneColor: "#44d62c",
          lowlightColor: "#35ad22",
          baseColor: "#1c278b",
          blurFactor: 0.74,
          speed: 3.3,
          zoom: 0.9,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <Jumbotron imageurl={BackgroundPG} ref={myRef}>
      <NewNavbarHome />
      <JumbotronContainer>
        <JumbotronContentDb />
      </JumbotronContainer>
    </Jumbotron>
  );
};

export default HeaderDisbursement;
