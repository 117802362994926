import React from "react";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { ContentCP, LogoCP } from "./ContohPenggunaan.styled";
import { ContainerPG } from "./JangkauPenerimaan.styled";
import Fintech_Logo from "../../../assets/images/Fintech_Logo.png";
import { JK_Logo } from "../Home/JaminanKeamanan.styled";

const ContohPengunaan = () => {
  return (
    <ContainerPG>
      <ContentCP>
        <div className="cp-title mt-4">
          <TextTitle fw="600">
            Contoh Penggunaan Disbursement Untuk Bisnis Anda
          </TextTitle>
        </div>
        <div className="cp-content-container mt-4">
          <div className="check">
            <h3>✔</h3>
            <div className="cp-content mb-4">
              <TextTitle size="18px" fw="600">
                Pembayaran Gaji (Payroll)
              </TextTitle>
              <TextDesc size="16px">
                Memudahkan pembayaran gaji ke berbagai akun bank karyawan Anda.
              </TextDesc>
            </div>
          </div>

          <div className="check">
            <h3>✔</h3>
            <div className="cp-content ">
              <TextTitle size="18px" fw="600">
                Pencairan Dana Pinjaman
              </TextTitle>
              <TextDesc size="16px">
                Distribusikan dana pinjaman anda dengan praktis dan menghemat
                waktu anda
              </TextDesc>
            </div>
          </div>
        </div>
        <div className="cp-content-container mt-4">
          <div className="check">
            <h3>✔</h3>
            <div className="cp-content mb-4">
              <TextTitle size="18px" fw="600">
                Pembayaran Vendor
              </TextTitle>
              <TextDesc size="16px">
                Gunakan pembayaran terjadwal/berkala otomatis yang praktis.
              </TextDesc>
            </div>
          </div>

          <div className="check">
            <h3>✔</h3>
            <div className="cp-content">
              <TextTitle size="18px" fw="600">
                Pengembalian Dana (Refund)
              </TextTitle>
              <TextDesc size="16px">
                Mengembalikan dana kepada penerima secara otomatis.
              </TextDesc>
            </div>
          </div>
        </div>
      </ContentCP>
    </ContainerPG>
  );
};

export default ContohPengunaan;
