import React from 'react'
import ContentFaq from './Faq/Content/ContentFaq'
import HeaderFaq from './Faq/Header/HeaderFaq'
import {Wrapper} from './Faq.styled'
import HeaderPrivacyAbout from './Privacy/Header/HeaderPrivacyAbout'
import { Helmet } from 'react-helmet'

const Faq = () => {
   return (
      <>
      <Helmet>
        <title>E2Pay - FAQ</title>
        <meta name="description" content="E2pay FAQ Page" />
      </Helmet>
         <HeaderFaq className="header" />
         <ContentFaq />
      </>
   )
}

export default Faq
