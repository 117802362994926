import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 100px;
  height: 100%;
  margin: 0;
  // border: solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  /* overflow: hidden !important; */

  .bubble {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 575px;
  }

  .bg1 {
    position: absolute;
    z-index: 1;
    left: -100px;
    top: 550px;
  }

  .bg2 {
    position: absolute;
    z-index: 1;
    left: -300px;
    bottom: -150px;
  }
`;

export const Title1 = styled.div`
  position: relative;
  top: -180px;
  background-color: #fff;
  padding-left: 110px;
  width: 80%;
  height: 175px;
  margin: 0;
  // border: solid red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-radius: 15px;
  z-index: 13;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    display: none;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d62ed;
  }
`;

export const Row1 = styled.div`
  width: 78%;
  height: 200px;
  margin: 0;
  // border: solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 25px;
  margin-top: -200px;
  position: relative;
  padding: 0 100px;
  z-index: 13;
  margin-bottom: 75px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 50px;
  }

  .e2pay {
    width: 52%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 10px;
      margin-bottom: 50px;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: left;
      color: #44525b;
    }
  }

  .razer {
    height: 100%;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 10px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: left;
      color: #44525b;
    }
  }
`;

export const Row2 = styled.div`
  width: 150%;
  overflow: visible !important;
  height: 400px;
  margin: 0;
  background-color: ${(props) => props.bgColor};
  transition: background-color 5s ease-out;

  /* background-color: #39d5cf; */
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  margin-top: 200px;
  /* transition: all 5s; */

  h1 {
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    /* color: #39d5cf; */
    /* color: #39d5cf; */
    position: relative;
    top: -280px;
    transition: all 5s ease-out;

    @media screen and (max-width: 768px) {
      background-color: white;
      font-size: 40px;
      text-align: center;
      top: 0;
    }
  }
  div {
    position: relative;
    top: 110px;
    left: -120px;

     img {
      transition: opacity 1s ease-in-out;
    }

    .cashless {
      position: absolute;
      top: -450px;
      left: 550px;
      width: 127px;
      height: 158px;
    }

    .phone {
      position: absolute;
      top: -450px;
      right: -100px;
      width: 274px;
      height: 289px;
    }

    .bulb2 {
      position: absolute;
      top: -200px;
      left: 500px;
      width: 114px;
      height: 107px;
    }

    .woman2 {
      left: -50px;
    }

    .medal {
      position: absolute;
      top: -340px;
      left: 500px;
      width: 175px;
      height: 250px;
      z-index: 5;
    }

    .thumb {
      position: absolute;
      top: -500px;
      left: 400px;
      z-index: 5;
    }

    .confetti {
      position: absolute;
      top: -260px;
      right: 50px;
      width: 180px;
      height: 177px;
      z-index: 15;
    }

    .megaphone {
      position: absolute;
      top: -520px;
      right: 0px;
      width: 192px;
      height: 184px;
      z-index: 5;
    }

    .bulb {
      position: absolute;
      top: -200px;
      right: 50px;
      width: 104px;
      height: 113px;
      z-index: 5;
    }



    .creditcard {
      position: absolute;
      top: -400px;
      right: -100px;
      width: 192px;
      height: 134px;
      z-index: 5;
    }

    .clipboard {
      position: absolute;
      top: -380px;
      left: 450px;
      width: 235px;
      height: 257px;
      z-index: 5;
    }

    .mug {
      position: absolute;
      top: -520px;
      left: 350px;
      width: 157px;
      height: 173px;
      z-index: 5;
    }

    .jumbotron1 {
      /* display: none; */
      position: relative;
      animation-name: FadeInOut1;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 15s;
      animation-direction: normal;
    }

    .jumbotron2 {
      /* display: none; */
      position: relative;
      animation-name: FadeInOut2;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 15s;
      animation-direction: normal;
    }

    /* .jumbotron3 {
      display: none;
    } */

    .jumbotron3 {
      /* display: none; */
      position: relative;
      animation-name: FadeInOut3;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 15s;
      animation-direction: normal;
    }

    /* @keyframes FadeInOut {
      0% {
        opacity: 1;
      }
      45% {
        opacity: 1;
      }
      55% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes FadeInOut2 {
      0% {
        opacity: 0;
      }
      45% {
        opacity: 0;
      }
      55% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    } */

    @keyframes FadeInOut1 {
      0% {
        opacity: 1;
      }
      30% {
        opacity: 1;
      }
      35% {
        opacity: 0;
      }
      65% {
        opacity: 0;
      }
      70% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes FadeInOut2 {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 0;
      }
      35% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      70% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes FadeInOut3 {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 0;
      }
      35% {
        opacity: 0;
      }
      65% {
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  img {
    /* transform: scale(1); */
    position: absolute;
    bottom: 70px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .imgbot {
    left: -450px;
    bottom: -180px;
  }

  .imgtop {
    left: -450px;
    bottom: -150px;
  }
`;

export const Row3 = styled.div`
  width: 150%;
  overflow: visible !important;
  height: 1500px;
  margin: 0;
  margin-top: -16px;
  background-color: #edf6ff;
  // border: solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  border: none;
  padding: 100px 0;
  margin-bottom: 250px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .transaksi {
    width: 50%;
    height: 400px;
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    bottom: -200px;
    box-shadow: 0 5px 80px 0 rgba(60, 96, 234, 0.18);
    padding: 58px 105px 58px 105px;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 95%;
      padding: 40px 5px;
      bottom: -130px;
      box-shadow: none;
      border: 1px solid lightgray;
    }

    .hand2 {
      width: 250px;
      height: 450px;
      position: absolute;
      left: -300px;
      top: -50px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .hand1 {
      width: 220px;
      height: 318px;
      position: absolute;
      right: -250px;
      top: 20px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .coin {
      position: absolute;
      top: -80px;
      left: -150px;
    }

    h1 {
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #1c278b;
      padding-top: 20px;

      @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 30px;
      }
    }

    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: center;
      color: var(--272-d-3-b-text);

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border: none;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 100px;
    }

    h1 {
      font-size: 34px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: normal;
      text-align: left;
      color: var(--272-d-3-b-text);
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: left;
      color: var(--272-d-3-b-text);
    }

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      div {
        padding: 150px;
        height: 45%;
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }

      img {
        width: 100%;
        height: 55%;
        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      div {
        height: 45%;
        padding: 120px;
        text-align: left;
        gap: 1rem;

        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }

      img {
        width: 100%;
        height: 55%;
      }
    }
  }
`;

export const Row4 = styled.div`
  width: 100%;
  height: 1000px;
  margin: 0;
  background-color: #fff;
  // border: solid red;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  margin-top: 200px;
  padding-top: 100px;

  .phototop {
    width: 100%;
    height: 17%;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    img {
      width: 50%;
      height: 75%;

      @media screen and (max-width: 768px) {
        height: 40%;
        width: 120%;
      }
    }
  }

  .photobot {
    width: 100%;
    height: 17%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 50%;
      height: 75%;

      @media screen and (max-width: 768px) {
        height: 40%;
        width: 120%;
      }
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  .content {
    width: 100%;
    height: 66%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 250px;

    @media screen and (max-width: 768px) {
      padding: 10px;

      img {
        width: 628px;
        height: 526px;
        position: absolute;
        left: 200px;
        top: 275px;
        z-index: 0;

        @media screen and (max-width: 768px) {
          top: 250px;
          left: -50px;
        }
      }

      .content-text {
        width: 630px;
        height: 220px;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 768px) {
          width: 100%;
          top: -120px;
        }

        p {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: var(--272-d-3-b-text);
        }
      }
    }
    h1 {
      font-size: 72px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #39d5cf;
      position: relative;
      top: -80px;
    }
  }
`;

export const Row5 = styled.div`
  width: 150%;
  height: 400px;
  margin: 0;
  background-color: #fff;
  // border: solid red;
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  margin-top: 200px;
  padding: 0 150px;
  margin-bottom: 200px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 10px;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }

  .bubble {
    position: absolute;
    z-index: 2;
    left: -100px;
    top: 150px;
  }

  .certificate {
    width: 120%;
    height: 100%;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 10;
    padding-left: 20px;
    padding-top: 30px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
    .logo {
      height: 30%;
      width: 70%;
      gap: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
      }
    }

    h1 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #27328f;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #44525b;
    }
  }
`;

export const Row6 = styled.div`
  width: 150%;

  height: 600px;
  margin: 0;
  // border: solid red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  padding: 0 150px;

  @media screen and (max-width: 768px) {
    height: 400px;
    padding: 0 120px;
    margin-top: -50px;
  }

  .bgCurve {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      height: 50%;
    }
  }

  .quote {
    padding-top: 150px;
    position: relative;
    width: 70%;
    height: 100%;
    z-index: 2;

    @media screen and (max-width: 768px) {
      height: 25%;
      margin-top: -305px;
    }
    p {
      font-size: 32px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #1c278b;
      position: relative;
      z-index: 3;
    }

    img {
      position: relative;
      top: -250px;
      z-index: 2;
      left: -100px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      p {
        font-size: 20px;
      }
    }
  }
`;

export const Row7 = styled.div`
  width: 150%;
  height: 600px;
  margin: 0;
  background-image: url(${(props) => props.bgImg});

  /* background-color: yellow; */
  // border: solid red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  /* margin-bottom: 25px;
  z-index: 3; */
  position: relative;
  padding-left: 150px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    background-image: none;
    justify-content: center;
    margin-top: -125px;
  }

  .content {
    width: 558px;
    height: 335px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: none;
    border-radius: 20px;
    background-color: #fff;
    padding: 50px;

    @media screen and (max-width: 768px) {
      width: 90%;
      padding-left: 0;
      background-image: none;
      align-items: center;
      box-shadow: 0 5px 80px 0 rgba(60, 96, 234, 0.18);
      padding: 0;
      gap: 20px;
    }

    h1 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #27328f;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
    button {
      width: 292px;
      height: 50px;
      margin: 28.3px 168px 0 0;
      padding: 14px 0 13px;
      border-radius: 10px;
      border: solid 1px #707070;
      background-color: #2d62ed;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      outline: none;
      border: none;

      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }
  }
`;
