import React from 'react'
import { ContainerTerdaftar } from './Terdaftar.styled'
import { TextTitle } from '../../UI/Text.styled'
import { LogoBg } from '../../UI/Logo.styled'
import LogoOJK from '../../../assets/images/OJK.png'
import LogoBI from '../../../assets/images/bank-indonesia.png'
const Terdaftar = () => {
   return (
      <ContainerTerdaftar>
         <TextTitle size={'32px'} aligntext="center" fw={'bold'} fontsm="25px">
            Telah terdaftar dan diawasi oleh
         </TextTitle>
         <div>
            <LogoBg width={'289px'} height={'51px'} ImgBg={LogoBI} />
         </div>
      </ContainerTerdaftar>
   )
}

export default Terdaftar
