import React from 'react'
import { ContainerPG } from './JangkauPenerimaan.styled'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import { ContainerTB, IconTB, ItemTB, ContainerText } from './TujuanBisnis.styled'

import iconChart2 from '../../../assets/images/web_icon_chart2.svg'
import iconDart from '../../../assets/images/web_icon_dart.svg'
import iconArrow from '../../../assets/images/web_icon_arrow.svg'
import flexi from "../../../assets/images/PGIcons/flexi.svg"
import chart2 from "../../../assets/images/PGIcons/chart2.svg"
import target from "../../../assets/images/PGIcons/target.svg"
const LayananBisnis = () => {
   return (
      <ContainerPG>
         <ContainerText>
            <TextDesc size={'18px'} color={'#272d3b'}>
         Layanan E2Pay memungkinkan aplikasi yang anda miliki untuk bertransaksi dan mengumpulkan pembayaran tagihan secara efisien. Sistem yang terintegrasi dengan beberapa penyedia layanan pasca bayar untuk mendapatkan informasi detail terhadap tagihan dengan cepat. Cukup lakukan pembayaran di aplikasi yang anda miliki dan kemudian tagihan kebutuhan pengguna anda sudah terbayar.
         </TextDesc> 
         </ContainerText>
        
         <ContainerTB>
            <ItemTB>
               {/* <IconTB bgImg={chart2} bgSize="20px 20px" /> */}
               <img src={chart2} width="30px" height="30px"></img>
               <TextTitle color={'#27328f'} fw={'bold'} size={'22px'}>
                  Integrasi layanan yang memahami bisnis anda
               </TextTitle>
               <TextDesc>
                  Pilihan teknologi integrasi ke layanan kami yang disesuaikan
                  dengan kebutuhan platform bisnis anda.
               </TextDesc>
            </ItemTB>
            <ItemTB>
               {/* <IconTB bgImg={target} /> */}
               <img src={target} width="30px" height="30px"></img>
               <TextTitle color={'#27328f'} fw={'bold'} size={'22px'}>
                  Efisiensi penting bagi setiap operasional bisnis
               </TextTitle>
               <TextDesc>
                  Laporan transaksi tersedia secara real-time Akses kapanpun ke
                  laporan transaksi terkini guna memastikan keberhasilan
                  transaksi konsumen.
               </TextDesc>
            </ItemTB>
            <ItemTB>
               {/* <IconTB bgImg={flexi} /> */}
               <img src={flexi} width="30px" height="30px"></img>

               <TextTitle color={'#27328f'} fw={'bold'} size={'22px'}>
                  Terbuka bagi penyedia layanan apapun
               </TextTitle>
               <TextDesc>
                  Anda merupakan penyedia layanan yang ingin menerima pembayaran
                  konsumen dalam bentuk pra-bayar ataupun pasca-bayar?
                  Komunikasikan dengan kami
               </TextDesc>
            </ItemTB>
            <div style={{ width: '460px' }}></div>
         </ContainerTB>
      </ContainerPG>
   )
}

export default LayananBisnis
