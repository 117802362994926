import React from "react";
import ContentPrivacy from "./Privacy/Content/ContentPrivacy";
import HeaderPrivacyAbout from "./Privacy/Header/HeaderPrivacyAbout";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>E2Pay - Privacy & Policy</title>
        <meta name="description" content="E2pay Payment Gateway Page" />
      </Helmet>
      <HeaderPrivacyAbout
        title="Privasi dalam pembayaran elektronik adalah prioritas kami"
        halaman="Kebijakan Privasi"
      />
      <ContentPrivacy />
    </>
  );
};

export default Privacy;
