import React from 'react'
import { TextDesc, TextTitle } from '../../UI/Text.styled'
import { ContainerInformasi, InformasiContent } from './Informasi.styled'

const SubInformasi = (props) => {
   return (
      <ContainerInformasi bgCol={props.bgCol}>
         <InformasiContent>
            <div className="align-self-start">
               <TextTitle fw={'bold'} color={'#27328f'} size={'22px'}>
                  {props.title}
               </TextTitle>
            </div>
            <TextDesc color={'#44525b'}>{props.narasi1}</TextDesc>
            <TextDesc color={'#44525b'}>{props.narasi2}</TextDesc>
            {props.narasi3 && (
               <TextDesc color={'#44525b'}>{props.narasi3}</TextDesc>
            )}
         </InformasiContent>
      </ContainerInformasi>
   )
}

export default SubInformasi
