import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import translationID from './locales/de/translation.json'

// console.log(translation.translation)
// const resources = {
//   de: {
//     translation: translationID
//   }
// };
i18n
.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      
      de: {
        translations: {
          contact: "Hubungi Kami",
          company: "Perusahaan",
          pg: "Terima Pembayaran",
          db: "Transfer Dana",
          bg: "Tagihan & Voucher",
          emoney: "Uang Elektronik",
          supports: "Bantuan",
          about: "Tentang Kami",
          why: "Mengapa Kami",
          careers: "Karir",
          pgid: "Payment Channels (Indonesia)",
          pgasean: "Payment Channels (ASEAN)",
          localPayout: "Transfer Dana Domestik",
          remittance: "Terima Dana dari Luar Negeri",
          billPayments: "Bayar Tagihan",
          voucherPurchase: "Beli Voucher",
          b2b: "B2B Account Linkage",
          mbayarapp: "M-Bayar App",
          faq: "FAQ",
          listPrice: "Biaya",
          api: "Dokumentasi & API",
          apply: "Lamar ",
          responsibilities: "Tanggung Jawab",
          requirements: "Syarat",
        },
      },
      en: {
        translations: {
          apply: "Apply ",
          responsibilities: "Responsibilities",
          requirements: "Requirements",
          contact: "Contact Us ",
          company: "Company",
          pg: "Accept Payment",
          db: "Payout Service",
          bg: "Bills & Vouchers",
          emoney: "E-money",
          supports: "Supports",
          about: "About Us",
          why: "Why Us",
          careers: "Careers",
          pgid: "Payment Channels (Indonesia)",
          pgasean: "Payment Channels (ASEAN)",
          localPayout: "Local Payout",
          remittance: "Remittance",
          billPayments: "Bill Payments",
          voucherPurchase: "Voucher Purchase",
          b2b: "B2B Account Linkage",
          mbayarapp: "M-Bayar App",
          faq: "FAQ",
          listPrice: "List Price",
          api: "API Information",
        },
      },
    },
    fallbackLng: "de",
    debug: true,
    lng: 'de',

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
