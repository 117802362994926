import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HeadTab, ButtonContact } from "./Tabs/TabsFaq.styled";

import { TextDesc, TextTitle } from "../../UI/Text.styled";

const TabPaneBillerGateway = () => {
  return (
    <Tab.Pane eventKey="biller-gateway-desktop">
      <TextTitle bold>Biller Gateway</TextTitle>
      <a href="/hubungi-sales">
        <ButtonContact>Hubungi Kami</ButtonContact>
      </a>
    </Tab.Pane>
  );
};

export default TabPaneBillerGateway;
