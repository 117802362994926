import React from "react";
import { TextDesc, TextTitle } from "../../UI/Text.styled";
import { ContainerInformasi, InformasiContent } from "./Informasi.styled";

const SubInformasi = (props) => {
  console.log(props.narasi1);
  return (
    <ContainerInformasi bgCol={props.bgCol}>
      <InformasiContent>
        <div className="align-self-start">
          <TextTitle fw={"bold"} color={"#27328f"} size={"22px"}>
            {props.title}
          </TextTitle>
        </div>
        <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
          {props.narasi1}
        </TextDesc>
        {props.narasi2 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi2}
          </TextDesc>
        )}
        {props.narasi3 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi3}
          </TextDesc>
        )}
        {props.narasi4 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi4}
          </TextDesc>
        )}
        {props.narasi5 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi5}
          </TextDesc>
        )}
        {props.narasi6 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi6}
          </TextDesc>
        )}
        {props.narasi7 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi7}
          </TextDesc>
        )}
        {props.narasi8 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi8}
          </TextDesc>
        )}
        {props.narasi9 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi9}
          </TextDesc>
        )}
        {props.narasi10 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi10}
          </TextDesc>
        )}
        {props.narasi11 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi11}
          </TextDesc>
        )}
        {props.narasi12 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi12}
          </TextDesc>
        )}
        {props.narasi13 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi13}
          </TextDesc>
        )}
        {props.narasi14 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi14}
          </TextDesc>
        )}
        {props.narasi15 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi15}
          </TextDesc>
        )}
        {props.narasi16 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi16}
          </TextDesc>
        )}
        {props.narasi17 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi17}
          </TextDesc>
        )}
        {props.narasi18 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi18}
          </TextDesc>
        )}
        {props.narasi19 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi19}
          </TextDesc>
        )}
        {props.narasi20 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi20}
          </TextDesc>
        )}
        {props.narasi21 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi21}
          </TextDesc>
        )}
        {props.narasi22 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi22}
          </TextDesc>
        )}
        {props.narasi23 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi23}
          </TextDesc>
        )}
        {props.narasi24 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi24}
          </TextDesc>
        )}
        {props.narasi25 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi25}
          </TextDesc>
        )}
        {props.narasi26 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi26}
          </TextDesc>
        )}
        {props.narasi27 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi27}
          </TextDesc>
        )}
        {props.narasi28 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi28}
          </TextDesc>
        )}
        {props.narasi29 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi29}
          </TextDesc>
        )}
        {props.narasi30 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi30}
          </TextDesc>
        )}
        {props.narasi131 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi31}
          </TextDesc>
        )}
        {props.narasi32 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi32}
          </TextDesc>
        )}
        {props.narasi33 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi33}
          </TextDesc>
        )}
        {props.narasi34 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi34}
          </TextDesc>
        )}
        {props.narasi35 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi35}
          </TextDesc>
        )}
        {props.narasi36 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi36}
          </TextDesc>
        )}
        {props.narasi37 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi37}
          </TextDesc>
        )}
        {props.narasi38 && (
          <TextDesc color={"#44525b"} style={{ whiteSpace: "pre-line" }}>
            {props.narasi38}
          </TextDesc>
        )}
      </InformasiContent>
    </ContainerInformasi>
  );
};

export default SubInformasi;
