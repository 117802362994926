import React from 'react'
import { Fragment } from 'react'
import Table from 'react-bootstrap/Table'
import { TextDesc } from '../../../UI/Text.styled'
import {
   BtnTableBiaya,
   WrapNamaChannel,
   WrapTable,
   WrapVAT,
} from './TableBiaya.styled'

export const TableBiaya = (props) => {
   return (
      <WrapTable>
         <Table responsive borderless size="sm" style={{ textAlign: 'left', marginBottom:"100px"}}>
            <thead>
               <tr>
                  <th>Nama Channel</th>
                  <th>
                     MDR Bank & <br /> Biaya E2Pay
                  </th>
                  <th>
                     Hari <br /> Settlement
                  </th>
                  <th>V.A.T</th>
               </tr>
            </thead>
            <tbody>
               {props.data.contentTable.map((content, index) => (
                  <Fragment key={index}>
                     <tr className="border-bottom">
                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                           <WrapNamaChannel>
                              <div
                                 style={{
                                    width: '95px',
                                    height: '60px',
                                 }}
                              >
                                 <img src={content.image} alt="Logo" />
                              </div>
                              <div style={{ width: '160px' }}>
                                 <TextDesc fw="700">
                                    {content.namaChannel}
                                 </TextDesc>
                              </div>
                           </WrapNamaChannel>
                        </td>
                        <td style={{textAlign: "left", verticalAlign: "middle"}}>
                           <WrapVAT>
                               <TextDesc>{content.price}</TextDesc>
                           </WrapVAT>
                          
                        </td>
                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                           <WrapVAT>
                               <TextDesc>{content.hari}</TextDesc>
                           </WrapVAT>
                          
                        </td>
                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                           <WrapVAT>
                              <TextDesc>{content.value}</TextDesc>
                              {/* <BtnTableBiaya type="button">
                                 <TextDesc>{content.button}</TextDesc>
                              </BtnTableBiaya> */}
                           </WrapVAT>
                        </td>
                     </tr>
                     {/* ))} */}
                  </Fragment>
               ))}
            </tbody>
         </Table>
      </WrapTable>
   )
}

export const TableBiaya2 = (props) => {
   return (
      <WrapTable>
         <Table responsive borderless size="sm" style={{ textAlign: 'left' }}>
            <thead>
               <tr>
                  <th>Nama Channel</th>
                  <th>
                     MDR Bank & <br /> Biaya E2Pay
                  </th>
                  {/* <th>
                     Hari <br /> Settlement
                  </th> */}
                  <th>V.A.T</th>
               </tr>
            </thead>
            <tbody>
               {props.data.contentTable.map((content, index) => (
                  <Fragment key={index}>
                     <tr className="border-bottom">
                        <td style={{textAlign: "center", verticalAlign: "middle"}}> 
                           <WrapNamaChannel>
                              <div
                                 style={{
                                    width: '95px',
                                    height: '60px',
                                 }}
                              >
                                 <img src={content.image} alt="Logo" />
                              </div>
                              <div style={{ width: '160px' }}>
                                 <TextDesc fw="700">
                                    {content.namaChannel}
                                 </TextDesc>
                              </div>
                           </WrapNamaChannel>
                        </td>
                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                           <WrapVAT>
                               <TextDesc>{content.price}</TextDesc>
                           </WrapVAT>
                          
                        </td>
                        {/* <td>
                           <TextDesc>{content.hari}</TextDesc>
                        </td> */}
                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                           <WrapVAT>
                              <TextDesc>{content.value}</TextDesc>
                              {/* <BtnTableBiaya type="button">
                                 <TextDesc>{content.button}</TextDesc>
                              </BtnTableBiaya> */}
                           </WrapVAT>
                        </td>
                     </tr>
                     {/* ))} */}
                  </Fragment>
               ))}
            </tbody>
         </Table>
      </WrapTable>
   )
}


