import styled from 'styled-components'

export const ContainerLayanan = styled.div`
   margin-top: 170px;
   margin-bottom: 100px;
   background-image: linear-gradient(
      to bottom,
      #edf6ff,
      rgba(255, 255, 255, 0)
   );
   padding: 30px 0;
`

export const LayananItem = styled.div`
   width: 80%;
   height: 50%;
   border: solid;
`
