import { ContainerTopup } from "./Topup.styled";
import { LogoBg } from "../../UI/Logo.styled";
import LogoOJK from "../../../assets/images/OJK.png";
import LogoBI from "../../../assets/images/bank-indonesia.png";
import alfadandan from "../../../assets/images/HalamanBiaya/Biaya-AlfaLawsonDandan.png"
import atmbersama from "../../../assets/images/payment/bersama.png"
import prima from "../../../assets/images/payment/prima.png"
import alto from "../../../assets/images/payment/alto.png"
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { TextTitle } from "../../UI/Text.styled";
import {
  IconChannel,
  LogoChannel,
  MenuImage,
  MenuList,
  MenuListPG,
  MenuPG,
  WrapImage,
} from "./Topup.styled";
import "./Topup.css";
import SpriteLogoChannel from "../../../assets/images/sprite_pg-channel.png";
import SpriteIconPGBlue from "../../../assets/images/Sprite_IconTabs_Blue.svg";
import SpriteIconPGGrey from "../../../assets/images/Sprite_IconTabs_Grey.svg";
import cc from "../../../assets/images/PGIcons/cc.svg";
import ccblue from "../../../assets/images/PGIcons/ccblue.svg";
import ibanking from "../../../assets/images/PGIcons/ibanking.svg";
import ibankingblue from "../../../assets/images/PGIcons/ibankingblue.svg";
import VA from "../../../assets/images/PGIcons/VA.png";
import VAblue from "../../../assets/images/PGIcons/VAblue.png";
import financing from "../../../assets/images/PGIcons/financing.svg";
import financingblue from "../../../assets/images/PGIcons/financingblue.svg";
import counter from "../../../assets/images/PGIcons/counter.svg";
import counterblue from "../../../assets/images/PGIcons/counterblue.svg";
import octoclicks from "../../../assets/images/payment/octoclicks.png";
import muamalat from "../../../assets/images/payment/muamalat.png";
import danamon from "../../../assets/images/payment/danamon.png";
import shopee from "../../../assets/images/payment/shopee.png";
import octomobile from "../../../assets/images/payment/octomobile.png";
import ovo from "../../../assets/images/payment/ovo.png";
import gopay from "../../../assets/images/payment/gopay.svg";
import linkaja from "../../../assets/images/payment/linkaja2.png";
import mbayar from "../../../assets/images/payment/m-bayar.png";
import dana from "../../../assets/images/payment/dana.png";
import visa from "../../../assets/images/payment/visa.png";
import jcb from "../../../assets/images/payment/jcb.png";
import mastercard from "../../../assets/images/payment/mastercard.png";
import flip from "../../../assets/images/Emoney/flip.png"
import bca from "../../../assets/images/payment/bca.png";
import bni from "../../../assets/images/payment/bni.png";
import bri from "../../../assets/images/payment/bri.png";
import cimb from "../../../assets/images/payment/cimb.png";
import mandiri from "../../../assets/images/payment/mandiri.png";
import permata from "../../../assets/images/payment/permata.png";
import indomaret from "../../../assets/images/payment/indomaret.png";
import alfamart from "../../../assets/images/payment/alfamart.png";
import alfamidi from "../../../assets/images/payment/alfamidi.png";
import indodana from "../../../assets/images/payment/indodana.png";
import kredivo from "../../../assets/images/payment/kredivo.png";

const Topup = () => {
  const [ToggleState, setToggleState] = useState(2);
  const refAnchor = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#jangkau-penerimaan") {
      refAnchor.current.scrollIntoView();
    }
  }

  let activeStyle = {
    backgroundColor: "#edf2fe",
    color: "#2d62ed",
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (i) => {
    return ToggleState === i;
  };

  let displayLogo = "";

  switch (ToggleState) {
    case 1:
      displayLogo = (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer ke Bank Lainnya</li>
                    <li>Masukkan Kode Bank Tujuan (022 - CIMB Niaga)</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor Handphone Anda, yaitu 7119 + 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih m-Transfer</li>
                    <li>
                      Pilih Daftar Transfer Antar Bank (Jika Anda belum
                      mendaftarkan Rekening M-Bayar Anda)
                    </li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx,
                      pilih Bank CIMB Niaga lalu klik Send (Jika Anda belum
                      mendaftarkan Rekening M-Bayar Anda)
                    </li>
                    <li>Pilh Menu Transfer Antar Rekening</li>
                    <li>
                      Pilih Rekening Tujuan Anda, dan Masukkan Nominal Top Up
                      lalu klik Send
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer Dana</li>
                    <li>
                      Pilih Daftar Transfer Antar Bank (Jika Anda belum
                      mendaftarkan Rekening M-Bayar Anda)
                    </li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx,
                      pilih Bank CIMB Niaga lalu klik Send (Jika Anda belum
                      mendaftarkan Rekening M-Bayar Anda)
                    </li>
                    <li>Pilih Transfer ke Rek. Bank Lain Dalam Negeri</li>
                    <li>
                      Pilih Nomor Rekening yang sebelumnya sudah Anda daftarkan
                    </li>
                    <li>
                      Pilih layanan transfer ONLINE (Pastikan Anda menggunakan
                      pilihan metode transfer ONLINE untuk Top-up Saldo anda.
                      Jika Anda menggunakan metode transfer RKN/LLG atau RTGS,
                      dana akan dikembalikan oleh bank melalui mekanisme
                      pengembalian dana).
                    </li>
                    <li>Masukkan Nominal Top Up</li>
                    <li>Masukkan KEYBCA Anda, lalu klik Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case 2:
      displayLogo = (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer ke Bank Lainnya</li>
                    <li>Masukkan Kode Bank Tujuan (022 - CIMB Niaga)</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor Handphone Anda, yaitu 7119 + 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer</li>
                    <li>Pilih Ke Bank Lain Dalam Negeri</li>
                    <li>Masukkan Kode Bank Tujuan Bank CIMB Niaga</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu klik lanjut</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer</li>
                    <li>
                      Pilih Transfer Antar Bank Online (Pastikan Anda
                      menggunakan pilihan metode transfer ONLINE untuk Top-up
                      Saldo anda. Jika Anda menggunakan metode transfer RKN/LLG
                      atau RTGS, dana akan dikembalikan oleh bank melalui
                      mekanisme pengembalian dana).
                    </li>
                    <li>Masukkan Kode Bank Tujuan CIMB Niaga</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor Handphone Anda, yaitu 7119 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu klik Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case 3:
      displayLogo = (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Lain &gt; Transfer</li>
                    <li>Pilih Virtual Account</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan Format
                      8570 + Nomor Handphone Anda, yaitu 8570 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer</li>
                    <li>Pilih Virtual Account</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan Format
                      8570 + Nomor Handphone Anda, yaitu 8570 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer</li>
                    <li>Pilih Virtual Account</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan Format
                      8570 + Nomor Handphone Anda, yaitu 8570 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;


    case 5:
      displayLogo = (
        <>
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer ke Bank Lainnya</li>
                    <li>Masukkan Kode Bank Tujuan (022 - CIMB Niaga)</li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor Handphone Anda, yaitu 7119 + 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer</li>
                    <li>
                      Pilih Transfer ke Bank Lain
                    </li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Kode Bank Tujuan CIMB Niaga</li>
                    <li>
                    Masukkan Nominal Top Up lalu klik OK
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transfer Antar Bank</li>
                    <li>
                    Masukkan Kode Bank Tujuan (022 - CIMB Niaga)
                    </li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu klik Kirim</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;

    case 6:
      displayLogo = (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Jenis Transaksi</li>
                    <li>Pilih Transfer</li>
                    <li>
                      Antar Rekening / Rekening CIMB Niaga
                    </li>
                    <li>Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, yaitu 7119 + 08xxxxxxxxxx</li>
                    <li>Masukkan Nominal Top Up</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Login ke Akun Go Mobile Anda</li>
                    <li>
                     Pilih menu Top Up
                    </li>
                    <li>
                     Pilih menu e-Wallet
                    </li>
                    <li>
                     Pilih logo M-Bayar
                    </li>
                    <li>Masukkan nomor ponsel Anda, yaitu 08xxxxxxxxxx</li>
                    <li>
                     Masukkan Nominal Top Up
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Jenis Transaksi</li>
                    <li>
                    Pilih Transfer
                    </li>
                    <li>
                   Antar Rekening / Rekening CIMB Niaga
                    </li>
                    <li>
                      Masukkan Nomor Rekening Virtual Account Anda dengan format
                      7119 + Nomor handphone Anda, yaitu 7119 08xxxxxxxxxx
                    </li>
                    <li>Masukkan Nominal Top Up</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 7:
      displayLogo = (
        <>
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Transaksi Lainnya</li>
                    <li>Pilih Menu Pembayaran</li>
                    <li>Pilih Menu Pembayaran Lainnya</li>
                    <li>
                    Pilih Menu Pilih Menu Virtual Account, kemudian masukkan Nomor Rekening Virtual Account Anda, yaitu 8451 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Lanjutkan</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Pembayaran</li>
                    <li>
                    Pilih Menu Pembayaran Tagihan dan Pilih Virtual Account
                    </li>
                    <li>
                    Masukan Nomor Rekening Virtual Account Anda, yaitu 8451 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Klik Submit</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Menu Pembayaran</li>
                    <li>
                      Pilih Menu Pembayaran Tagihan dan Pilih Virtual Account
                    </li>
                    <li>
                    Masukkan Nomor Rekening Virtual Account Anda, yaitu 8451 xxxx xxxx xxxx
                    </li>
                    <li>Masukkan Nominal Top Up lalu Klik Submit</li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;


    case 8:
      displayLogo = (
        <>
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  ATM
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Pilih Jenis Transaksi</li>
                    <li>Pilih Transfer</li>
                    <li>
                      Antar Bank / Transfer ke Bank Lain
                    </li>
                    <li>Pilih Bank Tujuan Transfer</li>
                    <li>Masukkan Kode Bank Tujuan (022-CIMB Niaga)</li>
                    <li>Masukkan Nomor Rekening Virtual Account Anda dengan Format 7119 + Nomor Handphone Anda, yaitu 7119 081xxxxxxxxx</li>
                    <li>Masukkan Nominal Top Up</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Mobile Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                <ul>
                    <li>Pilih Jenis Transaksi</li>
                    <li>Pilih Transfer</li>
                    <li>
                      Antar Bank / Transfer ke Bank Lain
                    </li>
                    <li>Pilih Bank Tujuan Transfer</li>
                    <li>Masukkan Kode Bank Tujuan (022-CIMB Niaga)</li>
                    <li>Masukkan Nomor Rekening Virtual Account Anda dengan Format 7119 + Nomor Handphone Anda, yaitu 7119 081xxxxxxxxx</li>
                    <li>Masukkan Nominal Top Up</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Internet Banking
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                <ul>
                    <li>Pilih Jenis Transaksi</li>
                    <li>Pilih Transfer</li>
                    <li>
                      Antar Bank / Transfer ke Bank Lain
                    </li>
                    <li>Pilih Bank Tujuan Transfer</li>
                    <li>Masukkan Kode Bank Tujuan (022-CIMB Niaga)</li>
                    <li>Masukkan Nomor Rekening Virtual Account Anda dengan Format 7119 + Nomor Handphone Anda, yaitu 7119 081xxxxxxxxx</li>
                    <li>Masukkan Nominal Top Up</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;

      case 9:
         displayLogo = (
           <>
             <div class="accordion" id="accordionPanelsStayOpenExample">
               <div class="accordion-item">
                 <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                   <button
                     class="accordion-button"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#panelsStayOpen-collapseOne"
                     aria-expanded="true"
                     aria-controls="panelsStayOpen-collapseOne"
                   >
                     Merchant
                   </button>
                 </h2>
                 <div
                   id="panelsStayOpen-collapseOne"
                   class="accordion-collapse collapse show"
                   aria-labelledby="panelsStayOpen-headingOne"
                 >
                   <div class="accordion-body">
                     <ul>
                       <li>Pilih Jenis Transaksi</li>
                       <li>Pilih Transfer</li>
                       <li>
                         Antar Bank / Transfer ke Bank Lain
                       </li>
                       <li>Pilih Bank Tujuan Transfer</li>
                       <li>Masukkan Kode Bank Tujuan (022-CIMB Niaga)</li>
                       <li>Masukkan Nomor Rekening Virtual Account Anda dengan Format 7119 + Nomor Handphone Anda, yaitu 7119 081xxxxxxxxx</li>
                       <li>Masukkan Nominal Top Up</li>
                     </ul>
                   </div>
                 </div>
               </div>
              
             </div>
           </>
         );
         break;

         case 10:
         displayLogo = (
           <>
             <div class="accordion" id="accordionPanelsStayOpenExample">
               <div class="accordion-item">
                 <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                   <button
                     class="accordion-button"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#panelsStayOpen-collapseOne"
                     aria-expanded="true"
                     aria-controls="panelsStayOpen-collapseOne"
                   >
                     Aplikasi Flip
                   </button>
                 </h2>
                 <div
                   id="panelsStayOpen-collapseOne"
                   class="accordion-collapse collapse show"
                   aria-labelledby="panelsStayOpen-headingOne"
                 >
                   <div class="accordion-body">
                     <ul>
                       <li>Buka Aplikasi Flip dan Lakukan Login dengan memasukkan nomor hp an passwor flip</li>
                       <li>Pilih Menu Transfer</li>
                       <li>
                         Pilih Jenis Transfer ke Rekening Bank
                       </li>
                       <li>Pilih Bank CIMB Niaga</li>
                       <li>Masukkan Nomor Rekening M-Bayar Anda dengan menggunakan prefix 7119 + Nomor Handphone Anda, yaitu 7119 081xxxxxxxxx</li>
                       <li>Masukkan Nominal Top Up</li>
                       <li>Tekan Lanjutkan</li>
                       <li>Ikuti panduan transfer ke rekening flip untuk proses top up</li>
                       <li>Bila menapatkan status sukses transaksi maka salo M-Bayar telah bertambah</li>
                     </ul>
                   </div>
                 </div>
               </div>
              
             </div>
           </>
         );
         break;
  }

  useEffect(() => scrollToComponent(), []);

  return (
    <>
      <div
        ref={refAnchor}
        style={{ maxWidth: "1080px" }}
        name="topup"
        className="mx-auto px-5 px-sm-0 mt-5"
      >
        <TextTitle  id="topup" aligntext={"center"} fontsm="25px" fw="600" bold>
            Langkah Mudah Top Up M-Bayar 
         
        </TextTitle>
      </div>
      <MenuPG>
        <MenuListPG>
          <MenuList
            className={getActiveClass(1) && "menu-active"}
            onClick={() => toggleTab(1)}
          >
            <IconChannel>
              <img src={bca}></img>
            </IconChannel>
            {/* <span style={{ width: "135px" }}>BCA</span> */}
          </MenuList>
          <MenuList
            className={getActiveClass(2) && "menu-active"}
            onClick={() => toggleTab(2)}
          >
            <IconChannel>
              <img src={mandiri}></img>
            </IconChannel>
          </MenuList>
          <MenuList
            className={getActiveClass(3) && "menu-active"}
            onClick={() => toggleTab(3)}
          >
            <IconChannel>
              <img src={bni}></img>
            </IconChannel>
          </MenuList>

          <MenuList
            className={getActiveClass(5) && "menu-active"}
            onClick={() => toggleTab(5)}
          >
            <IconChannel>
              <img src={bri}></img>
            </IconChannel>
          </MenuList>
          <MenuList
            className={getActiveClass(6) && "menu-active"}
            onClick={() => toggleTab(6)}
          >
            <IconChannel>
              <img src={cimb}></img>
            </IconChannel>
          </MenuList>
          <MenuList
            className={getActiveClass(7) && "menu-active"}
            onClick={() => toggleTab(7)}
          >
            <IconChannel>
              <img src={permata}></img>
            </IconChannel>
          </MenuList>
          <MenuList
            className={getActiveClass(8) && "menu-active"}
            onClick={() => toggleTab(8)}
          >
            <IconChannel>
              <img src={atmbersama}></img>
              <img src={prima}></img>
              <img src={alto}></img>
            </IconChannel>
          </MenuList>

          <MenuList
            className={getActiveClass(9) && "menu-active"}
            onClick={() => toggleTab(9)}
          >
            <IconChannel>
              <img className="merchant1" src={alfadandan}></img>
            </IconChannel>
          </MenuList>
          <MenuList
            className={getActiveClass(10) && "menu-active"}
            onClick={() => toggleTab(10)}
          >
            <IconChannel>
              <img className="merchant2" src={flip} ></img>
            </IconChannel>
          </MenuList>
        </MenuListPG>
        <MenuImage id="MenuImage">
          <WrapImage>{displayLogo}</WrapImage>
        </MenuImage>
      </MenuPG>
    </>
  );
};

export default Topup
