import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { TextDesc } from '../../UI/Text.styled'
import mailSent from '../../../assets/images/mailsent.gif'
const ModalSukses = (props) => {
   let styleBody = {
      maxWidth: '100%',
      height: '475px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '15px',
   }
   return (
      <>
         <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
         >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={styleBody} className="p-0 m-0">
               {!props.iserror && (
                  <>
                     <div>
                        <TextDesc size="18px" fw="600">
                           Gagal Mengirim!
                        </TextDesc>
                     </div>
                  </>
               )}
               {props.iserror && (
                  <>
                     <div>
                        <img height="162px" src={mailSent} />
                     </div>
                     <div>
                        <TextDesc size="18px" fw="600">
                           Berhasil Terkirim
                        </TextDesc>
                     </div>
                     <div>
                        <TextDesc size="14px" aligntext="center">
                           Terimakasih, sales kami akan segera menghubungi anda
                        </TextDesc>
                     </div>
                  </>
               )}
            </Modal.Body>
         </Modal>
      </>
   )
}

export default ModalSukses
