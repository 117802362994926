import styled from "styled-components";

export const ContainerTP = styled.div`
  width: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Background */
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 768px) {
    height: 350px;
    min-height: max-content;
  }
`;

export const BGImg = styled.div`
  z-index: 0;
  position: absolute;
  top: 4525px;
  /* &:hover {
    animation: shake 2s linear infinite;
  } */

  /* @keyframes shake {
    0% {
      transform: translate(5.48889px, -1.661111px);
    }
    25% {
      transform: translate(6.8px, 7.07778px);
    }
    50% {
      transform: translate(2.8px, 3.07778px);
    }
    75% {
      transform: translate(4.8px, 9.07778px);
    }
    100% {
      transform: translate(1.8px, 5.07778px);
    }
  } */
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const TextBerkembang = styled.div`
  width: 50%;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    width: 95%;
  }
`;

