import React from 'react'
import { Narasi } from './Informasi.styled'
import SubInformasi from './SubInformasi'

const Informasi = () => {
   return (
      <div>
         <Narasi>
          <div>
            <br/><br/>
               <br/>
               E2pay Razer Fintech (“kami”) menyediakan layanan pembayaran guna
               mempermudah para merchant memperoleh pembayaran secara
               elektronik, sehingga merchant dapat memfokuskan diri pada produk
               dan layanannya. Adapun pembayaran elektronik yang kami layani
               dapat berkaitan dengan informasi yang bersifat sensitif dari
               pihak-pihak yang terlibat di dalamnya.
            </div>
            <br/>
            <div>
               Kami memahami pentingnya privasi bagi para merchant, pemegang
               kartu/akun e-money/rekening bank, serta pengunjung situs kami
               (“Anda”). Wujud penghargaan kami terhadap privasi Anda dituangkan
               dalam kebijakan yang menjelaskan bagaimana kami mengumpulkan,
               menggunakan dan melindungi informasi Anda. Dengan menerima
               kebijakan privasi ini, Anda memberikan persetujuan atas
               penggunaan informasi Anda oleh kami.
            </div>
            <br/>
         </Narasi>
         <br/>
         <br/>

         <SubInformasi
            title={'I. Informasi Merchant'}
            bgCol={'#edf6ff'}
            narasi1={
               ' Kami mengumpulkan informasi berbentuk dokumen resmi perusahaan dan/atau personal dari masing-masing merchant dalam proses pendaftaran. Informasi yang kami kumpulkan dapat berupa surat izin usaha (TDP, SIUP, Akte Perusahaan), nomor pajak (NPWP), nama dan nomor telepon yang selanjutnya digunakan sebagai bahan verifikasi kami terhadap bisnis merchant. Apabile diperlukan, kami berhak meminta data tambahan dari pihak yang bersangkutan maupun pihak ketiga yang berwenang dalam validasi data untuk memperoleh keakuratan verifikasi.'
            }
            narasi2={
               'Setelah merchant terhubung dengan layanan E2Pay di lingkungan produksi, kami berhak mengumpulkan informasi mengenai transaksi merchant secara detil. Seluruh informasi transaksi merchant digunakan oleh kami untuk melakukan pemantauan dan analisa terhadap perkembangan layanan kami, pola transaksi, serta pencegahan dan/atau penanganan terhadap penipuan transaksi.      '
            }
         />

         <SubInformasi
            title={'II. Informasi Pemegang Kartu / Akun e-Money'}
            narasi1={
               'Kami mengumpulkan informasi dari para pemegang kartu kredit (pembayaran dengan kartu kredit), kartu debit (pembayaran dengan internet banking), maupun akun e-money yang dapat berupa nomor telepon selular, yang melakukan pembayaran atas pembelian produk atau layanan merchant kami. Informasi yang dimasukkan secara sukarela, digunakan untuk melakukan identifikasi dan verifikasi terhadap pemegang kartu / akun e-money oleh prinsipal maupun issuer sesuai kanal pembayaran yang dipilih. Selain itu, informasi ini juga kami gunakan sebagai dasar melakukan proses penyelesaian masalah dengan merchant.'
            }
            narasi2={
               'Kami juga mengumpulkan informasi berupa email para pemegang kartu / akun e-money untuk digunakan dalam pengiriman notifikasi status pembayaran serta untuk penanganan apabila terjadi penipuan transaksi.'
            }
         />

         <SubInformasi
            title={'III. Informasi Pengunjung Situs E2Pay'}
            bgCol={'#edf6ff'}
            narasi1={
               'Kami mengumpulkan informasi yang bersifat non-personal dari setiap pengunjung situs E2Pay, seperti IP-Address dan/atau jenis browser web yang Anda gunakan, sehingga kami dapat melakukan pemantauan trafik dan analisa dalam rangka meningkatkan kualitas layanan situs kami.'
            }
            narasi2={
               'Kami juga mengumpulkan informasi yang bersifat personal, seperti alamat email, nomor telepon dan sebagainya, yang dimasukkan secara sukarela oleh pengunjung situs kami yang menginginkan informasi lebih lanjut mengenai layanan pembayaran elektronik yang kami tawarkan melalui situs kami. Informasi yang kami kumpulkan tersebut kami gunakan untuk melakukan komunikasi lebih lanjut dengan yang bersangkutan sehingga yang bersangkutan memperoleh informasi dan pelayanan yang sesuai dengan kebutuhannya.'
            }
            narasi3={
               'Informasi sensitif lainnya yang kami kumpulkan dari pengunjung situs yang memasukkannya secara sukarela adalah sebagai bagian dari pendaftaran awal merchant pada layanan pembayaran elektronik kami. Penggunakan atas informasi ini mengacu pada bagian “Informasi Merchant” pada kebijakan privasi kami.'
            }
         />

         <SubInformasi
            title={'IV. Perlindungan Informasi'}
            narasi1={
               'Seluruh informasi yang diperoleh dari Anda, akan dilindungi serta dijaga kerahasiaannya dari akses yang tidak berkepentingan. Selain itu, kami juga berkomitmen untuk memenuhi ketentuan standar PCI-DSS yang berkaitan dengan informasi kartu yang mencakup perlindungan dan pemusnahan informasi tersebut.'
            }
            narasi2={
               'Kami melakukan usaha perlindungan terhadap informasi Anda dengan menerapkan beberapa peralatan pengamanan, di antaranya Secure Socket Layer (SSL) 128/256-bit encryption, Antivirus, Firewall and DDoS Guard.'
            }
            narasi3={
               'Meskipun kami telah memberikan perlindungan terhadap informasi Anda, Anda tetap dianjurkan untuk mengikuti saran praktis berikut. Anda tidak diperkenankan untuk memberikan informasi akun E2Pay Anda kepada pihak lain. Apabila Anda merasa bahwa informasi dalam akun E2Pay Anda telah diketahui oleh pihak lain, Anda diwajibkan untuk segera melakukan penggantian password saat melakukan akses akun E2Pay Anda, serta melaporkan perihal kebocoran tersebut kepada tim Support kami.'
            }
         />

         <SubInformasi
            title={'V. Penyertaan Informasi ke Pihak Ketiga'}
            bgCol={'#edf6ff'}
            narasi1={
               'Kami dapat memberikan informasi tertentu kepada pihak ketiga yang berkepentingan dalam proses validasi transaksi, penyelesaian perselisihan, serta hal-hal lain terkait transaksi, dimana pihak ketiga tersebut juga berkewajiban menjaga dan melindungi kerahasiaan informasi yang kami berikan.'
            }
            narasi2={
               'Dengan tujuan untuk meningkatkan nilai dari para pengguna layanan kami, maka kami dalam melakukan kerjasama dan terhubung dengan situs yang dikelola oleh pihak ketiga. Namun demikian, E2Pay tidak memiliki kendali atas situs pihak ketiga yang mungkin memiliki kebijakan privasi tersendiri terkait pengumpulkan dan penggunaan informasi. Adapun situs pihak ketiga tersebut dihubungkan dengan E2Pay demi meningkatkan kemudahan dan kenyamanan Anda, sehingga segala resiko selama Anda mengakses situs tersebut adalah menjadi tanggung jawab Anda.'
            }
         />
               <br/>
               <br/>

      </div>
   )
}

export default Informasi
