import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import Header from "./Emoney/HeaderDownload/HeaderEmoneyDownload";
import "./EmoneyDownload.css";
import logo from "../assets/images/logo-bg-white.svg";
import mbayar from "../assets/images/mbayar-icon.svg";
import playstore from "../assets/images/LogoGooglePlay.png";
import appstore from "../assets/images/LogoAppStore.png";
import star from "../assets/images/star-icon.svg";

const EmoneyDownload = () => {

  function detectOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }
  
  useEffect(() => {
     let userOS = detectOS();
     console.log("The user's operating system is: " + userOS);
     if (userOS === "iOS") {
       setTimeout(function () {
         window.location.replace("https://apps.apple.com/id/app/m-bayar/id1436288939");
       }, 0);
     } else if (userOS === "Android") {
       setTimeout(function () {
         window.location.replace("https://play.google.com/store/search?q=mbayar&c=apps");
       }, 0);
     } else {
       return;
     }
   }, []);
  
  return (
    <>
      {" "}
      <Helmet>
        <title>E2Pay - Mbayar App Download </title>
        <meta name="description" content="E2pay Ekosistem Page" />
      </Helmet>
      <div className="wrapper">
        <div className="content-wrapper">
          <div className="content">
              <img src={logo} />
          <div className="icon-mbayar">
            <img src={mbayar} />
            <div className="mbayar-title">
              <p>M-Bayar</p>
              <img src={star} />
            </div>
          </div>
          <p id="pilih-sistem">Pilih sistem perangkat anda</p>
          <div className="store">
            <a href="https://play.google.com/store/search?q=mbayar&c=apps">
                 <img src={playstore} />
            </a>
            <a href="https://apps.apple.com/id/app/m-bayar/id1436288939">
                <img src={appstore} />
            </a>
         
          
          </div>
          <p id="copyright">© 2023 E2Pay Global Utama</p>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default EmoneyDownload;
