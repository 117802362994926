import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 400px;
display: flex;
justify-content: center;
align-items: center;
background-color: none;
margin-bottom: 50px;
margin-top: 50px;
`
export const ContainerSlider = styled.div`
  /* display: flex;
  justify-content: center; */
  width: 60%;
  height: 100%;
  /* background-color: #bbd9fd; */
  /* border-radius: 20px; */
  overflow: visible;
  @media screen and (max-width: 768px) {
    margin-top: -30px;
    width: 80%;
  }
`;